import axios from "axios";

const state = {
  PickUpAssigned: [],
  // couriers: [],
  // packageInfo: [],
  // couriersPackageAll: [],
  // allDriverAssign: [],
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
};

const getters = {
  PickUpAssigned: (state) => state.PickUpAssigned,
  // couriers: (state) => state.couriers,
  // couriersPackageAll: (state) => state.couriersPackageAll,
  // packageInfo: (state) => state.packageInfo,
  // allDriverAssign: (state) => state.allDriverAssign,
};

const actions = {
  async fetchPickUpAssigned({ commit }, filter) {
    const PickUpAssigned = await axios.get("package/get-pickup-assigned", {
      params: {
        start: filter.start,
        end: filter.end,
        code:filter.code,
        page:filter.page
      },
    });
    console.log(PickUpAssigned)
    commit("setPickUpAssigned", PickUpAssigned.data.package);
    commit("setPagination", PickUpAssigned.data.pagination);
    return PickUpAssigned;
  },

  async getPickUpAssignedById({ commit }, id) {
    const packageIn = await axios.get("package/view-more/" + id);
    commit("setPackage", packageIn.data.data);
    return packageIn.data;
  },

  async UpdatePickup({ commit }, toSend) {
    const user = await axios.post("package/courier-update", toSend);
    commit("newUser", user.data.data);
    return user;
  },
};

const mutations = {
  setPickUpAssigned: (state, PickUpAssigned) =>
    (state.PickUpAssigned = PickUpAssigned),
  setCouriers: (state, couriers) => (state.couriers = couriers),
  setPackage: (state, packageInfo) => (state.packageInfo = packageInfo),
  setCouriersPackageAll: (state, couriersPackageAll) =>
    (state.couriersPackageAll = couriersPackageAll),
  removePackage: (state, id) => {
    state.PickUpAssigned = state.PickUpAssigned.filter(
      (user) => user.id !== id
    );
  },
  setAllDriverAssign: (state, allDriverAssign) =>
    (state.allDriverAssign = allDriverAssign),
  setPagination: (state, paginate) => (state.paginate = paginate),
  setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
