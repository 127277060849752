import axios from "axios";

const state = {
  username: "",
  vehicle: null,
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
  vehicles: [],
  vehicleForOperation: [],
  vehiclesTypes: [
    {
      id: 1,
      name: "Motor Bikes",
    },
    {
      id: 2,
      name: "Three Wheels",
    },
    {
      id: 3,
      name: "Van",
    },
    {
      id: 4,
      name: "Mini Truck",
    },
    {
      id: 5,
      name: "Lorry",
    },
  ],
  vehicleStatus: [
    {
      id: 1,
      name: "Active",
    },
    {
      id: 0,
      name: "Deactivated",
    },
  ],
};

const getters = {
  allVehicles: (state) => state.vehicles,
  vehicleForOperation: (state) => state.vehicleForOperation,
  editVehicle: (state) => state.vehicle,
  vehiclesPaginate: (state) => state.paginate,
  vehicleStatus: (state) => state.vehicleStatus,
  vehiclesTypes: (state) => state.vehiclesTypes,
};

const actions = {
  async fetchVehicles({ commit }, filter) {
    const allVehicles = await axios.get("vehicle/get-paginate", {
      params: {
        type: filter.type,
        page: filter.page,
        name: filter.search,
      },
    });
    commit("setVehicles", allVehicles.data.data);
    commit("setPagination", allVehicles.data.pagination);
    return allVehicles;
  },

  async getAllVehicles({ commit }) {
    const allVehicles = await axios.get("vehicle");
    commit("setVehicle", allVehicles.data);

    return allVehicles.data;
  },
  async getForOperation({ commit }, date) {
    const allVehicles = await axios.get("vehicle-assign/get-for-operation", {
      params: {
        date: date,
      },
    });
    commit("setVehicleForOperation", allVehicles.data.data);

    return allVehicles.data.data;
  },

  async vehicleRegister(
    { commit },
    { name, branch_id, fuel_type, is_shuttle, reg_number, is_company, type }
  ) {
    const vehicle = await axios.post("vehicle", {
      name,
      branch_id,
      fuel_type,
      is_shuttle,
      reg_number,
      is_company,
      type,
    });
    commit("newVehicle", vehicle.data.data);
    return vehicle;
  },

  async vehicleUpdate(
    { commit },
    { id, name, branch_id, fuel_type, is_shuttle, reg_number, is_company, type }
  ) {
    const vehicle = await axios.put("vehicle/" + id, {
      name,
      branch_id,
      fuel_type,
      is_shuttle,
      reg_number,
      is_company,
      type,
    });
    commit("updateVehicle", vehicle.data.data);
    return vehicle;
  },

  async getVehicleById({ commit }, vehicleId) {
    const vehicle = await axios.get("vehicle/" + vehicleId);
    commit("setVehicle", vehicle.data.data);
    return vehicle.data.data;
  },

  async deleteVehicleById({ commit }, id) {
    await axios.delete(`vehicle/${id}`);

    commit("removeVehicle", id);
  },
};

const mutations = {
  setVehicles: (state, vehicles) => (state.vehicles = vehicles),
  setVehicleForOperation: (state, vehicleForOperation) =>
    (state.vehicleForOperation = vehicleForOperation),
  setVehicle: (state, vehicle) => (state.vehicle = vehicle),
  setPagination: (state, paginate) => (state.paginate = paginate),
  setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
  newVehicle: (state, vehicle) => {
    state.vehicles.unshift(vehicle);
    state.editVehicle = null;
  },
  removeVehicle: (state, id) => {
    state.vehicles = state.vehicles.filter((vehicle) => vehicle.id !== id);
  },
  updateVehicle: (state, updvehicle) => {
    const index = state.vehicles.findIndex(
      (vehicle) => vehicle.id === updvehicle.id
    );

    if (index !== -1) {
      state.vehicles.splice(index, 1, updvehicle);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
