<template>
  <Dashboard selectedMainItem="Operation" selectedSubItem="Admin Packages">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-text-field v-model="filter.code" label="Code" class="ml-5" @input="reload()" type="text" required>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3"><v-menu ref="menu" v-model="menu" :close-on-content-click="false"
            :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="date" class="ml-5" label="Start Date (Pickup)" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="
                $refs.menu.save(date);
              reload();
              ">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3"><v-menu ref="menu2" :items-per-page="15" v-model="menu2"
            :close-on-content-click="false" :return-value.sync="date_end" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="date_end" class="ml-5" label="End Date (Pickup)" prepend-icon="mdi-calendar"
                readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="
                $refs.menu2.save(date_end);
              reload();
              ">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col align="right" cols="12" sm="6" md="2">
          <v-select @change="reload()" v-model="filter.stage_id" :items="stage_types" item-text="name" item-value="id"
            :label="'Stage Type'">
          </v-select> </v-col>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="right" cols="12" sm="12" md="12">
          <v-btn class="ml-auto mr-4" color="primary" :to="{ name: 'admin-packages-add' }">
            Add
            <v-icon right>
              mdi-file-plus
            </v-icon>
          </v-btn>

        </v-col>
      </v-row>

      <v-data-table :headers="headers" :items="AllPackages" item-key="id" class="elevation-1" hide-default-footer :items-per-page="ROWS_PER_PAGE">
        <template v-slot:[`item.active_stage`]="{ item }">
          <span>{{ item.active_stage.stage_name }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn :disabled="item.active_stage.order_index > 6" class="ma-4" color="primary" @click="editPackage(item)"> Update </v-btn>
          <v-btn color="success" @click="viewPackage(item)"> View More </v-btn>
        </template>
      </v-data-table>
      <paginate :paginate="pagination" @paginated="handlePagination" />
    </v-card>
  </Dashboard>
</template>

<script>
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/PaginateAxios";
import axios from "axios";

export default {
  name: "admin-add-package",
  data() {
    return {
      pagination: {
        meta: {
          current_page: 1,
        },
      },
      name: "",
      filter: {
        page: 1,
        stage_id: "",
        code: "",
        start: this.date,
        end: this.date_end,
      },
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date_end: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      isLoading: false,
      menu: false,
      menu2: false,
      dialog: false,
      item: {},
      selected: [],
      stage_types: [],
      AllPackages: [],
    };
  },
  components: {
    Dashboard,
    paginate,
  },

  computed: {
    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Client name",
          align: "start",
          sortable: false,
          value: "client_name",
        },
        {
          text: "Package Type",
          value: "package_type_text",
          sortable: false,
        },
        {
          text: "Pickup City",
          value: "pickup_city_name",
          sortable: false,
        },
        {
          text: "Delivery City",
          value: "delivery_city_name",
          sortable: false,
        },
        {
          text: "Active Stage",
          value: "active_stage.stage.name",
          sortable: false,
        },
        {
          text: "Pickup Date",
          value: "package_date",
          sortable: false,
        },
        {
          text: "Actions",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.getStages();
    this.reload();
  },
  mounted() {
    this.getData();
  },
  methods: {
    handlePagination(page) {
      this.filter.page = page;
      this.getData();
    },
    getStages() {
      axios.get("/get-stages").then((response) => {
        this.stage_types = response.data;
      });
    },
    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    async editPackage(item) {
      this.$router.push({
        name: "edit-admin-packages",
        params: { item: item },
      });
    },
    reload() {
      this.getData();
    },

    clear() {
      this.filter = {
        stage_id: "",
        code: "",
      };
      this.date = "";
      this.date_end = "";
      this.reload();
    },

    async getData() {
      await axios
        .get("package/admin-add-package-get", {
          params: {
            stage_id: this.filter.stage_id,
            start: this.date,
            end: this.date_end,
            code: this.filter.code,
            page:this.filter.page
          },
        })
        .then((response) => {
          this.AllPackages = response.data.package;
          this.pagination =  response?.data?.pagination;
        });
    },
  },
};
</script>
