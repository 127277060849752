<template>
  <Dashboard selectedMainItem="Administration" selectedSubItem="Route">
    <v-row>
      <v-col cols="12" md="6">
        <v-card style="margin: 30px; border: 0" :loading="isLoading">
          <v-card-title> Route Register </v-card-title>
          <v-card-subtitle>
            register Route
            <template>
              <div>
                <v-breadcrumbs class="pl-0 pt-0" :items="pageNavItems">
                </v-breadcrumbs>
              </div>
            </template>
          </v-card-subtitle>
          <form style="margin-top: 40px; border: 0">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-select
                    v-model="branch"
                    :items="allBranches"
                    item-text="name"
                    item-value="id"
                    :error-messages="branchErrors"
                    label="Branch"
                    solo
                    required
                    @input="$v.branch.$touch()"
                    @blur="$v.branch.$touch()"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="name"
                    :error-messages="nameErrors"
                    :counter="100"
                    label="Route Name"
                    placeholder="Placeholder"
                    solo
                    required
                    @input="$v.name.$touch()"
                    @blur="$v.name.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="description"
                    :error-messages="descriptionErrors"
                    :counter="1000"
                    label="Description"
                    solo
                    required
                    @input="$v.description.$touch()"
                    @blur="$v.description.$touch()"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-select
                    v-model="status"
                    :items="RouteStatus"
                    item-text="name"
                    item-value="id"
                    :error-messages="statusErrors"
                    label="Status"
                    placeholder="Placeholder"
                    solo
                    required
                    @input="$v.status.$touch()"
                    @blur="$v.status.$touch()"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-btn
                  class="ma-4"
                  @click="submit"
                  :color="$v.$anyError ? 'gray' : 'primary'"
                  :disabled="btnDisabled"
                >
                  <span class="white--text"> Register </span>
                </v-btn>
                <!-- <v-btn 
          class="mr-4"
          @click="clear">
            clear
          </v-btn> -->
                <v-btn class="ma-4" @click="cancelRegister" :color="'red'">
                  <span class="white--text"> Cancel </span>
                </v-btn>
              </v-row>
            </v-container>
          </form>
        </v-card>
      </v-col>
    </v-row>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar";

export default {
  name: "rote-register",
  mixins: [validationMixin],

  validations: {
    name: { required, maxLength: maxLength(100) },
    description: { required },
    branch: { required },
    status: { required },
  },

  data: () => ({
    name: "",
    description: "",
    branch: "",
    status: 1,
    isLoading: false,
    btnDisable: false,
    pageNavItems: [
      {
        text: "Dashboard",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "dashboard" },
      },
      {
        text: "Route",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "route" },
      },
      {
        text: "Register",
        disabled: true,
        link: true,
        exact: true,
        to: { name: "dashboard" },
      },
    ],
  }),
  created() {
    this.getAllBranches();
  },
  components: {
    Dashboard,
  },
  computed: {
    allBranches: {
      get() {
        return this.$store.getters.allBranchesList;
      },
    },
    RouteStatus: {
      get() {
        return this.$store.getters.vehicleStatus;
      },
    },
    btnDisabled() {
      const visible =
        this.$v.$anyError ||
        this.isLoading ||
        !(this.name && this.description && this.branch);
      return visible;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.required && errors.push("Description is required.");
      return errors;
    },
    branchErrors() {
      const errors = [];
      if (!this.$v.branch.$dirty) return errors;
      !this.$v.branch.required && errors.push("Branch is required.");
      return errors;
    },
    statusErrors() {
      const errors = [];
      if (!this.$v.status.$dirty) return errors;
      !this.$v.status.required && errors.push("Status is required.");
      return errors;
    },
  },

  methods: {
    ...mapActions(["routeRegister", "getAllBranches"]),

    async submit() {
      this.$v.$touch();

      this.btnDisable = true;
      this.isLoading = true;

      await this.routeRegister({
        name: this.name,
        description: this.description,
        branch_id: this.branch,
        status: this.status,
      })
        .then((response) => {
          console.log(response);
          this.$toast.success("Route added successfully!");
          this.clear();
          this.isLoading = false;
          this.cancelRegister();
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
          this.isLoading = false;
          this.btnDisable = false;
        });
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.regNumber = "";
      this.type = "";
      this.status = null;
      this.isCompany = false;
    },
    uppercase() {
      this.regNumber = this.regNumber.toUpperCase();
    },
    cancelRegister() {
      this.$router.push({ name: "route" });
    },
  },
};
</script>
