<template>
  <Dashboard selectedMainItem="Transport" selectedSubItem="Vehicle">
    <template>
      <FilterBar @updateFilterData="setFilterValues" forName="Vehicle" />
    </template>

    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col cols="12" sm="6" md="4"> </v-col>
        <v-col cols="12" sm="6" md="4"> </v-col>
        <v-col align="right" cols="12" sm="6" md="4">
          <v-btn
            class="mr-5"
            color="primary"
            elevation="2"
            :to="{ name: 'vehicle-register' }"
          >
            Register
            <v-icon right> mdi-file-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="allVehicles"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.is_company`]="{ item }">
          <span>{{ item.is_company == 1 ? "Yes" : "No" }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editVehicle(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-pencil </v-icon>
                  Edit
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="setVehicleDelete(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-delete </v-icon>
                  Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template :v-slot="body.append">
          <tr>
            <td></td>
            <td>
              <v-text-field
                v-model="calories"
                type="number"
                label="Less than"
              ></v-text-field>
            </td>
            <td colspan="4"></td>
          </tr>
        </template>
      </v-data-table>
      <paginate store="Vehicle" method="fetchVehicles" :filter="filter" />
      <!-- ******************* delete user dialog ********************** -->

      <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
          <v-card-title class="headline">Vehicle Delete?</v-card-title>
          <v-card-text>Do you want to Delete ?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialogDelete = false"
              >Cancel</v-btn
            >

            <v-btn color="red darken-1" text @click="deleteVehicle"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../../components/Layouts/Leftbar";
import paginate from "../../../components/Common/Paginate";
import FilterBar from "../../../components/Common/FilterBar";

export default {
  name: "all-operation",
  data() {
    return {
      filter: {
        type: "",
        search: "",
        page: 1,
      },
      search: "",
      calories: "",
      deleteId: "",
      dialogDelete: false,
      isLoading: false,
    };
  },
  components: {
    paginate,
    FilterBar,
    Dashboard,
  },
  computed: {
    allVehicles: {
      get() {
        return this.$store.getters.allVehicles;
      },
    },
    vehicleTypes: {
      get() {
        const typeAll = [{ id: "", name: "All Types" }];
        const vehicleArray = this.$store.getters.vehiclesTypes;

        return typeAll.concat(vehicleArray);
      },
    },
    headers() {
      return [
        {
          text: "Branch name",
          align: "start",
          sortable: false,
          value: "branch_name",
        },
        {
          text: "Vehicle name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Register number",
          value: "reg_number",
          sortable: false,
        },
        {
          text: "Company Owned",
          value: "is_company",
          sortable: false,
        },
        {
          text: "Fuel Type",
          value: "fuel_type_text",
          sortable: false,
        },
        {
          text: "Vehicle Type",
          value: "type_text",
          sortable: false,
        },
        {
          text: "Actions",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchVehicles(this.filter);
  },
  methods: {
    ...mapActions(["fetchVehicles", "deleteVehicleById"]),

    async editVehicle(vehicle) {
      await this.$store.dispatch("getVehicleById", vehicle.id);
      this.$router.push({ name: "vehicle-edit" });
    },
    setVehicleDelete(item) {
      this.deleteId = item.id;
      this.dialogDelete = true;
    },
    async deleteVehicle() {
      this.dialogDelete = false;
      await this.deleteVehicleById(this.deleteId)
        .then((response) => {
          console.log(response);
          this.$toast.success("Vehicle deleted successfully!");
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
        });
    },
    setFilterValues(childValues) {
      this.filter.search = childValues.search;
      this.filter.type = childValues.type;

      this.fetchVehicles(this.filter);
    },
  },
};
</script>
