<template>

<Dashboard
  selectedMainItem = "Administration"
  selectedSubItem = "Branch"
>
<v-row>
      <v-col cols="12" md="6">
  <v-card
  style="margin:20px;border: 0;"
  :loading="isLoading"
  >
  <v-card-title>
    Branch Edit
  </v-card-title>
  <v-card-subtitle>
    Edit branch here
    <template>
    <div>
      <v-breadcrumbs
      class="pl-0 pt-0"  
      :items="pageNavItems"></v-breadcrumbs>
    </div>
  </template>
  </v-card-subtitle>
     <form style="margin-top:40px;border: 0;">
        <v-container>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="name"
              :error-messages="nameErrors"
              :counter="20"
              label="Branch Name"
              solo
              required
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="contact"
              :error-messages="contactErrors"
              :counter="20"
              label="Contact no"
              solo
              required
              @input="$v.contact.$touch()"
              @blur="$v.contact.$touch()"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >  
          <v-text-field
              v-model="openTime"
              :error-messages="openTimeErrors"
              :counter="20"
              label="Opening Time"
              solo
              required
              @input="$v.openTime.$touch()"
              @blur="$v.openTime.$touch()"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-textarea
              v-model="address"
              :error-messages="addressErrors"
              :counter="50"
              label="Address"
              solo
              required
              @input="$v.address.$touch()"
              @blur="$v.address.$touch()"
            ></v-textarea>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-model="status"
              :items="activeStatus"
              item-text="name"
              item-value="id"
              :error-messages="statusErrors"
              label="Status"
              solo
              required
              @input="$v.status.$touch()"
              @blur="$v.status.$touch()"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12">
          </v-col>
        </v-row>
        <v-row>
          <v-btn
            class="ma-4"
            @click="submit"
            :color="$v.$anyError ? 'gray' : 'primary'"
            :disabled="btnDisabled"
          >
          <span class="white--text">
            EDIT
          </span>
          </v-btn>
          <!-- <v-btn 
          class="mr-4"
          @click="clear">
            clear
          </v-btn> -->
          <v-btn 
          class="ma-4"
          @click="cancelEdit"
          :color="'red'">
          <span class="white--text">
            Cancel
          </span>
          </v-btn>
        </v-row>
        </v-container>
    </form>
  </v-card>
  </v-col>
  </v-row>
</Dashboard>
</template>

<script>
  import { mapActions } from "vuex";
  import { validationMixin } from 'vuelidate'
  import { required, minLength  } from 'vuelidate/lib/validators'
  import Dashboard from '../../components/Layouts/Leftbar'


  export default {
    name:'branch-edit',
    mixins: [validationMixin],

    validations: {
      name: { required},
      contact: { required, minLength: minLength(10)  },
      openTime: { required },
      address: { required },
    },
    data(){
      return{
        id : '',
        name : '',
        status : '',
        contact : '',
        openTime : '',
        address : '',
        isLoading : false,
        btnDisable : false,
        pageNavItems: [
        {
          text: 'Dashboard',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
        {
          text: 'Branch',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'branch'}
        },
        {
          text: 'Edit',
          disabled: true,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
      ],
      }
    },
    components:{
        Dashboard
    },
    computed: {
    activeStatus:{
      get(){
          return this.$store.getters.vehicleStatus;
      }
    },
    btnDisabled () {
        const visible = this.$v.$anyError || !(this.name && this.contact && this.openTime && this.address );
        return visible
      },
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.required && errors.push('Name is required')
        return errors
      },
      contactErrors () {
        const errors = []
        if (!this.$v.contact.$dirty) return errors
        !this.$v.contact.minLength && errors.push('Contact number at least 10 digits.')
        !this.$v.contact.required && errors.push('Contact number is required.')
        return errors
      },
      openTimeErrors () {
        const errors = []
        if (!this.$v.openTime.$dirty) return errors
        !this.$v.openTime.required && errors.push('Open time is required.')
        return errors
      },
      addressErrors () {
        const errors = []
        if (!this.$v.address.$dirty) return errors
        !this.$v.address.required && errors.push('Address is required.')
        return errors
      },
    },
    created(){
      this.setEditValues();
    },
    methods: {

      ...mapActions(["branchUpdate"]),


      async submit () {

        this.$v.$touch();
        this.btnDisable = true;
        this.isLoading = true;

        await this.branchUpdate({
            id : this.id ,
            name : this.name ,
            status : this.status ,
            phone : this.contact ,
            opening_time : this.openTime ,
            address : this.address ,
        }).then( response =>{

          console.log(response);

          this.$toast.success("Branch updated successfully!");
          this.isLoading = false;
          this.cancelEdit();

        }).catch((e)=>{
          this.$toast.error(e.response.data.message);
          this.isLoading = false;
          
        });
      },
      clear () {
        this.$v.$reset()
        this.name = ''
        this.contact = ''
        this.openTime = ''
        this.address = ''
      },
      uppercase() {
        this.regNumber = this.regNumber.toUpperCase();
    },
    setEditValues(){
      console.log( this.$store.getters.editBranch.id );
      const editBranch =  this.$store.getters.editBranch;
        this.id =  editBranch.id ;
        this.name =  editBranch.name ;
        this.status =  editBranch.status ;
        this.contact =  editBranch.phone ;
        this.openTime =  editBranch.opening_time ;
        this.address =  editBranch.address ;
    },
    cancelEdit(){
      this.$router.push({name:'branch'});
    }
    },
  }
</script>
