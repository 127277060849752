<template>
  <Dashboard selectedMainItem="Human Resource" selectedSubItem="User">
    <v-row>
      <v-col cols="12" md="6">
        <v-card style="margin: 20px; border: 0" :loading="isLoading">
          <v-card-title> User Edit </v-card-title>
          <v-card-subtitle>
            edit user here
            <template>
              <div>
                <v-breadcrumbs
                  class="pl-0 pt-0"
                  :items="pageNavItems"
                ></v-breadcrumbs>
              </div>
            </template>
          </v-card-subtitle>
          <form autocomplete="off" style="margin-top: 40px; border: 0">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="firstName"
                    :error-messages="firstNameErrors"
                    :counter="50"
                    label="First Name"
                    solo
                    required
                    @input="$v.firstName.$touch()"
                    @blur="$v.firstName.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="lastName"
                    :error-messages="lastNameErrors"
                    :counter="50"
                    label="last Name"
                    solo
                    required
                    @input="$v.lastName.$touch()"
                    @blur="$v.lastName.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="email"
                    :error-messages="emailErrors"
                    :counter="100"
                    label="Email"
                    placeholder="Email"
                    solo
                    required
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                    @keyup="uppercase"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="phone"
                    :counter="10"
                    label="Phone Number"
                    placeholder="type phone number"
                    solo
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-select
                    :disabled="isAuthUser"
                    v-model="role"
                    :items="userRoles"
                    item-text="name"
                    item-value="id"
                    :error-messages="roleErrors"
                    label="User Role"
                    placeholder="User Role"
                    solo
                    required
                    @input="$v.role.$touch()"
                    @blur="$v.role.$touch()"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12">
                  <v-select
                    v-model="branch"
                    :items="branches"
                    item-text="name"
                    item-value="id"
                    :error-messages="roleErrors"
                    label="Select branch"
                    placeholder="Select branch"
                    solo
                    required
                  ></v-select>
                </v-col>

                <!-- </v-row>
        <span class="grey--text">Optional</span>
          <v-divider
            class="ma-4"
            inset
          ></v-divider>
        <v-row> -->

                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="userName"
                    :error-messages="userNameErrors"
                    :counter="20"
                    label="User Name"
                    placeholder="User Name"
                    autocomplete="off"
                    solo
                    required
                    @input="$v.userName.$touch()"
                    @blur="$v.userName.$touch()"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="password"
                    label="Password"
                    placeholder="Password"
                    autocomplete="off"
                    solo
                    required
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    :type="showPassword ? 'text' : 'password'"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="confirmPassword"
                    :error-messages="confirmPasswordErrors"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    solo
                    required
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @input="$v.confirmPassword.$touch()"
                    @blur="$v.confirmPassword.$touch()"
                    @click:append="showConfirmPassword = !showConfirmPassword"
                    :type="showConfirmPassword ? 'text' : 'password'"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-select
                    v-model="status"
                    :items="allStatus"
                    item-text="name"
                    item-value="id"
                    :error-messages="statusErrors"
                    label="Status"
                    placeholder="Status"
                    solo
                    required
                    @input="$v.status.$touch()"
                    @blur="$v.status.$touch()"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-btn
                  class="ma-4"
                  @click="submit"
                  :color="$v.$anyError ? 'gray' : 'primary'"
                  :disabled="btnDisabled"
                >
                  <span class="white--text"> Edit </span>
                </v-btn>
                <!-- <v-btn 
          class="mr-4"
          @click="clear">
            clear
          </v-btn> -->
                <v-btn class="ma-4" @click="cancelEdit" :color="'red'">
                  <span class="white--text"> Cancel </span>
                </v-btn>
              </v-row>
            </v-container>
          </form>
        </v-card>
      </v-col>
    </v-row>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength, sameAs, email } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar";
import axios from "axios";

export default {
  name: "UserEdit",
  mixins: [validationMixin],

  validations: {
    firstName: { required, maxLength: maxLength(50) },
    lastName: { required },
    userName: { required },
    email: { email },
    role: { required },
    status: { required },
    confirmPassword: { required, sameAsPassword: sameAs("password") },
  },
  props: ["setVehicleEdit"],
  data() {
    return {
      id: "",
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      role: "",
      branch: null,
      branches: [],
      status: "",
      password: "",
      confirmPassword: "",
      isLoading: false,
      showConfirmPassword: false,
      showPassword: false,
      pageNavItems: [
        {
          text: "Dashboard",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "dashboard" },
        },
        {
          text: "Human Resource",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "user" },
        },
        {
          text: "Edit",
          disabled: true,
          link: true,
          exact: true,
          to: { name: "dashboard" },
        },
      ],
    };
  },
  components: {
    Dashboard,
  },
  computed: {
    isAuthUser() {
      return this.id == this.$store.state.Auth.user.user.id;
    },
    hasBranch() {
      if (this.role == null) return 0;
      const role = this.userRoles.filter((item) => {
        return item.id == this.role;
      })[0];
      return role.has_branch;
    },
    userRoles: {
      get() {
        let array = this.$store.getters.allUserRoles;
        array.pop();
        return array;
      },
    },
    allStatus: {
      get() {
        return this.$store.getters.vehicleStatus;
      },
    },
    btnDisabled() {
      return (
        this.$v.$anyError ||
        !(
          this.firstName &&
          this.lastName &&
          this.userName &&
          this.email &&
          this.role
        ) ||
        (this.hasBranch && this.branch == null) ||
        this.isLoading
      );
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required && errors.push("First Name is required");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required && errors.push("Last Name is required.");
      return errors;
    },
    userNameErrors() {
      const errors = [];
      if (!this.$v.userName.$dirty) return errors;
      !this.$v.userName.required && errors.push("user name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("this email is not valid.");
      return errors;
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.role.$dirty) return errors;
      !this.$v.role.required && errors.push("User Role is required.");
      return errors;
    },
    statusErrors() {
      const errors = [];
      if (!this.$v.status.$dirty) return errors;
      !this.$v.status.required && errors.push("Status is required.");
      return errors;
    },

    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty || !this.password) return errors;
      !this.$v.confirmPassword.sameAsPassword &&
        errors.push("password doesn't matched.");
      return errors;
    },
  },
  created() {
    this.setEditValues();
    this.getBranches();
  },
  methods: {
    ...mapActions(["userUpdate"]),

    async submit() {
      this.$v.$touch();

      this.isLoading = true;
      await axios.put("user/" + this.id,{
        username: this.userName,
        role_id: this.role,
        email: this.email,
        phone: this.phone,
        status: this.status,
        branch: this.branch,
        password: this.password,
        password_confirmation: this.confirmPassword,
        first_name: this.firstName,
        last_name: this.lastName,
      })
        .then(() => {
          this.$toast.success("User updated successfully!");
          this.cancelEdit();
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error(e.response?.data?.message);
        }).finally(()=>{
          this.isLoading = false;
        });
    },
    clear() {
      this.$v.$reset();
      this.userName = "";
      this.role = "";
      this.email = "";
      this.branch = null;
      this.phone = "";
      this.status = "";
      this.password = "";
      this.confirmPassword = "";
      this.firstName = "";
      this.lastName = "";
    },
    uppercase() {
      this.regNumber = this.regNumber.toUpperCase();
    },
    setEditValues() {
      const editUser = this.$store.getters.editUser;
      console.log(editUser);
      this.id = editUser.id;
      this.userName = editUser.username;
      this.role = editUser.role_id;
      this.email = editUser.email;
      this.status = editUser.status;
      this.branch = editUser.branches[0];
      this.firstName = editUser.first_name;
      this.lastName = editUser.last_name;
      this.phone = editUser.phone;
    },
    getBranches() {
      axios.get("branch").then((response) => {
        this.branches = response.data.data;
      });
    },
    cancelEdit() {
      this.$router.push({ name: "user" });
    },
  },
};
</script>
