import axios from "axios";

const state = {
  courierIndividualReport: [],
  attendanceReport: [],
  dailyReport: [],
  staffReport: [],
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
};

const getters = {
  staffReport: (state) => state.staffReport,
  dailyReport: (state) => state.dailyReport,
  attendanceReport: (state) => state.attendanceReport,
  courierIndividualReport: (state) => state.courierIndividualReport,
};

const actions = {
  async fetchDailyReports({ commit }, filter) {
    const report = await axios.get("reports/daily-pickup", {
      params: {
        page: filter.page,
        pickup_branch_id: filter.pickup_branch_id,
        report_start: filter.report_start,
        report_end: filter.report_end,
        code: filter.code,
      },
    });

    commit("setDailyReports", report.data.data);
    commit("setPagination", report.data.pagination);
    return report;
  },
  async fetchReportCourierIndividual({ commit }, filter) {
    const report = await axios.get("reports/courier-individual", {
      params: {
        courier_id: filter.courier_id,
        report_start: filter.report_start,
        report_end: filter.report_end,
        code: filter.code,
        payment_type: filter.payment_type,
        pay_by: filter.pay_by,
        page: filter.page,
      },
    });

    commit("setCourierIndividualReport", report.data.data);
    commit("setPagination", report.data.pagination);
    return report;
  },
  async fetchReportStaff({ commit }, filter) {
    const report = await axios.get("reports/staff", {
      params: {
        name: filter.name,
        role: filter.role,
        page: filter.page,
      },
    });

    commit("setStaffReport", report.data.data);
    commit("setPagination", report.data.pagination);
    return report;
  },
  async fetchAttendanceReport({ commit }, filter) {
    const report = await axios.get("reports/staff-attendance", {
      params: {
        courier_id: filter.courier_id,
        report_start: filter.report_start,
        report_end: filter.report_end,
        page: filter.page,
      },
    });

    commit("setAttendanceReport", report.data.data);
    commit("setPagination", report.data.pagination);
    return report;
  },
};

const mutations = {
  setDailyReports: (state, staffReport) => (state.staffReport = staffReport),
  setStaffReport: (state, staffReport) => (state.staffReport = staffReport),
  setAttendanceReport: (state, attendanceReport) => (state.attendanceReport = attendanceReport),
  setCourierIndividualReport: (state, courierIndividualReport) =>
    (state.courierIndividualReport = courierIndividualReport),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
