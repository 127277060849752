<template>
  <Dashboard selectedMainItem="Transport" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-card-subtitle>
        Vehicle Attendance
        <template>
          <div>
            <v-breadcrumbs
              class="pl-0 pt-0"
              :items="pageNavItems"
            ></v-breadcrumbs>
          </div>
        </template>
      </v-card-subtitle>

      <v-row v-for="(user, index) in users" :key="index">
        <v-col cols="12" sm="6" md="2">
          <span class="ml-5">{{ user.name }}</span
          ><br />
          <span class="role-text ml-5">( {{ user.type_text }} )</span>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-menu
            ref="inDate"
            v-model="user.inDateMenu"
            :close-on-content-click="false"
            :return-value.sync="user.in_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="user.in_date"
                label="In Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="user.in_date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="user.menu = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.inDate[index].save(user.in_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu></v-col
        >
        <v-col cols="12" sm="6" md="2">
          <v-menu
            ref="inTime"
            v-model="user.inTimeMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="user.in_time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="user.in_time"
                label="In Time"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="user.inTimeMenu"
              v-model="user.in_time"
              full-width
              format="24hr"
              @click:minute="$refs.inTime[index].save(user.in_time)"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-menu
            ref="outDate"
            v-model="user.outDateMenu"
            :close-on-content-click="false"
            :return-value.sync="user.out_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="user.out_date"
                label="Out Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="user.out_date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="user.outDateMenu = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.outDate[index].save(user.out_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu></v-col
        >
        <v-col cols="12" sm="6" md="2">
          <v-menu
            ref="outTime"
            v-model="user.outTimeMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="user.out_time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="user.out_time"
                label="Out Time"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="user.outTimeMenu"
              v-model="user.out_time"
              full-width
              format="24hr"
              @click:minute="$refs.outTime[index].save(user.out_time)"
            ></v-time-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="6" md="2">
          <v-btn
            class="ma-4"
            @click="confirmAttendance(user)"
            :color="!user.attended ? 'green' : 'red'"
          >
            <span class="text-white" v-if="user.attended"> Confirm Out </span>
            <span class="text-white" v-else> Confirm In</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import axios from "axios";
export default {
  name: "AttendanceCreate",
  data() {
    return {
      filter: {
        page: 1,
      },
      users: [],
      isLoading: false,
      current_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      current_time: new Date().toLocaleTimeString(),
      pageNavItems: [
        {
          text: "Dashboard",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "dashboard" },
        },
        {
          text: "Vehicle Attendance",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "vehicle-attendance" },
        },
        {
          text: "Add",
          disabled: true,
          link: true,
          exact: true,
          to: { name: "vehicle-attendance-create" },
        },
      ],
    };
  },
  components: {
    Dashboard,
  },

  created() {
    const filter = {
      date: this.attendance_date,
    };
    this.getAllVehicleAttendance(filter);
  },
  methods: {
    ...mapActions(["markVehicle"]),

    getAllVehicleAttendance() {
      axios
        .get(`vehicle-attendance/get-vehicles?date=${this.current_date}`)
        .then((response) => {
          const users = response.data?.data;

          this.users = [];

          users.forEach((item, index) => {
            let obj = {
              index,
              menu: false,
              id: item.id,
              attended: item.in_time != null,
              name: item.reg_number,
              type_text: item.type_text,
              in_time: item.in_time == null ? null : item.in_time,
              in_date: item.in_date == null ? this.current_date : item.in_date,
              out_time: item.out_time == null ? null : item.out_time,
              out_date: item.out_date == null ? null : item.out_date,
              inDateMenu: false,
              inTimeMenu: false,
              outDateMenu: false,
              outTimeMenu: false,
            };
            this.users.push(obj);
          });
        });
    },
    reloadAttendanceVehicles() {
      this.getAllVehicleAttendance();
    },
    async confirmAttendance(item) {
      const attendance = {
        id: item.id,
        in_time: item.in_time,
        out_time: item.out_time,
        in_date: item.in_date,
        out_date: item.out_date,
      };

      await this.markVehicle(attendance)
        .then(() => {
          this.$toast.success("Record updated successfully!");
          this.reloadAttendanceVehicles();
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
        });
    },
  },
};
</script>
<style>
.text-white {
  color: white;
}
</style>