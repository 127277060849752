import Vue from "vue";
import Vuex from "vuex";
import Auth from "./modules/Authentication";
import Vehicle from "./modules/Vehicle";
import Branch from "./modules/Branch";
import Faq from "./modules/Faq";
import User from "./modules/User";
import Attendance from "./modules/Attendance";
import VehicleAttendance from "./modules/VehicleAttendance";
import Location from "./modules/Location";
import Route from "./modules/Route";
import RouteCity from "./modules/RouteCity";
import BranchArea from "./modules/BranchArea";
import Pricing from "./modules/Pricing";
import Fuel from "./modules/Fuel";
import RouteAssign from "./modules/RouteAssign";
import DriverAssign from "./modules/DriverAssign";
import PendingPackages from "./modules/PendingPackages";
import PickUpAssigned from "./modules/PickUpAssigned";
import Collected from "./modules/Collected";
import Sorting from "./modules/Sorting";
import DeliveryAssigned from "./modules/DeliveryAssigned";
import Payment from "./modules/Payment";
import Transfer from "./modules/Transfer";
import Reports from "./modules/Reports";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    Vehicle,
    Branch,
    Faq,
    User,
    Attendance,
    Location,
    Route,
    RouteCity,
    BranchArea,
    Pricing,
    RouteAssign,
    Fuel,
    VehicleAttendance,
    DriverAssign,
    PendingPackages,
    PickUpAssigned,
    Collected,
    Sorting,
    DeliveryAssigned,
    Transfer,
    Payment,
    Reports,
  },
  plugins: [createPersistedState()],
});
