import axios from "axios";

const state = {
  branch: null,
  branches: [],
  allBranches: [],
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
};

const getters = {
  allBranches: (state) => state.branches,
  allBranchesList: (state) => state.allBranches,
  editBranch: (state) => state.branch,
  BranchPaginate: (state) => state.paginate,
};

const actions = {
  async fetchBranches({ commit }, filter) {
    const allBranches = await axios.get("branch/get-paginate", {
      params: {
        name: filter.search,
        page: filter.page,
      },
    });
    commit("setBranches", allBranches.data.data);
    commit("setPagination", allBranches.data.pagination);
    return allBranches;
  },

  async getAllBranches({ commit }) {
    const allBranches = await axios.get("branch");

    const data = allBranches.data.data;
    commit("setAllBranch", data);

    return allBranches;
  },

  async createBranch(
    { commit },
    { name, status, phone, opening_time, address }
  ) {
    const branch = await axios.post("branch", {
      name,
      status,
      phone,
      opening_time,
      address,
    });
    commit("newBranch", branch.data.data);
    return branch;
  },

  async branchUpdate(
    { commit },
    { id, name, status, phone, opening_time, address }
  ) {
    const branch = await axios.put("branch/" + id, {
      name,
      status,
      phone,
      opening_time,
      address,
    });
    commit("updateBranch", branch.data.data);
    return branch;
  },

  async getBranchById({ commit }, branchId) {
    const branch = await axios.get("branch/" + branchId);
    commit("setBranch", branch.data.data);
    return branch.data.data;
  },

  async deleteBranchById({ commit }, id) {
    await axios.delete(`branch/${id}`);

    commit("removeBranch", id);
  },
};

const mutations = {
  setBranches: (state, branches) => (state.branches = branches),
  setAllBranch: (state, branches) => (state.allBranches = branches),
  setBranch: (state, branch) => (state.branch = branch),
  setPagination: (state, paginate) => (state.paginate = paginate),
  setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
  newBranch: (state, branch) => {
    state.branches.unshift(branch);
    state.editVehicle = null;
  },
  removeBranch: (state, id) => {
    state.branches = state.branches.filter((branch) => branch.id !== id);
  },
  updateBranch: (state, upBranch) => {
    const index = state.branches.findIndex(
      (branch) => branch.id === upBranch.id
    );
    if (index !== -1) {
      state.branches.splice(index, 1, upBranch);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
