import { ROWS_PER_PAGE } from "@/common";
import axios from "axios";

const state = {
  pendingPackages: [],
  couriers: [],
  packageInfo: [],
  couriersPackageAll: [],
  // allDriverAssign: [],
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
};

const getters = {
  pendingPackages: (state) => state.pendingPackages,
  couriers: (state) => state.couriers,
  couriersPackageAll: (state) => state.couriersPackageAll,
  packageInfo: (state) => state.packageInfo,
  // allDriverAssign: (state) => state.allDriverAssign,
};

const actions = {
  async fetchPendingPackages({ commit }, filter) {
    const pendingPackages = await axios.get("package/get-pending", {
      params: {
        start: filter.start,
        end: filter.end,
        client_type: filter.client_type,
        package_type: filter.package_type,
        page: filter.page,
        code: filter.code,
        per_page:ROWS_PER_PAGE
      },
    });
    commit("setPendingPackages", pendingPackages.data.package);
    commit("setPagination", pendingPackages.data.pagination);
    return pendingPackages;
  },
  async fetchCouriers({ commit }, filter) {
    const couriers = await axios.get("route-assign/get-couriers-by-date", {
      params: {
        date: filter.date,
        city_id: filter.city_id,
      },
    });
    commit("setCouriers", couriers.data.data);
    return couriers;
  },
  async fetchCouriersPackageAll({ commit }, courierFilter) {
    const couriersPackageAll = await axios.get("courier-package", {
      params: {
        date: courierFilter.date,
        courier_id: courierFilter.courier_id,
      },
    });

    commit("setCouriersPackageAll", couriersPackageAll.data);
    return couriersPackageAll;
  },
  async deletePackage({ commit }, toSend) {

    const reason = toSend.desc;
    const id = toSend.id;
    await axios.put(`package/reject/${id}`, { reason });

    commit("removePackage", id);
  },
  async getPackageById({ commit }, id) {
    const packageIn = await axios.get("package/view-more/" + id);
    commit("setPackage", packageIn.data.data);
    return packageIn.data;
  },

  async markCourierAssign({ commit }, toSend) {
    const vehicle_id = toSend.vehicle_id;
    const courier_id = toSend.courier_id;
    const type = toSend.type;
    const package_id = toSend.package_id;
    const user = await axios.post("courier-package", {
      vehicle_id,
      courier_id,
      package_id,
      type,
    });
    commit("newUser", user.data.data);
    return user;
  },
  async markDelivery({ commit }, toSend) {
    const user = await axios.post("package/mark-delivery", toSend);
    commit("newUser", user.data.data);
    return user;
  },
};

const mutations = {
  setPendingPackages: (state, pendingPackages) =>
    (state.pendingPackages = pendingPackages),
  setCouriers: (state, couriers) => (state.couriers = couriers),
  setPackage: (state, packageInfo) => (state.packageInfo = packageInfo),
  setCouriersPackageAll: (state, couriersPackageAll) =>
    (state.couriersPackageAll = couriersPackageAll),
  removePackage: (state, id) => {
    state.pendingPackages = state.pendingPackages.filter(
      (user) => user.id !== id
    );
  },
  setAllDriverAssign: (state, allDriverAssign) =>
    (state.allDriverAssign = allDriverAssign),
  setPagination: (state, paginate) => (state.paginate = paginate),
  setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
