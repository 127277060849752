<template>
  <Dashboard selectedMainItem="Human Resource" selectedSubItem="Leave">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col cols="12" sm="6" md="2"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="form.date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.date"
                class="ml-5"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="form.date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(form.date);
                  getRecords();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        
        <v-col cols="12" sm="6" md="2">
          <v-select
            :items="users"
            label="User"
            class="ml-5"
            item-text="name"
            item-value="id"
            v-on:change="getRecords()"
            v-model="form.user_id"
          ></v-select>
        </v-col>

        <v-col align="right" cols="6" sm="4" md="2">
          <v-btn
          :disabled="isLoading || form.user_id == null"
            class="ma-4"
            color="primary"
            elevation="2"
            @click="store"
          >
            Save
          </v-btn>
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="tableData"
        item-key="id"
        :items-per-page="pagination.meta.per_page"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="openDeleteDialog(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-delete </v-icon>
                  Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate :paginate="pagination" @paginated="handlePagination" />
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="290">
      <v-card>
        <v-card-title class="headline">Record Delete?</v-card-title>
        <v-card-text>You are going to remove the record</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >

          <v-btn
            color="red darken-1"
            :disabled="isLoading"
            text
            @click="deleteRecord"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Dashboard>
</template>

<script>
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/PaginateAxios";
import axios from "axios";

export default {
  name: "all-operation",
  data() {
    return {
      pagination: {
        meta: {
          current_page: 1,
          per_page: 10,
        },
      },
      filter: {
        search: "",
        page: 1,
      },
      form:{
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
        user_id:null
      },
      tableData: [],
      users: [],
      deleteId: "",
      menu: false,
      isLoading: false,
      dialogDelete: false,
    };
  },
  components: {
    paginate,
    Dashboard,
  },
  computed: {
    headers() {
      return [
        {
          text: "Name",
          value: "name",
          sortable: false,
        },
        {
          text: "Role",
          value: "role_name",
          sortable: false,
        },
        {
          text: "Branch",
          value: "branch_name",
          sortable: false,
        },
        {
          text: "Date",
          value: "date",
          sortable: false,
        },
        {
          text: "Actions",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.getRecords();
    this.getUsers();
  },
  methods: {
    openDeleteDialog(item) {
      this.deleteId = item.id;
      this.dialogDelete = true;
    },
    async deleteRecord() {
      this.btnDisabled = true;
      axios
        .delete("leave/" + this.deleteId)
        .then(() => {
          this.$toast.success("Record deleted");
          this.getRecords();
          this.dialogDelete = false;
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setFilterValues(childValues) {
      this.filter.name = childValues.search;
      this.getRecords();
    },
    handlePagination(page) {
      this.filter.page = page;
      this.getRecords();
    },
    async getUsers(){
      axios.get("attendance/get-users").then((response) => {
        this.users = response.data.data;
        this.users.unshift({id:null,name:"All users"});
      });
    },
    clear(){
      this.form.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.form.user_id = null;
      this.getRecords();

    },
    async getRecords() {
      this.isLoading = true;
      axios
        .get("leave/get-paginate", {
          params: {
            ...this.form,
            page: this.filter.page,
          },
        })
        .then((response) => {
          this.tableData = response.data.data;
          this.pagination = response?.data?.pagination;
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.isLoading = false;
          this.btnDisabled = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async store() {
      this.isLoading = true;
      axios
        .post("leave",this.form)
        .then(() => {
          this.$toast.success('Record saved');
           this.getRecords();
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.btnDisabled = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
