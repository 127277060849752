<template>
  <Dashboard selectedMainItem="Human Resource" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-text-field
            v-model="filter.name"
            label="Name"
            class="ml-5"
            @input="reload()"
            type="text"
            required
          >
          </v-text-field>
        </v-col>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-select
            v-model="role"
            :items="allRoles"
            item-text="name"
            item-value="id"
            label="Select Role"
            placeholder="Select Role"
            text
            required
            @change="reload()"
          ></v-select>
        </v-col>
        <v-col align="left" cols="12" sm="6" md="4">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
          <v-btn   :disabled="exporting" depressed @click="exportExcel"  :color="exporting ? '#C9C9C9':'success'"
            ><v-icon class="mr-2"> mdi-file-excel </v-icon> Export</v-btn
          >
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="respData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.pay`]="{ item }">
          <p>{{ item.pay_by_text }} , {{ item.payment_type_text }}</p>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="success" @click="editPackage(item)"> View More </v-btn>
          <v-menu left bottom>
            <v-list>
              <v-list-item @click="viewPackage(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-eye </v-icon>
                  View More
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate :paginate="pagination" @paginated="handlePagination" />
    </v-card>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/PaginateAxios";
import axios from "axios";

export default {
  name: "PickupAssigned",
  data() {
    return {
      filter: {
        start: this.date,
        end: this.date_end,
        name: null,
        page: 1,
      },
      pagination: {
        meta: {
          current_page: 1,
        },
      },
      isLoading: false,
      date: "",
      date_end: "",
      menu: false,
      menu2: false,
      currier: "",
      item: {},
      role: "",
      respData: [],
      allRoles: [],
      exporting: false,
      package_id: "",
      client_id: "",
    };
  },
  components: {
    Dashboard,
    paginate,
  },
  computed: {
    fuelData: {
      get() {
        return this.$store.getters.PickUpAssigned;
      },
    },
    curriersData: {
      get() {
        return this.$store.getters.curriers;
      },
    },
    headers() {
      return [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Branch",
          align: "start",
          sortable: false,
          value: "branch",
        },
        {
          text: "Role",
          align: "start",
          sortable: false,
          value: "role",
        },
      ];
    },
  },
  created() {
    this.fetchCouriersUser();
  },
  mounted() {
    this.loadBranches();
    this.fetchData();
  },

  methods: {
    ...mapActions(["fetchCouriersUser"]),
    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.package_id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    loadBranches() {
      axios.get("role/get-staff-role").then((response) => {
        this.allRoles = response?.data?.data;
        const newFirstElement = {
          name: "All",
          id: null,
        };
        const newArray = [newFirstElement].concat(this.allRoles);

        for (let obj of newArray) {
          for (let key in obj) {
            if (typeof obj[key] === "string") {
              obj[key] = this.ucFirst(obj[key].toLowerCase().replace("_", " "));
            }
          }
        }

        this.allRoles = newArray;
      });
    },
    handlePagination(page) {
      this.filter.page = page;
      this.fetchData();
    },
    fetchData() {
      axios
        .get("/reports/staff", {
          params: {
            name: this.filter.name,
            role_id: this.role,
            page: this.filter.page,
          },
        })
        .then((response) => {
          const newArray = response?.data?.data;
          for (let obj of newArray) {
            for (let key in obj) {
              if (typeof obj[key] === "string") {
                obj[key] = this.ucFirst(
                  obj[key].toLowerCase().replace("_", " ")
                );
              }
            }
          }
          this.respData = newArray;
          this.pagination = response?.data?.pagination;
        });
    },
    reload() {
      this.fetchData();
    },
    clear() {
      this.filter = {
        code: "",
      };
      this.role = "";
      this.reload();
    },
    exportExcel() {
      let url = "/reports/staff/export?";
      if(this.filter.name != null && this.filter.name != ""){
        url += 'name='+this.filter.name+'&';
      }
      if(this.role != null && this.role != ""){
        url += 'role_id='+this.role+'&';
      }
      
      this.exporting = true;
      this.exportExcelCommon(url,"staff-report.xlsx",()=>{
        this.exporting = false;
      });
    },
  },
};
</script>