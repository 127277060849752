<template>
    <Dashboard selectedMainItem="Administration" selectedSubItem="Route">
      <v-row>
        <v-col cols="12" md="6">
          <v-card style="margin: 30px; border: 0" >
            <v-card-title> Branch Group Edit </v-card-title>
            <v-card-subtitle>
              edit Group
              <template>
                <div>
                 
                  
                </div>
              </template>
            </v-card-subtitle>
            <form style="margin-top: 40px; border: 0">
              <v-container>
                <v-row>
                  
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="name"
                      
                      :counter="100"
                      label="Group Name ( required )"
                      placeholder="Placeholder"
                      solo
                      required
                    
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="rate"
                      type ="number"
                     
                      :counter="1000"
                      label="Rate ( required )"
                      solo
                      required
                     
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="additional"
                      type ="number"
                      :counter="1000"
                      label="Additional ( required )"
                      solo
                      required
                     
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="document"
                      type ="number"
                      :counter="1000"
                      label="Document ( required )"
                      solo
                      required
                     
                    >
                    </v-text-field>
                  </v-col>
  
                  
                </v-row>
                <v-row>
                  <v-btn
                    class="ma-4"
                    @click="submit"
                    :color="$v.$anyError ? 'gray' : 'primary'"
                    
                  >
                    <span class="white--text"> Update </span>
                  </v-btn>
                  <!-- <v-btn 
            class="mr-4"
            @click="clear">
              clear
            </v-btn> -->
                  <v-btn class="ma-4" @click="cancelRegister" :color="'red'">
                    <span class="white--text"> Cancel </span>
                  </v-btn>
                </v-row>
              </v-container>
            </form>
          </v-card>
        </v-col>
      </v-row>
    </Dashboard>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import { validationMixin } from "vuelidate";
  import { required, maxLength } from "vuelidate/lib/validators";
  import Dashboard from '../../../components/Layouts/Leftbar'
  import axios from "axios";


  export default {
    name: "branch-group-edit",
    mixins: [validationMixin],
  
    validations: {
      name: { required, maxLength: maxLength(100) },
      rate: { required }
    },
  
    data: () => ({
      name: "",
      rate: 0,
      id: "",
      additional: null,
      document: null,
      isLoading:false,
    
      pageNavItems: [
        {
          text: "Dashboard",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "dashboard" },
        },
        {
          text: "Route",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "route" },
        },
        {
          text: "Register",
          disabled: true,
          link: true,
          exact: true,
          to: { name: "dashboard" },
        },
      ],
    }),
    created() {
      this.getData();
    },
    components: {
      Dashboard,
    },
  
  
    methods: {
      ...mapActions(["routeRegister", "getAllBranches"]),
  
      async submit() {
       
  
        this.btnDisable = true;
       
        
      await axios
        .put("branch-group/update/"+this.id , {
          name: this.name,
          rate: this.rate,
          document: this.document,
          additional_parcel: this.additional
        })
        .then((response) => {
          console.log(response);
          this.$toast.success("Branch Group Update successfully!");
         
          this.btnDisabled = false;
          this.cancelRegister();
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
         
          this.btnDisabled = false;
        });
  
        
      },
      clear() {
        this.$v.$reset();
        this.name = "";
        this.rate = "";
    
      },
      uppercase() {
        this.regNumber = this.regNumber.toUpperCase();
      },
      cancelRegister() {
        this.$router.push({ name: "branch-group" });
      },
      async getData() {
      const item = this.$route.params?.item;
      this.id = item.id;
      if (item == null) {
        return this.cancelRegister();
      }
      await axios
        .get("branch-group/get-by-id/"+this.id)
        .then((response) => {
          this.name = response.data.data.name;
          this.rate = response.data.data.rate;
          this.document = response.data.data.document;
          this.additional = response.data.data.additional;
        });
    },
    },
  };
  </script>
  