<template>
  <Dashboard selectedMainItem="Finance" selectedSubItem="Payments">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-select
            class="ml-3"
            :items="paymentStatus"
            v-model="filter.payment_status"
            item-text="name"
            item-value="id"
            label="Payment Status"
            required
            v-on:change="reload()"
          ></v-select>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items-per-page="pagination.meta.per_page"
        :items="paymentData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-if="item.total_amount > item.paid_amount" color="info" @click="viewPackage(item)">Add Payment</v-btn>
          <v-chip v-else color="green">
            <span class="text-white">Payment Completed</span>
          </v-chip>
        </template>
        <template v-slot:[`item.branches`]="{ item }">
          {{item.pickup_branch  != null? item.pickup_branch : '-'}} - {{item.delivery_branch != null  ? item.delivery_branch : '-'}}
        </template>
      </v-data-table>
      <paginate :paginate="pagination" @paginated="handlePagination"  />
    </v-card>    
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/PaginateAxios";
import axios from 'axios';

export default {
  name: "PaymentList",
  data() {
    return {
      pagination:{
        meta:{
          current_page:1,
          per_page:10
        }
      },
      filter: {
        payment_status: null,
        page: 1,
      },
      paymentData:[],
      paymentStatus: [
        { name: "All", id: null },
        { name: "Outstanding", id: 1 },
        { name: "Completed", id: 2 },
      ],
      package_type: [
        { name: "Select All", id: 0 },
        { name: "Documents", id: 1 },
        { name: "Parcel", id: 2 },
      ],
      isLoading:false
    };
  },
  components: {
    Dashboard,
    paginate,
  },
  computed: {
    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Client Name",
          align: "start",
          sortable: false,
          value: "client_name",
        },
        {
          text: "Package Type",
          value: "package_type_text",
          sortable: false,
        },
        {
          text: "Payment Type",
          value: "payment_type_text",
          sortable: false,
        },
        {
          text: "Item Price",
          value: "item_price_formatted",
          align: "right",
          sortable: false,
        },
        {
          text: "Total Amount",
          value: "total_amount_formatted",
          align: "right",
          sortable: false,
        },
        {
          text: "Paid Amount",
          value: "paid_amount_formatted",
          align: "right",
          sortable: false,
        },
        {
          text: "Pickup/Delivery Branches",
          value: "branches",
          sortable: false,
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchPayment();
  },
  mounted() {},
  methods: {
    ...mapActions(["deletePackage"]),
    async editPackage(item) {
      this.$router.push({
        name: "delivery-assign",
        params: { item: item, type: 1 },
      });
    },
    viewPackage(item) {
      this.$router.push({
        name: "payment-edit",
        params: { item: item },
      });
    },
    handlePagination(page){
      this.filter.page = page;
      this.fetchPayment();
    },
    fetchPayment() {
    axios.get("/package/get-for-payment", {
      params: {
        payment_status: this.filter.payment_status,
        page:this.filter.page
      },
    }).then((response) => {
         this.paymentData = response?.data?.package;
         this.pagination =  response?.data?.pagination;
        });
  },
    reload() {
      this.fetchPayment();
    },
    toDelete(item) {
      this.dialog = true;
      this.item = item;
    },
    async deleteItem() {
      await this.deletePackage(this.item.id)
        .then((response) => {
          console.log(response);
          this.$toast.success("Item rejected");
          this.dialog = false;
        })
        .catch((e) => {
          
          this.$toast.error(e.response?.data?.message)
          this.dialog = false;
        });
    },
  },
};
</script>