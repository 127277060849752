<template>
  <Dashboard selectedMainItem="Operation" selectedSubItem="Vehicle">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-card-title>Package Assign</v-card-title>

      <v-card-subtitle>
        Package Assign
        <template>
          <div>
            <v-breadcrumbs
              class="pl-0 pt-0"
              :items="pageNavItems"
            ></v-breadcrumbs>
          </div>
        </template>
      </v-card-subtitle>
      <form style="margin-top: 40px; border: 0">
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="to_day"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="to_day"
                    class="ml-5"
                    label="Select Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="to_day" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(to_day)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :items="couriersData"
                v-model="courierId"
                item-text="name"
                item-value="id"
                label="select Couriers"
                :error-messages="typeErrors"
                solo
                v-on:change="directToBank(couriersData)"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                class="ml-5"
                name="name"
                label="Name"
                id="id"
                v-model="selectedItem.name"
                filled
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                disabled
                class="ml-5"
                filled
                v-model="selectedItem.id"
                name="name"
                label="Reg Number"
                id="id"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-btn color="success" class="ma-4" @click="submit">
              <span> Assign </span>
            </v-btn>

            <v-btn class="ma-4" @click="cancelRegister" :color="'red'">
              <span class="white--text"> Cancel </span>
            </v-btn>
          </v-row>
        </v-container>
      </form>
      <v-data-table
        :headers="headers"
        :items="couriersData"
        item-key="id"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.package_type`]="{ item }">
          <span>{{ setPageTypeName(item) }}</span>
        </template></v-data-table
      >
    </v-card>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar.vue";

export default {
  name: "pickup-pending",
  mixins: [validationMixin],

  validations: {
    vehicleId: { required },
    fuel_qty: { required },
  },

  data: () => ({
    type: "",
    status: 1,
    package: "",
    selectedItem: {},
    courierId: "",
    isLoading: false,
    btnDisable: false,
    to_day: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    item: [],
    pageNavItems: [
      {
        text: "Dashboard",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "dashboard" },
      },
      {
        text: "Pending",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "pickup-pending" },
      },
      {
        text: "Add",
        disabled: true,
        link: true,
        exact: true,
        to: { name: "package-pending-assign" },
      },
    ],
  }),
  components: {
    Dashboard,
  },
  created() {
    const filter = {
      date: this.to_day,
      city_id: this.$route.params.item.delivery_city_id,
    };
    this.fetchCouriers(filter);
    const courierFilter = {
      date: this.to_day,
      courier_id: this.courierId,
    };
    this.fetchCouriersPackageAll(courierFilter);
  },
  computed: {
    couriersData: {
      get() {
        return this.$store.getters.couriers;
      },
    },
    packageAll: {
      get() {
        return this.$store.getters.couriersPackageAll;
      },
    },
    // btnDisabled() {
    //   const visible = this.$v.$anyError || !(this.courierId && this.type);
    //   return visible;
    // },
    headers() {
      return [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Date",
          value: "date",
          sortable: false,
        },

        {
          text: "Courier Name",
          value: "vehicle.name",
          sortable: false,
        },
        {
          text: "Vehicle",
          value: "vehicle_reg",
          sortable: false,
        },
        {
          text: "Type",
          value: "vehicle.type",
          sortable: false,
        },
      ];
    },
  },
  mounted() {
    this.package = this.$route.params.item;

    var result = this.couriersData.filter((obj) => {
      return obj.selected === 1;
    });
    this.selectedItem = result[0];
  },
  methods: {
    ...mapActions([
      "fetchCouriers",
      "fetchCouriersPackageAll",
      "markCourierAssign",
    ]),
    async directToBank(bankID) {
      var result = bankID.filter((obj) => {
        return obj.id === this.courierId;
      });
      this.selectedItem = result[0];
    },
    async submit() {
      this.$v.$touch();

      this.btnDisable = true;
      this.isLoading = true;
      const date = this.to_day;
      const courier_id = this.selectedItem.vehicle.user_id;
      const vehicle_id = this.selectedItem.vehicle.id;
      const package_id = this.package.id;
      const type = this.package.package_type;
      const toSend = {
        date,
        package_id,
        vehicle_id,
        type,
        courier_id,
      };

      await this.markCourierAssign(toSend)
        .then(() => {
          this.$toast.success("Record added successfully!");
          this.isLoading = false;
          this.clear();
          this.clear();
        })
        .catch((e) => {
          this.isLoading = false;
          this.$toast.error(e.response.data.message);
        });
    },
    clear() {
      this.$v.$reset();
      this.type = "";
      this.vehicleId = "";
    },
    uppercase() {
      this.regNumber = this.regNumber.toUpperCase();
    },
    cancelRegister() {
      this.$router.push({ name: "fuel-issuing" });
    },
  },
};
</script>
