<template>
<Dashboard
  selectedMainItem = "Administration"
  selectedSubItem = "Route City">

  <v-card
      style="margin:30px;border: 0;"
      :loading="isLoading"
  >
  <v-card-title>
    City Assign
  </v-card-title>
  <v-card-subtitle>
    route city assign
    <template>
    <div>
      <v-breadcrumbs
      class="pl-0 pt-0"  
      :items="pageNavItems">
      </v-breadcrumbs>
    </div>
  </template>
  </v-card-subtitle>

      <v-row
      class="ml-2"
      >
      <v-col
          cols="4"
          sm="6"
          md="4"
          >
            <v-text-field
              class="ma-5"
              v-model="route.name"
              type="text"
              readonly
              solo
            ></v-text-field>
      </v-col>
      <v-col
          cols="6"
          sm="6"
          md="6"
          >
          <v-autocomplete
            class="ma-5"
            v-model="city"
            :items="allCities"
            item-text="hierarchy"
            item-value="id"
            solo
            label="Select City"
          ></v-autocomplete>
      </v-col>
      <v-col
          align="right"
          cols="2"
          sm="6"
          md="2"
          >
          <v-btn
          class="ma-5 mr-15"
          style="height:48px;"
          color="primary"
          elevation="2"
          :disabled="addBtnDisabled"
          @click="addRouteCity"
          >
          ASSIGN
              <v-icon right>
                  mdi-file-plus
              </v-icon>
          </v-btn>
          
      </v-col>
    </v-row>
      <v-text-field
        v-model="filter.search"
        append-icon="mdi-magnify"
        label="Search"
        class="ml-10 mr-10"
        solo
        hide-details
        @keyup="setFilterValues"
      ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="allRouteCities"
      :search="filter.search"
      item-key="id"
      class="elevation-1 ml-10 mr-10" 
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">

          <v-btn 
          class="ma-4"
          @click="setDelete(item)"
          :color="'red'">
          <span class="white--text">
            Delete
          </span>
          </v-btn>
    </template>
    </v-data-table>
        <paginate
        store="RouteCity"
        method="fetchCityRoutes"
        :filter="filter"
        />
      <!-- ******************* delete user dialog ********************** -->
    <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
        <v-card-title class="headline">Delete?</v-card-title>
        <v-card-text>Do you want to remove assigned city ?</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialogDelete = false">Cancel</v-btn>

            <v-btn color="red darken-1" text @click="deleteCity">Delete</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
  </v-card>
</Dashboard>
</template>

<script>
  import { mapActions } from "vuex";
  import Dashboard from '../../../components/Layouts/Leftbar'
  import paginate from '../../../components/Common/Paginate';

  export default {
    name:"route-list",
    data () {
      return {
        filter:{
          route_id:'',
          search:'',
          page:1,
        },
        route: '',
        city: '',
        deleteId:'',
        dialogDelete: false,
        isLoading : false,
        pageNavItems: [
        {
          text: 'Dashboard',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
        {
          text: 'Route',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'route'}
        },
        {
          text: 'City Assign',
          disabled: true,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
      ],
      }
    },
    components:{
      paginate,
      Dashboard,
    },
    computed: {
      allCities:{
          get(){
              return this.$store.getters.getAllCities;
          }
      },
      allRouteCities:{
          get(){
              console.log(this.$store.getters.getAllRouteCities);
              return this.$store.getters.getAllRouteCities;
          }
      },
      addBtnDisabled () {
        return (this.city == '');
      },
      headers () {
        return [
          {
            text: 'City name',
            align: 'start',
            sortable: false,
            value: 'location.name',
          },
          { 
            text: 'Action',  
            align: 'end', 
            value: 'actions',
            sortable: false 
          },
        ]
      },
    },
    created() {
            this.getAllCities(this.filter);
      this.route = this.$store.getters.setRouteCity;
      this.filter.route_id = this.$store.getters.setRouteCity.id;
    },
    mounted(){
      this.fetchCityRoutes(this.filter);
    },
    methods: {
      ...mapActions(["fetchCityRoutes","deleteRouteCityById","getAllCities","routeCityCreate"]),

      async addRouteCity(){

        await this.routeCityCreate( { 
            route_id: this.route.id,
            location_hierarchy_id : this.city,

        }).then( response =>{
          console.log(response);
          this.city = '';
          this.$toast.success("City assigned successfully!");
        }).catch((e)=>{
          this.$toast.error(e.response.data.message);
        });
      },

      setDelete(item){
        this.deleteId = item.id;
        this.dialogDelete = true;
      },
      async deleteCity(){
          this.dialogDelete = false;
          await this.deleteRouteCityById(this.deleteId).then( response =>{
          console.log(response);
           this.fetchCityRoutes(this.filter);
          this.$toast.success("Assigned city removed successfully!");
        }).catch((e)=>{
          
          this.$toast.error(e.response.data.message);
        });
      },
      setFilterValues(){
        this.fetchCityRoutes(this.filter);
      },
      
    },
  }
</script>