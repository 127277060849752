<template>
  <Dashboard selectedMainItem="Transfer Operation" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-text-field
            class="ml-5"
            v-model="filter.code"
            label="Code"
            @input="reload()"
            type="text"
            required
          >
          </v-text-field>
        </v-col>
        <v-col align="right" cols="12" sm="6" md="8"></v-col>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="pendingData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
      <template v-slot:[`item.dimensions`]="{ item }">
          <span>{{item.height}} cm X {{item.width}} cm X {{item.length}} cm : {{item.weight}} kg</span>
        </template>


        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            color="success"
            @click="
              dialog = true;
              loadDriverCurrier(item);
            "
            >Assign Transfer</v-btn
          >
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="viewPackage(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-eye </v-icon>
                  View More
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate store="User" method="fetchPendingTransfer" :filter="filter" />
    </v-card>
    <v-dialog v-model="dialog" max-width="1490">
      <v-card>
        <v-data-table
          :headers="headersTransfer"
          :items="transData"
          item-key="name"
          class="elevation-1"
          hide-default-footer
        >
       
          <template v-slot:[`item.branches`]="{ item }">
            {{item.from_branch_name}} -> {{item.to_branch_name}}
          </template>
          <template v-slot:[`item.vehicle`]="{ item }">
            {{item.reg_number}} - {{item.vehicle_type}}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{item.status_text}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="success" @click="selectTrans(item)">Select</v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/Paginate";
import axios from "axios";

export default {
  name: "PendingTransfer",
  data() {
    return {
      filter: {
        code: "",
      },
      deleteId: "",
      dialogDelete: false,
      isLoading: false,
      date: "",
      date_end: "",
      package: "",
      menu: false,
      menu2: false,
      transData: [],
      dialog: false,
      item: {},
    };
  },
  components: {
    paginate,
    Dashboard,
  },
  computed: {
    pendingData: {
      get() {
        return this.$store.getters.pending;
      },
    },
    transferData: {
      get() {
        return this.$store.getters.transfer;
      },
    },
    headersTransfer() {
      return [
        {
          text: "From / To",
          align: "start",
          sortable: false,
          value: "branches",
        },
        {
          text: "Driver Name",
          align: "start",
          sortable: false,
          value: "driver_name",
        },
        {
          text: "Courier Name",
          align: "start",
          sortable: false,
          value: "courier_name",
        },
        {
          text: "Vehicle",
          value: "vehicle",
          sortable: false,
        },
        {
          text: "Package Count",
          value: "package_count",
          sortable: false,
        },
        {
          text: "Remaining Count",
          value: "remaining_count",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
    headers() {
      return [
      {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
      {
          text: "Dimensions",
          align: "start",
          sortable: false,
          value: "dimensions",
        },
        {
          text: "Delivery Branch",
          align: "start",
          sortable: false,
          value: "delivery_branch",
        },
        {
          text: "Delivery Address",
          align: "start",
          sortable: false,
          value: "delivery_address",
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchPendingTransfer(this.filter);
  },

  methods: {
    ...mapActions(["fetchPendingTransfer", "transferById"]),

    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    reload() {
      const filter = {
        code: this.filter.code,
        page: 1,
      };
      this.fetchPendingTransfer(filter);
    },

    clear() {
      this.filter = {
        code: "",
      };
    },

    async selectTrans(item) {
      const transfer_id = item.id;
      const package_id = this.package.id;
      const status = 1;
      console.log(transfer_id, package_id);
      const items = {
        transfer_id,
        package_id,
        status,
      };
      await this.transferById(items)
        .then((response) => {
          console.log(response);
          this.$toast.success("Assigned successfully!");
          this.fetchPendingTransfer(this.filter);
          this.dialog = false;
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.dialog = false;
        });
    },
    loadDriverCurrier(item) {
      this.package = item;
      axios.get("transfer?available=1").then((response) => {
        this.transData = response.data.data;
      });
    },
  },
};
</script>