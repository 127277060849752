<template>
  <Dashboard
    selectedMainItem="Return Operation"
    selectedSubItem="Return Pending"
  >
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="3">
          <v-text-field
            v-model="filter.code"
            class="ml-5"
            label="Code"
            @change="reload()"
            type="text"
            required
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                class="ml-5"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                class="ml-5"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col align="right" cols="12" sm="6" md="2">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="tableData"
        item-key="id"
        class="elevation-1"
        hide-default-footer
        :items-per-page="ROWS_PER_PAGE"
      >
        <template v-slot:[`item.code`]="{ item }">
          <CodeColumn
            :code="item.code"
            :stage_return="item.active_stage.recurring"
            :sender_return="item.returned"
          />
        </template>

        <template v-slot:[`item.same_day`]="{ item }">
          <span v-if="item.same_day" class="text-red">Same Day</span>
          <span v-else>Next Day</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn class="ma-4" color="success" @click="markCompleted(item)">
            Confirm
          </v-btn>
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="viewPackage(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-eye </v-icon>
                  View More
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate store="User" method="fetchAllAssigning" :filter="filter" />
    </v-card>
    <!-- ******************* modal ********************** -->
    <v-dialog v-model="modal_view" max-width="290">
      <v-card>
        <v-card-title class="headline">Move Package?</v-card-title>
        <v-card-text
          >You are going to move this package to
          <b>SORTING STAGE</b></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="
              modal_view = false;
              modal_id = null;
            "
            >Cancel</v-btn
          >

          <v-btn color="green darken-1" text @click="modalConfirm">Move</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Dashboard>
</template>

<script>
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/Paginate";
import axios from "axios";
import CodeColumn from "@/components/Common/CodeColumn/Index";

export default {
  name: "returnPending",
  data() {
    return {
      name: "",
      filter: {
        page: 1,
        code: "",
      },
      modal_id: null,
      modal_view: false,
      isLoading: false,
      menu: false,
      menu2: false,
      date: "",
      date_end: "",
      dialog: false,
      item: {},
      selected: [],
      tableData: [],
    };
  },
  components: {
    Dashboard,
    paginate,
    CodeColumn,
  },
  computed: {
    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Package Type",
          value: "package_type_text",
          sortable: false,
        },
        {
          text: "Pickup Date",
          value: "package_date",
          sortable: false,
        },
        {
          text: "Delivery Type",
          value: "same_day",
          sortable: false,
        },
        {
          text: "Delivery City",
          value: "delivery_city_name",
          sortable: false,
        },
        {
          text: "Delivery Address",
          align: "start",
          sortable: false,
          value: "delivery_address",
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    async editPackage(item) {
      this.$router.push({
        name: "delivery-assign",
        params: { item: item, type: 3 },
      });
    },
    clear() {
      this.filter = {
        code: "",
      };
      this.date = "";
      this.date_end = "";
      this.reload();
    },
    reload() {
      this.getData();
    },
    async getData() {
      await axios
        .get("/package/return-pending", {
          params: {
            start: this.date,
            end: this.date_end,
            code: this.filter.code,
            page: this.filter.page,
            per_page: this.ROWS_PER_PAGE,
          },
        })
        .then((response) => {
          this.tableData = response.data.package;
          this.pagination = response?.data?.pagination;
        });
    },
    markCompleted(item) {
      this.modal_id = item.id;
      this.modal_view = true;
    },
    modalConfirm() {
      axios
        .post("package/return/move-to-sort", {
          package_id: this.modal_id,
        })
        .then(() => {
          this.modal_view = false;
          this.$toast.success("Package moved successfully!");
          this.reload();
        });
    },
  },
};
</script>
