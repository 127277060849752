import axios from "axios";

const state = {
  routeAssign: [],
  AllRouteAssign: [],
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
};

const getters = {
  routeAssign: (state) => state.routeAssign,
  AllRouteAssign: (state) => state.AllRouteAssign,
};

const actions = {
  async fetchRouteAssign({ commit }, filter) {
    const routeAssign = await axios.get("route-assign/get-paginate", {
      
      params: {
        route_id: filter.route_id,
        start: filter.start,
        end: filter.end,
        code: filter.code,
        status:1,
        page:filter.page
      },
    });
    console.log(routeAssign.data);
    commit("setRouteAssign", routeAssign.data.data);
    commit("setPagination", routeAssign.data.pagination);
    return routeAssign;
  },

  async getAllRouteAssign({ commit },params = {}) {
    const routeAssign = await axios.get("route-assign",{params});
    commit("setAllRouteAssign", routeAssign.data.data);
    return routeAssign.data;
  },

  async vehicleAssign({ commit }, { user_id, date, route_id }) {
    const user = await axios.post("route-assign", {
      user_id,
      date,
      route_id,
    });

    commit("newUser", user.data.data);
    return user;
  },
};

const mutations = {
  setRouteAssign: (state, routeAssign) => (state.routeAssign = routeAssign),
  setAllRouteAssign: (state, AllRouteAssign) =>
    (state.AllRouteAssign = AllRouteAssign),
  setPagination: (state, paginate) => (state.paginate = paginate),
  setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
