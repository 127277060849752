<template>
  <Dashboard selectedMainItem="Delivery Operation" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="3">
          <v-text-field
            v-model="filter.code"
            class="ml-5"
            label="Code"
            @change="reload()"
            type="text"
            required
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                class="ml-5"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                class="ml-5"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(date_end);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col align="right" cols="12" sm="6" md="2">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="AllAssigning"
        item-key="id"
        class="elevation-1"
        hide-default-footer
        :items-per-page="ROWS_PER_PAGE"
      >
        <template v-slot:[`item.code`]="{ item }">
          <CodeColumn
            :code="item.code"
            :stage_return="item.active_stage.recurring"
            :sender_return="item.returned"
          />
        </template>

        <template v-slot:[`item.same_day`]="{ item }">
          <span v-if="item.same_day" class="text-red">Same Day</span>
          <span v-else>Next Day</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="green" dark @click="editPackage(item)">
            Assign Courier
          </v-btn>
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="viewPackage(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-eye </v-icon>
                  View More
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-list>
              <v-list-item v-if="isPaymentDone(item)">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title style="opacity: 0.5;" :disabled="true" v-bind="attrs" v-on="on">
                      <v-icon :disabled="true" class="mr-3"> mdi-keyboard-return </v-icon>
                      Return
                    </v-list-item-title>
                  </template>
                  <span
                    >Sender return option is unavailable after payment is done.</span
                  >
                </v-tooltip>
              </v-list-item>
              <v-list-item v-else @click="toReturn(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-keyboard-return </v-icon>
                  Return
                </v-list-item-title>
              </v-list-item>
              
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate store="User" method="fetchAllAssigning" :filter="filter" />
    </v-card>
    <v-dialog v-model="dialog_reject" max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          You are going to return this package back to sender.What is the
          reason?
        </v-card-title>
        <v-textarea
          v-model="description"
          label="Reason"
          class="ma-5"
        ></v-textarea>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error darken-1" text @click="dialog_reject = false">
            cancel
          </v-btn>

          <v-btn color="green" text @click="returnItem()"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/Paginate";
import axios from "axios";
import CodeColumn from "@/components/Common/CodeColumn/Index";

export default {
  name: "all-operation",
  data() {
    return {
      name: "",
      filter: {
        page: 1,
        code: "",
      },
      description: "",
      dialog_reject: false,
      isLoading: false,
      menu: false,
      menu2: false,
      date: "",
      date_end: "",
      dialog: false,
      item: {},
      selected: [],
      collectedType: [
        { name: "collected", id: 1 },
        { name: "not-collected", id: 2 },
      ],
    };
  },
  components: {
    Dashboard,
    paginate,
    CodeColumn,
  },
  computed: {
    AllAssigning: {
      get() {
        return this.$store.getters.AllAssigning;
      },
    },

    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Package Type",
          value: "package_type_text",
          sortable: false,
        },
        {
          text: "Pickup Date",
          value: "package_date",
          sortable: false,
        },
        {
          text: "Delivery Type",
          value: "same_day",
          sortable: false,
        },
        {
          text: "Delivery City",
          value: "current_delivery_city_name",
          sortable: false,
        },
        {
          text: "Delivery Address",
          align: "start",
          sortable: false,
          value: "current_delivery_address",
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchAllAssigning(this.filter);
  },
  methods: {
    ...mapActions(["fetchAllAssigning"]),
    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    isPaymentDone(item) {
      return item.paid_amount > 0;
    },
    toReturn(item) {
      this.dialog_reject = true;
      this.description = "";
      this.item = item;
    },
    async editPackage(item) {
      this.$router.push({
        name: "delivery-assign",
        params: { item: item, type: 2 },
      });
    },
    clear() {
      this.filter = {
        code: "",
      };
      this.date = "";
      this.date_end = "";
      this.reload();
    },
    reload() {
      const filter = {
        start: this.date,
        end: this.date_end,
        code: this.filter.code,
      };
      this.fetchAllAssigning(filter);
    },
    async returnItem() {
      const data = {
        package_id: this.item.id,
        reason: this.description,
      };
      axios
        .post("/package/return/mark-pending", data)
        .then(() => {
          this.$toast.success("Item return");
          this.dialog_reject = false;
          this.description = "";
          this.reload();
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.dialog_reject = false;
        });
    },
  },
};
</script>
