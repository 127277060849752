<template>
  <Dashboard selectedMainItem="Transfer Operation" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col cols="12" sm="6" md="2">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="filter.start"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filter.start"
                class="ml-5"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.start" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(filter.start);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="2"
          ><v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="filter.end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filter.end"
                class="ml-5"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(filter.end);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-select
            v-model="filter.status"
            :items="tranStatus"
            item-text="name"
            v-on:change="reload()"
            item-value="id"
            label="Status"
          ></v-select
        ></v-col>
        <v-col align="right" cols="12" sm="6" md="2"> </v-col>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-btn
            class="ma-4"
            color="primary"
            elevation="2"
            :to="{ name: 'transfer-create' }"
          >
            Create New
            <v-icon right> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="transferData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.branches`]="{ item }">
          <span>{{ item.from_branch_name}} -> {{ item.to_branch_name}}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            color="success"
            class="ma-4"
            v-if="item.status == 1"
            @click="openLeave(item.id)"
          >
            <span>Mark as Leaved</span>
          </v-btn>
          <v-btn
            color="red"
            class="ma-4"
            v-if="item.status == 2"
            @click="openFinished(item.id)"
          >
            <span class="text-white">Mark as Finished</span>
          </v-btn>
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="viewMore(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-eye </v-icon>
                  View
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="edit(item)" v-if="item.status == 1">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-pencil </v-icon>
                  Edit
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="toDelete(item)" v-if="item.status == 1">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-delete </v-icon>
                  Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate store="User" method="fetchTransfer" :filter="filter" />
    </v-card>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete this item?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            cancel
          </v-btn>

          <v-btn color="error" text @click="deleteItem()"> delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="leaveModal" max-width="365">
      <v-card>
        <v-card-title class="text-h5">
          Mark this transfer as leaved ?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error darken-1" text @click="leaveModal = false">
            CANCEL
          </v-btn>

          <v-btn color="green" text @click="confirmLeave()"> CONFIRM </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="finishModal" max-width="380">
      <v-card>
        <v-card-title class="text-h5">
          Mark this transfer as finished ?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error darken-1" text @click="finishModal = false">
            CANCEL
          </v-btn>

          <v-btn color="green darken-1" text @click="confirmFinish()">
            CONFIRM
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/Paginate";
import axios from "axios";

export default {
  name: "all-operation",
  data() {
    return {
      filter: {
        start: this.date,
        end: this.date_end,
        page: 1,
        status: 1,
      },
      leaveModal: false,
      finishModal: false,
      leaveTransfer: null,
      finishTransfer: null,
      deleteId: "",
      tranStatus: [
        {
          name: "Pending",
          id: 1,
        },
        {
          name: "Leaved",
          id: 2,
        },
        {
          name: "Finished",
          id: 3,
        },
      ],
      dialogDelete: false,
      isLoading: false,
      menu: false,
      menu2: false,
      dialog: false,
      item: {},
    };
  },
  components: {
    paginate,
    Dashboard,
  },
  computed: {
    transferData: {
      get() {
        return this.$store.getters.transfer;
      },
    },

    headers() {
      return [
        {
          text: "From -> To",
          align: "start",
          sortable: false,
          value: "branches",
        },
        {
          text: "Vehicle Number",
          align: "start",
          sortable: false,
          value: "reg_number",
        },
        {
          text: "Driver Name",
          align: "start",
          sortable: false,
          value: "driver_name",
        },
        {
          text: "Courier Name",
          align: "start",
          sortable: false,
          value: "courier_name",
        },
        {
          text: "Date",
          value: "date",
          sortable: false,
        },
        {
          text: "Package Count",
          value: "package_count",
          sortable: false,
        },
        {
          text: "Remaining Count",
          value: "remaining_count",
          sortable: false,
        },

        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchTransfer(this.filter);
  },
  methods: {
    ...mapActions(["fetchTransfer", "deleteTransferById"]),

    reload() {
      this.fetchTransfer(this.filter);
    },
    async edit(item) {
      this.$router.push({
        name: "transfer-edit",
        params: { item: item },
      });
    },

    async viewMore(id) {
      await this.$store.dispatch("getTransferById", id);
      this.$router.push({
        name: "transfer-view",
      });
    },
    clear() {
      this.filter = {
        code: "",
        status:"",
        start : "",
      end : ""
      };
      
      this.reload();
    },
    toDelete(item) {
      this.item = item;
      this.dialog = true;
    },
    async deleteItem() {
      await this.deleteTransferById(this.item.id)
        .then((response) => {
          console.log(response);
          this.reload();
          this.$toast.success("Deleted successfully!");
          this.dialog = false;
        })
        .catch((e) => {
          this.reload();
          this.$toast.error(e.response?.data?.message);
          this.dialog = false;
        });
    },
    openLeave(id) {
      this.leaveTransfer = id;
      this.leaveModal = true;
    },
    openFinished(id) {
      this.finishTransfer = id;
      this.finishModal = true;
    },
    async confirmLeave() {
      axios
        .put("transfer/mark-leaved/" + this.leaveTransfer)
        .then((response) => {
          console.log(response);
          this.$toast.success("Transfer mark as leaved");
          this.leaveModal = false;
          this.leaveTransfer = null;
          this.fetchTransfer(this.filter);
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.leaveModal = false;
          this.leaveTransfer = null;
        });
    },
    async confirmFinish() {
      axios
        .put("transfer/mark-finished/" + this.finishTransfer)
        .then((response) => {
          console.log(response);
          this.$toast.success("Transfer mark as finished");
          this.finishModal = false;
          this.finishTransfer = null;
          this.fetchTransfer(this.filter);
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.finishModal = false;
          this.finishTransfer = null;
        });
    },
  },
};
</script>
<style scoped>
.text-white {
  color: white;
}
</style>