<template>
  <Dashboard selectedMainItem="Operation" selectedSubItem="Vehicle">
    <v-row>
      <v-col cols="12" md="6">
        <v-card style="margin: 30px; border: 0" :loading="isLoading">
          <v-card-title> Add Transfer </v-card-title>
          <v-card-subtitle>
            Add
            <template>
              <div>
                <v-breadcrumbs
                  class="pl-0 pt-0"
                  :items="pageNavItems"
                ></v-breadcrumbs>
              </div>
            </template>
          </v-card-subtitle>
          <form style="margin-top: 40px; border: 0">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="to_day"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="to_day"
                        label="Select Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="to_day" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(to_day)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="12">
                  <v-select
                    :items="branches"
                    v-model="from_branch"
                    item-text="name"
                    item-value="id"
                    label="From Branch"
                    :error-messages="from_branchErrors"
                    solo
                    required
                    @input="$v.from_branch.$touch()"
                    @blur="$v.from_branch.$touch()"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="12">
                  <v-select
                    :items="branches"
                    v-model="to_branch"
                    item-text="name"
                    item-value="id"
                    label="To Branch"
                    :error-messages="to_branchErrors"
                    solo
                    required
                    @input="$v.to_branch.$touch()"
                    @blur="$v.to_branch.$touch()"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12">
                  <v-select
                    :items="vehicleData"
                    v-model="vehicleId"
                    item-text="reg_number"
                    item-value="id"
                    label="Vehicle"
                    :error-messages="typeErrors"
                    solo
                    required
                    @input="$v.vehicleId.$touch()"
                    @blur="$v.vehicleId.$touch()"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12">
                  <v-select
                    :items="couriersData"
                    v-model="courierId"
                    item-text="name"
                    item-value="user_id"
                    label="Courier"
                    :error-messages="courierErrors"
                    solo
                    required
                    @input="$v.courierId.$touch()"
                    @blur="$v.courierId.$touch()"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12">
                  <v-select
                    :items="driversData"
                    v-model="driverId"
                    item-text="name"
                    item-value="user_id"
                    label="Driver"
                    :error-messages="driverErrors"
                    solo
                    required
                    @input="$v.driverId.$touch()"
                    @blur="$v.driverId.$touch()"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-btn
                  :disabled="btnDisabled"
                  color="success"
                  class="ma-4"
                  @click="submit"
                  aa
                >
                  <span> Add </span>
                </v-btn>

                <v-btn class="ma-4" @click="cancelRegister" :color="'red'">
                  <span class="white--text"> Cancel </span>
                </v-btn>
              </v-row>
            </v-container>
          </form>
        </v-card>
      </v-col>
    </v-row>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar.vue";
import axios from "axios";

export default {
  name: "vehicle-register",
  mixins: [validationMixin],

  validations: {
    vehicleId: { required },
    driverId: { required },
    courierId: { required },
    to_branch: { required },
    from_branch: { required },
  },

  data: () => ({
    name: "",
    vehicleId: "",
    driverId: "",
    status: 1,
    from_branch: null,
    to_branch: null,
    isCompany: 0,
    isLoading: false,
    btnDisable: false,
    checkbox: false,
    to_day: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    from: 1,
    to: 2,
    vehicleData: [],
    branches: [],
    item: {},
    courierId: "",
    pageNavItems: [
      {
        text: "Dashboard",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "dashboard" },
      },
      {
        text: "Transfer",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "transfer" },
      },
      {
        text: "Add",
        disabled: true,
        link: true,
        exact: true,
        to: { name: "transfer-create" },
      },
    ],
  }),
  components: {
    Dashboard,
  },
  created() {
    const filter = {
      date: this.to_day,
    };
    this.fetchCouriersAttended(filter);
    this.fetchDriversAttended(filter);
    this.getBranches();
  },
  computed: {
    vehicleTypes: {
      get() {
        return this.$store.getters.allVehicles;
      },
    },
    driversData: {
      get() {
        return this.$store.getters.driversAttended;
      },
    },
    vehicleStatus: {
      get() {
        return this.$store.getters.vehicleStatus;
      },
    },
    couriersData: {
      get() {
        return this.$store.getters.couriersAttended;
      },
    },

    btnDisabled() {
      const visible =
        this.$v.$anyError ||
        !(
          this.vehicleId &&
          this.driverId &&
          this.courierId &&
          this.to_branch &&
          this.from_branch
        );
      return visible;
    },
    headers() {
      return [
        {
          text: "Driver Name",
          align: "start",
          sortable: false,
          value: "driver_name",
        },
        {
          text: "Courier Name",
          align: "start",
          sortable: false,
          value: "courier_name",
        },
        {
          text: "Date",
          value: "date",
          sortable: false,
        },
        {
          text: "Package Count",
          value: "package_count",
          sortable: false,
        },
        {
          text: "Remaining Count",
          value: "remaining_count",
          sortable: false,
        },
      ];
    },
    to_branchErrors() {
      const errors = [];
      if (!this.$v.to_branch.$dirty) return errors;
      !this.$v.to_branch.required && errors.push("To branch is required.");
      return errors;
    },
    from_branchErrors() {
      const errors = [];
      if (!this.$v.from_branch.$dirty) return errors;
      !this.$v.from_branch.required && errors.push("From branch is required.");
      return errors;
    },
    courierErrors() {
      const errors = [];
      if (!this.$v.courierId.$dirty) return errors;
      !this.$v.courierId.required && errors.push("courier is required.");
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.vehicleId.$dirty) return errors;
      !this.$v.vehicleId.required && errors.push("vehicle is required.");
      return errors;
    },
    driverErrors() {
      const errors = [];
      if (!this.$v.driverId.$dirty) return errors;
      !this.$v.driverId.required && errors.push("driver is required.");
      return errors;
    },
  },
  mounted() {
    this.getAllVehicles()
      .then((response) => {
        const arr = response.data;
        this.vehicleData = arr;
      })
      .catch((e) => {
        this.$toast.error(e.response.data.message);
      });
  },
  methods: {
    ...mapActions([
      "getAllVehicles",
      "markTransfer",
      "fetchDriversAttended",
      "fetchCouriersAttended",
    ]),

    async submit() {
      this.$v.$touch();

      this.btnDisable = true;
      this.isLoading = true;
      const date = this.to_day;
      const vehicle_id = this.vehicleId;
      const driver_id = this.driverId;
      const courier_id = this.courierId;
      const from_branch_id = this.from_branch;
      const to_branch_id = this.to_branch;

      const toSend = {
        date,
        driver_id,
        courier_id,
        vehicle_id,
        from_branch_id,
        to_branch_id,
      };
      await this.markTransfer(toSend)
        .then(() => {
          this.$toast.success("Record saved successfully!");
          this.isLoading = false;

          this.clear();
          this.cancelRegister();
        })
        .catch((e) => {
          this.isLoading = false;
          this.$toast.error(e.response.data.message);
        });
    },
    getBranches() {
      axios.get("/branch/get-all-without-auth").then((response) => {
        this.branches = response.data.data;
      });
    },
    clear() {
      this.$v.$reset();
      this.driverId = "";
      this.courierId = "";
      this.vehicleId = "";
      this.from_branch = "";
      this.to_branch = "";
    },
    uppercase() {
      this.regNumber = this.regNumber.toUpperCase();
    },
    cancelRegister() {
      this.$router.push({ name: "transfer" });
    },
  },
};
</script>
