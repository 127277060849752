<template>
  <Dashboard selectedMainItem="Operation" selectedSubItem="Vehicle">
    <v-card style="margin: 20px; border: 0" :loading="isLoading">
      <v-card-subtitle>
        <v-icon @click="$router.go(-1)">mdi-arrow-left-circle-outline</v-icon>
        Transfer
      </v-card-subtitle>
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <v-card>
              <v-row>
                <v-col cols="12" md="3">
                  <v-card-text>
                    <p class="text-h5 text--primary">Transfer details</p>
                  </v-card-text> </v-col
                ><v-col cols="12" md="6">
                  <v-card-text>
                    <v-chip v-if="transferInfo.status === 1" color="green">
                      <span class="text-white">{{
                        transferInfo.status_text.toUpperCase()
                      }}</span>
                    </v-chip>
                    <v-chip v-if="transferInfo.status === 2" color="blue">
                      <span class="text-white">{{
                        transferInfo.status_text.toUpperCase()
                      }}</span>
                    </v-chip>
                    <v-chip v-if="transferInfo.status === 3" color="red">
                      <span class="text-white">{{
                        transferInfo.status_text.toUpperCase()
                      }}</span>
                    </v-chip>
                  </v-card-text>
                </v-col></v-row
              >

              <v-row>
                <v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Driver Name : {{ transferInfo.driver_name }}
                  </p> </v-col
                ><v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Vehicle Number: {{ transferInfo.reg_number }}
                  </p> </v-col
                ><v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Courier Name: {{ transferInfo.courier_name }}
                  </p> </v-col
                ><v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Date: {{ transferInfo.date }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-card style="margin-top: 30px; border: 0" :loading="false">
          <v-container>
            <v-card-title>Packages </v-card-title>
            <v-row v-for="item in transferInfo.packages" :key="item.title">
              <v-col cols="12" md="9">
                <p class="details-title">
                  Package Code : {{ item.package_code }}
                </p>
              </v-col>

              <v-col cols="12" md="3" class="refresh-button">
                <v-btn outlined color="red" @click="toUncheck(item)">
                  <span> Remove </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-container>
    </v-card>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to remove this item?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            cancel
          </v-btn>

          <v-btn color="error" text @click="uncheckItem(item.id)">
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";

export default {
  name: "TransferView",

  data() {
    return {
      isLoading: false,
      dialog: false,
      item: {},
    };
  },
  components: {
    Dashboard,
  },
  mounted() {},
  methods: {
    ...mapActions([ "transferById", "getTransferById"]),
    toUncheck(item) {
      this.item = item;
      this.dialog = true;
    },
    async uncheckItem() {
      const transfer_id = this.transferInfo.id;
      const package_id = this.item.package_id;
      const status = 0;
      const items = {
        transfer_id,
        package_id,
        status,
      };
      this.isLoading = true;
      await this.transferById(items)
        .then(() => {
          this.false = true;
          this.isLoading = false;

          this.$toast.success("Package removed successfully!");

          this.viewMore();
          this.dialog = false;
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.dialog = false;
          this.false = true;
          this.isLoading = false;
        });
    },
    async viewMore() {
      await this.$store.dispatch("getTransferById", this.transferInfo);
    },
  },
  computed: {
    transferInfo: {
      get() {
        return this.$store.getters.transferById;
      },
    },
  },
};
</script>
<style scoped>
.stage-warapper {
  background-color: rgb(229, 229, 229);
  border-radius: 10px;
  padding: 10px 10px;
  margin: 10px 0;
}
</style>
