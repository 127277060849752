<template>
  <Dashboard selectedMainItem="Administration" selectedSubItem="Customers">
    <v-row>
      <v-col cols="12" md="12">
        <v-card style="margin: 20px; border: 0" :loading="isLoading">
          <v-card-title> Customer Edit </v-card-title>
          <v-card-subtitle>
            Edit customer here
            <template>
              <div>
                <v-breadcrumbs
                  class="pl-0 pt-0"
                  :items="pageNavItems"
                ></v-breadcrumbs>
              </div>
            </template>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <form autocomplete="off" style="margin-top: 40px; border: 0">
          <v-container>
            <v-card>
              <v-row>
                <v-col cols="12" md="4">
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="12" md="12">
                        <h5>Customer Details</h5>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="ml-3" md="12">
                      <v-text-field
                        v-model="firstName"
                        type="text"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="ml-3" md="12">
                      <v-text-field
                        v-model="lastName"
                        type="text"
                        placeholder="Last Name"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="ml-3" md="12">
                      <v-text-field
                        v-model="email"
                        type="email"
                        placeholder="Email address"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" md="11">
                      <v-text-field
                        v-model="phone"
                        type="text"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="11">
                      <v-text-field
                        v-model="address"
                        type="text"
                        placeholder="Address"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="11">
                      <v-select
                        v-model="type"
                        :items="types"
                        item-text="name"
                        item-value="id"
                        :error-messages="typeErrors"
                        label="Customer Type"
                        placeholder="Select type"
                        solo
                        required
                        @input="$v.type.$touch()"
                        @blur="$v.type.$touch()"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <hr class="line" />
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="ml-3" md="12">
                      <v-text-field
                        v-model="bank_name"
                        placeholder="Bank name"
                        type="text"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="ml-3" md="12">
                      <v-text-field
                        v-model="acc_name"
                        placeholder="Account name"
                        type="text"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="ml-3" md="12">
                      <v-text-field
                        v-model="branch_name"
                        placeholder="Branch name"
                        type="text"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="ml-3" md="12">
                      <v-text-field
                        v-model="acc_number"
                        placeholder="Account number"
                        type="text"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-btn
                    class="ml-3"
                    @click="submit"
                    :color="$v.$anyError ? 'gray' : 'primary'"
                    :disabled="btnDisabled"
                  >
                    <span class="white--text"> Save </span>
                  </v-btn>

                  <v-btn class="ma-4" @click="cancelEdit" :color="'red'">
                    <span class="white--text"> Cancel </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-container>
          <v-card>
            <v-row>
              <v-col cols="12" md="4">
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" md="12">
                      <h5>Special Rates</h5>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-data-table
                  :headers="headers"
                  :items="allPriceFactors"
                  item-key="id"
                  class="elevation-1"
                  hide-default-footer
                >
                  <template v-slot:[`item.initial`]="{ item }">
                    <v-text-field
                      @input="markRowChanged(item)"
                      v-model="item.initial"
                      type="number"
                      required
                      min="0"
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.additional`]="{ item }">
                    <v-text-field
                      @input="markRowChanged(item)"
                      v-model="item.additional"
                      type="number"
                      required
                      :disabled="item.package_type == 1"
                      min="0"
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      :disabled="checkRowChanged(item)"
                      class="ma-4"
                      @click="setEditFactor(item)"
                      :color="'primary'"
                    >
                      <span> Save Changes </span>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </Dashboard>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar";
import axios from "axios";

export default {
  name: "customer-edit",
  mixins: [validationMixin],

  validations: {
    type: { required },
  },
  props: ["setVehicleEdit"],
  data() {
    return {
      id: "",
      firstName: "",
      lastName: "",
      userName: "",
      phone: "",
      address: "",
      email: "",
      bank_name: "",
      branch_name: "",
      acc_name: "",
      acc_number: "",
      type: "",
      banks: [],
      allPriceFactors: [],
      percentage: "",
      isLoading: false,
      btnDisabled: false,
      showConfirmPassword: false,
      showPassword: false,
      changedRows: [],
      types: [
        {
          id: 0,
          name: "Regular",
        },
        {
          id: 1,
          name: "Corporate",
        },
      ],
      pageNavItems: [
        {
          text: "Dashboard",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "dashboard" },
        },
        {
          text: "Human Resource",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "customer" },
        },
        {
          text: "Edit",
          disabled: true,
          link: true,
          exact: true,
          to: { name: "dashboard" },
        },
      ],
    };
  },
  components: {
    Dashboard,
  },
  mounted() {
    this.getPricingData();
  },
  computed: {
    headers() {
      return [
        {
          text: "Package Type",
          align: "start",
          sortable: false,
          value: "package_type_text",
        },
        {
          text: "Area Type",
          value: "area_type_text",
          sortable: false,
        },
        {
          text: "Initial",
          value: "initial",
          sortable: false,
        },
        {
          text: "Additional",
          value: "additional",
          sortable: false,
        },
        {
          text: "Actions",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
    typeErrors() {
      const errors = [];

      return errors;
    },
  },
  created() {
    this.setEditValues();
  },
  methods: {
    markRowChanged(item) {
      this.changedRows.indexOf(item.id) === -1
        ? this.changedRows.push(item.id)
        : "";
    },
    checkRowChanged(item) {
      return this.changedRows.indexOf(item.id) === -1;
    },
    removeRowChanged(item) {
      const index = this.changedRows.indexOf(item.id);
      this.changedRows.splice(index, 1);
    },
    async submit() {
      // this.$v.$touch();

      this.isLoading = true;
      this.btnDisabled = true;

      await axios
        .put("user/customer/" + this.id, {
          type: this.type,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone: this.phone,
          address: this.address,
          acc_number: this.acc_number,
          acc_name: this.acc_name,
          bank_name: this.bank_name,
          branch_name: this.branch_name,
        })
        .then(() => {
          this.$toast.success("Customer updated successfully!");
          this.isLoading = false;
          this.btnDisabled = false;
          this.cancelEdit();
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.isLoading = false;
          this.btnDisabled = false;
        });
    },
    setEditValues() {
      const item = this.$route.params?.item;
      if (item == null) {
        return this.cancelEdit();
      }
      this.id = item.id;
      this.firstName = item.first_name;
      this.lastName = item.last_name;
      this.email = item.email;
      this.phone = item.phone;
      this.type = item.is_corporate;
      this.address = item.address;
      this.userName = item.userName;

      this.bank_name = item.bank_name;
      this.branch_name = item.branch_name;
      this.acc_name = item.acc_name;
      this.acc_number = item.acc_number;
    },
    cancelEdit() {
      this.$router.push({ name: "customers" });
    },
    async setAddFactor(id) {
      this.$router.push({
        name: "price-store",
        params: { id: id },
      });
    },

    async getPricingData() {
      await axios
        .get("/pricing-factor", {
          params: {
            user_id: this.id,
          },
        })
        .then((response) => {
          console.log(response);
          this.allPriceFactors = response.data.data;
        });
    },
    async setEditFactor(price) {
      await axios
        .post("/pricing-factor/customer-price-store", {
          user_id: this.id,
          package_type: price.package_type,
          area_type: price.area_type,
          initial: parseFloat(price.initial),
          additional: parseFloat(price.additional),
        })
        .then((response) => {
          console.log(response);
          this.$toast.success("Price updated successfully!");
          this.isLoading = false;
          this.removeRowChanged(price);
          //this.cancelEdit();
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error(e.response.data.message);
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.line {
  border-top: 3px solid rgb(8, 8, 8);
}
</style>
