<template>
  <v-container fluid fill-height>
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm4>
        <v-card shaped elevation="8" >
          <v-card-text>
            <div>
              <v-form  ref="form" action="login" method="post">
                <center>
                  <v-img
                    lazy-src="@/assets/image/falcon-icon.png"
                    max-height="auto"
                    max-width="50%"
                    style="background-position: center"
                    src="@/assets/image/falcon-icon.png"
                  ></v-img>
                </center>
                <!-- <v-text-field
                  name="_token"
                  :value="csrf"
                  v-model="form.csrf"
                  v-show="false"
                ></v-text-field> -->
                <v-text-field
                  label="Enter your username"
                  v-model="email"
                  :value="email"
                  name="email"
                  outlined
                  :error-messages="email_errors"
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                ></v-text-field>
                <v-text-field
                  label="Enter your password"
                  v-model="password"
                  :value="password"
                  name="password"
                  :error-messages="password_errors"
                  outlined
                  min="8"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  :type="showPassword ? 'text' : 'password'"
                  required
                  @input="$v.password.$touch()" 
                  @blur="$v.password.$touch()"
                ></v-text-field>
                <v-btn
                  block
                  :color="$v.$anyError ? 'gray' : 'primary'"
                  large
                  @click="loginAsAdmin()"
                  :disabled="$v.$anyError || btnDisable"
                  >Sign In
                  </v-btn>

                <v-layout> </v-layout>
              </v-form>
            </div>
          </v-card-text>
          <v-card-actions>
            <!-- <v-btn small text @click="goForRegister">Register</v-btn> -->
            <v-spacer></v-spacer>
            <!-- <a href="/password/reset">Forgot Password</a> -->
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from 'vuelidate';
import { POSITION } from "vue-toastification";
import { required } from 'vuelidate/lib/validators';

export default {
  name: "AdminLogin",
  mixins: [validationMixin],

  validations: {
    email: { required },
    password: { required },
  },

  data: () => ({
    showPassword: false,
      csrf: '',
      email: '',
      password: '',
      btnDisable: false,
  }),
  methods:{

  ...mapActions(["login"]),

  async loginAsAdmin(){
      this.btnDisable = true;
      await this.$v.$touch();

        await this.login( { 
          username: this.email, 
          password: this.password 
          }).then( response =>{
            console.log(response);
            this.$router.push({name:'dashboard'});
          }).catch((e)=>{
            this.$toast.error(e.response.data.message,{ position: POSITION.TOP_CENTER });
          }).finally(()=>{
            this.btnDisable = false;
          });
    },
  },
  computed:{
      password_errors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.required && errors.push('Password is required.')
        return errors
      },
      email_errors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.required && errors.push('Username is required')
        return errors
      },
    },
};
</script>
