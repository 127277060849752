<template>
  <Dashboard selectedMainItem="Transport" selectedSubItem="Vehicle">
    <v-row>
      <v-col cols="12" md="6">
        <v-card style="margin: 30px; border: 0" :loading="isLoading">
          <v-card-title> Add Vehicle </v-card-title>
          <v-card-subtitle>
            Add vehicle
            <template>
              <div>
                <v-breadcrumbs
                  class="pl-0 pt-0"
                  :items="pageNavItems"
                ></v-breadcrumbs>
              </div>
            </template>
          </v-card-subtitle>
          <form style="margin-top: 40px; border: 0">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-select
                    v-model="branch"
                    :items="branches"
                    item-text="name"
                    item-value="id"
                    :error-messages="branchErrors"
                    label="Select branch"
                    placeholder="Select branch"
                    solo
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="12">
                  <v-select
                    v-model="type"
                    :items="vehicleTypes"
                    item-text="name"
                    item-value="id"
                    :error-messages="typeErrors"
                    label="Type"
                    solo
                    required
                    @input="$v.type.$touch()"
                    @blur="$v.type.$touch()"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="regNumber"
                    :error-messages="regNumberErrors"
                    :counter="10"
                    label="Reg Number"
                    solo
                    required
                    @input="$v.regNumber.$touch()"
                    @blur="$v.regNumber.$touch()"
                    @keyup="uppercase"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="name"
                    :error-messages="nameErrors"
                    :counter="20"
                    label="Vehicle Name"
                    solo
                    required
                    @input="$v.name.$touch()"
                    @blur="$v.name.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-select
                    v-model="fuel"
                    :items="fuelTypes"
                    item-text="name"
                    item-value="id"
                    :error-messages="fuelErrors"
                    label="Fuel Type"
                    solo
                    required
                    @input="$v.fuel.$touch()"
                    @blur="$v.fuel.$touch()"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12">
                  <v-select
                    v-model="status"
                    :items="vehicleStatus"
                    item-text="name"
                    item-value="id"
                    :error-messages="statusErrors"
                    label="Status"
                    solo
                    required
                    @input="$v.status.$touch()"
                    @blur="$v.status.$touch()"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" md="6">
                  <v-checkbox
                    v-model="isCompany"
                    :error-messages="checkboxErrors"
                    label="Company Owned"
                    @change="$v.isCompany.$touch()"
                    @blur="$v.isCompany.$touch()"
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" md="6">
                  <v-checkbox
                    v-model="shuttle"
                    :error-messages="shuttleErrors"
                    label="Shuttle"
                    @change="$v.shuttle.$touch()"
                    @blur="$v.shuttle.$touch()"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-btn
                  class="ma-4"
                  @click="submit"
                  :color="btnDisabled ? 'gray' : 'primary'"
                  :disabled="btnDisabled"
                >
                  <span class="white--text"> Register </span>
                </v-btn>
                <v-btn class="ma-4" @click="cancelRegister" :color="'red'">
                  <span class="white--text"> Cancel </span>
                </v-btn>
              </v-row>
            </v-container>
          </form>
        </v-card>
      </v-col>
    </v-row>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Dashboard from "../../../components/Layouts/Leftbar";
import axios from 'axios';

export default {
  name: "vehicle-register",
  mixins: [validationMixin],

  validations: {
    name: { required, maxLength: maxLength(20) },
    regNumber: { required },
    type: { required },
    status: { required },
    fuel: { required },
    branch: { required },
    isCompany: {
      checked(val) {
        return val;
      },
    },
    shuttle: {
      checked(val) {
        return val;
      },
    },
  },

  data: () => ({
    name: "",
    regNumber: "",
    type: "",
    shuttle: 0,
    branch: null,
    branches: [],
    fuel: null,
    status: 1,
    isCompany: 0,
    isLoading: false,
    btnDisable: false,
    checkbox: false,
    fuelTypes: [
      {
        id: 1,
        name: "Petrol",
      },
      {
        id: 2,
        name: "Diesel",
      },
    ],
    pageNavItems: [
      {
        text: "Dashboard",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "dashboard" },
      },
      {
        text: "Vehicle",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "vehicle" },
      },
      {
        text: "Register",
        disabled: true,
        link: true,
        exact: true,
        to: { name: "dashboard" },
      },
    ],
  }),
  components: {
    Dashboard,
  },
  created() {
    this.getBranches();
  },
  computed: {
    vehicleTypes: {
      get() {
        return this.$store.getters.vehiclesTypes;
      },
    },
    vehicleStatus: {
      get() {
        return this.$store.getters.vehicleStatus;
      },
    },
    btnDisabled() {
     return !(this.name && this.regNumber && this.type && this.fuel && this.branch) || this.isLoading;
    },
    fuelErrors() {
      const errors = [];
      if (!this.$v.fuel.$dirty) return errors;
      return errors;
    },
    shuttleErrors() {
      const errors = [];
      if (!this.$v.shuttle.$dirty) return errors;
      return errors;
    },
    branchErrors() {
      const errors = [];
      if (!this.$v.branch.$dirty) return errors;
      !this.$v.branch.required && errors.push("Branch is required.");
      return errors;
    },
    checkboxErrors() {
      const errors = [];
      if (!this.$v.isCompany.$dirty) return errors;
      return errors;
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    },
    regNumberErrors() {
      const errors = [];
      if (!this.$v.regNumber.$dirty) return errors;
      !this.$v.regNumber.required &&
        errors.push("Register number is required.");
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.type.$dirty) return errors;
      !this.$v.type.required && errors.push("Type is required.");
      return errors;
    },
    statusErrors() {
      const errors = [];
      if (!this.$v.status.$dirty) return errors;
      !this.$v.status.required && errors.push("Status is required.");
      return errors;
    },
  },

  methods: {
    ...mapActions(["vehicleRegister"]),
    getBranches() {
      axios
        .get("branch")
        .then((response) => {
          this.branches = response.data.data;
        })
    },

    async submit() {
      this.$v.$touch();

      this.btnDisable = true;
      this.isLoading = true;

      await this.vehicleRegister({
        name: this.name,
        branch_id: this.branch,
        fuel_type: this.fuel,
        is_shuttle: this.shuttle,
        reg_number: this.regNumber,
        type: this.type,
        status: this.status,
        is_company: this.isCompany,
      })
        .then((response) => {
          console.log(response);

          this.$toast.success("Vehicle added successfully!");
          this.clear();
          this.isLoading = false;
          this.cancelRegister();
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
          this.isLoading = false;
          this.btnDisable = false;
        });
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.fuel = null;
      this.branch = null;
      this.shuttle = false;
      this.regNumber = "";
      this.type = "";
      this.status = null;
      this.isCompany = false;
    },
    uppercase() {
      this.regNumber = this.regNumber.toUpperCase();
    },
    cancelRegister() {
      this.$router.push({ name: "vehicle" });
    },
  },
};
</script>
