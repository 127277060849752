<template>
    <Dashboard selectedMainItem="Administration" selectedSubItem="Route City">

        <v-card style="margin:30px;border: 0;" :loading="isLoading">
            <v-card-title>
                Branch Assign
            </v-card-title>
            <v-card-subtitle>
                branch assign
                <template>
                    <div>
                        <v-breadcrumbs class="pl-0 pt-0" :items="pageNavItems">
                        </v-breadcrumbs>
                    </div>
                </template>
            </v-card-subtitle>

            <v-row class="ml-2">
                <v-col cols="4" sm="6" md="4">
                    <v-text-field class="ma-5" v-model="groupName" type="text" readonly solo></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                    <!-- <v-autocomplete class="ma-5" v-model="city" :items="avilableBranch" item-text="hierarchy"
                        item-value="id" solo label="Select Branch"></v-autocomplete> -->
                    <v-autocomplete class="ma-5" v-model="branch" :items="avilableBranch" item-text="name"
                        item-value="id" label="Select Branch" autocomplete="off" solo
                        @change="setFilterValues"></v-autocomplete>
                </v-col>
                <v-col align="right" cols="2" sm="6" md="2">
                    <v-btn class="ma-5 mr-15" style="height:48px;" color="primary" elevation="2"
                        :disabled="addBtnDisabled" @click="addBranch">
                        ASSIGN
                        <v-icon right>
                            mdi-file-plus
                        </v-icon>
                    </v-btn>

                </v-col>
            </v-row>
            <v-text-field v-model="filter.search" append-icon="mdi-magnify" label="Search" class="ml-10 mr-10" solo
                hide-details @keyup="setFilterValues"></v-text-field>

            <v-data-table :headers="headers" :items="groupBranch" :search="filter.search" item-key="id"
                class="elevation-1 ml-10 mr-10" hide-default-footer>
                <template v-slot:[`item.actions`]="{ item }">

                    <v-btn class="ma-4" @click="deleteBranch(item)" :color="'red'">
                        <span class="white--text">
                            Delete
                        </span>
                    </v-btn>
                </template>
            </v-data-table>
            <paginate store="RouteCity" method="fetchCityRoutes" :filter="filter" />
            <!-- ******************* delete user dialog ********************** -->
            <v-dialog v-model="dialogDelete" max-width="290">
                <v-card>
                    <v-card-title class="headline">Delete?</v-card-title>
                    <v-card-text>Do you want to remove assigned city ?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="dialogDelete = false">Cancel</v-btn>

                        <v-btn color="red darken-1" text @click="deleteBranch">Delete</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </Dashboard>
</template>
    
<script>
import { mapActions } from "vuex";
import Dashboard from '../../../components/Layouts/Leftbar'
import paginate from '../../../components/Common/Paginate';
import axios from "axios";

export default {
    name: "branch-list",
    data() {
        return {
            filter: {
                route_id: '',
                search: '',
                page: 1,
            },
            id: '',
            groupName: '',
            route: '',
            branch: '',
            deleteId: '',
            avilableBranch: [],
            groupBranch: [],
            dialogDelete: false,
            isLoading: false,
            pageNavItems: [
                {
                    text: 'Dashboard',
                    disabled: false,
                    link: true,
                    exact: true,
                    to: { name: 'dashboard' }
                },
                {
                    text: 'Branch Group',
                    disabled: false,
                    link: true,
                    exact: true,
                    to: { name: 'route' }
                },
                {
                    text: 'Branch Assign',
                    disabled: true,
                    link: true,
                    exact: true,
                    to: { name: 'dashboard' }
                },
            ],
        }
    },
    components: {
        paginate,
        Dashboard,
    },

    computed: {


        addBtnDisabled() {
            return (this.branch == '');
        },
        headers() {
            return [
                {
                    text: 'Branch name',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: 'Action',
                    align: 'end',
                    value: 'actions',
                    sortable: false
                },
            ]
        },
    },
    created() {
        this.getAllCities(this.filter);
        this.route = this.$store.getters.setRouteCity;
        this.filter.route_id = this.$store.getters.setRouteCity.id;
    },
    mounted() {
        this.fetchCityRoutes(this.filter);
        this.getAvillableGroups();
        this.getGroupBranch();
    },
    methods: {
        ...mapActions(["fetchCityRoutes", "deleteRouteCityById", "getAllCities", "routeCityCreate"]),

        async addBranch() {
            console.log(4)
            await axios
                .post("branch-group/branch-add",{
                    branch_id:this.branch,
                    group_id:this.id
                })
                .then((response) => {
                    console.log(response);
                    
                    this.$toast.success("Branch assigned successfully!");
                    this.getGroupBranch();
                })
                .catch((e) => {
                    this.$toast.error(e.response?.data?.message);

                    this.btnDisabled = false;
                });


           
        },

       async deleteBranch(item) {
        await axios
                .get("branch-group/branch-delete/"+item.id)
                .then((response) => {
                    console.log(response);
                   
                    this.$toast.success("Branch Delete assigned successfully!");
                    this.getGroupBranch();
                })
                .catch((e) => {
                    this.$toast.error(e.response?.data?.message);

                    this.btnDisabled = false;
                });
        },
        // async deleteCity() {
        //     this.dialogDelete = false;
        //     await this.deleteRouteCityById(this.deleteId).then(response => {
        //         console.log(response);
        //         this.fetchCityRoutes(this.filter);
        //         this.$toast.success("Assigned city removed successfully!");
        //     }).catch((e) => {

        //         this.$toast.error(e.response.data.message);
        //     });
        
        setFilterValues() {
            this.fetchCityRoutes(this.filter);
        },
        async getAvillableGroups() {
            await axios
                .get("branch-group/avillable-branch")
                .then((response) => {
                    console.log(response.data.data);
                    this.avilableBranch = response.data.data
                })
                .catch((e) => {
                    this.$toast.error(e.response?.data?.message);

                    this.btnDisabled = false;
                });

        },

        async getGroupBranch() {
            const item = this.$route.params?.item;
            this.id = item.id;
            this.groupName =item.name

            if (item == null) {
                return this.cancelRegister();
            }
            console.log(item);
            await axios
                .get("branch-group/group-branch/"+this.id)
                .then((response) => {
                    console.log(response.data.data);
                    this.groupBranch = response.data.data
                })
                .catch((e) => {
                    this.$toast.error(e.response?.data?.message);

                    this.btnDisabled = false;
                });

        },
        cancelRegister() {
        this.$router.push({ name: "branch-group" });
      },


    },
}
</script>