<template>
  <Dashboard selectedMainItem="Operation" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col cols="12" sm="6" md="2"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                class="ml-5"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  reloadRouteAssign();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="2"
          ><v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                class="ml-5"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(date_end);
                  reloadRouteAssign();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-select
            :items="routeData"
            label="Route"
            class="ml-5"
            item-text="name"
            item-value="id"
            v-on:change="reloadRouteAssign()"
            v-model="routeId"
          ></v-select>
        </v-col>

        <v-col align="right" cols="6" sm="6" md="4">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
        <v-col align="right" cols="6" sm="6" md="2">
          <v-btn
            class="ma-4"
            color="primary"
            elevation="2"
            :to="{ name: 'route-assign-create', params: { date } }"
          >
            Assign
            <v-icon right> mdi-file-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="routeAssignData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
        :items-per-page="ROWS_PER_PAGE"
      >
      </v-data-table>
      <paginate store="User" method="fetchRouteAssign" :filter="filter" />
    </v-card>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/Paginate";

export default {
  name: "route-assign",
  data() {
    return {
      filter: {
        start: this.date,
        end: this.date_end,
        route_id: this.routeId,
        code: "",
        page: 1,
      },
      search: "",
      calories: "",
      deleteId: "",
      dialogDelete: false,
      isLoading: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date_end: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      routeData: [],
      routeId: "",
    };
  },
  components: {
    paginate,
    Dashboard,
  },
  computed: {
    routeAssignData: {
      get() {
        return this.$store.getters.routeAssign;
      },
    },
    headers() {
      return [
        {
          text: "Route Name",
          align: "start",
          sortable: false,
          value: "route_name",
        },
        {
          text: "Date",
          value: "date",
          sortable: false,
        },
        {
          text: "Name",
          value: "user_name",
          sortable: false,
        },
      ];
    },
  },
  created() {
    const filter = {
        start: this.date,
        end: this.date_end,
        code: this.filter.code,
        route_id: this.routeId,
      };
    this.fetchRouteAssign(filter);
  },
  mounted() {
    const filter = {};
    this.fetchRoutes(filter)
      .then((response) => {
        this.routeData = response.data.data;
      })
      .catch((e) => {
        this.$toast.error(e.response.data.message);
      });
      this.reloadRouteAssign();
  },
  methods: {
    ...mapActions(["fetchRouteAssign", "fetchRoutes"]),
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    clear() {
      this.filter = {
        code: "",
      };
      this.date = "";
      this.date_end = "";
      this.reloadRouteAssign();
    },
    reloadRouteAssign() {
      const filter = {
        start: this.date,
        end: this.date_end,
        code: this.filter.code,
        route_id: this.routeId,
      };
      this.fetchRouteAssign(filter);
    },
  },
};
</script>