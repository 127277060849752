import axios from 'axios';


const state = {
    route: null,
    paginate: {
        meta:
        {
            current_page : 1,
            last_page : 1,
        }
    },
    routes: [],
};

const getters = {
    
    allRoutes: state => state.routes,
    setRoute: state => state.route,
    routesPaginate: state => state.paginate,
    routeStatus: state => state.routeStatus,
    routesTypes: state => state.routesTypes,

};

const actions = {

    async fetchRoutes({ commit }, filter) {
            const allroutes = await axios.get('route/get-paginate',{params: {
                page : filter.page,
                name : filter.search,
            }});
            commit("setRoutes",  allroutes.data.data);
            commit("setCityRoutes",  []);
            commit("setPagination",  allroutes.data.pagination);
            return allroutes;
    },
    
    async getAllroutes({commit}){
        const allroutes = await axios.get('route');
        commit("setroute",  allroutes.data);
        return allroutes.data;
    },

    async routeRegister({ commit }, { name, description, branch_id, status }) {
        
        const route = await axios.post('route', {
            name,
            description,
            branch_id,
            status,
        });
        commit("newRoute",  route.data.data );
        return route;
    },

    async routeUpdate({ commit },  { id, name, description, branch_id, status }) {

        const route = await axios.put('route/'+id, {
            name,
            description,
            branch_id,
            status,
        });
        commit("updateRoute",  route.data.data );
        return route;
    },

    async getRouteById({ commit },routeId) {
        
        const route = await axios.get('route/'+ routeId);
        commit("setRoute",  route.data.data );
        return route.data.data;
    },

    async deleteRouteById({ commit }, id) {
        await axios.delete(`route/${id}`);
    
        commit('removeRoute', id);
    }

};

const mutations = {
    setRoutes: (state, routes) => (state.routes = routes),
    setCityRoutes: (state, cityRoutes) => (state.cityRoutes = cityRoutes),
    setRoute: (state, route) => (state.route = route),
    setPagination: (state, paginate) => (state.paginate = paginate),
    setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
    newRoute: (state, route) =>{
        state.routes.unshift(route);
        state.editroute = null;
    },   
    removeRoute: (state, id) =>{
        state.routes = state.routes.filter(route => route.id !== id);
    },
    updateRoute: (state, updroute) => {
        const index = state.routes.findIndex(route => route.id === updroute.id);
        if (index !== -1) {
        state.routes.splice(index, 1, updroute);
        }
    }
};

export default {
state,
getters,
actions,
mutations
};