<template>
  <Dashboard selectedMainItem="Administration" selectedSubItem="Setting">
    <v-row>
      <v-col cols="12" md="6">
        <v-card style="margin: 20px; border: 0" :loading="isLoading">
          <v-card-title> Setting Edit </v-card-title>
          <v-card-subtitle>
            Edit setting here
            <template>
              <div>
                <v-breadcrumbs
                  class="pl-0 pt-0"
                  :items="pageNavItems"
                ></v-breadcrumbs>
              </div>
            </template>
          </v-card-subtitle>
          <form style="margin-top: 40px; border: 0">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <label>Setting Name</label>
                  <v-text-field
                    v-model="display_name"
                    :disabled="true"
                    :error-messages="initialErrors"
                    label="Setting Name"
                    solo
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" >
                  <label>Value</label>
                  <v-text-field
                    v-model="value"
                    :error-messages="valueErrors"
                    label="Value"
                    solo
                    required
                    @input="$v.value.$touch()"
                    @blur="$v.value.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12"> </v-col>
              </v-row>
              <v-row>
                <v-btn
                  class="ma-4"
                  @click="submit"
                  :color="$v.$anyError ? 'gray' : 'primary'"
                  :disabled="btnDisabled"
                >
                  <span class="white--text"> EDIT </span>
                </v-btn>
                <v-btn class="ma-4" @click="cancelEdit" :color="'red'">
                  <span class="white--text"> Cancel </span>
                </v-btn>
              </v-row>
            </v-container>
          </form>
        </v-card>
      </v-col>
    </v-row>
  </Dashboard>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar";
import axios from "axios";

export default {
  name: "branch-edit",
  mixins: [validationMixin],

  validations: {
    value: { required },
  },
  data() {
    return {
      id: "",
      name: "",
      display_name: "",
      value: "",
      initialErrors:"",
      isLoading: false,
      pageNavItems: [
        {
          text: "Dashboard",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "dashboard" },
        },
        {
          text: "Settings",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "settings" },
        },
        {
          text: "Edit",
          disabled: true,
          link: true,
          exact: true,
          to: { name: "dashboard" },
        },
      ],
    };
  },
  components: {
    Dashboard,
  },
  computed: {
    btnDisabled() {
      return (this.value == null);
    },
    valueErrors() {
      const errors = [];
      if (!this.$v.value.$dirty) return errors;
      !this.$v.value.required && errors.push("Value is required");
      return errors;
    },
  },
  created() {
    this.setEditValues();
  },
  methods: {
    async submit() {
     // this.$v.$touch();

     this.isLoading = true;

      axios
        .put("system-setting/" + this.id, {
          name: this.name,
          current_value: this.value,
        })
        .then((response) => {
          console.log(response);
          this.$toast.success("Record updated successfully!");
          this.isLoading = false;
          // this.btnDisabled = false;
          this.cancelEdit();
        })
        .catch((e) => {
          
          this.$toast.error(e.response?.data?.message);
          this.isLoading = false;
        });
    },
    setEditValues() {
      const item = this.$route.params?.item;
      this.id = item.id;
      this.name = item.name;
      this.value = item.current_value;
      this.display_name = item.display_name;
    },
    cancelEdit() {
      this.$router.push({ name: "settings" });
    },
  },
};
</script>
