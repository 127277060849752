<template>
  <Dashboard selectedMainItem="Pickup Operation" selectedSubItem="Pickup Pending">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-text-field
            v-model="filter.code"
            class="ml-5"
            label="Code"
            @input="reload()"
            type="text"
            required
          ></v-text-field>
        </v-col>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-select
            :items="package_type"
            v-model="filter.package_id"
            item-text="name"
            item-value="id"
            label="Package Type"
            required
            v-on:change="reload()"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                class="ml-5"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                label="Start Date ( Pickup )"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                class="ml-5"
                label="End Date ( Pickup )"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(date_end);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col align="right" cols="12" sm="6" md="2">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="fuelData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
        :items-per-page="ROWS_PER_PAGE"
      >
        <template v-slot:[`item.code`]="{ item }">
          <CodeColumn :code="item.code" :stage_return="item.active_stage.recurring" :sender_return="item.returned" />
        </template>
        <template v-slot:[`item.same_day`]="{ item }">
            <span v-if="item.same_day" class="text-red">Same Day</span>
            <span v-else >Next Day</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="success" @click="editPackage(item)">
            Assign Courier
          </v-btn>
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="viewPackage(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-eye </v-icon>
                  View More
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="toDelete(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-delete </v-icon>
                  Reject
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate store="User" method="fetchPendingPackages" :filter="filter" />
    </v-card>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to reject this item?
        </v-card-title>
        <v-textarea
          label="Reason"
          class="ma-5"
          @input="$v.description.$touch()"
          @blur="$v.description.$touch()"
          v-model="description"
          :error-messages="descErrors"
        ></v-textarea>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            cancel
          </v-btn>

          <v-btn
            color="error"
            text
            @click="deleteItem()"
            :disabled="btnDisabled"
          >
            reject
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/Paginate";
import CodeColumn from "@/components/Common/CodeColumn/Index";

export default {
  name: "all-operation",
  mixins: [validationMixin],

  validations: {
    description: { required },
  },
  data() {
    return {
      filter: {
        start: this.date,
        end: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
        package_id: null,
        page: 1,
        code: "",
      },
      fuelErrors: "",
      search: "",
      calories: "",
      deleteId: "",
      dialogDelete: false,
      isLoading: false,
      date: "",
      date_end: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      description: "",
      dialog: false,
      item: {},
      clientType: [
        { name: "corporate", id: 1 },
        { name: "regular", id: 2 },
      ],
      package_type: [
        { name: "Documents", id: 1 },
        { name: "Parcel", id: 2 },
      ],
      vehicleId: "",
      client_id: "",
    };
  },
  components: {
    Dashboard,
    paginate,
    CodeColumn
},
  computed: {
    fuelData: {
      get() {
        return this.$store.getters.pendingPackages;
      },
    },
    btnDisabled() {
      const visible = this.$v.$anyError || !this.description;
      return visible;
    },
    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Client Name",
          align: "start",
          sortable: false,
          value: "client_name",
        },
        {
          text: "Package Type",
          value: "package_type_text",
          sortable: false,
        },
        {
          text: "Delivery Type",
          value: "same_day",
          sortable: false,
        },
        {
          text: "Pickup Date",
          value: "package_date",
          sortable: false,
        },
        {
          text: "Pickup Address",
          value: "pickup_address",
          sortable: false,
        },
        {
          text: "Delivery Address",
          value: "delivery_address",
          sortable: false,
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
    descErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.required && errors.push("Description is required.");
      return errors;
    },
  },
  created() {
    this.fetchPendingPackages(this.filter);
    this.fetchCouriersUser();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "fetchPendingPackages",
      "deletePackage",
      "fetchCouriersUser",
    ]),
    async editPackage(item) {
      this.$router.push({
        name: "delivery-assign",
        params: { item: item, type: 1 },
      });
    },
    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    reload() {
      const filter = {
        start: this.date,
        code: this.filter.code,
        end: this.date_end,
        client_type: this.client_id === 0 ? "" : this.client_id,
        package_type:
          this.filter.package_id === 0 ? "" : this.filter.package_id,
      };
      this.fetchPendingPackages(filter);
    },
    clear() {
      this.filter = {
        stage_id: "",
        code: "",
        package_id: "",
      };
      this.date = "";
      this.description = "";
      this.date_end = "";
      this.$v.$reset();
      this.reload();
    },
    toDelete(item) {
      this.dialog = true;
      this.item = item;
    },
    async deleteItem() {
      this.$v.$touch();
      this.btnDisable = true;
      this.isLoading = true;
      const toSend = {
        id: this.item.id,
        desc: this.description,
      };
      await this.deletePackage(toSend)
        .then((response) => {
          console.log(response);
          this.$toast.success("Item rejected");
          this.dialog = false;
          this.isLoading = false;
          this.clear();
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.isLoading = false;
          this.dialog = false;
        });
    },
  },
};
</script>
<style >
.text-red{
  color: red;
}

.text-green{
  color: green;
}
</style>