<template>
  <Dashboard selectedMainItem="Transfer Operation" selectedSubItem="Transfer In">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-text-field
            v-model="filter.code"
            class="ml-5"
            label="Code"
            @input="reload()"
            type="text"
            required
          ></v-text-field>
        </v-col>

        <v-col align="right" cols="12" sm="6" md="4"></v-col>
        <v-col align="right" cols="12" sm="6" md="4">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="packages"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="success" class="ma-4" @click="markItem(item)">
            <span>Received</span>
          </v-btn>
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="viewPackage(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-eye </v-icon>
                  View More
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate store="User" method="packages" :filter="filter" />
    </v-card>
    <v-dialog v-model="dialogCollected" max-width="450">
      <v-card>
        <v-card-title class="headline"
          >Do you want to mark as received ?</v-card-title
        >
        <v-card-text>You're going to mark the package as collected</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" dark @click="dialogCollected = false"
            >Cancel</v-btn
          >

          <v-btn color="green darken-1" dark @click="editPackage()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Dashboard>
</template>

<script>
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/Paginate";
import axios from "axios";

export default {
  name: "TransferIn",
  data() {
    return {
      name: "",
      filter: {
        name: this.name,
        status: 1,
        code: "",
      },
      packages: [],
      isLoading: false,
      menu: false,
      code: "",
      type: "",
      menu2: false,
      dialogCollected: false,
      dialog: false,
      item: {},
      mark_item: {},
      collectedType: [
        { name: "collected", id: 1 },
        { name: "not-collected", id: 2 },
      ],
    };
  },
  components: {
    Dashboard,
    paginate,
  },
  computed: {
    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Vehicle Number",
          value: "vehicle_reg",
          sortable: false,
        },
        {
          text: "Package Type",
          value: "package_type_text",
          sortable: false,
        },
        {
          text: "Pickup City",
          value: "pickup_city_name",
          sortable: false,
        },
        {
          text: "Delivery City",
          value: "delivery_city_name",
          sortable: false,
        },
        {
          text: "Date",
          value: "package_date",
          sortable: false,
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.getPackages();
  },
  mounted() {},
  methods: {
    editPackage() {
      axios
        .put("transfer-package/received/" + this.mark_item.transfer_package_id)
        .then(() => {
          this.getPackages();
          this.dialogCollected = false;
          this.$toast.success("Package marked as received");
        });
    },
    getPackages() {
      axios
        .get("package/get-transferring", {
          params: {
            name: this.filter.name,
            page: this.filter.page,
            code: this.filter.code,
          },
        })
        .then((response) => {
          console.log(response.data.package);
          this.packages = response.data.package;
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.isLoading = false;
          this.btnDisabled = false;
        });
    },
    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    markItem(item) {
      this.mark_item = item;
      this.dialogCollected = true;
    },
    reload() {
      this.getPackages();
    },
    clear() {
      this.filter = {
        stage_id: "",
        code: "",
      };
      this.reload();
    },
  },
};
</script>
