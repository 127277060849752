import axios from "axios";

const state = {
  attendanceUsers: [],
  attendanceAllUsers: [],
  userRoles: [],
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
};

const getters = {
  attendanceUsers: (state) => state.attendanceUsers,
  attendanceAllUsers: (state) => state.attendanceAllUsers,
  setPagination: (state, paginate) => (state.paginate = paginate),
  setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
  // allUserRoles: (state) => state.userRoles,
  // editUser: (state) => state.user,
  // usersPaginate: (state) => state.paginate,
  // userStatus: (state) => state.userStatus,
  // usersTypes: (state) => state.usersTypes,
};

const actions = {
  async fetchAttendanceUsers({ commit }, filter) {
    const allUsers = await axios.get("attendance/get-paginate", {
      params: {
        name: filter.name,
        start: filter.start,
        end: filter.end,
        page: filter.page,
      },
    });

    commit("setAttendanceUsers", allUsers.data.data);
    commit("setPagination", allUsers.data.pagination);
    return allUsers;
  },

  async getAllUsersAttendance({ commit }, filter) {
    const allUsers = await axios.get("attendance/get-users", {
      params: { date: filter.date, page: filter.page },
    });

    commit("setAttendanceAllUsers", allUsers.data.data);
    return allUsers.data;
  },

  async markUser({ commit }, attendance) {
    const user_id = attendance.id;
    const in_date = attendance.in_date;
    const out_date = attendance.out_date;
    const out_time = attendance.out_time;
    const in_time = attendance.in_time;
    const user = await axios.post("attendance", {
      user_id,
      in_date,
      out_date,
      in_time,
      out_time,
    });
    commit("newUser", user.data.data);
    return user;
  },

  // async userUpdate(
  //   { commit },
  //   {
  //     id,
  //     username,
  //     role_id,
  //     email,
  //     phone,
  //     status,
  //     password,
  //     password_confirmation,
  //     first_name,
  //     last_name,
  //   }
  // ) {
  //   console.log(username);
  //   const user = await axios.put("user/" + id, {
  //     username,
  //     role_id,
  //     email,
  //     phone,
  //     status,
  //     password,
  //     password_confirmation,
  //     first_name,
  //     last_name,
  //   });
  //   console.log('server response');
  //   console.log(user);
  //   commit("updateUser", user.data.data);
  //   return user;
  // },

  async getUserById({ commit }, userId) {
    const user = await axios.get("user/" + userId);
    commit("setUser", user.data.data);
    return user.data.data;
  },

  async deleteUserById({ commit }, id) {
    await axios.delete(`user/${id}`);

    commit("removeUser", id);
  },
};

const mutations = {
  setAttendanceUsers: (state, attendanceUsers) =>
    (state.attendanceUsers = attendanceUsers),
  setAttendanceAllUsers: (state, attendanceAllUsers) =>
    (state.attendanceAllUsers = attendanceAllUsers),
  setUser: (state, user) => (state.user = user),
  setAllUserRoles: (state, Roles) => (state.userRoles = Roles),
  setPagination: (state, paginate) => (state.paginate = paginate),
  setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
  // newUser: (state, user) => {
  //   // state.users.unshift(user);
  //   state.user = null;
  // },
  // removeUser: (state, id) => {
  //   state.users = state.users.filter((user) => user.id !== id);
  // },
  // updateUser: (state, updUser) => {
  //   console.log("muta", state);
  //   const index = state.users.findIndex((user) => user.id === updUser.id);
  //   console.log("index", index);
  //   if (index !== -1) {
  //     state.users.splice(index, 1, updUser);
  //   }
  // },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
