<template>
<v-app id="app">
  <router-view></router-view>
</v-app>
</template>

<script>
export default {

  name: 'App',

  data(){

    return{

      user:null

    };
  },
};
</script>
