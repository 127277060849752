import Vue from 'vue';
import axios from "axios";
import * as common from './common';


Vue.mixin({
    data() {
        return {
            ROWS_PER_PAGE:common.ROWS_PER_PAGE
        }
    },    
    methods: {
        hasAnyPermission: function (permission_array) {
            if (!Array.isArray(permission_array)) {
                permission_array = [permission_array];
            }
            if (permission_array.length == 0) return true;
            var allPermissions = this.$store.getters.authUser.permissions;
            var hasPermission = false;
            permission_array.forEach(function (item) {
                if (allPermissions.includes(item)) {
                    hasPermission = true;
                    return;
                }
            });
            
            return hasPermission;
        },
        ucFirst(string) {
            const lower = string.toLowerCase();
            return lower.charAt(0).toUpperCase() + lower.slice(1);
        },
        showError(message = 'Unexpected error'){
            this.$toast.error(message);
        },
        
        exportExcelCommon(url, name, finalCallback) {
            axios
            .get(url, {
                    headers:
                    {
                        'Content-Disposition': "attachment; filename=template.xlsx",
                        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    },
                    responseType: 'arraybuffer',
                })
                .then((response) => {
                    // if (response.data.status == "success") {
                        const url = window.URL.createObjectURL(
                            new Blob([response.data])
                            );
                            const link = document.createElement("a");
                            console.log(link);
                            link.href = url;
                            link.setAttribute("download", name); //or any other extension
                            // link.setAttribute("target", "_blank");
                            document.body.appendChild(link);
                            link.click();
                            // } else {
                                //   alert("Not Authenticated");
                    // }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    finalCallback();
                });
            },
            formatNumbers(number) {
            const options = {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            };
            return Number(number).toLocaleString('en', options);
        },

    }
});
