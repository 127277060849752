<template>
<Dashboard
  selectedMainItem = "Administration"
  selectedSubItem = "Faq">
      <template >
      <FilterBar @updateFilterData="setFilterValues" forName="branch" />
    </template>

  <v-card
      style="margin:30px;border: 0;"
      :loading="isLoading"
  >
      <v-row>
      <v-col
          cols="12"
          sm="6"
          md="4"
          >
      </v-col>
      <v-col
          cols="12"
          sm="6"
          md="4"
          >
      </v-col>
      <v-col
          align="right"
          cols="12"
          sm="6"
          md="4"
          >
          <v-btn
          class="mr-5"
          color="primary"
          elevation="2"
          :to="{name: 'branch-create'}"
          >
          ADD
              <v-icon right>
                  mdi-file-plus
              </v-icon>
          </v-btn>
          
      </v-col>
    </v-row>


    <v-data-table
      :headers="headers"
      :items="allBranches"
      item-key="id"
      class="elevation-1"
      hide-default-footer
    >

      <template v-slot:[`item.actions`]="{ item }">


      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="editBranch(item)"
          >
            <v-list-item-title>
              <v-icon
                class="mr-3"
              >
                mdi-pencil
              </v-icon>
              Edit
            </v-list-item-title>
          </v-list-item>

          <v-list-item
          @click="branchView(item)"
          >
            <v-list-item-title>
              <v-icon
                class="mr-3"
              >
                mdi-eye
              </v-icon>
              View
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="AssignCity(item)"
          >
            <v-list-item-title>
              <v-icon
                class="mr-3"
              >
                mdi-home
              </v-icon>
              Assign City
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="setBranchDelete(item)"
          >
            <v-list-item-title>
              <v-icon
                class="mr-3"
              >
                mdi-delete
              </v-icon>
              Delete
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    </v-data-table>
        <paginate
        store="Branch"
        method="fetchBranches"
        :filter ="filter"
        />
      <!-- ******************* delete user dialog ********************** -->

    <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
        <v-card-title class="headline">Branch Delete?</v-card-title>
        <v-card-text>Do you want to Delete ?</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialogDelete = false">Cancel</v-btn>

            <v-btn color="red darken-1" text @click="branchDelete">Delete</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
  </v-card>
</Dashboard >
</template>

<script>
  import { mapActions } from "vuex";
  import Dashboard from '../../components/Layouts/Leftbar'
  import paginate from '../../components/Common/Paginate';
  import FilterBar from '../../components/Common/FilterBar';

  export default {
    name:"all-operation",
    data () {
      return {
        filter:{
          search:'',
          page:1,
        },
        search: '',
        calories: '',
        deleteId: '',
        isLoading: false,
        dialogDelete: false,
      }
    },
    components:{
      paginate,
      FilterBar,
      Dashboard,
    },
    computed: {

    allBranches:{
        get(){
            return this.$store.getters.allBranches;
        }
      },
    vehicleTypes:{
        get(){
          const typeAll =[{ id:'', name: 'All Types'}] ;
          const vehicleArray = this.$store.getters.vehiclesTypes;
          
          return typeAll.concat(vehicleArray);
        }
      },
      headers () {
        return [
          {
            text: 'Branch name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          {
            text: 'Address',
            value: 'address',
            sortable: false
          },
          { 
            text: 'Phone', 
            value: 'phone',
            sortable: false 
          },
          { 
            text: 'Opening Time', 
            value: 'opening_time',
            sortable: false 
          },
          { 
            text: 'Actions',  
            align: 'end', 
            value: 'actions',
            sortable: false 
          },
        ]
      },
    },
    created() {
      this.fetchBranches(this.filter);

    },
    methods: {
      ...mapActions(["fetchBranches","deleteBranchById"]),
      filterOnlyCapsText (value, search) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },

      async editBranch(branch){
        await this.$store.dispatch('getBranchById',branch.id);
        this.$router.push({name:'branch-edit'});
      },

      async branchView(branch){
        await this.$store.dispatch('getBranchById',branch.id);
        this.$router.push({name:'branch-view'});
      },
      setBranchDelete(item){
        
        this.deleteId = item.id;
        this.dialogDelete = true;
      },
      AssignCity(item){
        this.$router.push({ name: 'branch-areas', params: { branch: item } });
      },
      
      async branchDelete(){
          this.dialogDelete = false;
          await this.deleteBranchById(this.deleteId).then( response =>{

          console.log(response);
          this.$toast.success("Branch deleted successfully!");

        }).catch((e)=>{
          this.$toast.error(e.response.data.message);
        });
      },
      setFilterValues(childValues){

        this.filter.search = childValues.search;
        this.fetchBranches(this.filter);
      },
      uppercase() {
        this.search = this.search.toUpperCase();
      },
    },
  }
</script>