<template>

<Dashboard
  selectedMainItem = "Administration"
  selectedSubItem = "Route">

  <v-card
  style="margin:20px;border: 0;"
  :loading="isLoading"
  >
  <v-card-title>
    Route View
  </v-card-title>
  <v-card-subtitle>
    view route here
    <template>
    <div>
      <v-breadcrumbs
      class="pl-0 pt-0"  
      :items="pageNavItems"></v-breadcrumbs>
    </div>
  </template>
  </v-card-subtitle>
    <v-container>
      <v-row
        class="ml-5 mr-5" 
      >
        <v-col
          cols="12"
          md="6"
        >
        <v-card
          height="200"
          elevation="2"
        >
          <v-card-title>
            Route Details
          </v-card-title>

            <v-list-item >
              <v-list-item-content>
                <v-list-item-title>Name  : {{ route.name }}</v-list-item-title>
                <v-list-item-title>Description  : {{ route.description }}</v-list-item-title>
                <v-list-item-title>Total Cities  : {{ route.cities_count }}</v-list-item-title>
                <v-list-item-title> Status  :
                {{ (route.status)?'Active':'Inactive' }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

        </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
        <v-card
          height="200"
          elevation="2"
        >
          <v-card-title>
            Branch Details
          </v-card-title>

            <v-list-item >
              <v-list-item-content>
                <v-list-item-title>Name  : {{ route.branch.name }}</v-list-item-title>
                <v-list-item-title>Address  : {{ route.branch.address }}</v-list-item-title>
                <v-list-item-title>Phone  : {{ route.branch.phone }}</v-list-item-title>
                <v-list-item-title>Opening Time  : {{ route.branch.phone }}</v-list-item-title>
                <v-list-item-title>Status  : {{ (route.branch.status)?'Active':'Inactive' }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

        </v-card>
        </v-col>
      </v-row>

      <v-row
        class="ml-5 mr-5" 
      >
          <v-col
          cols="12"
          md="12"
        >
        <v-col
          cols="12"
          md="12"
        >
        <h3>Covering Cities </h3>
        </v-col>
            <v-data-table
            :headers="headers"
            :items="route.cities"
            item-key="id"
            class="elevation-1"
            hide-default-footer
            >
            </v-data-table>
        </v-col>
      </v-row>
    </v-container>

  </v-card>
</Dashboard>
</template>

<script>
  import Dashboard from '../../components/Layouts/Leftbar'


  export default {
    name:'route-view',

    data(){
      return{

        isLoading : false,
        pageNavItems: [
        {
          text: 'Dashboard',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
        {
          text: 'Route',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'route'}
        },
        {
          text: 'View',
          disabled: true,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
      ],
      }
    },
    components:{
        Dashboard
    },
    computed: {
      route:{
        get(){
            return this.$store.getters.setRoute;
        }
      },
        headers () {
        return [
          {
            text: 'City name',
            value: 'location_name',
            sortable: false
          }
        ]
      },

    },
    methods: {

    cancelEdit(){
      this.$router.push({name:'route'});
    }
    },
  }
</script>
