<template>

<Dashboard
    selectedMainItem = "Transport"
  selectedSubItem = "Vehicle">
  <v-row>
      <v-col cols="12" md="6">
  <v-card
  style="margin:20px;border: 0;"
  :loading="isLoading"
  >
  <v-card-title>
    Vehicle Edit
  </v-card-title>
  <v-card-subtitle>
    Edit vehicle
    <template>
    <div>
      <v-breadcrumbs
      class="pl-0 pt-0"  
      :items="pageNavItems"></v-breadcrumbs>
    </div>
  </template>
  </v-card-subtitle>
      <form style="margin-top:40px;border: 0;">
        <v-container>
        <v-row>
          <v-col cols="12" md="12">
                  <v-select
                    v-model="branch"
                    :items="branches"
                    item-text="name"
                    item-value="id"
                    :error-messages="branchErrors"
                    label="Select branch"
                    placeholder="Select branch"
                    solo
                    required
                  ></v-select>
                </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-model="type"
              :items="vehicleTypes"
              item-text="name"
              item-value="id"
              :error-messages="typeErrors"
              label="Type"
              solo
              required
              @input="$v.type.$touch()"
              @blur="$v.type.$touch()"
            ></v-select>
          </v-col>
          
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="regNumber"
              :error-messages="regNumberErrors"
              :counter="10"
              label="Reg Number"
              solo
              required
              @input="$v.regNumber.$touch()"
              @blur="$v.regNumber.$touch()"
              @keyup="uppercase"
            >
            </v-text-field>
          </v-col>
          
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="name"
              :error-messages="nameErrors"
              :counter="20"
              label="Vehicle Name"
              solo
              required
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-model="fuel"
              :items="fuelTypes"
              item-text="name"
              item-value="id"
              :error-messages="fuelErrors"
              label="Fuel Type"
              solo
              required
              @input="$v.fuel.$touch()"
              @blur="$v.fuel.$touch()"
            ></v-select>
          </v-col>
          
          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-model="status"
              :items="vehicleStatus"
              item-text="name"
              item-value="id"
              :error-messages="statusErrors"
              label="Status"
              solo
              required
              @input="$v.status.$touch()"
              @blur="$v.status.$touch()"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            md="6">
            <v-checkbox
              v-model="isCompany"
              :error-messages="checkboxErrors"
              label="Is Company?"
              @change="$v.checkbox.$touch()"
              @blur="$v.checkbox.$touch()"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="6"
            md="6">
            <v-checkbox
              v-model="shuttle"
              :error-messages="shuttleErrors"
              label="Shuttle"
              @change="$v.shuttle.$touch()"
              @blur="$v.shuttle.$touch()"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>          
          <v-btn
            class="ma-4"
            @click="submit"
            :color="$v.$anyError ? 'gray' : 'primary'"
            :disabled="btnDisabled"
          >
            Edit
          </v-btn>
          <!-- <v-btn 
          class="mr-4"
          @click="clear">
            clear
          </v-btn> -->
          <v-btn  
          class="ma-4"
          @click="cancelEdit"
          :color="'error'">
            Cancel
          </v-btn>
        </v-row>
        </v-container>
    </form>
  </v-card>
  </v-col>
  </v-row>
</Dashboard>
</template>

<script>
  import { mapActions } from "vuex";
  import { validationMixin } from 'vuelidate'
  import { required, maxLength } from 'vuelidate/lib/validators'
  import Dashboard from '../../../components/Layouts/Leftbar'
  import axios from 'axios';


  export default {
    name:'vehicle-edit',
    mixins: [validationMixin],

    validations: {
      name: { required, maxLength: maxLength(20) },
      regNumber: { required },
      type: { required },
      status: { required },
       branch: { required },
      fuel: { required },
      isCompany: {
        checked (val) {
          return val
        },
      },
      shuttle: {
        checked (val) {
          return val
        },
      },
    },
    props:['setVehicleEdit'],
    data(){
      return{
        id : '',
        name : '',
        regNumber : '',
        type : '',
         branch: null,
    branches: [],
        shuttle:0,
        fuel:null,
        status : '',
        isCompany : '',
        isLoading : false,
        checkbox: false,
        fuelTypes:[
          {
            id:1,
            name:'Petrol'
          },
          {
            id:2,
            name:'Diesel'
          }
        ],
        pageNavItems: [
        {
          text: 'Dashboard',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
        {
          text: 'Vehicle',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'vehicle'}
        },
        {
          text: 'Edit',
          disabled: true,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
      ],
      }
    },
    created() {
    this.getBranches();
      this.setEditValues();

  },
    components:{
        Dashboard
    },
    computed: {
      vehicleTypes:{
        get(){
            return this.$store.getters.vehiclesTypes;
        }
      },
      vehicleStatus:{
      get(){
          return this.$store.getters.vehicleStatus;
        }
      },
      btnDisabled () {
        return !(this.name && this.regNumber && this.type && this.fuel && this.branch) || this.isLoading;
      },
      fuelErrors () {
        const errors = []
        if (!this.$v.fuel.$dirty) return errors
        return errors
      },
      shuttleErrors () {
        const errors = []
        if (!this.$v.shuttle.$dirty) return errors
        return errors
      },
      checkboxErrors () {
        const errors = []
        if (!this.$v.isCompany.$dirty) return errors
        return errors
      },
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.required && errors.push('Name is required')
        return errors
      },
      regNumberErrors () {
        const errors = []
        if (!this.$v.regNumber.$dirty) return errors
        !this.$v.regNumber.required && errors.push('Register number is required.')
        return errors
      },
      branchErrors() {
      const errors = [];
      if (!this.$v.branch.$dirty) return errors;
      !this.$v.branch.required && errors.push("Branch is required.");
      return errors;
    },
      typeErrors () {
        const errors = []
        if (!this.$v.type.$dirty) return errors
        !this.$v.type.required && errors.push('Type is required.')
        return errors
      },
      statusErrors () {
        const errors = []
        if (!this.$v.status.$dirty) return errors
        !this.$v.status.required && errors.push('Status is required.')
        return errors
      },
    },
    methods: {

      ...mapActions(["vehicleUpdate"]),

      getBranches() {
      axios
        .get("branch")
        .then((response) => {
          this.branches = response.data.data;
        })
    },

      async submit () {

        this.$v.$touch();

        this.isLoading = true;
        await this.vehicleUpdate({
            id:this.id,
            name: this.name,
            branch_id: this.branch,
            fuel_type:this.fuel,
            is_shuttle:this.shuttle,
            reg_number : this.regNumber,
            type : this.type,
            status : this.status,
            is_company : this.isCompany,
        }).then( response =>{

          console.log(response);
          this.$toast.success("Vehicle updated successfully!");
          this.isLoading = false;
          this.cancelEdit();


        }).catch((e)=>{
          
          this.$toast.error(e.response.data.message);
          this.isLoading = false;
        });
      },
      clear () {
        this.$v.$reset()
        this.name = ''
        this.regNumber = ''
        this.type = ''
      this.branch = null;
        this.fuel = null
        this.shuttle = false
        this.status = null
        this.isCompany = false
      },
      uppercase() {
        this.regNumber = this.regNumber.toUpperCase();
    },
    setEditValues(){
        this.id =  this.$store.getters.editVehicle.id ; 
        this.name =  this.$store.getters.editVehicle.name ; 
        this.branch =  this.$store.getters.editVehicle.branch_id ; 
        this.regNumber =  this.$store.getters.editVehicle.reg_number ; 
        this.type =  this.$store.getters.editVehicle.type ; 
        this.fuel =  this.$store.getters.editVehicle.fuel_type ; 
        this.shuttle =  this.$store.getters.editVehicle.is_shuttle ; 
        this.status =  this.$store.getters.editVehicle.status ; 
        this.isCompany =  this.$store.getters.editVehicle.is_company ; 
    },
    cancelEdit(){
      this.$router.push({name:'vehicle'});
    }
    },
  }
</script>
