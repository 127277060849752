<template>
  <Dashboard selectedMainItem="Finance" selectedSubItem="Payments">
    <v-row>
      <v-col cols="12" md="6">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-card-title>Payment</v-card-title>

      <v-card-subtitle>
        Payment
        <template>
          <div>
            <v-breadcrumbs
              class="pl-0 pt-0"
              :items="pageNavItems"
            ></v-breadcrumbs>
          </div>
        </template>
      </v-card-subtitle>
      <form style="margin-top: 40px; border: 0">
        <v-container>
          <v-row>

            <v-col cols="12" md="12">
              <p>Total Amount : {{amount.toFixed(2)}}</p>
              <!-- <v-text-field
                type="number"
                name="amount"
                label="Amount"
                :disabled="true"
                id="id"
                v-model="amount"
              ></v-text-field> -->
            </v-col>

            <v-col cols="12" md="12">
              <v-select
                :items="types"
                v-model="type"
                item-text="name"
                item-value="id"
                label="Select Payment Type"
                :error-messages="typeErrors"
                v-on:change="directToBank(couriersData)"
                required
              ></v-select>
            </v-col>

           
            <v-col cols="12" md="12">
              <v-text-field
                type="text"
                name="description"
                label="Description"
                v-model="description"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn @click="cancelRegister"  :color="'red'">
                <span class="white--text"> Cancel </span>
              </v-btn>
              <v-btn color="success" class="ml-2" @click="submit">
                <span> Submit </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </form>
      <!-- <v-data-table
        :headers="headers"
        :items="paymentData"
        item-key="id"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.package_type`]="{ item }">
          <span>{{ setPageTypeName(item) }}</span>
        </template></v-data-table
      > -->
    </v-card>
    </v-col>
    </v-row>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar.vue";

export default {
  name: "payment-create",
  mixins: [validationMixin],

  validations: {
    vehicleId: { required },
    fuel_qty: { required },
  },

  data: () => ({
    type: 3,
    amount: 0,
    status: 1,
    typeErrors: "",
    description: "",
    types: [
      { name: "Bank Transfer", id: 3 },
    ],
    package: "",
    selectedItem: {},
    isLoading: false,
    btnDisable: false,
    to_day: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    item: [],
    pageNavItems: [
      {
        text: "Dashboard",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "dashboard" },
      },
      {
        text: "Payment",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "payment" },
      },
      {
        text: "Add",
        disabled: true,
        link: true,
        exact: true,
        to: { name: "payment-edit" },
      },
    ],
  }),
  components: {
    Dashboard,
  },
  created() {
    this.fetchPaymentGetAll();
  },
  computed: {
    couriersData: {
      get() {
        return this.$store.getters.couriers;
      },
    },
    paymentData: {
      get() {
        return this.$store.getters.PaymentGetAll;
      },
    },
    headers() {
      return [
        {
          text: "Client Name",
          align: "start",
          sortable: false,
          value: "client_name",
        },
        {
          text: "Package Type",
          value: "package_type_text",
          sortable: false,
        },
        {
          text: "Payment Type",
          value: "payment_type_text",
          sortable: false,
        },
        {
          text: "Total Amount",
          value: "total_amount",
          sortable: false,
        },
        {
          text: "Paid Amount",
          value: "paid_amount",
          sortable: false,
        },
      ];
    },
  },
  mounted() {
    const item = this.$route.params?.item;
    if (item == null) {
      this.$router.push({ name: "payment" });
    }
    this.package = item;
    this.amount = item.total_amount;

    var result = this.couriersData.filter((obj) => {
      return obj.selected === 1;
    });
    this.selectedItem = result[0];
  },
  methods: {
    ...mapActions(["fetchPaymentGetAll", "UpdatePayment"]),
    async directToBank(bankID) {
      var result = bankID.filter((obj) => {
        return obj.id === this.courierId;
      });
      this.selectedItem = result[0];
    },
    async submit() {
      this.$v.$touch();
      this.btnDisable = true;
      this.isLoading = true;
      const type = this.type;
      const amount = this.amount;
      const package_id = this.package.id;
      const toSend = {
        package_id,
        type,
        amount,
        description: this.description,
      };

      await this.UpdatePayment(toSend)
        .then((response) => {
          console.log(response);
          this.$toast.success("Payment added successfully!");
          this.isLoading = false;
          this.$router.push({ name: "payment" });
          this.clear();
        })
        .catch((e) => {
          this.isLoading = false;
          this.$toast.error(e.response.data.message);
        });
    },
    clear() {
      this.$v.$reset();
      this.amount = "";
      this.type = 3;
      this.description = "";
    },
    uppercase() {
      this.regNumber = this.regNumber.toUpperCase();
    },
    cancelRegister() {
      this.$router.push({ name: "payment" });
    },
  },
};
</script>
