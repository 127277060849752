<template>
    <Dashboard
      selectedMainItem="Pickup Operation"
      selectedSubItem="Pickup Accept"
    >
      <v-card style="margin: 30px; border: 0" :loading="isLoading">
        <v-row>
          <v-col align="right" cols="12" sm="6" md="2">
            <v-text-field
              v-model="filter.code"
              label="Code"
              class="ml-5"
              @input="getData()"
              type="text"
              required
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3"
            ><v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  class="ml-5"
                  label="Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.menu.save(date);
                    getData();
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="3"
            ><v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="date_end"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_end"
                  class="ml-5"
                  label="End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date_end" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu2 = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.menu2.save(date_end);
                    getData();
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
  
          <v-col align="right" cols="12" sm="6" md="2"> </v-col>
          <v-col align="right" cols="12" sm="6" md="2">
            <v-btn color="error" class="ma-4" @click="clear">
              <span>Clear</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="PickupAccept"
          item-key="id"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="error" @click="toReject(item)"> Reject </v-btn>
  
            <v-menu left bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
  
              <v-list>
                <v-list-item @click="viewPackage(item)">
                  <v-list-item-title>
                    <v-icon class="mr-3"> mdi-eye </v-icon>
                    View More
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="toAccept(item)">
                  <v-icon class="mr-3"> mdi-checkbox-marked-circle </v-icon>
                  <v-list-item-title> Accept </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <paginate store="User" method="getData" :filter="filter" />
      </v-card>
      <v-dialog v-model="dialog_reject" max-width="500">
        <v-card>
          <v-card-title class="text-h5">
            Are you sure you want to reject this item?
          </v-card-title>
          <v-textarea
            v-model="description"
            label="Reason"
            class="ma-5"
          ></v-textarea>
          <v-card-actions>
            <v-spacer></v-spacer>
  
            <v-btn color="green darken-1" text @click="dialog_reject = false">
              cancel
            </v-btn>
  
            <v-btn color="error" text @click="rejectItem()"> reject </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog_accept" max-width="500">
        <v-card>
          <v-card-title class="text-h5">
            Are you sure you want to accept this item?
          </v-card-title>
         
          <v-card-actions>
            <v-spacer></v-spacer>
  
            <v-btn color="error" text @click="dialog_accept = false">
              cancel
            </v-btn>
  
            <v-btn color="green darken-1" text @click="acceptItem()"> accept </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </Dashboard>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import Dashboard from "../../components/Layouts/Leftbar";
  import paginate from "../../components/Common/Paginate";
  import axios from "axios";
  
  export default {
    name: "pickup-assigned-staff",
    data() {
      return {
        name: "",
        filter: {
          name: this.name,
          status: 1,
          page: 1,
          code: "",
        },
        PickupAccept:[],
        isLoading: false,
        menu: false,
        date: "",
        date_end: "",
        menu2: false,
        description: "",
        dialog: false,
        dialog_reject: false,
        dialog_accept:false,
        mark_item: {},
        selected: [],
        collectedType: [
          { name: "collected", id: 1 },
          { name: "not-collected", id: 2 },
        ],
      };
    },
    components: {
      Dashboard,
      paginate,
    },
    computed: {
      DeliveryAssigned: {
        get() {
          return this.$store.getters.DeliveryAssigned;
        },
      },
  
      headers() {
        return [
          {
            text: "Code",
            align: "start",
            sortable: false,
            value: "code",
          },
          {
            text: "Courier name",
            align: "start",
            sortable: false,
            value: "pickup_courier_name",
          },
          {
            text: "Package Type",
            value: "package_type_text",
            sortable: false,
          },
          {
            text: "Pickup City",
            value: "pickup_city_name",
            sortable: false,
          },
          {
            text: "Pickup Address",
            align: "start",
            sortable: false,
            value: "pickup_address",
          },
          {
            text: "Pickup Date",
            value: "package_date",
            sortable: false,
          },
          {
            text: "Operation",
            align: "end",
            value: "actions",
            sortable: false,
          },
        ];
      },
    },
    created() {
      this.getData(this.filter);
    },
    mounted() {},
    methods: {
      ...mapActions(["MoveToShipping"]),
      async editPackage() {
        this.isLoading = true;
  
        await this.MoveToShipping(this.mark_item.id)
          .then((response) => {
            console.log(response);
            this.$toast.success("Updated successfully!");
            this.dialog = false;
            this.isLoading = false;
            this.getData(this.filter);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message);
            this.dialog = false;
            this.isLoading = false;
          });
      },
      markItem(item) {
        this.mark_item = item;
        this.dialog = true;
      },
      toReject(item) {
        this.dialog_reject = true;
        this.item = item;
      },
      toAccept(item) {
        this.dialog_accept = true;
        this.item = item;
      },
  
      async getData() {
        const filter = {
          start: this.date,
          end: this.date_end,
          code: this.filter.code,
        };
        await axios.get("/package/get-pickup-accept",  {
        params: {
          start: filter.start,
          end: filter.end,
          code:filter.code,
        },
      }).then((response) => {
          console.log(response);
          this.PickupAccept=response.data.package
        });
      },
  
      async rejectItem() {
        const data = {
          package_id: this.item.id,
          reason: this.description,
        };
        axios
          .post("/package/reject-pickup-accept", data)
          .then(() => {
            this.$toast.success("Item rejected");
            this.dialog_reject = false;
            this.description = "";
            this.getData(this.filter);
          })
          .catch((e) => {
            this.$toast.error(e.response?.data?.message);
            this.dialog_reject = false;
          });
      },
      async acceptItem() {
       
        axios
          .get("/package/courier-package/assign/"+this.item.id)
          .then(() => {
            this.$toast.success("Package Accept Success");
            this.dialog_accept = false;
            this.description = "";
            this.getData(this.filter);
          })
          .catch((e) => {
            this.$toast.error(e.response?.data?.message);
            this.dialog_reject = false;
          });
      },
      async viewPackage(item) {
        await this.$store.dispatch("getPackageById", item.id);
        this.$router.push({
          name: "package-pending-view",
        });
      },
      clear() {
        this.filter = {
          code: "",
        };
        this.date = "";
        this.date_end = "";
        this.getData();
      },
    },
  };
  </script>
  