<template>
  <Dashboard
    selectedMainItem="Delivery Operation,Courier Operation"
    selectedSubItem="Delivery Assigned"
  >
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-text-field
            v-model="filter.code"
            label="Code"
            class="ml-5"
            @change="reload()"
            type="text"
            required
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                class="ml-5"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                class="ml-5"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(date_end);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col align="right" cols="12" sm="6" md="2"> </v-col>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="tableData"
        item-key="id"
        class="elevation-1"
        hide-default-footer
        :items-per-page="100"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-if="authUserRole == 'COURIER'"
            color="green"
            dark
            @click="markItem(item)"
            >Acknowledge</v-btn
          >
          <v-btn v-else color="error" @click="toReject(item)"> Reject </v-btn>
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="viewPackage(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-eye </v-icon>
                  View More
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="authUserRole == 'COURIER'"
                @click="toReject(item)"
              >
                <v-icon class="mr-3"> mdi-delete </v-icon>
                <v-list-item-title> Reject </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate :paginate="pagination" @paginated="handlePagination" />
    </v-card>
    <v-dialog v-model="dialog_reject" max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to reject this item?
        </v-card-title>
        <v-textarea
          v-model="description"
          label="Reason"
          class="ma-5"
        ></v-textarea>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog_reject = false">
            cancel
          </v-btn>

          <v-btn color="error" text @click="rejectItem()"> reject </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Accept this package ?</v-card-title>
        <v-card-text>You are going to confirm package collected </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" dark @click="dialog = false">no</v-btn>

          <v-btn color="green darken-1" dark @click="acknowledge()">yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/PaginateAxios";
import axios from "axios";

export default {
  name: "DeliveryAssigned",
  data() {
    return {
      name: "",
      pagination: {
        meta: {
          current_page: 1,
          per_page: 10,
        },
      },
      filter: {
        name: this.name,
        status: 1,
        page: 1,
        code: "",
      },
      tableData: [],
      isLoading: false,
      menu: false,
      date: "",
      date_end: "",
      menu2: false,
      description: "",
      dialog: false,
      dialog_reject: false,
      mark_item: {},
      selected: [],
      collectedType: [
        { name: "collected", id: 1 },
        { name: "not-collected", id: 2 },
      ],
    };
  },
  components: {
    Dashboard,
    paginate,
  },
  computed: {
    authUserRole() {
      return this.$store?.state?.Auth?.user?.role[0];
    },
    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Courier name",
          align: "start",
          sortable: false,
          value: "delivery_courier",
        },
        {
          text: "Package Type",
          value: "package_type_text",
          sortable: false,
        },
        {
          text: "Pickup City",
          value: "pickup_city_name",
          sortable: false,
        },
        {
          text: "Delivery Address",
          align: "start",
          sortable: false,
          value: "current_delivery_address",
        },
        {
          text: "Pickup Date",
          value: "package_date",
          sortable: false,
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchDeliveryAssigned(this.filter);
  },
  mounted() {},
  methods: {
    ...mapActions(["MoveToShipping"]),
    async fetchDeliveryAssigned(filter) {
      axios
        .get("package/get-delivery-assigned", {
          params: {
            page: filter.page,
            start: filter.start,
            end: filter.end,
            code: filter.code,
            per_page: this.ROWS_PER_PAGE,
          },
        })
        .then((response) => {
          this.tableData = response.data.package;
          this.pagination = response?.data?.pagination;
        });
    },
    handlePagination(page) {
      this.filter.page = page;
      this.fetchDeliveryAssigned(this.filter);
    },
    async acknowledge() {
      this.isLoading = true;
      await this.MoveToShipping(this.mark_item.id)
        .then(() => {
          this.$toast.success("Updated successfully!");
          this.dialog = false;
          this.isLoading = false;
          this.fetchDeliveryAssigned(this.filter);
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
          this.dialog = false;
          this.isLoading = false;
        });
    },
    markItem(item) {
      this.mark_item = item;
      this.dialog = true;
    },
    toReject(item) {
      this.dialog_reject = true;
      this.item = item;
    },
    async rejectItem() {
      const data = {
        package_id: this.item.id,
        reason: this.description,
      };

      if (this.item.active_stage.stage_id == 16) {
        axios
          .post("/package/reject-return", data)
          .then(() => {
            this.$toast.success("Item rejected");
            this.dialog_reject = false;
            this.description = "";
            this.fetchDeliveryAssigned(this.filter);
          })
          .catch((e) => {
            this.$toast.error(e.response?.data?.message);
            this.dialog_reject = false;
          });
      } else {
        axios
          .post("/package/reject-delivery", data)
          .then(() => {
            this.$toast.success("Item rejected");
            this.dialog_reject = false;
            this.description = "";
            this.fetchDeliveryAssigned(this.filter);
          })
          .catch((e) => {
            this.$toast.error(e.response?.data?.message);
            this.dialog_reject = false;
          });
      }
    },
    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    clear() {
      this.filter = {
        code: "",
      };
      this.date = "";
      this.date_end = "";
      this.reload();
    },
    reload() {
      const filter = {
        start: this.date,
        end: this.date_end,
        code: this.filter.code,
      };
      this.fetchDeliveryAssigned(filter);
    },
  },
};
</script>
