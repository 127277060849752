import { ROWS_PER_PAGE } from "@/common";
import axios from "axios";

const state = {
  Collected: [],
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
};

const getters = {
  Collected: (state) => state.Collected,
};

const actions = {
  async fetchCollected({ commit }, filter) {
    const Collected = await axios.get("package/get-collected", {
      params: {
        code: filter.code,
        type: filter.type,
        page:filter.page,
        per_page:ROWS_PER_PAGE
      },
    });

    commit("setCollected", Collected.data.package);
    commit("setPagination", Collected.data.pagination);
    return Collected;
  },

  async getCollectedById({ commit }, id) {
    const packageIn = await axios.get("package/view-more/" + id);
    commit("setPackage", packageIn.data.data);

    return packageIn.data;
  },

  async UpdateCollected({ commit }, toSend) {
    const user = await axios.post("package/mark-collected", {
      package_id: toSend,
    });
    commit("newUser", user.data.data);
    return user;
  },
};

const mutations = {
  setCollected: (state, Collected) => (state.Collected = Collected),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
