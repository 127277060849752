<template>
  <div>
    <v-tooltip v-if="show" top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"  class="text-white badge bg-warning"> Internal Return </span>
        </template>
        <span
          >This record has rejected back to this stage.Please view timeline for
          more details.</span
        >
      </v-tooltip>
    
  </div>
</template>
<script>
export default {
  name: "InternalReturn",
  props:['show']
};
</script>
