<template>
  <Dashboard selectedMainItem="Operation" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-text-field
            v-model="filter.code"
            label="Code"
            class="ml-5"
            @input="reload()"
            type="text"
            required
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                class="ml-5"
                label="Start Date (Pickup)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu2"
        :items-per-page="15"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                class="ml-5"
                label="End Date (Pickup)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(date_end);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col align="right" cols="12" sm="6" md="2">
          <v-select
            @change="reload()"
            v-model="filter.stage_id"
            :items="stage_types"
            item-text="name"
            item-value="id"
            :label="'Stage Type'"
          >
          </v-select> </v-col
        >
       
        
        <v-col align="right" cols="12" sm="6" md="2">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="AllPackages"
        item-key="id"
        class="elevation-1"
        hide-default-footer
        :items-per-page="ROWS_PER_PAGE"
      >
        <template v-slot:[`item.active_stage`]="{ item }">
          <span>{{ item.active_stage.stage_name }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn class="action-btn" color="success" @click="viewPackage(item)"> View More </v-btn>
          <v-btn class="action-btn text-white" v-if="hasAnyPermission(['super-admin-override-packages'])" color="red" @click="override(item)"> Override </v-btn>
        </template>
      </v-data-table>
      <paginate store="User" method="fetchAllPackages" :filter="filter" />
    </v-card>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/Paginate";
import axios from "axios";
export default {
  name: "all-operation",
  data() {
    return {
      name: "",
      filter: {
        page: 1,
        stage_id: "",
        code: "",
        start: this.date,
        end: this.date_end,
      },
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date_end: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      isLoading: false,
      menu: false,
      menu2: false,
      dialog: false,
      item: {},
      selected: [],
      stage_types: [],
    };
  },
  components: {
    Dashboard,
    paginate,
    
  },
  
  computed: {
    AllPackages: {
      get() {
        return this.$store.getters.AllPackages;
      },
    },

    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Client name",
          align: "start",
          sortable: false,
          value: "client_name",
        },
        {
          text: "Package Type",
          value: "package_type_text",
          sortable: false,
        },
        {
          text: "Pickup City",
          value: "pickup_city_name",
          sortable: false,
        },
        {
          text: "Delivery City",
          value: "delivery_city_name",
          sortable: false,
        },
        {
          text: "Active Stage",
          value: "active_stage",
          sortable: false,
        },
        {
          text: "Pickup Date",
          value: "package_date",
          sortable: false,
        },
        {
          text: "Actions",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.getStages();
    this.reload();
  },
  mounted() {    
  },
  methods: {
    ...mapActions(["fetchAllPackages"]),
    getStages() {
      axios.get("/get-stages").then((response) => {
        this.stage_types = response.data;
      });
    },

    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    reload() {
      const filter = {
        start: this.date,
        end: this.date_end,
        code: this.filter.code,
        stage_id: this.filter.stage_id,
      };
      this.fetchAllPackages(filter);
    },
    async override(item) {
      this.$router.push({
        name: "super-admin-override-packages",
        query: { id: item.id },
      });
    },
   

    clear() {
      this.filter = {
        stage_id: "",
        code: "",
      };
      this.date = "";
      this.date_end = "";
      this.reload();
    },
  },
};
</script>
<style scoped>
.action-btn {
  margin: 5px;
}
.text-white{
  color: white;
}
</style>