import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router/routes';
import DashboardLayout from "./components/Layouts/Leftbar";
import Vuelidate from 'vuelidate';
import Toast from "vue-toastification";
import store from'./store/index';
//import VDContainer from 'vue-flexable-dnd'

import "vue-toastification/dist/index.css";

import './util/axios';
import './mixed.js';



Vue.use(Vuelidate);
Vue.use(Toast);
//Vue.use(VDContainer);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.component("layoutDashboard", DashboardLayout );
