<template>
  <div>
    <span
      >{{ code }} &nbsp;
      <InternalReturn :show="stage_return" />
      <SenderReturn :show="sender_return" />
    </span>
  </div>
</template>
<script>
import InternalReturn from "./InternalReturn.vue";
import SenderReturn from "./SenderReturn.vue";

export default {
  props: ["code", "stage_return", "sender_return"],
  name: "CodeColumn",
  components: { InternalReturn,SenderReturn },
};
</script>
<style>
.badge {
  border-radius: 4px;
  padding: 0 10px;
  margin: 0 5px 0 0;
  white-space: nowrap;
}
.bg-warning {
  background-color: rgb(202, 195, 1);
}
.bg-red {
  background-color: red;
}
</style>
