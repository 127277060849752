<template>
  <Dashboard
    selectedMainItem="Courier Operation"
    selectedSubItem="Courier Screen"
  >
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col class="field-mobile" align="right" cols="12" sm="6" md="4">
          <v-text-field
            v-model="filter.code"
            label="Code"
            class="ml-5"
            @input="reload()"
            type="text"
            required
          >
          </v-text-field>
        </v-col>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>

      <draggable
        class="ml-5"
        v-model="packageData"
        :animation="300"
        :disabled="allowDrag"
        @change="onRecordChange"
      >
        <div
          class="mobile-packge"
          v-for="(packge, index) in packageData"
          :key="index"
          :style="{ width: '850px' }"
        >
          <v-card
            id="d"
            class="mobile-card"
            :style="{
              borderStyle: 'solid',
              borderColor: 'green',
              borderRadius: '5px',
            }"
          >
            <v-row class="mobile-text">
              <v-col cols="12" md="6" class="bg-danger">
                <p class="text-dark card-content">
                  Code : {{ packge.package_code }}&nbsp;
                  <small>({{ packge.package_type_text }})</small>
                </p>
                <p class="text-dark card-content">
                  Package date : {{ packge.package_date }}
                </p>
                <div v-if="packge.type == 1">
                  <p class="text-dark card-content">
                    <span>Sender name : {{ packge.sender_name }}</span>
                  </p>
                </div>
                <div v-if="packge.type == 2">
                  <p class="text-dark card-content">
                    <span>Receiver name : {{ packge.receiver_name }}</span>
                  </p>
                  <p class="text-dark card-content">
                    <small>( Sent By : {{ packge.sender_name }} )</small>
                  </p>
                </div>

                <p class="text-dark card-content">
                  {{ personType(packge) }} phone :
                  <a :href="getTelAttribute(packge)">
                    {{ packge.contact_person_phone }}
                  </a>
                </p>
                <p class="text-dark card-content">
                  {{ personType(packge) }} address :
                  {{ packge.contact_person_address }}
                </p>
              </v-col>
              <v-col cols="12" md="6" class="btn-section-wrapper bg-success">
                <div class="text-right">
                  <span>{{
                    packge.pay_by_text + " - " + packge.payment_type_text
                  }}</span>
                  <br />
                  <span class="text-green" v-if="packge.package.paid_amount > 0"
                    >Paid</span
                  >
                  <span class="text-red" v-else-if="isPaymentRequired(packge)"
                    >Payment Required</span
                  >
                  <br />
                </div>
                <div class="text-right">
                  <v-btn
                    v-if="packge.type == 1"
                    color="#3B71CA"
                    class="white--text rounded-lg"
                    @click="viewPickup(packge)"
                  >
                    Pickup
                  </v-btn>
                  <v-btn
                    v-else
                    color="#14A44D"
                    class="white--text rounded-lg"
                    @click="viewDelivery(packge)"
                  >
                    Delivery
                  </v-btn>
                  &nbsp; &nbsp;
                  <v-btn
                    color="#14A44D"
                    class="white--text rounded-lg"
                    @click="viewPackage(packge)"
                  >
                    View
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <br />
        </div>
      </draggable>
    </v-card>
  </Dashboard>
</template>

<script>
import Dashboard from "../../components/Layouts/Leftbar";
import axios from "axios";
import draggable from "vuedraggable";

export default {
  name: "CourierScreen",
  data() {
    return {
      filter: {
        reg_number: this.vehicleId,
        code: "",
        page: 1,
      },
      packageData: [],
      search: "",
      calories: "",
      deleteId: "",
      dialogDelete: false,
      isLoading: false,
      allowDrag: false,
      date: "",
      date_end: "",
      menu: false,
      menu2: false,
      dialog: false,
      item: {},
      package_type: [
        { name: "Documents", id: 1 },
        { name: "Parcel", id: 2 },
      ],
      vehicleId: "",
      package_id: "",
      client_id: "",
    };
  },
  components: {
    Dashboard,
    draggable,
  },
  mounted() {
    this.getData();
  },
  methods: {
    isPaymentRequired(packge) {
      if (packge.package.payment_type == 4) return false;
      if (packge.package_type == 1 && packge.package.pay_by == 1) {
        //pickup && sender
        return true;
      }
      if (packge.package_type == 2) {
        //delivery
        return true;
      }
      return false;
    },
    personType(packg) {
      if (packg.type == 1) return "Sender";
      return "Receiver";
    },
    getTelAttribute(packge) {
      return "tel:" + packge.contact_person_phone;
    },
    reload() {
      this.getData();
    },
    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.package_id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    async getData() {
      await axios
        .get("courier/get-courier-screen-data", {
          params: {
            code: this.filter.code,
          },
        })
        .then((response) => {
          this.packageData = response.data.package;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clear() {
      this.filter = {
        code: "",
      };
      this.date = "";
      this.client_id = "";
      this.package_id = "";
      this.date_end = "";
      this.reload();
    },

    async viewPickup(item) {
      await this.$store.dispatch("getPackageById", item.package.id);
      this.$router.push({
        name: "pickup-assigned-edit",
        params: { item: item.package },
      });
    },
    async viewDelivery(item) {
      await this.$store.dispatch("getPackageById", item.package.id);
      this.$router.push({
        name: "mark-delivery",
        params: { item: item.package },
      });
    },
    async onRecordChange() {
      const newList = [...this.packageData].map((item, index) => {
        const newSort = index + 1;
        // also add in a new property called has changed if you want to style them / send an api call
        item.hasChanged = item.sortOrder !== newSort;
        if (item.hasChanged) {
          item.sortOrder = newSort;
        }
        return item;
      });
      this.packageData = newList;
      const new_data = {
        package: this.packageData,
      };

      await axios
        .post("courier/update-courier-package-order", new_data)
        .then(() => {
          //  this.allowDrag = false;
          // // var tables = document.getElementsByTagName('table');
          // // var rows = tables[0].getElementsByTagName('tr');
          // for (var i = 0; i < rows.length; i++) {

          //   rows[i].className = "";
          // }
          // var d = document.getElementById("d");
          // d.classList.remove("drag");
          this.$toast.success("Update successfully!");

          this.getData();
        })
        .catch((error) => {
          this.allowDrag = false;
          console.log(error);
        });
    },
    async onDragFinish(event, data) {
      var tables = document.getElementsByTagName("table");
      var rows = tables[0].getElementsByTagName("tr");
      for (var i = 0; i < rows.length; i++) {
        rows[i].className = "drag";
      }

      this.allowDrag = true;
      const new_data = {
        new_index: event.newIndex,
        old_index: event.oldIndex,
        package_id: data[event.oldIndex].id,
        package: data,
      };

      await axios
        .post("courier/update-courier-package-order", new_data)
        .then(() => {
          this.allowDrag = false;
          var tables = document.getElementsByTagName("table");
          var rows = tables[0].getElementsByTagName("tr");
          for (var i = 0; i < rows.length; i++) {
            rows[i].className = "";
          }
          this.$toast.success("Update successfully!");

          this.getData();
        })
        .catch((error) => {
          this.allowDrag = false;
          console.log(error);
        });

      // dragging = false
    },
  },
};
</script>
<style scoped>
.v-data-footer__select {
  display: none;
}

.bg-success {
}

.bg-danger {
}

.drag {
  opacity: 0.45;
}

.card-content {
  margin-bottom: 0px;
  margin-left: 5px;
}

.clickable-button {
  cursor: pointer;
}

.item-card {
  border: 3px solid #b3d9de87;
  border-radius: 10px;
  color: white;
  min-height: 20vh;
}

#dashboard-card {
  padding: 10px;
}

.title {
  margin-top: 2vh;
  font-size: 5vh !important;
}

.dashboard-title {
  font-size: 30px;
}

.mdi-fullscreen {
  font-size: 30px;
}

.text {
  font-size: 10vh;
}

@media screen and (max-width: 959px) {
  .mobile-packge {
    max-width: 95%;

    /* // margin-left: 0; */
    /* //height: 150px; */
  }
  .mobile-text {
    text-align: center;
  }
  .mobile-card {
    min-height: 240px;
  }
  .field-mobile {
    max-width: 95%;
  }
  .btn-section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .text-red {
    color: red;
  }
}
</style>
