import axios from "axios";

const state = {
  attendanceVehicles: [],
  attendanceAllVehicles: [],
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
};

const getters = {
  attendanceVehicles: (state) => state.attendanceVehicles,
  attendanceAllVehicles: (state) => state.attendanceAllVehicles,
};

const actions = {
  async fetchAttendanceVehicles({ commit }, filter) {
    const allUsers = await axios.get("vehicle-attendance/get-paginate", {
      params: {
        reg_number: filter.name,
        start: filter.start,
        end: filter.end,
        page: filter.page,
      },
    });
    
    commit("setAttendanceVehicles", allUsers.data.data);
    commit("setPagination", allUsers.data.pagination);
    return allUsers;
  },

  async getAllVehicleAttendance({ commit }, date) {
    const allUsers = await axios.get("vehicle-attendance/get-vehicles", {
      params: {
        date: date,
      },
    });

    commit("setAttendanceAllVehicles", allUsers.data.data);

    return allUsers.data;
  },

  async markVehicle({ commit }, attendance) {
    const vehicle_id = attendance.id;
    const in_date = attendance.in_date;
    const out_date = attendance.out_date;
    const out_time = attendance.out_time;
    const in_time = attendance.in_time;
    const user = await axios.post("vehicle-attendance", {
      vehicle_id,
      in_date,
      out_date,
      in_time,
      out_time,
    });

    commit("newUser", user.data.data);
    return user;
  },
};

const mutations = {
  setAttendanceVehicles: (state, attendanceVehicles) =>
    (state.attendanceVehicles = attendanceVehicles),
  setAttendanceAllVehicles: (state, attendanceAllVehicles) =>
    (state.attendanceAllVehicles = attendanceAllVehicles),
  setPagination: (state, paginate) => (state.paginate = paginate),
  setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
