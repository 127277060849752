import { ROWS_PER_PAGE } from "@/common";
import axios from "axios";

const state = {
  Sorting: [],
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
};

const getters = {
  Sorting: (state) => state.Sorting,
};

const actions = {
  async fetchSorting({ commit }, filter) {
    const Sorting = await axios.get("package/get-sorting", {
      params: {
        start: filter.start,
        end: filter.end,
        code: filter.code,
        page:filter.page,
        per_page:ROWS_PER_PAGE
      },
    });

    commit("setSorting", Sorting.data.package);
    commit("setPagination", Sorting.data.pagination);
    return Sorting;
  },

  async getSortingById({ commit }, id) {
    const packageIn = await axios.get("package/view-more/" + id);
    commit("setPackage", packageIn.data.data);

    return packageIn.data;
  },

  async UpdateSorting({ commit }, toSend) {
    const package_id = toSend.package_id;
    const branch_id = toSend.branch_id;
    const user = await axios.post("package/sort-delivery", {
      package_id,
      branch_id,
    });
    console.log(user.data.data);
    commit("newUser", user.data.data);
    return user;
  },
};

const mutations = {
  setSorting: (state, Sorting) => (state.Sorting = Sorting),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
