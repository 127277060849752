import axios from 'axios';


const state = {
    username: "",
    user:null
};

const getters = {
    
    authUser: state => state.user,

};

const actions = {

    async login({ commit }, { username, password }) {
        
        const loginResponse = await axios.post('login', {
            username,
            password
        });
        commit("login",  loginResponse.data );
        return loginResponse;
    },
    async logout({ commit }){

        await axios.post('logout');
        commit("logout");
    },
    async hasPermission({permission}){
        const userPermission = state.user.permissions;
        return (userPermission.includes(permission));
    }

};

const mutations = {
    async login(state,  response ) {
        state.username =  response.data.user.first_name;
        state.user = response.data;
        await localStorage.setItem('token', response.data.token);
        localStorage.setItem('isLogin', true);

    },
    logout(state ) {
        state.username = null;
        state.user = null;
        localStorage.removeItem('token');
        localStorage.setItem('isLogin', false);
    }
};

export default {
state,
getters,
actions,
mutations
};