import axios from "axios";
import * as common from '../../common';

const state = {
  DeliveryAssigned: [],
  AllCouriersUser: [],
  shipped: [],
  AllAssigning: [],
  AllPackages: [],
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
};

const getters = {
  DeliveryAssigned: (state) => state.DeliveryAssigned,
  shipped: (state) => state.shipped,
  AllPackages: (state) => state.AllPackages,
  AllAssigning: (state) => state.AllAssigning,
  AllCouriersUser: (state) => state.AllCouriersUser,
};

const actions = {
  async fetchDeliveryAssigned({ commit }, filter) {
    const DeliveryAssigned = await axios.get("package/get-delivery-assigned", {
      params: {
        page: filter.page,
        start: filter.start,
        end: filter.end,
        code: filter.code,
        per_page:common.ROWS_PER_PAGE
      },
    });

    commit("setSorting", DeliveryAssigned.data.package);
    commit("setPagination", DeliveryAssigned.data.pagination);
    return DeliveryAssigned;
  },
  async fetchShipped({ commit }, filter) {
    const DeliveryAssigned = await axios.get("package/get-shipped", {
      params: {
        page: filter.page,
        code: filter.code,
        per_page:common.ROWS_PER_PAGE
      },
    });

    commit("setShipped", DeliveryAssigned.data.package);
    commit("setPagination", DeliveryAssigned.data.pagination);
    return DeliveryAssigned;
  },
  async fetchAllPackages({ commit }, filter) {
    const DeliveryAssigned = await axios.get("package/get-all", {
      params: {
        page: filter.page,
        stage_id: filter.stage_id,
        start: filter.start,
        end: filter.end,
        code: filter.code,
        client_id: filter.client_id
      },
    });
    commit("setAllPackages", DeliveryAssigned.data.package);
    commit("setPagination", DeliveryAssigned.data.pagination);
    return DeliveryAssigned;
  },
  async fetchAllCouriers({ commit }) {
    const DeliveryAssigned = await axios.get("user/get-couriers");

    commit("setAllCouriersUser", DeliveryAssigned.data.data);
    return DeliveryAssigned;
  },
  async fetchAllAssigning({ commit }, filter) {
    const DeliveryAssigned = await axios.get("package/get-assigning", {
      params: {
        start: filter.start,
        end: filter.end,
        code: filter.code,
        page: filter.page,
        per_page:common.ROWS_PER_PAGE
      },
    });

    commit("setAllAssigning", DeliveryAssigned.data.package);
    commit("setPagination", DeliveryAssigned.data.pagination);

    return DeliveryAssigned;
  },

  async getSortingById({ commit }, id) {
    const packageIn = await axios.get("package/view-more/" + id);
    commit("setPackage", packageIn.data.data);
    return packageIn.data;
  },

  async MoveToShipping({ commit }, id) {
    const package_id = id;
    const user = await axios.post("package/move-to-shipping", {
      package_id,
    });
    commit("newUser", user.data.data);
    return user;
  },
};

const mutations = {
  setSorting: (state, DeliveryAssigned) =>
    (state.DeliveryAssigned = DeliveryAssigned),
  setAllPackages: (state, AllPackages) => (state.AllPackages = AllPackages),
  setShipped: (state, shipped) => (state.shipped = shipped),
  setAllAssigning: (state, AllAssigning) => (state.AllAssigning = AllAssigning),
  setAllCouriersUser: (state, AllCouriersUser) =>
    (state.AllCouriersUser = AllCouriersUser),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
