<template>
  <v-card style="margin: 30px" :loading="isLoading" color="" cols="12" md="12">
    <v-toolbar :elevation="0" style="padding: 0">
      <v-container fill-height>
        <v-row>
          <v-col
            v-show="forName != 'Pricing' && forName !== 'Attendance'"
            class="filter-search"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="search"
              :label="'Search by ' + forName + ' name'"
              class="mx-4"
              @keyup="sendData"
            ></v-text-field>
          </v-col>
          <v-col v-show="forName == 'Vehicle'" cols="12" md="4">
            <v-select
              v-model="type"
              :items="vehicleTypes"
              item-text="name"
              item-value="id"
              :label="'Select ' + forName + ' type'"
              placeholder="Placeholder"
              @change="sendData"
            ></v-select>
          </v-col>

          <v-col
            v-show="forName == 'User'"
            class="filter-search"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="firstName"
              :label="'Search by first name'"
              class="mx-4"
              @keyup="sendData"
            ></v-text-field>
          </v-col>
          <v-col
            v-show="forName == 'Attendance'"
            class="filter-search"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="name"
              :label="'Search by name'"
              class="mx-4"
              @keyup="sendData"
            ></v-text-field>
          </v-col>
          <v-col
            v-show="forName == 'Attendance'"
            class="filter-search"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="date"
              :label="'Search by date'"
              class="mx-4"
              @keyup="sendData"
            ></v-text-field>
          </v-col>

          <v-col
            v-show="forName == 'User'"
            class="filter-search"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="email"
              :label="'Search by email'"
              class="mx-4"
              @keyup="sendData"
            ></v-text-field>
          </v-col>

          <v-col
            v-show="forName == 'User'"
            class="filter-search"
            cols="12"
            md="3"
          >
            <v-select
              v-model="userRole"
              :items="staffRoles"
              item-text="name"
              item-value="id"
              :label="'Select user Role'"
              placeholder="Select Role"
              @change="sendData"
            ></v-select>
          </v-col>

          <v-col v-show="forName == 'Location'" cols="12" md="4">
            <v-select
              v-model="locationType"
              :items="locationTypes"
              item-text="name"
              item-value="id"
              :label="'Select Location type'"
              @change="setLocation"
            ></v-select>
          </v-col>

          <v-col v-show="forName == 'Location'" cols="12" md="4">
            <v-select
              v-model="location"
              :disabled="locationDisabled"
              :items="parentLocations"
              item-text="name"
              item-value="id"
              :label="'Select Parent Location'"
              @change="sendData"
            >
            </v-select>
          </v-col>

          <v-col v-show="forName == 'Pricing'" cols="12" md="4">
            <v-select
              v-model="packageType"
              :items="packageTypes"
              item-text="name"
              item-value="id"
              :label="'Package Type'"
              @change="sendData"
            >
            </v-select>
          </v-col>

          <v-col v-show="forName == 'Pricing'" cols="12" md="4">
            <v-select
              v-model="branchAreaType"
              :items="branchAreaTypes"
              item-text="name"
              item-value="id"
              :label="'Branch Area Type'"
              @change="sendData"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      name: "",
      date: "",
      search: "",
      type: "",
      firstName: "",
      email: "",
      userRole: "",
      locationType: "",
      location: "",
      packageType: "",
      branchAreaType: "",
      parentLocations: [],
      isLoading: false,
      locationDisabled: true,
    };
  },
  props: ["forName"],
  methods: {
    ...mapActions(["getAllUsersRoles", "setParentLocation"]),

    sendData() {
      this.$emit("updateFilterData", {
        search: this.search,
        name: this.name,
        type: this.type,
        firstName: this.firstName,
        email: this.email,
        userRole: this.userRole,
        location: this.location,
        locationType: this.locationType,
        packageType: this.packageType,
        branchAreaType: this.branchAreaType,
      });

      this.filterLoading();
    },

    async setLocation() {
      this.location = "";
      this.setParentLocation(this.locationType).then((response) => {
        if (response) {
          this.parentLocations = response;
          this.locationDisabled = false;
        } else {
          this.locationDisabled = true;
        }
      });
      await this.sendData();
    },

    filterLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
  },
  computed: {
    vehicleTypes: {
      get() {
        const typeAll = { id: "", name: "All Types" };
        const vehicleArray = this.$store.getters.vehiclesTypes;
        vehicleArray.push(typeAll);

        return vehicleArray;
      },
    },
    userRoles: {
      get() {
        let array = this.$store.getters.allUserRoles;
        array.unshift({ id: null, name: "ALL" });
        return array;
      },
    },
    staffRoles: {
      get() {
        let array = this.$store.getters.allUserRoles;
        array = array.filter(function (obj) {
          return obj.id !== 12;
        });
        array.unshift({ id: null, name: "ALL" });
        return array;
      },
    },
    locationTypes: {
      get() {
        return this.$store.getters.locationTypes;
      },
    },
    packageTypes: {
      get() {
        return this.$store.getters.getAllPackageTypes;
      },
    },
    branchAreaTypes: {
      get() {
        const typeAll = [{ id: "", name: "All Types" }];
        let priceArray = this.$store.getters.getBranchAreaTypes;
        priceArray.push({ id: 4, name: "Long Distance Area" });

        return typeAll.concat(priceArray);
      },
    },
  },
  created() {
    this.getAllUsersRoles();
  },
};
</script>

<style>
.filter-search {
  padding-left: 0px;
}
.v-toolbar {
  box-shadow: 0 !important;
}
.v-sheet {
  box-shadow: 0 !important;
}
</style>
