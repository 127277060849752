<template>
  <Dashboard
    selectedMainItem = "Administration"
  selectedSubItem = "Locations">
  <v-card
    style="margin:30px;border: 0;"
    :loading="isLoading"
  >
  <v-card-title>
    Location Add
</v-card-title>
  <v-card-subtitle>
    add location
    <template>
    <div>
      <v-breadcrumbs
      class="pl-0 pt-0"  
      :items="pageNavItems"></v-breadcrumbs>
    </div>
  </template>
  </v-card-subtitle>
      <form style="margin-top:40px;border: 0;">
        <v-container>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="location"
              :items="parentLocations"
              :error-messages="locationErrors"
              item-text="name"
              item-value="id"
              label="Select District"
              solo
              @input="$v.location.$touch()"
              @blur="$v.location.$touch()"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="name"
              :error-messages="nameErrors"
              label="City Name"
              solo
              required
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="status"
              :items="vehicleStatus"
              item-text="name"
              item-value="id"
              :error-messages="statusErrors"
              label="Status"
              solo
              required
              @input="$v.status.$touch()"
              @blur="$v.status.$touch()"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-btn
            class="ma-4"
            @click="submit"
            :color="$v.$anyError ? 'gray' : 'primary'"
            :disabled="btnDisabled"
          >
          <span class="white--text">
            Register
          </span>
          </v-btn>
          <v-btn 
          class="ma-4"
          @click="cancelRegister"
          :color="'red'">
          <span class="white--text">
            Cancel
          </span>
          </v-btn>
        </v-row>
        </v-container>
    </form>

  </v-card>
  </Dashboard>
</template>

<script>

  import { mapActions } from "vuex";
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  import Dashboard from '../../components/Layouts/Leftbar'
  import axios from "axios";


  export default {
    name:'vehicle-register',
    mixins: [validationMixin],

    validations: {
      name: { required },
      locationType: { required },
      location : { required },
      status: { required },
    },

    data: () => ({
        name : '',
        locationType : 4,
        location : '',
        status : 1,
        parentLocations : [],
        isLoading : false,
        btnDisabledoff : false,
        locationDisabled : true,
        pageNavItems: [
        {
          text: 'Dashboard',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
        {
          text: 'Location',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'location'}
        },
        {
          text: 'Add',
          disabled: true,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
      ],
    }),
    components:{
        Dashboard
    },
    mounted(){
      this.loadDistricts();
    },
    computed: {
      vehicleStatus:{
      get(){
          return this.$store.getters.vehicleStatus;
        }
      },
      btnDisabled () {
        const visible = this.$v.$anyError || !(this.name && this.locationType );
        return visible
      },
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.required && errors.push('Name is required')
        return errors
      },
      locationErrors () {
        const errors = []
        if (!this.$v.location.$dirty) return errors
        !this.$v.location.required && errors.push('Parent Location  is required.')
        return errors
      },
      statusErrors () {
        const errors = []
        if (!this.$v.status.$dirty) return errors
        !this.$v.status.required && errors.push('Status is required.')
        return errors
      },
    },

    methods: {

      ...mapActions(["locationRegister"]),


      async submit () {

        this.$v.$touch();

        this.btnDisable = true;
        this.isLoading = true;

        await this.locationRegister( { 
            name: this.name,
            type: this.locationType,
            status: this.status,
            parent_id: this.location,
        }).then( response =>{

          console.log(response);
          this.$toast.success("Location added successfully!");
          this.clear();
          this.isLoading = false;
          this.cancelRegister();
        }).catch((e)=>{
          this.$toast.error(e.response.data.message);
          this.isLoading = false;
          this.btnDisable = false;
        });
      },

      clear () {
        this.$v.$reset()
        this.name = ''
        this.location = ''
        this.status = null
      },
      loadDistricts(){
        axios.get('location-hierarchy',{params:{type : 3 }})
        .then((response) => {
          this.parentLocations = response.data.data;
        });
      },
      cancelRegister(){
        this.$router.push({name:'location'});
      },
    },
  }
</script>
