<template>
    <div class="text-center">
        <v-pagination
            @change="paginated"
            v-model="currentPage"
            :length="paginate.meta.last_page"
            :total-visible="6"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
        ></v-pagination>
    </div>
</template>
<script>
export default {
    props:['paginate'],
    name:'axios-paginate',
    computed:{
        currentPage:{
            get(){
                return this.paginate.meta.current_page;
            },
            set(value){
                this.$emit('paginated',value);
            }
        },
    },
    methods: {
        paginated(pageNumber){
            console.log(pageNumber);
        }
    }
}
</script>