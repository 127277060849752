import axios from 'axios';


const state = {
    question: null,
    Questions: [],
    paginate: {
        meta:
        {
            current_page : 1,
            last_page : 1,
        }
    },

};

const getters = {
    
    allQuestions: state => state.Questions,
    editQuestion: state => state.question,
    QuestionPaginate: state => state.paginate,
    QuestionStatus: state => state.vehicleStatus,
    QuestionTypes: state => state.vehiclesTypes,

};

const actions = {

    async fetchQuestions({ commit }, filter) {
            const allQuestions= await axios.get('faq/get-paginate',{params: {
                keyword : filter.search,
                page : filter.page,
            }});
            commit("setQuestions",  allQuestions.data.data);
            commit("setPagination",  allQuestions.data.pagination);
            return allQuestions;
    },
    
    async getAllQuestions({commit}){
        const allQuestions = await axios.get('faq');
        commit("setQuestion",  allQuestions.data);
        return allQuestions.data;
    },

    async createQuestion({ commit }, { questionContent, answer}) {

        const question = await axios.post('faq', {
            question:questionContent,
            answer,
        });
        commit("newQuestion",  question.data.data );
        return question;
    },

    async questionUpdate({ commit },  { id, questionContent, answer }) {

        const question = await axios.put('faq/'+id, {
            question:questionContent,
            answer,
        });
        commit("updateQuestion",  question.data.data );
        return question;
    },

    async getQuestionById({ commit },questionId) {
        
        const question = await axios.get('faq/'+ questionId);
        commit("setQuestion",  question.data.data );
        return question.data.data;
    },

    async deleteQuestionById({ commit }, id) {
        await axios.delete(`faq/${id}`);
    
        commit('removeQuestion', id);
    }

};

const mutations = {
    setQuestions: (state, Questions) => (state.Questions = Questions),
    setQuestion: (state, question) => (state.question = question),
    setPagination: (state, paginate) => (state.paginate = paginate),
    setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
    newQuestion: (state, question) =>{
        state.Questions.unshift(question);
        state.editVehicle = null;
    },   
    removeQuestion: (state, id) =>{
        state.Questions = state.Questions.filter(question => question.id !== id);
    },
    updateQuestion: (state, upQuestion) => {
        const index = state.Questions.findIndex(question => question.id === upQuestion.id);
        console.log('index',index);
        if (index !== -1) {
        state.Questions.splice(index, 1, upQuestion);
        }
    }
};

export default {
state,
getters,
actions,
mutations
};