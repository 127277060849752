<template>
  <Dashboard selectedMainItem="Administration" selectedSubItem="Pricing">
    <v-row>
      <v-col cols="12" md="6">
        <v-card style="margin: 20px; border: 0" :loading="isLoading">
          <v-card-title> Price Edit </v-card-title>
          <v-card-subtitle>
            edit price here
            <template>
              <div>
                <v-breadcrumbs
                  class="pl-0 pt-0"
                  :items="pageNavItems"
                ></v-breadcrumbs>
              </div>
            </template>
          </v-card-subtitle>
          <form style="margin-top: 40px; border: 0">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <label>Package Type </label>
                  <v-select
                    @change="packageChanged"
                    v-model="packageType"
                    :items="packageTypes"
                    :disabled="true"
                    :error-messages="packageTypeErrors"
                    item-text="name"
                    item-value="id"
                    :label="'Package Type'"
                    solo
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <label>Branch Area Type </label>
                  <v-select
                    v-model="branchAreaType"
                    :items="branchAreaTypes"
                    :disabled="true"
                    :error-messages="branchAreaTypeErrors"
                    item-text="name"
                    item-value="id"
                    :label="'Branch Area Type'"
                    solo
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <label>Initial Value</label>
                  <v-text-field
                    v-model="initial"
                    :error-messages="initialErrors"
                    label="Initial Value"
                    solo
                    required
                    @input="valueChanged"
                    @blur="$v.initial.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" v-if="packageType != 1">
                  <label>Additional Value</label>
                  <v-text-field
                    v-model="additional"
                    :error-messages="additionalErrors"
                    label="Additional Value"
                    solo
                    required
                    @input="$v.additional.$touch()"
                    @blur="$v.additional.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12"> </v-col>
              </v-row>
              <v-row>
                <v-btn
                  class="ma-4"
                  @click="submit"
                  :color="$v.$anyError ? 'gray' : 'primary'"
                  :disabled="btnDisabled"
                >
                  <span class="white--text"> EDIT </span>
                </v-btn>

                <v-btn class="ma-4" @click="cancelEdit" :color="'red'">
                  <span class="white--text"> Cancel </span>
                </v-btn>
              </v-row>
            </v-container>
          </form>
        </v-card>
      </v-col>
    </v-row>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar";

export default {
  name: "branch-edit",
  mixins: [validationMixin],

  validations: {
    packageType: { required },
    branchAreaType: { required },
    initial: { required },
    additional: { required },
  },
  data() {
    return {
      id: "",
      packageType: "",
      branchAreaType: "",
      initial: "",
      additional: "",
      isLoading: false,
      pageNavItems: [
        {
          text: "Dashboard",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "dashboard" },
        },
        {
          text: "Branch",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "branch" },
        },
        {
          text: "Edit",
          disabled: true,
          link: true,
          exact: true,
          to: { name: "dashboard" },
        },
      ],
    };
  },
  components: {
    Dashboard,
  },
  computed: {
    activeStatus: {
      get() {
        return this.$store.getters.vehicleStatus;
      },
    },
    packageTypes: {
      get() {
        return this.$store.getters.getAllPackageTypes;
      },
    },
    branchAreaTypes: {
      get() {
        return this.$store.getters.getBranchAreaTypes;
      },
    },
    btnDisabled() {
      const visible =
        this.$v.$anyError ||
        !(
          this.packageType &&
          this.branchAreaType &&
          this.initial &&
          this.additional
        );
      return visible;
    },
    packageTypeErrors() {
      const errors = [];
      if (!this.$v.packageType.$dirty) return errors;
      !this.$v.packageType.required && errors.push("Package Type is required");
      return errors;
    },
    branchAreaTypeErrors() {
      const errors = [];
      if (!this.$v.branchAreaType.$dirty) return errors;
      !this.$v.branchAreaType.required &&
        errors.push("Branch Type Type is required");
      return errors;
    },
    initialErrors() {
      const errors = [];
      if (!this.$v.initial.$dirty) return errors;
      !this.$v.initial.required && errors.push("Initial is required.");
      return errors;
    },
    additionalErrors() {
      const errors = [];
      if (!this.$v.additional.$dirty) return errors;
      !this.$v.additional.required &&
        errors.push("Additional Value is required.");
      return errors;
    },
  },
  created() {
    this.setEditValues();
  },
  mounted() {
    this.packageChanged();
  },
  methods: {
    ...mapActions(["priceUpdate"]),

    packageChanged() {
      if (this.packageType == 1) {
        this.additional = this.initial;
      }
    },
    valueChanged() {
      if (this.packageType == 1) {
        this.additional = this.initial;
      }
      this.$v.initial.$touch();
    },
    async submit() {
      this.$v.$touch();
      this.btnDisable = true;
      this.isLoading = true;

      await this.priceUpdate({
        id: this.id,
        package_type: this.packageType,
        area_type: this.branchAreaType,
        initial: this.initial,
        additional: this.additional,
      })
        .then(() => {
          this.$toast.success("Price updated successfully!");
          this.isLoading = false;
          this.cancelEdit();
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
          this.isLoading = false;
        });
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.contact = "";
      this.openTime = "";
      this.address = "";
    },
    uppercase() {
      this.regNumber = this.regNumber.toUpperCase();
    },
    setEditValues() {
      const editBranch = this.$store.getters.editPrice;
      this.id = editBranch.id;
      this.packageType = editBranch.package_type;
      this.branchAreaType = editBranch.area_type;
      this.initial = editBranch.initial;
      this.additional = editBranch.additional;
    },
    cancelEdit() {
      this.$router.push({ name: "price" });
    },
  },
};
</script>
