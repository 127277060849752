<template>
  <Dashboard selectedMainItem="Pickup Operation" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-text-field
            v-model="filter.code"
            label="Code"
            class="ml-5"
            @input="reload()"
            type="text"
            required
          >
          </v-text-field>
        </v-col>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-select
            v-model="branch"
            :items="allBranches"
            item-text="name"
            item-value="id"
            label="Pickup branch"
            placeholder="Select branch"
            text
            required
            @change="reload()"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="2"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                class="ml-5"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                label="Start Date"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="2"
          ><v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                class="ml-5"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(date_end);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col align="left" cols="12" sm="6" md="4">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
          <v-btn   :disabled="exporting" depressed @click="exportExcel"  :color="exporting ? '#C9C9C9':'success'"
            ><v-icon class="mr-2"> mdi-file-excel </v-icon> Export</v-btn
          >
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="respData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="success" @click="viewPackage(item)"> View More </v-btn>
        </template>
      </v-data-table>
      <paginate :paginate="pagination" @paginated="handlePagination" />
    </v-card>
  </Dashboard>
</template>

<script>
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/PaginateAxios";
import axios from "axios";

export default {
  name: "ReportPickupAssigned",
  data() {
    return {
      pagination: {
        meta: {
          current_page: 1,
        },
      },
      filter: {
        start: this.date,
        end: this.date_end,
        reg_number: this.vehicleId,
        code: "",
        page: 1,
      },
      exporting: false,
      isLoading: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date_end: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      item: {},
      branch: null,
      respData: [],
      allBranches: [],
      clientType: [
        { name: "corporate", id: 1 },
        { name: "regular", id: 2 },
      ],
      package_type: [
        { name: "Documents", id: 1 },
        { name: "Parcel", id: 2 },
      ],
      vehicleId: "",
      package_id: "",
      client_id: "",
    };
  },
  components: {
    Dashboard,
    paginate,
  },
  computed: {
    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "CollectedBy",
          align: "start",
          sortable: false,
          value: "collected_by",
        },
        {
          text: "Package Type",
          value: "package_type_text",
          sortable: false,
        },
        {
          text: "Pickup Address",
          value: "pickup_address",
          sortable: false,
        },
        {
          text: "Pay By",
          value: "pay_by_text",
          sortable: false,
        },
        {
          text: "Payment Type",
          value: "payment_type_text",
          sortable: false,
        },
        {
          text: "Collected At",
          value: "pickup_at",
          sortable: false,
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchDailyReports();
  },
  mounted() {
    this.loadBranches();
  },
  methods: {
    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    handlePagination(page) {
      this.filter.page = page;
      this.fetchDailyReports();
    },
    loadBranches() {
      axios.get("branch").then((response) => {
        this.allBranches = response.data.data;
        const newFirstElement = {
          name: "All",
          id: null,
        };
        const newArray = [newFirstElement].concat(this.allBranches);
        this.allBranches = newArray;
      });
    },
    fetchDailyReports() {
      axios
        .get("/reports/daily-pickup", {
          params: {
            pickup_branch_id: this.branch,
            report_start: this.date,
            report_end: this.date_end,
            code: this.filter.code,
            page: this.filter.page,
          },
        })
        .then((response) => {
          this.respData = response?.data?.data;
          this.pagination = response?.data?.pagination;
        });
    },
    reload() {
      this.fetchDailyReports();
    },
    clear() {
      this.filter = {
        code: null,
      };
      this.date = "";
      this.branch = null;
      this.date_end = "";
      this.reload();
    },
    exportExcel() {
      let url = "/reports/daily-pickup/export?";
      if(this.filter.code != null){
        url += 'code='+this.filter.code+'&';
      }
      if(this.branch != null){
        url += 'pickup_branch_id='+this.branch+'&';
      }
      if(this.date != null && this.date != ""){
        url += 'report_start='+this.date+'&';
      }
      if(this.date_end != null && this.date_end != ""){
        url += 'report_end='+this.date_end;
      }
      this.exporting = true;
      this.exportExcelCommon(url,"daily-pickup-report.xlsx",()=>{
        this.exporting = false;
      });
    },
  },
};
</script>