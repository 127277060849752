import axios from 'axios';


const state = {
    priceFactors : [],
    editPriceFactor: null,
    paginate: {
        meta:
        {
            current_page : 1,
            last_page : 1,
        }
    },
    packageTypes : [
        {
            id : '',
            name : 'All Type'
        },
        {
            id : 1,
            name : 'Document'
        },
        {
            id : 2,
            name : 'Parcel'
        },
    ]
};

const getters = {
    
    getAllPriceFactors: state => state.priceFactors,
    getAllPackageTypes: state => state.packageTypes,
    editPrice: state => state.editPriceFactor,
    pricesPaginate: state => state.paginate,


};

const actions = {

    async fetchPriceFactors({ commit }, filter) {
            const allPrice = await axios.get('pricing-factor/get-paginate',{params: {
                package_type: filter.package_type, 
                area_type : filter.area_type,
                page : filter.page,
            }});
            console.log(allPrice)
            commit("setPrice",  allPrice.data.data);
            commit("setPagination",  allPrice.data.pagination);
            return allPrice.data.data;
    },

    async priceUpdate({ commit },  { id, package_type, area_type, initial, additional,user_id }) {
        const price = await axios.put('pricing-factor/'+id, {
            package_type,
            area_type,
            initial,
            additional,
            user_id
        });
        commit("updatePrice",  price.data.data );
        return price;
    },

    async getPriceById({ commit },priceId) {
        
        const price = await axios.get('pricing-factor/'+ priceId);
        commit("setPriceFactor",  price.data.data );
        console.log('price',price);
        return price.data.data;
    },
    async customerPriceStore({ commit },  { user_id, package_type, area_type, initial, additional }) {
        const price = await axios.post('/pricing-factor/customer-price-store', {
            package_type,
            area_type,
            initial,
            additional,
            user_id
        });
        commit("customerPriceStore",  price.data.data );
        return price;
    },
    async getPriceByCustomerId({ commit },filter) {
        
        const allPrice = await axios.get('pricing-factor/',{params: {
            user_id : filter.user_id,
        }});
        console.log(allPrice)
         commit("setPrice",  allPrice.data.data);
         commit("setPagination",  allPrice.data.pagination);
       
        return allPrice.data.data;
    },
};

const mutations = {
    setPrice: (state, factors) => (state.priceFactors = factors),
    setPriceFactor: (state, factor) => (state.editPriceFactor = factor),
    setPagination: (state, paginate) => (state.paginate = paginate),
    setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
    updatePrice: (state, updprice) => {
        const index = state.priceFactors.findIndex(price => price.id === updprice.id);
        if (index !== -1) {
        state.priceFactors.splice(index, 1, updprice);
        }
    }
};

export default {
state,
getters,
actions,
mutations
};