<template>
  <Dashboard selectedMainItem="Transport" selectedSubItem="Vehicle Attendance">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col cols="12" sm="6" md="2">
          <v-text-field
            class="ml-5"
            v-model="reg_number"
            single-line
            @keyup="reloadAttendanceVehicles()"
            type="text"
            label="Vehicle Number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="2"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                class="ml-5"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  reloadAttendanceVehicles();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="2"
          ><v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                class="ml-5"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(date_end);
                  reloadAttendanceVehicles();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="2"> </v-col>
        <v-col align="right" cols="12" sm="6" md="4">
          <v-btn
            class="mr-5"
            color="primary"
            elevation="2"
            :to="{ name: 'vehicle-attendance-create', params: { date } }"
          >
            Mark
            <v-icon right> mdi-file-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="vehicleAttendance"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.out_time`]="{ item }">
          <v-chip v-if="item.out_time == null" color="green">
            <span class="text-white">On Duty</span>
          </v-chip>
          <span v-else>{{ item.out_time }}</span>
        </template>
        <template v-slot:[`item.out_date`]="{ item }">
          <v-chip v-if="item.out_date == null" color="green">
            <span class="text-white">On Duty</span>
          </v-chip>
          <span v-else>{{ item.out_date }}</span>
        </template>
      </v-data-table>
      <paginate :paginate="pagination" @paginated="handlePagination"  />
    </v-card>
  </Dashboard>
</template>

<script>
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/PaginateAxios";
import axios from "axios";

export default {
  name: "vehicle-attendance",
  data() {
    return {
      pagination:{
        meta:{
          current_page:1
        }
      },
      filter: {
        reg_number: this.reg_number,
        start: this.date,
        end: this.date_end,
        page: 1,
      },
      reg_number: '',
      vehicleAttendance:[],
      deleteId: "",
      dialogDelete: false,
      isLoading: false,
      date: "",
      date_end: "",
      menu: false,
      menu2: false,
    };
  },
  components: {
    paginate,
    Dashboard,
  },
  computed: {
    headers() {
      return [
        {
          text: "Vehicle Number",
          align: "start",
          sortable: false,
          value: "reg_number",
        },
        {
          text: "In Time",
          align: "center",
          value: "in_time",
          sortable: false,
        },
        {
          text: "In Date",
          align: "center",
          value: "in_date",
          sortable: false,
        },

        {
          text: "Out Time",
          align: "center",
          value: "out_time",
          sortable: false,
        },
        {
          text: "Out Date",
          align: "center",
          value: "out_date",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.reloadAttendanceVehicles();
  },
  methods: {
    handlePagination(page){
      this.filter.page = page;
      this.reloadAttendanceVehicles();
    },
    reloadAttendanceVehicles() {
      this.isLoading = true;
      const filter = {
        reg_number: this.reg_number,
        start: this.date,
        end: this.date_end,
        page: this.filter.page,
      };
      axios
        .get("vehicle-attendance/get-paginate", {
          params:filter
        })
        .then((response) => {
          this.isLoading = false;
          this.vehicleAttendance = response.data.data;
          this.pagination =  response?.data?.pagination;
          console.log(this.vehicleAttendance);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>