<template>
<Dashboard
  selectedMainItem = "Administration"
  selectedSubItem = "Route">

  <v-card
      style="margin:30px;border: 0;"
      :loading="isLoading"
  >
  <v-card-title>
    Branch Areas
  </v-card-title>
  <v-card-subtitle>
    Branch city assign
    <template>
    <div>
      <v-breadcrumbs
      class="pl-0 pt-0"  
      :items="pageNavItems">
      </v-breadcrumbs>
    </div>
  </template>
  </v-card-subtitle>

      <v-row
      class="ml-2"
      >
      <v-col
          cols="12"
          sm="6"
          md="3"
          >
            <v-autocomplete
              class="ma-5"
              v-model="branch"
              :items="allBranches"
              item-text="name"
              item-value="id"
              label="Select Branch"
              autocomplete="off"
              solo
              @change="setFilterValues"
            ></v-autocomplete>
      </v-col>
      <v-col
          cols="12"
          sm="6"
          md="3"
          >
          <v-autocomplete
            class="ma-5"
            v-model="type"
            :items="branchAreaTypes"
            item-text="name"
            item-value="id"
            autocomplete="off"
            solo
            label="Area Type"
            @change="setFilterValues"
          ></v-autocomplete>
      </v-col>
      <v-col
          cols="12"
          sm="6"
          md="3"
          >
          <v-autocomplete
            class="ma-5"
            v-model="city"
            :items="unAllocatedCites"
            item-text="name"
            item-value="id"
            autocomplete="off"
            solo
            label="Select City"
          ></v-autocomplete>
      </v-col>
      <v-col
          align="right"
          cols="12"
          sm="6"
          md="3"
          >
          <v-btn
          class="ma-5 mr-10"
          style="height:48px;"
          color="primary"
          elevation="2"
          :disabled="addBtnDisabled"
          @click="addBranchAreaCity"
          >
          ASSIGN
              <v-icon right>
                  mdi-file-plus
              </v-icon>
          </v-btn>
          
      </v-col>
    </v-row>
      <v-text-field
        v-model="filter.search"
        append-icon="mdi-magnify"
        label="Search"
        class="ml-10 mr-10"
        solo
        hide-details
        @keyup="setFilterValues"
      ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="allBranchCities"
      :disable-pagination="true"
      :search="filter.search"
      item-key="id"
      class="elevation-1 ml-10 mr-10" 
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">

          <v-btn 
          class="ma-4"
          @click="setDelete(item)"
          :color="'red'">
          <span class="white--text">
            Delete
          </span>
          </v-btn>
    </template>
    </v-data-table>
 
      <!-- ******************* delete user dialog ********************** -->
    <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
        <v-card-title class="headline">Remove city ?</v-card-title>
        <v-card-text>Do you want to remove assigned city ?</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialogDelete = false">Cancel</v-btn>

            <v-btn color="red darken-1" text @click="deleteCity">Delete</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
  </v-card>
</Dashboard>
</template>

<script>
  import { mapActions } from "vuex";
  import Dashboard from '../../../components/Layouts/Leftbar'
  import axios from "axios";

  export default {
    name:"branch-area",
    data () {
      return {
        filter:{
          branch_id:'',
          type:'',
          search:'',
          page:1,
        },
        allBranches:[],
        allBranchCities:[],
        branch: '',
        type: '',
        city: '',
        deleteId:'',
        dialogDelete: false,
        isLoading : false,
        pageNavItems: [
        {
          text: 'Dashboard',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
        {
          text: 'Branch',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'branch'}
        },
        {
          text: 'City Assign',
          disabled: true,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
      ],
      }
    },
    components:{
      Dashboard,
    },
    computed: {
      branchAreaTypes:{
        get(){
          let areas = this.$store.getters.getBranchAreaTypes;
          return areas.filter(function(e) { return e.id != 4 && e.id != 3 })
        }
      },
      unAllocatedCites:{
        get(){
            return this.$store.getters.getAllUnAllocatedCites;
        }
      },
      addBtnDisabled () {
        return !(this.branch && this.type && this.city );
      },
      headers () {
        return [
          {
            text: 'Branch Name',
            align: 'start',
            sortable: false,
            value: 'branch_name',
          },
          {
            text: 'Area Type',
            align: 'start',
            sortable: false,
            value: 'type_text',
          },
          {
            text: 'City',
            align: 'start',
            sortable: false,
            value: 'location_name',
          },
          { 
            text: 'Action',  
            align: 'end', 
            value: 'actions',
            sortable: false 
          },
        ]
      },
    },
    created() {
      this.getAllCities();
      this.branch = this.$route.params?.branch?.id ?? null;
      this.filter.branch_id = this.$route.params?.branch?.id ?? null;
    },
    mounted(){
      this.loadBranches();
      this.fetchBranchArea(this.filter);
    },
    methods: {
      ...mapActions(["deleteBranchAreaById","getAllCities","branchAreaCreate"]),

      loadBranches(){
        axios.get('branch')
        .then((response) => {
          this.allBranches = response.data.data;
        });
      },

      async addBranchAreaCity(){

        await this.branchAreaCreate( { 

            branch_id: this.branch,
            location_hierarchy_id: this.city,
            type : this.type,

        }).then( () =>{

          this.$toast.success("City assigned successfully!");
          this.getAllCities();
          this.fetchBranchArea(this.filter);
        }).catch((e)=>{
          this.$toast.error(e.response.data.message);
        });
      },
      fetchBranchArea(filter){
        axios.get('branch-area/get-allocated-city',{params: {
                branch_id : filter.branch_id,
                name : filter.search,
                type : filter.type,
                page : filter.page,
            }}).then((response)=>{
              this.allBranchCities = response.data.data;
            });
      },
      setDelete(item){
        this.deleteId = item.id;
        this.dialogDelete = true;
      },
      async deleteCity(){
          this.dialogDelete = false;
          await this.deleteBranchAreaById(this.deleteId).then( () =>{
            this.fetchBranchArea(this.filter);
          this.$toast.success("City removed successfully!");
          this.getAllCities();

        }).catch((e)=>{
          this.$toast.error(e.response.data.message);
        });
      },
      setFilterValues(){
        this.filter.branch_id = this.branch;
        this.filter.type = this.type;
        this.fetchBranchArea(this.filter);
      },
      
    },
  }
</script>