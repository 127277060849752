<template>
  <Dashboard
    selectedMainItem="Courier Operation"
    selectedSubItem="Courier Screen"
  >
    <v-card style="margin: 30px; border: 0" :loading="false">
      <v-card-title>Mark Delivery</v-card-title>
      <v-card-subtitle>
        <template>
          <div>
            <v-breadcrumbs
              class="pl-0 pt-0"
              :items="pageNavItems"
            ></v-breadcrumbs>
          </div>
        </template>
      </v-card-subtitle>
    </v-card>
    <form style="margin-top: 40px; border: 0">
      <v-card style="margin: 30px; border: 0" :loading="confirmLoading">
        <v-container>
          <v-card-title>Package Details</v-card-title>
          <v-row>
            <v-col cols="12" md="3">
              <p class="details-title">Code :</p>
              <p class="details-value">{{ package_details.code }}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card style="margin: 30px; border: 0" :loading="false">
        <v-container>
          <v-card-title
            >Payment Details &nbsp;
            <v-chip v-if="package_details.paid" color="green">
              <span class="text-white">PAYMENT COMPLETED</span>
            </v-chip>
            <v-chip v-else color="red">
              <span v-if="refreshing" class="text-white">....</span>
              <span v-else class="text-white">PAYMENT PENDING </span>
            </v-chip>
          </v-card-title>
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                v-model="package_details.pay_by"
                :items="pay_by_types"
                :disabled="disableAll"
                @change="payByChanged"
                item-text="name"
                item-value="id"
                label="Pay By"
                filled
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                @change="paymentTypeChange"
                v-model="package_details.payment_type"
                :items="payment_types"
                item-text="name"
                :disabled="disableAll"
                item-value="id"
                :label="'Payment Type'"
                filled
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
              <p class="details-title">Total Amount :</p>
              <p class="details-value">
                {{ formattedTotalAmount }}
              </p>
            </v-col>
            <v-col cols="12" md="3">
              <p class="details-title">Paid Amount :</p>
              <p class="details-value">
                {{ formattedPaidAmount }}
              </p>
            </v-col>
            <v-col cols="12" md="3" class="refresh-button">
              <v-btn outlined color="indigo" @click="getData">
                <span v-if="refreshing"> Refreshing... </span>
                <span v-else> Refresh </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card style="margin: 30px; border: 0" :loading="statusLoading">
        <v-container>
          <v-card-title>Change Status</v-card-title>
          <v-card-subtitle
            >You can mark the package as delivered or send back to assigning
            stage
          </v-card-subtitle>
          <v-row>
            <v-col cols="12" md="4">
              <v-select
                v-model="stage_id"
                :items="stage_types"
                item-text="name"
                item-value="id"
                :label="'Stage Type'"
                filled
              >
              </v-select>
            </v-col>
            <v-col
              v-if="this.stage_id == 12 || this.stage_id == 18"
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="acceptor_name"
                label="Acceptor's Name"
                placeholder="Acceptor Name"
                solo
              ></v-text-field>
            </v-col>
            <v-col
              v-if="this.stage_id == 12 || this.stage_id == 18"
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="acceptor_contact"
                label="Acceptor's Contact"
                placeholder="Acceptor Contact"
                solo
              ></v-text-field>
            </v-col> </v-row
          ><v-row>
            <v-col cols="12" md="12">
              <v-textarea
                filled
                v-model="description"
                name="Description"
                label="Description"
                id="id"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <div class="ma-4" v-if="btnDisabled">
              <span class="text-danger">Payment is pending </span>
            </div>
            <v-btn v-else color="success" class="ma-4" @click="submit">
              <span> Update </span>
            </v-btn>

            <v-btn class="ma-4" @click="cancelRegister" :color="'red'">
              <span class="white--text"> Cancel </span>
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </form>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar.vue";
import axios from "axios";

export default {
  name: "MarkDelivery",
  mixins: [validationMixin],

  validations: {
    vehicleId: { required },
    fuel_qty: { required },
  },

  data: () => ({
    type: "",
    barcode: "",
    description: "",
    package_id: "",
    stage_id: "",
    acceptor_name: "",
    acceptor_contact: "",
    refreshing: false,
    package_details: {},
    confirmLoading: false,
    statusLoading: false,
    pay_by_types: [
      { name: "Sender", id: 1 },
      { name: "Receiver", id: 2 },
    ],
    stage_types: [
      { name: "Back to Assigning", id: 9 },
      { name: "Delivered", id: 12 },
    ],
    delivery_city: {},
    pickup_city: {},
    isLoading: false,
    btnDisable: false,
    pageNavItems: [
      {
        text: "Dashboard",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "dashboard" },
      },
      {
        text: "Pickup assigned",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "pickup-assigned" },
      },
      {
        text: "Add",
        disabled: true,
        link: true,
        exact: true,
        to: { name: "pickup-assigned" },
      },
    ],
  }),

  components: {
    Dashboard,
  },
  computed: {
    payment_types() {
      return [
        {
          name: "Cash",
          id: 1,
          disabled: this.package_details.pay_by == 1 ? true : false,
        },
        {
          name: "Card",
          id: 2,
          disabled: this.package_details.pay_by == 1 ? true : false,
        },
        { name: "Bank Transfer", id: 3 },
        { name: "Credit", id: 4 },
      ];
    },
    paymentPending() {
      if (!this.package_details) return true;
      if (
        this.package_details.pay_by == 1 &&
        this.package_details.payment_type == 4
      )
        return false;
      return (
        this.package_details.paid_amount < this.package_details.total_amount
      );
    },
    disableAll() {
      return this.package_details.paid;
    },
    packageData() {
      return this.$store.getters.packageInfo;
    },
    couriersData: {
      get() {
        return this.$store.getters.couriers;
      },
    },
    packageAll: {
      get() {
        return this.$store.getters.couriersPackageAll;
      },
    },
    btnDisabled() {
      return (
        this.paymentPending &&
        this.stage_id == 12 &&
        this.package_details?.payment_type != 1 &&
        this.package_details?.payment_type != 4
      );
    },

    /* pricing */
    totalWeightForPayment() {
      const width = Number(this.package_details?.width);
      const height = Number(this.package_details?.height);
      const length = Number(this.package_details?.length);
      const volume = width * height * length;
      const weightVolumeRatio = 0.2;
      const weightForVolume = (volume * weightVolumeRatio) / 1000;
      const maxWeight = Math.max(this.package_details?.weight, weightForVolume);
      return Math.round(maxWeight * 100) / 100;
    },
    packageType() {
      if (this.totalWeightForPayment < 0.5) {
        return { id: 1, name: "Document" };
      } else {
        return { id: 2, name: "Parcel" };
      }
    },
    getAreaPrices() {
      if (this.delivery_city == null) return null;
      let deliveryPrice = 0;
      let pickupPrice = 0;
      if (this.packageType.id == 1) {
        deliveryPrice = this.delivery_city?.document_prices;
        pickupPrice = this.pickup_city?.document_prices;
      } else {
        deliveryPrice = this.delivery_city?.parcel_prices;
        pickupPrice = this.pickup_city?.parcel_prices;
      }
      if (deliveryPrice?.initial > pickupPrice?.initial) {
        return deliveryPrice;
      } else {
        return pickupPrice;
      }
    },
    getPriceForWeight() {
      if (this.getAreaPrices == null) return 0;
      if (this.totalWeightForPayment == 0) return 0;
      const initialPrice = this.getAreaPrices.initial;
      const additionalPrice = this.getAreaPrices.additional;
      const additionalWeight = Math.max(0, this.totalWeightForPayment - 1);
      const additionalTotal = additionalWeight * additionalPrice;
      return initialPrice + additionalTotal;
    },
    getWithSpecialPricing() {
      let currentTotal = this.getPriceForWeight;
      if (this.package_details?.same_day == 1) {
        currentTotal += this.pricing.same_day;
      }
      if (this.package_details?.cod > 0) {
        currentTotal +=
          (this.package_details?.cod * this.pricing.cod_commission) / 100;
      }
      return currentTotal;
    },
    getWithTransactionPrice() {
      let total = Number(this.getWithSpecialPricing);
      if (this.package_details?.payment_type == "2") {
        total += total * Number(this.card_transaction_free);
      }
      return total;
    },
    getNetTotalPrice() {
      return (
        Number(this.getWithTransactionPrice) + Number(this.package_details?.cod)
      );
    },
    getDifference() {
      return Number(this.getNetTotalPrice - this.package_details?.total_amount);
    },
    formattedPaidAmount() {
      return this.package_details.paid_amount
        ? Number(this.package_details.paid_amount).toFixed(2)
        : 0;
    },
    formattedTotalAmount() {
      return this.package_details.total_amount
        ? Number(this.package_details.total_amount).toFixed(2)
        : 0;
    },
    /*pricing end */
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions(["fetchCouriers", "fetchCouriersPackageAll", "UpdatePickup"]),

    payByChanged(id) {
      this.package_details.pay_by = id;
      if (id == 1) {
        this.package_details.payment_type = 4;
      } else {
        this.package_details.payment_type = 1;
      }
      this.paymentTypeChange();
    },
    paymentTypeChange() {
      this.refreshing = true;
      axios
        .put("/package/update-payment-type", {
          package_id: this.packageData.package.id,
          payment_type: this.package_details.payment_type,
          pay_by: this.package_details.pay_by,
        })
        .then(() => {
          this.getData();
          this.$toast.success("Payment details changed");
        })
        .catch((e) => {
          this.refreshing = false;
          this.$toast.error(e.response.data.message);
        });
    },

    getData() {
      this.refreshing = true;
      axios
        .get("/package/view-more/" + this.packageData.package.id)
        .then((response) => {
          this.package_details = response.data.data.package;
          this.pickup_city = response.data.data.package.pickup_city;
          this.delivery_city = response.data.data.package.delivery_city;
          this.package_details.paid =
            this.package_details.total_amount <=
            this.package_details.paid_amount;
          this.refreshing = false;
        });
    },
    async submit() {
      this.$v.$touch();
      this.btnDisable = true;
      this.statusLoading = true;
      const stage_id = this.stage_id;
      const package_id = this.package_details?.id;
      const description = this.description;
      const payment_type = this.package_details.payment_type;
      const toSend = {
        package_id,
        description,
        payment_type,
        stage_id,
        acceptor_contact: this.acceptor_contact,
        acceptor_name: this.acceptor_name,
      };
      if (this.packageData.active_stage.stage_id == 17) {
        toSend.stage_id =18
       
        axios
          .post("/package/returned-package", toSend)
          .then(() => {
            this.$toast.success("Record saved successfully!");
            this.statusLoading = false;
            this.$router.push({ name: "courier-screen" });
            this.clear();
          })
          .catch((e) => {
            this.statusLoading = false;
            this.$toast.error(e.response.data.message);
          });
      } else {
        axios
          .post("/package/mark-delivery", toSend)
          .then(() => {
            this.$toast.success("Record saved successfully!");
            this.statusLoading = false;
            this.$router.push({ name: "courier-screen" });
            this.clear();
          })
          .catch((e) => {
            this.statusLoading = false;
            this.$toast.error(e.response.data.message);
          });
      }
    },
    clear() {
      this.$v.$reset();
    },
    cancelRegister() {
      this.$router.push({ name: "courier-screen" });
    },
  },
  watch: {
    packageData() {
      this.getData();
    },
  },
};
</script>
<style scoped>
.details-title {
  font-size: 1rem;
  display: inline;
}
.details-value {
  font-size: 1rem;
  display: inline;
}
.text-danger {
  color: red;
}
.text-red {
  color: red;
}
.text-green {
  color: green;
}
.refresh-button {
  margin-top: -10px;
}
</style>
