import axios from "axios";

const state = {
  driverAssign: [],
  driverCouriers: [],
  attendedUsers: [],
  vehicleAssigned: [],
  allDriverAssign: [],
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
};

const getters = {
  driverCouriers: (state) => state.driverCouriers,
  attendedUsers: (state) => state.driverCouriers,
  driverAssign: (state) => state.driverAssign,
  allDriverAssign: (state) => state.allDriverAssign,
};

const actions = {
  async fetchDriverAssign({ commit }, filter) {
    const driverAssign = await axios.get("vehicle-assign/get-paginate", {
      params: {
        start: filter.start,
        end: filter.end,
        reg_number: filter.reg_number,
        page: filter.page,
      },
    });

    commit("setDriverAssign", driverAssign.data.data);
    commit("setPagination", driverAssign.data.pagination);
    return driverAssign;
  },
  async deleteDriverById({ commit }, id) {
    await axios.delete(`vehicle-assign/${id}`);

    commit("removeDriverAssign", id);
  },
  async getAllDriverAssign({ commit }) {
    const driverAssign = await axios.get("driverAssign");

    commit("setAllDriverAssign", driverAssign.data.data);
    return driverAssign.data;
  },
  async fetchVehicleAssigned({ commit, date }) {
    const driverAssign = await axios.get("attendance/get-attended-vehicles", {
      params: {
        date: date,
      },
    });

    commit("setAllVehicleAssign", driverAssign.data.data);
    return driverAssign.data;
  },

  async getAllDriversByDate({ commit }) {
    const driverAssign = await axios.get("/attendance/get-attended-drivers");

    commit("setAttendedUsers", driverAssign.data.data);
    return driverAssign.data;
  },

  async markDriverAssign({ commit }, toSend) {
    const vehicle_id = toSend.vehicle_id;
    const date = toSend.date;
    const user_id = toSend.user_id;
    const user = await axios.post("vehicle-assign", {
      vehicle_id,
      date,
      user_id
    });

    commit("newUser", user.data.data);
    return user;
  },
};

const mutations = {
  setDriverAssign: (state, driverAssign) => (state.driverAssign = driverAssign),

  setAllVehicleAssign: (state, driverAssign) =>
    (state.driverAssign = driverAssign),
  setAttendedUsers: (state, attendedUsers) =>
    (state.attendedUsers = attendedUsers),
  setAllCouriers: (state, driverCouriers) =>
    (state.driverCouriers = driverCouriers),
  removeDriverAssign: (state, id) => {
    state.driverAssign = state.driverAssign.filter((user) => user.id !== id);
  },
  setAllDriverAssign: (state, allDriverAssign) =>
    (state.allDriverAssign = allDriverAssign),
  setPagination: (state, paginate) => (state.paginate = paginate),
  setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
