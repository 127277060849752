<template>
  <Dashboard selectedMainItem="Finance" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-text-field
            v-model="filter.code"
            label="Code"
            class="ml-5"
            @input="reload()"
            type="text"
            required
          >
          </v-text-field>
        </v-col>
        <v-col align="right" cols="12" sm="6" md="4">
          <v-select
            v-model="client_id"
            :items="customers"
            item-text="name"
            item-value="id"
            class="ml-3"
            label="Client "
            placeholder="Client"
            @change="reload()"
          ></v-select>
        </v-col>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-select
            v-model="pay_by"
            :items="pay_by_types"
            item-text="name"
            item-value="id"
            label="Pay By"
            placeholder="Pay By"
            text
            required
            @change="reload()"
          ></v-select>
        </v-col>
        <v-col align="right" cols="12" sm="6" md="3">
          <v-select
            v-model="payment_type"
            :items="payment_types"
            item-text="name"
            item-value="id"
            label="Payment Type"
            placeholder="Payment Type"
            text
            required
            @change="reload()"
          ></v-select>
        </v-col>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-select
            v-model="has_cod"
            :items="codValues"
            item-text="name"
            item-value="id"
            label="Cod"
            class="ml-5"
            placeholder="Cod"
            text
            required
            @change="reload()"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                class="ml-5"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                label="Start Date"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                class="ml-5"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(date_end);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col align="left" cols="12" sm="6" md="4">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
          <v-btn
            :disabled="exporting"
            depressed
            @click="exportExcel"
            :color="exporting ? '#C9C9C9' : 'success'"
            ><v-icon class="mr-2"> mdi-file-excel </v-icon> Export</v-btn
          >
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="respData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.charges`]="{ item }">
          <span style="white-space: nowrap"
            >Courier Charge : {{ item.courier_charge }} </span
          ><br />
          <span v-if="item.cod > 0" style="white-space: nowrap"
            >Item Price : {{ item.cod_charge }} </span
          ><br />
        </template>
        <template v-slot:[`item.pay_by_text`]="{ item }">
          {{ item.pay_by_text }} / {{ item.payment_type_text }}
        </template>
        <template v-slot:[`item.payment_type`]="{ item }">
          {{ item.payment_type_text }} / {{ item.pay_by_text }}
        </template>
        <template v-slot:[`item.addresses`]="{ item }">
          Pickup : {{ item.pickup }}<br />
          Delivery : {{ item.delivery }}<br />
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span v-if="item.payment_completed">Completed</span>
          <span v-else>Pending</span>
        </template>
      </v-data-table>
      <paginate :paginate="pagination" @paginated="handlePagination" />
    </v-card>
  </Dashboard>
</template>

<script>
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/PaginateAxios";
import axios from "axios";

export default {
  name: "ReportPickupAssigned",
  data() {
    return {
      pagination: {
        meta: {
          current_page: 1,
        },
      },
      has_cod: null,
      exporting: false,
      customers: [],
      filter: {
        start: this.date,
        end: this.date_end,
        reg_number: this.vehicleId,
        code: "",
        page: 1,
      },
      isLoading: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date_end: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      item: {},
      branch: "",
      respData: [],
      pay_by_types: [
        { name: "All", id: null },
        { name: "Sender", id: 1 },
        { name: "Receiver", id: 2 },
      ],
      payment_types: [
        { name: "All", id: null },
        { name: "Cash", id: 1 },
        { name: "Card", id: 2 },
        { name: "Bank Transfer", id: 3 },
        { name: "Credit", id: 4 },
      ],
      codValues: [
        { name: "All", id: null },
        { name: "Has Item Price", id: 1 },
        { name: "No Item Price", id: 0 },
      ],
      package_type: [
        { name: "All", id: null },
        { name: "Documents", id: 1 },
        { name: "Parcel", id: 2 },
      ],
      pay_by: "",
      client_id: "",
      payment_type: "",
    };
  },
  components: {
    Dashboard,
    paginate,
  },
  computed: {
    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Package Date",
          align: "start",
          sortable: false,
          value: "package_date",
        },
        {
          text: "Pickup / Delivery",
          value: "addresses",
          sortable: false,
        },
        {
          text: "Stage",
          value: "current_stage",
          sortable: false,
        },
        {
          text: "Payment Type",
          value: "payment_type",
          sortable: false,
        },
        {
          text: "Charges",
          value: "charges",
          align: "end",
          sortable: false,
        },
        {
          text: "Total Amount",
          value: "formatted_total",
          sortable: false,
        },
        {
          text: "Paid Amount",
          value: "paid_amount",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          value: "status",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    getCustomers() {
      axios.get("/user/get-all-customers").then((response) => {
        const newFirstElement = {
          name: "All",
          id: null,
        };
        const newArray = [newFirstElement].concat(response?.data?.data);
        this.customers = newArray;
      });
    },
    handlePagination(page) {
      this.filter.page = page;
      this.fetchData();
    },
    fetchData() {
      axios
        .get("/reports/payment", {
          params: {
            client_id: this.client_id,
            report_start: this.date,
            report_end: this.date_end,
            code: this.filter.code,
            has_cod: this.has_cod,
            payment_type: this.payment_type,
            pay_by: this.pay_by,
            page: this.filter.page,
          },
        })
        .then((response) => {
          this.respData = response?.data?.data;
          this.pagination = response?.data?.pagination;
        });
    },
    reload() {
      this.fetchData();
    },
    clear() {
      this.filter = {
        code: "",
      };
      this.payment_type = "";
      this.pay_by = "";
      this.has_cod = null;
      this.client_id = "";
      this.date = "";
      this.date_end = "";
      this.reload();
    },
    exportExcel() {
      let url = "/reports/payment/export?";
      if (this.client_id != null && this.client_id != "") {
        url += "client_id=" + this.client_id + "&";
      }
      if (this.has_cod != null && this.has_cod != "") {
        url += "has_cod=" + this.has_cod + "&";
      }
      if (this.payment_type != null && this.payment_type != "") {
        url += "payment_type=" + this.payment_type + "&";
      }
      if (this.pay_by != null && this.pay_by != "") {
        url += "pay_by=" + this.pay_by + "&";
      }
      if (this.date != null && this.date != "") {
        url += "report_start=" + this.date + "&";
      }
      if (this.date_end != null && this.date_end != "") {
        url += "report_end=" + this.date_end;
      }
      if (this.filter.code != null) {
        url += "code=" + this.filter.code + "&";
      }

      this.exporting = true;
      this.exportExcelCommon(url, "payment-report.xlsx", () => {
        this.exporting = false;
      });
    },
  },
};
</script>
