import axios from "axios";

const state = {
  Payment: [],
  PaymentGetAll: [],
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
};

const getters = {
  Payment: (state) => state.Payment,
  PaymentGetAll: (state) => state.PaymentGetAll,
};

const actions = {
  async fetchPayment({ commit }, filter) {
    const Payment = await axios.get("package/get-for-payment", {
      params: {
        payment_status: filter.payment_status,
      },
    });

    commit("setPayment", Payment.data.package);
    commit("setPagination", Payment.data.pagination);
    return Payment;
  },
  async fetchPaymentGetAll({ commit }) {
    const Payment = await axios.get("package/get-all");

    commit("setPaymentAll", Payment.data.package);
    commit("setPagination", Payment.data.pagination);
    return Payment;
  },

  async UpdatePayment({ commit }, toSend) {
    const package_id = toSend.package_id;
    const amount = toSend.amount;
    const type = toSend.type;
    const user = await axios.post("payment/store", {
      package_id,
      amount,
      type,
    });
    commit("newUser", user.data.data);
    return user;
  },
};

const mutations = {
  setPayment: (state, Payment) => (state.Payment = Payment),
  setPaymentAll: (state, PaymentGetAll) =>
    (state.PaymentGetAll = PaymentGetAll),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
