<template>
  <Dashboard selectedMainItem="Operation" selectedSubItem="Vehicle">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-card-title> Assign Route </v-card-title>
      <v-card-subtitle>
        Assign Route
        <template>
          <div>
            <v-breadcrumbs
              class="pl-0 pt-0"
              :items="pageNavItems"
            ></v-breadcrumbs>
          </div>
        </template>
      </v-card-subtitle>
      <form style="margin-top: 40px; border: 0">
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="to_day"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="to_day"
                    class="ml-5"
                    label="Select Date"
                    prepend-icon="mdi-calendar"
                    readonly                   
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker  v-model="to_day" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(to_day);reload()">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :items="userData"
                v-model="userId"
                :item-text="getUserItemText"
                item-value="user_id"
                label="User Name"
                :error-messages="userIdErrors"
                required
                @input="$v.userId.$touch()"
                @blur="$v.userId.$touch()"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :items="routeData"
                v-model="routeId"
                :item-text="getRouteItemText"
                item-value="id"
                label="Route Name"
                :error-messages="typeErrors"
                required
                @input="$v.routeId.$touch()"
                @blur="$v.routeId.$touch()"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-btn
              :disabled="btnDisabled"
              color="success"
              class="ma-4"
              @click="submit"
            >
              <span> Add </span>
            </v-btn>

            <v-btn class="ma-4" @click="cancelAssign" :color="'red'">
              <span class="white--text"> Cancel </span>
            </v-btn>
          </v-row>
        </v-container>
      </form>
      <v-data-table
        :headers="headers"
        :items="routeAssignData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
        :items-per-page="ROWS_PER_PAGE"
      >
      </v-data-table>
    </v-card>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar.vue";
import axios from "axios";

export default {
  name: "route-assign-create",
  mixins: [validationMixin],

  validations: {
    routeId: { required },
    userId: { required },
  },

  data: () => ({
    name: "",
    regNumber: "",
    type: "",
    status: 1,
    isCompany: 0,
    isLoading: false,
    btnDisable: false,
    checkbox: false,
    to_day: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    vehicleData: [],
    routeData: [],
    userId: "",
    routeId: "",

    pageNavItems: [
      {
        text: "Dashboard",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "dashboard" },
      },
      {
        text: "Route",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "route-assign" },
      },
      {
        text: "Add",
        disabled: true,
        link: true,
        exact: true,
        to: { name: "route-assign-create" },
      },
    ],
  }),
  components: {
    Dashboard,
  },
  created() {
    const filter = {
      date: this.to_day,
    };
    this.reload();
    this.fetchCouriersAttended(filter);
  },
  computed: {
    routeAssignData: {
      get() {
        return this.$store.getters.AllRouteAssign;
      },
    },
    userData: {
      get() {
        return this.$store.getters.couriersAttended;
      },
    },
    headers() {
      return [
        {
          text: "Route Name",
          align: "start",
          sortable: false,
          value: "route_name",
        },
        {
          text: "Date",
          value: "date",
          sortable: false,
        },
        {
          text: "Name",
          value: "user_name",
          sortable: false,
        },
      ];
    },
    btnDisabled() {
      const visible = this.$v.$anyError || !(this.routeId && this.userId);
      return visible;
    },

    userIdErrors() {
      const errors = [];
      if (!this.$v.userId.$dirty) return errors;
      !this.$v.userId.required && errors.push("number of liters is required.");
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.routeId.$dirty) return errors;
      !this.$v.routeId.required && errors.push("routeId is required.");
      return errors;
    },
  },
  mounted() {
    this.getAllRoutes();
  },
  methods: {
    ...mapActions([
      "fetchCouriersAttended",
      "vehicleAssign",
      "getAllRouteAssign",
    ]),
    getAllRoutes() {
      axios
        .get("route")
        .then((response) => {
          this.routeData = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
        });
    },
    getUserItemText(item) {
      return item.name + " (" + item.branch + ")";
    },
    getRouteItemText(item) {
      return item.name + " (" + item.branch.name + " branch)";
    },
    reload() {
      const filter = {
        start: this.to_day,
        end: this.to_day,
        status:1
      };
      this.getAllRouteAssign(filter);
    },
    async submit() {
      this.$v.$touch();

      this.btnDisable = true;
      this.isLoading = true;
      const date = this.to_day;
      const route_id = this.routeId;
      const user_id = this.userId;
      const toSend = {
        date,
        route_id,
        user_id,
      };

      await this.vehicleAssign(toSend)
        .then(() => {
          this.$toast.success("Record saved successfully!");
          this.isLoading = false;
          this.reload();
          this.clear();
        })
        .catch((e) => {
          this.isLoading = false;
          this.$toast.error(e.response?.data?.message);
        });
    },
    clear() {
      this.$v.$reset();
      this.userId = "";
      this.routeId = "";
    },

    cancelAssign() {
      this.$router.push({ name: "route-assign" });
    },
  },
};
</script>
