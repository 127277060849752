<template>
    <div class="text-center">
        <v-pagination
            v-model="currentPage"
            :length="lastPage"
            :total-visible="6"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
        ></v-pagination>
    </div>
</template>
<script>
export default {
    props:[
        'store',
        'filter',
        'method',
        ],
    name:'paginate-bar',
    watch:{
        currentPage(newVal){
            this.paginatePage(newVal);
        }
    },
    computed:{
        currentPage:{
            get(){
                return this.$store.state[this.store].paginate.meta.current_page;
            },
            set(value){
                this.$store.commit('setCurrentPage',value);
            }
        },
        lastPage:{
            get(){
                return this.$store.state[this.store].paginate.meta.last_page;
            }
        },
    },
    methods: {
        paginatePage(pageNumber){
            var params = this.filter;
            params.page = pageNumber;
            this.$store.dispatch(this.method, this.filter);
        }
    }
}
</script>