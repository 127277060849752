<template>

<Dashboard
  selectedMainItem = "Administration"
  selectedSubItem = "Branch">

  <v-card
  style="margin:20px;border: 0;"
  :loading="isLoading"
  >
  <v-card-title>
    Branch View
  </v-card-title>
  <v-card-subtitle>
    view branch here
    <template>
    <div>
      <v-breadcrumbs
      class="pl-0 pt-0"  
      :items="pageNavItems"></v-breadcrumbs>
    </div>
  </template>
  </v-card-subtitle>
    <v-container>
      <v-row
        class="ml-5 mr-5" 
      >
        <v-col
          cols="12"
          md="6"
        >
        <v-card
          height="200"
          elevation="2"
        >
          <v-card-title>
            Branch Details
          </v-card-title>

            <v-list-item >
              <v-list-item-content>
                <v-list-item-title>Name  : {{ branch.name }}</v-list-item-title>
                <v-list-item-title>Address  : {{ branch.address }}</v-list-item-title>
                <v-list-item-title>Phone  : {{ branch.phone }}</v-list-item-title>
                <v-list-item-title>Opening Time  : {{ branch.opening_time }}</v-list-item-title>
                <v-list-item-title>Status  : {{ (branch.status)?'Active':'Inactive' }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

        </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
        </v-col>
      </v-row>

      <v-row
        class="ml-5 mr-5 pt-3" 
      >
      <v-col
          cols="12"
          md="12"
        >
        <h3>Branch Areas </h3>
        </v-col>
          <v-col
          cols="12"
          md="12"
        >
            <v-data-table
            :headers="headers"
            :disable-pagination="true"
            :items="branch.branch_areas"
            item-key="id"
            class="elevation-1"
            hide-default-footer
            >
            </v-data-table>
        </v-col>
      </v-row>
    </v-container>

  </v-card>
</Dashboard>
</template>

<script>
  import Dashboard from '../../components/Layouts/Leftbar'


  export default {
    name:'route-view',

    data(){
      return{

        isLoading : false,
        pageNavItems: [
        {
          text: 'Dashboard',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
        {
          text: 'Branch',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'branch'}
        },
        {
          text: 'View',
          disabled: true,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
      ],
      }
    },
    components:{
        Dashboard
    },
    computed: {
      branch:{
        get(){
            return this.$store.getters.editBranch;
        }
      },
        headers () {
        return [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          {
            text: 'Location name',
            value: 'location_name',
            sortable: false
          },
          { 
            text: 'Type', 
            value: 'type_text',
            sortable: false 
          },
        ]
      },

    },
    methods: {

    cancelEdit(){
      this.$router.push({name:'route'});
    }
    },
  }
</script>
