<template>
<Dashboard
  selectedMainItem = "Administration"
  selectedSubItem = "Branch">
      <template >
      <FilterBar @updateFilterData="setFilterValues" forName="question" />
    </template>

  <v-card
      style="margin:30px;border: 0;"
      :loading="isLoading"
  >
      <v-row>
      <v-col
          cols="12"
          sm="6"
          md="4"
          >
      </v-col>
      <v-col
          cols="12"
          sm="6"
          md="4"
          >
      </v-col>
      <v-col
          align="right"
          cols="12"
          sm="6"
          md="4"
          >
          <v-btn
          class="mr-5"
          color="primary"
          elevation="2"
          :to="{name: 'faq-create'}"
          >
          ADD
              <v-icon right>
                  mdi-file-plus
              </v-icon>
          </v-btn>
          
      </v-col>
    </v-row>


    <v-data-table
      :headers="headers"
      :items="allQuestions"
      item-key="id"
      class="elevation-1"
      hide-default-footer
    >
          <template v-slot:[`item.question`]="{ item }">
        <span>{{ item.question.slice(0, 150).concat('...') }}</span>
        <br/>
        <span class="ml-2 grey--text">{{ item.answer.slice(0, 150).concat('...') }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="editFaq(item)"
          >
            <v-list-item-title>
              <v-icon
                class="mr-3"
              >
                mdi-pencil
              </v-icon>
              Edit
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="setFaqDelete(item)"
          >
            <v-list-item-title>
              <v-icon
                class="mr-3"
              >
                mdi-delete
              </v-icon>
              Delete
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    </v-data-table>
        <paginate
        store="Branch"
        method="fetchQuestions"
        :filter="filter"
        />
      <!-- ******************* delete user dialog ********************** -->

    <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
        <v-card-title class="headline">Branch Delete?</v-card-title>
        <v-card-text>Do you want to Delete ?</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialogDelete = false">Cancel</v-btn>

            <v-btn color="red darken-1" text @click="questionDelete">Delete</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
  </v-card>
</Dashboard >
</template>

<script>
  import { mapActions } from "vuex";
  import Dashboard from '../../components/Layouts/Leftbar'
  import paginate from '../../components/Common/Paginate';
  import FilterBar from '../../components/Common/FilterBar';

  export default {
    name:"all-operation",
    data () {
      return {
        filter:{
          search:'',
          page:1,
        },
        search: '',
        calories: '',
        deleteId: '',
        isLoading: false,
        dialogDelete: false,
      }
    },
    components:{
      paginate,
      FilterBar,
      Dashboard,
    },
    computed: {

    allQuestions:{
        get(){
            return this.$store.getters.allQuestions;
        }
      },
      headers () {
        return [
          {
            text: 'Questions',
            align: 'start',
            sortable: false,
            value: 'question',
          },
          { 
            text: 'Operation',  
            align: 'end', 
            value: 'actions',
            sortable: false 
          },
        ]
      },
    },
    created() {
      this.fetchQuestions(this.filter);

    },
    methods: {
      ...mapActions(["fetchQuestions","deleteQuestionById"]),
      filterOnlyCapsText (value, search) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },
      async editFaq(branch){

        await this.$store.dispatch('getQuestionById',branch.id);
        this.$router.push({name:'faq-edit'});
      
      },
      setFaqDelete(item){
        
        this.deleteId = item.id;
        this.dialogDelete = true;
      },
      async questionDelete(){
          this.dialogDelete = false;
          await this.deleteQuestionById(this.deleteId).then( response =>{

          console.log(response);
          this.$toast.success("Branch deleted successfully!");

        }).catch((e)=>{
          
          this.$toast.error(e.response.data.message);
        });
      },
      setFilterValues(childValues){

        this.fetchQuestions(childValues);
      },
      uppercase() {
        this.search = this.search.toUpperCase();
      },
    },
  }
</script>