import axios from 'axios';


const state = {
    route: null,
    paginate: {
        meta:
        {
            current_page : 1,
            last_page : 1,
        }
    },
    cityRoutes: [],
    allCities: [],
};

const getters = {
    
    getAllCities: state => state.allCities,
    getAllRouteCities: state => state.cityRoutes,
    setRouteCity: state => state.route,
    routeCityPaginate: state => state.paginate,

};

const actions = {

    async fetchCityRoutes({ commit }, filter) {
            const allCityRoutes = await axios.get('route-city/get-paginate',{params: {
                route_id : filter.route_id,
                page : filter.page,
                name : filter.search,
            }});
            commit("setCityRoutes",  allCityRoutes.data.data);
            commit("setPagination",  allCityRoutes.data.pagination);
            return allCityRoutes;
    },
    
    async getAllCities({ commit }){
        
        const cities = await axios.get('location-hierarchy',{params:{type : 4 }});
        commit("setAllCities",  cities.data.data);
        return cities.data.data;
    },
    
    async routeCityCreate({ commit }, { route_id, location_hierarchy_id }) {
        
        const route = await axios.post('route-city', {
            route_id,
            location_hierarchy_id,
        });
        commit("newRoute",  route.data.data );
        return route;
    },

    async deleteRouteCityById({ commit }, id) {
        console.log(commit);
        return await axios.delete(`route-city/${id}`);
    }

};

const mutations = {
    setCityRoutes: (state, cities) =>(state.cityRoutes = cities),
    setAllCities: (state, cities) => (state.allCities = cities),
    setRoute: (state, route) => (state.route = route),
    setPagination: (state, paginate) => (state.paginate = paginate),
    setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
    newRoute: (state, route) =>{
        state.cityRoutes.unshift(route);
        state.editroute = null;
    },   
    removeRoute: (state, id) =>{
        state.routes = state.routes.filter(route => route.id !== id);
    },
};

export default {
state,
getters,
actions,
mutations
};