<template>
  <Dashboard selectedMainItem="Administration" selectedSubItem="Route">
    <template>
      <FilterBar @updateFilterData="setFilterValues" forName="Group" />
    </template>

    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col cols="12" sm="6" md="4"> </v-col>
        <v-col cols="12" sm="6" md="4"> </v-col>
        <v-col align="right" cols="12" sm="6" md="4">
          <v-btn
            class="mr-5"
            color="primary"
            elevation="2"
            :to="{ name: 'branch-group-create' }"
          >
            ADD
            <v-icon right> mdi-file-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="allGroups"
        item-key="id"
        class="elevation-1"
        hide-default-footer
      >
        <template :v-slot="body.append">
          <tr>
            <td></td>
            <td>
              <v-text-field
                v-model="calories"
                type="number"
                label="Less than"
              ></v-text-field>
            </td>
            <td colspan="4"></td>
          </tr>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editGroup(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-pencil </v-icon>
                  Edit
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="branchAssign(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-home </v-icon>
                  Branch Assign
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate store="Route" method="fetchRoutes" :filter="filter" />
      
    </v-card>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../../components/Layouts/Leftbar";

import paginate from "../../../components/Common/Paginate";
import FilterBar from "../../../components/Common/FilterBar";
import axios from "axios";

export default {
  name: "branch-group",
  data() {
    return {
      filter: {
        search: "",
        page: 1,
      },
      search: "",
      calories: "",
      deleteId: "",
      dialogDelete: false,
      isLoading: false,
      allGroups: [],
    };
  },
  components: {
    paginate,
    FilterBar,
    Dashboard,
  },
  mounted() {
    this.getGroups();
  },
  computed: {
    headers() {
      return [
        {
          text: "Group name",
          align: "name",
          sortable: false,
          value: "name",
        },
        {
          text: "Rate",
          value: "rate",
          sortable: false,
        },
        {
          text: "Additional",
          value: "additional",
          sortable: false,
        },
        {
          text: "Document",
          value: "document",
          sortable: false,
        },
        {
          text: "Actions",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchRoutes(this.filter);
  },
  methods: {
    ...mapActions(["fetchRoutes", "deleteRouteById"]),

    async editGroup(item) {
      this.$router.push({
        name: "branch-group-update",
        params: { item: item },
      });
    },

    async branchAssign(item) {
      this.$router.push({
        name: "branch-group-branch-assign",
        params: { item: item },
      });
    },

    async deleteRoute() {
      this.dialogDelete = false;
      await this.deleteRouteById(this.deleteId)
        .then(() => {
          this.$toast.success("Route deleted successfully!");
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
        });
    },
    async setFilterValues(childValues) {
      this.filter.search = childValues.search;
      this.filter.page = childValues.page;

      await axios
        .get("branch-group/get-all", {
          params: {
            name: this.filter.search,
          },
        })
        .then((response) => {
          console.log(response);
          this.allGroups = response.data.data;
        });
    },
    async getGroups() {
      await axios.get("branch-group/get-all").then((response) => {
        console.log(response);
        this.allGroups = response.data.data;
      });
    },
  },
};
</script>
