<template>
  <Dashboard
    selectedMainItem="Courier Operation"
    selectedSubItem="Courier Screen"
  >
    <v-card style="margin: 30px; border: 0" :loading="false">
      <v-card-title>Courier Update</v-card-title>
      <v-card-subtitle>
        <template>
          <div>
            <v-breadcrumbs
              class="pl-0 pt-0"
              :items="pageNavItems"
            ></v-breadcrumbs>
          </div>
        </template>
      </v-card-subtitle>
    </v-card>
    <form style="margin-top: 40px; border: 0">
      <v-card style="margin: 30px; border: 0" :loading="confirmLoading">
        <v-container>
          <v-card-title
            >Confirm Details
            <v-chip
              class="ml-4"
              v-if="package_details.courier_confirm"
              color="green"
            >
              <span class="text-white">CONFIRMED</span>
            </v-chip>
            <v-chip class="ml-4" v-else color="red">
              <span class="text-white">NOT CONFIRMED</span>
            </v-chip></v-card-title
          >
          <v-card-subtitle
            >Double check and confirm the package details.You can edit if the
            details are incorrect.
          </v-card-subtitle>
          <v-row>
            <v-col cols="12" md="3">
              <p class="details-title">Code :</p>
              <p class="details-value">{{ package_details.code }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                :disabled="disableAll"
                filled
                @input="calTotal"
                v-model="package_details.weight"
                type="number"
                name="Weight"
                label="Weight ( Kg )"
                id="id"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                :disabled="disableAll"
                @input="calTotal"
                type="number"
                name="length"
                label="Length ( cm )"
                id="id"
                v-model="package_details.length"
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                filled
                :disabled="disableAll"
                @input="calTotal"
                v-model="package_details.width"
                name="Width"
                type="number"
                label="Width ( cm )"
                id="id"
              ></v-text-field> </v-col
            ><v-col cols="12" md="3">
              <v-text-field
                filled
                :disabled="disableAll"
                @input="calTotal"
                v-model="package_details.height"
                name="Height"
                type="number"
                label="Height ( cm )"
                id="id"
              ></v-text-field>
            </v-col> </v-row
          ><v-row>
            <v-col cols="12" md="3">
              <v-select
                v-model="package_details.pay_by"
                :items="pay_by_types"
                :disabled="disableAll"
                @change="payByChanged"
                item-text="name"
                item-value="id"
                label="Pay By"
                filled
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="3" v-if="package_details.pay_by == 1">
              <v-select
                v-model="package_details.payment_type"
                :items="payment_types"
                @change="calTotal"
                item-text="name"
                :disabled="disableAll"
                item-value="id"
                :label="'Payment Type'"
                filled
              >
              </v-select>
            </v-col>
            <!-- <v-col cols="12" md="4" v-if="package_details.pay_by == 1">
              <v-text-field
                filled
                :disabled="package_details.payment_type !== 1 || package_details.pay_by != 1 || disableAll"
                v-model="package_details.paid_amount"
                name="paid_amount"
                label="Paid Amount"
                id="id"
              ></v-text-field>
            </v-col> -->
          </v-row>

          <v-row v-if="!disableAll">
            <v-col cols="12" md="4">
              <v-btn color="info" :disabled="calculating" @click="confirm">
                <span> Confirm Details </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card style="margin: 30px; border: 0" :loading="false">
        <v-container>
          <v-card-title
            >Payment Details &nbsp;
            <v-chip v-if="package_details.paid" color="green">
              <span class="text-white">PAYMENT COMPLETED</span>
            </v-chip>
            <v-chip v-else color="red">
              <span v-if="refreshing" class="text-white">....</span>
              <span v-else class="text-white">PAYMENT PENDING </span>
            </v-chip>
          </v-card-title>
          <v-row>
            <v-col cols="12" md="3">
              <p class="details-title">Estimated Weight :</p>
              <p class="details-value">{{ totals.total_weight }} Kg</p>
            </v-col>
            <v-col cols="12" md="3">
              <p class="details-title">Total Amount :</p>
              <p class="details-value">
                {{ formattedTotalAmount }}
                <span v-if="getDifference > 0" class="text-green">
                  + {{ getDifference.toFixed(2) }}</span
                >
                <span v-if="getDifference < 0" class="text-red">
                  - {{ getDifference.toFixed(2) }}</span
                >
                <span v-if="getDifference != 0">
                  =
                  <b>
                    {{
                      Number(
                        getDifference + package_details.total_amount
                      ).toFixed(2)
                    }}</b
                  ></span
                >
              </p>
            </v-col>
            <v-col cols="12" md="3">
              <p class="details-title">Paid Amount :</p>
              <p class="details-value">
                {{ formattedPaidAmount }}
              </p>
            </v-col>
            <v-col cols="12" md="3" class="refresh-button">
              <v-btn outlined color="indigo" @click="getData">
                <span v-if="refreshing"> Refreshing... </span>
                <span v-else> Refresh </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card style="margin: 30px; border: 0" :loading="statusLoading">
        <v-container>
          <v-card-title>Change Status</v-card-title>
          <v-card-subtitle
            >You can collect or send package back to pending status here
          </v-card-subtitle>
          <v-row>
            <v-col cols="12" md="4">
              <v-select
                v-model="stage_id"
                :items="stage_types"
                item-text="name"
                item-value="id"
                :label="'Stage Type'"
                filled
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :disabled="stage_id != 4"
                name="barcode"
                label="Barcode"
                id="id"
                v-model="barcode"
                filled
              ></v-text-field>
            </v-col> </v-row
          ><v-row>
            <v-col cols="12" md="12">
              <v-textarea
                filled
                v-model="description"
                name="Description"
                label="Description"
                id="id"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <div class="ma-4" v-if="btnDisabled">
              <span class="text-danger" v-if="!package_details.courier_confirm"
                >Details not confirmed yet
              </span>
              <span class="text-danger" v-else>Payment is pending </span>
            </div>
            <v-btn
              :disabled="calculating"
              v-else
              color="success"
              class="ma-4"
              @click="submit"
            >
              <span> Update </span>
            </v-btn>

            <v-btn class="ma-4" @click="cancelRegister" :color="'red'">
              <span class="white--text"> Cancel </span>
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </form>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar.vue";
import axios from "axios";

export default {
  name: "CourierConfirm",
  mixins: [validationMixin],

  validations: {
    vehicleId: { required },
    fuel_qty: { required },
  },

  data: () => ({
    totals: {
      total_weight: 0,
      package_type: { id: 1, name: "Document" },
      with_transaction_cost: 0,
      net_total: 0,
    },
    type: "",
    calculating: false,
    barcode: "",
    description: "",
    package_id: "",
    stage_id: "",
    is_corporate: false,
    refreshing: false,
    package_details: {},
    confirmLoading: false,
    card_transaction_free: 3 / 100,
    statusLoading: false,
    pay_by_types: [
      { name: "Sender", id: 1 },
      { name: "Receiver", id: 2 },
    ],
    stage_types: [
      { name: "Pending", id: 2 },
      { name: "Collected", id: 4 },
    ],
    delivery_city: {},
    pickup_city: {},
    isLoading: false,
    btnDisable: false,
    pricing: {
      same_day: 0,
      cod_commission: 0,
      card: 0.03,
    },
    pageNavItems: [
      {
        text: "Dashboard",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "dashboard" },
      },
      {
        text: "Courier Screen",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "courier-screen" },
      },
      {
        text: "Add",
        disabled: true,
        link: true,
        exact: true,
        to: { name: "courier-screen" },
      },
    ],
  }),

  components: {
    Dashboard,
  },
  computed: {
    payment_types() {
      let array = [
        {
          id: 1,
          name: "Cash",
        },

        {
          id: 3,
          name: "Bank transfer",
        },
      ];
      if (this.package_details.pay_by == 1) {
        array.push({
          id: 2,
          name: "Card",
        });
      }
      if (this.is_corporate == 1) {
        array.push({
          id: 4,
          name: "Credit",
        });
      }
      return array;
    },
    paymentPending() {
      if (!this.package_details) return true;
      return (
        this.package_details.payment_type != 4 &&
        this.package_details.pay_by != 2 &&
        this.package_details.paid_amount < this.package_details.total_amount
      );
    },
    disableAll() {
      return this.package_details.courier_confirm == 1;
    },
    packageData() {
      return this.$store.getters.packageInfo;
    },
    couriersData: {
      get() {
        return this.$store.getters.couriers;
      },
    },
    packageAll: {
      get() {
        return this.$store.getters.couriersPackageAll;
      },
    },
    btnDisabled() {
      const disabled =
        (this.stage_id == 4 && !this.package_details?.courier_confirm) ||
        (this.paymentPending && this.stage_id == 4);
      return disabled;
    },
    getDifference() {
      return Number(this.totals.net_total - this.package_details?.total_amount);
    },
    formattedPaidAmount() {
      return this.package_details.paid_amount
        ? Number(this.package_details.paid_amount).toFixed(2)
        : 0;
    },
    formattedTotalAmount() {
      return this.package_details.total_amount
        ? Number(this.package_details.total_amount).toFixed(2)
        : 0;
    },
    /*pricing end */
  },
  mounted() {
    this.getPricingSettings();
    this.getData();
  },
  methods: {
    ...mapActions(["fetchCouriers", "fetchCouriersPackageAll", "UpdatePickup"]),
    calTotal() {
      this.calculating = true;
      const packageData = {
        id: this.package_details?.id,
        weight: this.package_details?.weight,
        height: this.package_details?.height,
        length: this.package_details?.length,
        width: this.package_details?.width,
        delivery_city_id: this.package_details?.delivery_city_id,
        pickup_city_id: this.package_details?.pickup_city_id,
        cod: this.package_details?.cod,
        same_day:
          this.package_details?.same_day == null
            ? 0
            : this.package_details?.same_day,
        payment_type: this.package_details?.payment_type,
      };
      axios
        .post("/client/cal-total", packageData)
        .then((response) => {
          this.totals = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.calculating = false;
        });
    },
    getPricingSettings() {
      axios.get("/get-pricing-settings").then((response) => {
        const data = response.data.data;
        const sameDay = data.filter((obj) => {
          return obj.name == "same_day";
        })[0];
        const cod = data.filter((obj) => {
          return obj.name == "cod_commission";
        })[0];
        const card = data.filter((obj) => {
          return obj.name == "card_payment";
        })[0];
        this.pricing.card = card.current_value / 100;
        this.pricing.cod_commission = cod.current_value / 100;
        this.pricing.same_day = sameDay.current_value / 100;
      });
    },

    confirm() {
      this.confirmLoading = true;
      let obj = {
        package_id: this.package_details?.id,
        width: this.package_details?.width,
        height: this.package_details?.height,
        length: this.package_details?.length,
        weight: this.package_details?.weight,
        payment_type: this.package_details?.payment_type,
        pay_by: this.package_details?.pay_by,
      };
      axios
        .put("/package/courier-confirm", obj)
        .then(() => {
          this.getData();
          this.$toast.success("Details confirmed");
          this.confirmLoading = false;
        })
        .catch((e) => {
          this.confirmLoading = false;
          this.$toast.error(e.response.data.message);
        });
    },
    payByChanged(id) {
      console.log(id);
      this.package_details.payment_type = 1;
      this.calTotal();
    },
    getData() {
      this.refreshing = true;
      axios
        .get("/package/view-more/" + this.packageData.package.id)
        .then((response) => {
          console.log(response.data);
          this.is_corporate = response.data.data.package.is_corporate;
          this.package_details = response.data.data.package;
          this.pickup_city = response.data.data.package.pickup_city;
          this.delivery_city = response.data.data.package.delivery_city;
          this.package_details.paid =
            this.package_details.total_amount <=
            this.package_details.paid_amount;
          this.refreshing = false;
          this.calTotal();
        });
    },
    async submit() {
      this.$v.$touch();

      this.btnDisable = true;
      this.statusLoading = true;
      const barcode = this.barcode;
      const stage_id = this.stage_id;
      const package_id = this.package_details?.id;
      const description = this.description;
      const toSend = {
        package_id,
        description,
        barcode,
        stage_id,
      };
      await this.UpdatePickup(toSend)
        .then(() => {
          this.$toast.success("Record saved successfully!");
          this.statusLoading = false;
          this.$router.push({ name: "courier-screen" });
          this.clear();
        })
        .catch((e) => {
          this.statusLoading = false;
          this.$toast.error(e.response.data.message);
        });
    },
    clear() {
      this.$v.$reset();
    },
    cancelRegister() {
      this.$router.push({ name: "courier-screen" });
    },
  },
  watch: {
    packageData() {
      this.getData();
    },
  },
};
</script>
<style scoped>
.details-title {
  font-size: 1rem;
  display: inline;
}
.details-value {
  font-size: 1rem;
  display: inline;
}
.text-danger {
  color: red;
}
.text-red {
  color: red;
}
.text-green {
  color: green;
}
.refresh-button {
  margin-top: -10px;
}
</style>
