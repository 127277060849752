<template>
    <Dashboard selectedMainItem="Operation" selectedSubItem="Vehicle">
      <v-card style="margin: 30px; border: 0" :loading="isLoading">
        <v-card-title> Excel Upload </v-card-title>
        <v-card-subtitle>
          Excel
          <template>
          </template>
        </v-card-subtitle>
        <form style="margin-top: 40px; border: 0">
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                    :items="customerList"
                    v-model="customer"
                    item-text="name"
                    item-value="id"
                    label="Customers"
                    solo
                    required
                    @change="setCustomer"
                  ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-file-input 
                    label="Excel File Upload"
                    class="form-control ml-5"
                    type="file"
                    v-model="file"
                    :disabled="fileDisable"
                    @change="uploadFile"
                    required
                    id="file"
                >
          </v-file-input >
              </v-col>
              <v-col cols="12" md="4">
                Number Of Row Count : {{count}}
              </v-col>
            </v-row>
  
            <v-row>
              <v-btn
                :disabled="btnDisabled"
                color="success"
                class="ma-4"
                @click="submit"
              >
                <span> Submit </span>
              </v-btn>
  
              <v-btn class="ma-4" @click="clear" :color="'red'">
                <span class="white--text"> clear </span>
              </v-btn>
            </v-row>
          </v-container>
        </form>
      </v-card>
    </Dashboard>
  </template>
  
  <script>
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import Dashboard from "../../components/Layouts/Leftbar.vue";
  import axios from 'axios';
  
  export default {
    name: "route-assign-create",
    mixins: [validationMixin],
  
    validations: {
      routeId: { required },
    },
  
    data: () => ({
      name: "",
      regNumber: "",
      type: "",
      status: 1,
      isCompany: 0,
      isLoading: false,
      btnDisabled: true,
      fileDisable:true,
      checkbox: false,
      menu: false,
      customerList:[],
      customer:"",
      count:0,
      file:""
    }),
    components: {
      Dashboard,
    },
   
    computed: {
      routeAssignData: {
        get() {
         return this.$store.getters.AllRouteAssign;
        },
      },
      userData: {
        get() {
          return this.$store.getters.couriersAttended;
        },
    },

    },
    mounted() {
      this.getCustomers();   
    },
    methods: {
      async getCustomers() {
          axios
            .get("user/get-all-customers",)
            .then((response) => {
              this.customerList =response.data.data;
              console.log(this.customerList);
            })
            .catch((e) => {
              this.$toast.error(e.response?.data?.message);
            });
      },

    async uploadFile() {
     
      var formData = new FormData();
      var excelFile = document.querySelector('#file');
      formData.append("file", excelFile.files[0]);
      this.file=excelFile.files[0]
      try {
         await axios.post('package/excel-upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((response)=>{
          this.count=response.data.row_count
           this.btnDisabled =false
        }).catch((e)=>{
          this.$toast.error(e.response?.data?.message);
        })
        // Handle the response from the backend
       
      } catch (e) {
        this.$toast.error(e.response?.data?.message);

      }
    },
     setCustomer() {
       this.fileDisable=false
    },
      
      clear() {
        
        this.customer = "";
        this.btnDisabled = true;
        this.fileDisable=true;
        this.count=0;
        this.file="";
      },

      async submit() {
        
        
        var formData = new FormData();
       // var excelFile = document.querySelector('#file');
        formData.append("file", this.file);
        formData.append("client", this.customer);

        try {
          const response = await axios.post('package/excel-upload-save', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          this.$toast.success("Excel Upload successfully!");
            this.clear()
          console.log(response.data); 
        } catch (error) {
          console.error(error);
        }
    },
    },
  };
  </script>
  