<template>
  <Dashboard selectedMainItem="Human Resource" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-select
            v-model="user"
            :items="allUsers"
            class="ml-5"
            item-text="name"
            item-value="id"
            label="Select user"
            placeholder="Select user"
            text
            required
            @change="reload()"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="2"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                class="ml-5"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                label="Start Date"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="2"
          ><v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                class="ml-5"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(date_end);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col align="left" cols="12" sm="6" md="4">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
          <v-btn   :disabled="exporting" depressed @click="exportExcel"  :color="exporting ? '#C9C9C9':'success'"
            ><v-icon class="mr-2"> mdi-file-excel </v-icon> Export</v-btn
          >
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="respData"
        item-key="id"
        class="elevation-1"
        hide-default-footer
      >
      <template v-slot:[`item.name`]="{ item }">
          {{item.name}} ( {{item.role}} )
        </template>
      </v-data-table>
      <paginate :paginate="pagination" @paginated="handlePagination" />
    </v-card>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/PaginateAxios";
import axios from "axios";

export default {
  name: "reportStaffAttendance",
  data() {
    return {
      filter: {
        start: this.date,
        end: this.date_end,
        reg_number: this.vehicleId,
        code: "",
        page: 1,
      },
      pagination: {
        meta: {
          current_page: 1,
        },
      },
      isLoading: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date_end: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      user: "",
      item: {},
      courier_id: "",
      pay_by: "",
      payment_type: "",
      respData: [],
      allUsers: [],
      pay_by_types: [
        { name: "all", id: null },
        { name: "sender", id: 1 },
        { name: "receiver", id: 2 },
      ],
      package_type: [
        { name: "Documents", id: 1 },
        { name: "Parcel", id: 2 },
      ],
      exporting: false,
      package_id: "",
      client_id: "",
    };
  },
  components: {
    Dashboard,
    paginate,
  },
  computed: {
    fuelData: {
      get() {
        return this.$store.getters.PickUpAssigned;
      },
    },
    curriersData: {
      get() {
        return this.$store.getters.curriers;
      },
    },
    headers() {
      return [
        {
          text: "Employee Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        
        {
          text: "In Time",
          align: "start",
          sortable: false,
          value: "in_time",
        },

        {
          text: "Out Time",
          value: "out_time",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchCouriersUser();
  },
  mounted() {
    this.loadUsers();
    this.fetchData();
  },

  methods: {
    ...mapActions(["fetchCouriersUser"]),

    loadUsers() {
      axios.get("user/get-staff-all").then((response) => {
        this.allUsers = response.data.data;
        const newFirstElement = {
          name: "All",
          id: null,
        };
        const newArray = [newFirstElement].concat(this.allUsers);
        this.allUsers = newArray;
      });
    },
    handlePagination(page) {
      this.filter.page = page;
      this.fetchData();
    },
    fetchData() {
      axios
        .get("/reports/staff-attendance", {
          params: {
            report_start: this.date,
            report_end: this.date_end,
            page: this.filter.page,
            user_id: this.user,
          },
        })
        .then((response) => {
          this.respData = response?.data?.data;
          this.pagination = response?.data?.pagination;
        });
    },
    reload() {
      this.fetchData();
    },
    clear() {
      this.filter = {
        code: "",
      };
      this.date = "";
      this.user = "";
      this.date_end = "";
      this.reload();
    },
    exportExcel() {
      let url = "/reports/staff-attendance/export?";
      if(this.date != null && this.date != ""){
        url += 'report_start='+this.date+'&';
      }
      if(this.date_end != null && this.date_end != ""){
        url += 'report_end='+this.date_end+'&';
      }
      if(this.user != null && this.user != ""){
        url += 'user_id='+this.user+'&';
      }
      
      this.exporting = true;
      this.exportExcelCommon(url,"staff-attendance-report.xlsx",()=>{
        this.exporting = false;
      });
    },
  },
};
</script>