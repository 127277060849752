<template>
  <Dashboard selectedMainItem="Finance" selectedSubItem="Pay COD">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-card-title> COD Packages </v-card-title>
      <v-row>
        <v-col align="right" class="ml-5" cols="12" sm="6" md="3">
          <v-select
            @change="customerChanged()"
            v-model="filter.client_id"
            :items="allCustomers"
            item-text="name"
            item-value="id"
            :label="'Select Customer'"
          >
          </v-select>
        </v-col>

        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                class="ml-5"
                label="Start Date (Pickup)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  getAll();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu2"
            :items-per-page="15"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                class="ml-5"
                label="End Date (Pickup)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(date_end);
                  getAll();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col align="right" cols="12" sm="6" md="2">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="codHeaders"
        :items="cod_packages"
        :items-per-page="pagination.meta.per_page"
        item-key="id"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.total_amount`]="{ item }">
          <span>{{ item.total_amount.toFixed(2) }}</span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span>{{ item.payment_type_text }}</span>
        </template>
        <template v-slot:[`item.cod`]="{ item }">
          <span>{{ item.cod.toFixed(2) }}</span>
        </template>
        <template v-slot:[`item.active_stage`]="{ item }">
          <span>{{ item.active_stage.stage_name }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-checkbox
            class="text-right"
            :true-value="1"
            :false-value="false"
            color="blue"
            hide-details
            @change="addCodTotal(item)"
          ></v-checkbox>
        </template>
      </v-data-table>
    </v-card>
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-card-title> Credit Packages </v-card-title>
      <v-data-table
        :headers="creditHeaders"
        :items="credit_packages"
        :items-per-page="pagination.meta.per_page"
        item-key="id"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.total_amount`]="{ item }">
          <span>{{ item.total_amount.toFixed(2) }}</span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span>{{ item.payment_type_text }}</span>
        </template>
        <template v-slot:[`item.cod`]="{ item }">
          <span>{{ item.cod.toFixed(2) }}</span>
        </template>
        <template v-slot:[`item.credit_courier_charge`]="{ item }">
          <span>{{ formatNumbers(item.credit_courier_charge) }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-checkbox
            :true-value="1"
            :false-value="false"
            color="blue"
            hide-details
            @change="addCreditTotal(item)"
            :disabled="
              totalToPay - item.credit_courier_charge < 0 &&
              indexInArray(item.id, selected_credits) == null
            "
          ></v-checkbox>
        </template>
      </v-data-table>
    </v-card>
    <!-- <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row class="mx-2">
        <v-col align="left" cols="12" sm="6" md="4">
          <b class="green--text">
            COD Amount : {{ formatNumbers(cod) }}
          </b>
        </v-col>
        <v-col align="left" cols="12" sm="6" md="4">
          <b class="red--text">
            Credit Amount : {{ formatNumbers(credit) }}
          </b>
        </v-col>
        <v-col align="left" cols="12" sm="6" md="4">
          <b class="primary--text">
            Balance Amount : {{ formatNumbers(balance) }}
          </b>
        </v-col>
      </v-row>
    </v-card> -->
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row class="mx-2">
        <v-col align="left" cols="12" sm="6" md="4" class="green--text">
          <p>Packages Total: {{ formatNumbers(codSelectedTotal) }}</p>
        </v-col>
        <v-col align="left" cols="12" sm="6" md="4" class="red--text">
          <p>Courier Charge Total : {{ formatNumbers(creditSelectedTotal) }}</p>
        </v-col>
        <v-col align="left" cols="12" sm="6" md="4">
          <b> Total to pay : {{ formatNumbers(totalToPay) }} </b>
        </v-col>
      </v-row>
      <v-row class="mx-2">
        <v-col align="left" cols="12" sm="6" md="4">
          <v-text-field
            placeholder="Transaction number"
            v-model="transaction_number"
            type="text"
            required
          ></v-text-field>
        </v-col>
        <v-col align="left" cols="12" sm="6" md="2">
          <v-btn
            color="primary"
            :disabled="
              totalToPay > 0 && transaction_number != null ? false : true
            "
            @click="getPay"
          >
            <span>Pay</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </Dashboard>
</template>

<script>
import Dashboard from "../../components/Layouts/Leftbar";
import axios from "axios";
export default {
  name: "Cod-value",
  data() {
    return {
      pagination: {
        meta: {
          current_page: 1,
          per_page: 10000,
        },
      },
      name: "",
      cod: 0.0,
      credit: 0.0,
      balance: 0.0,
      check: false,
      filter: {
        page: 1,
        client_id: "",
        code: "",
        start: this.date,
        end: this.date_end,
      },
      date: null,
      date_end: null,
      isLoading: false,
      menu: false,
      menu2: false,
      dialog: false,
      item: {},
      selected_cods: [],
      selected_credits: [],
      allCustomers: [],
      cod_packages: [],
      credit_packages: [],
      transaction_number: null,
    };
  },
  components: {
    Dashboard,
  },

  computed: {
    selectedTotal() {
      return this.selected_cods.reduce((total, obj) => obj.total_amount + total, 0);
    },
    codSelectedTotal() {
      return this.selected_cods.reduce((total, obj) => obj.cod + total, 0);
    },
    creditSelectedTotal() {
      return this.selected_credits.reduce(
        (total, obj) => obj.credit_courier_charge + total,
        0
      );
    },
    totalToPay() {
      return this.codSelectedTotal - this.creditSelectedTotal;
    },
    codHeaders() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Payment Type",
          align: "start",
          sortable: false,
          value: "type",
        },
        {
          text: "Total Amount",
          value: "total_amount",
          align: "right",
          sortable: false,
        },
        {
          text: "COD Amount",
          value: "cod",
          align: "right",
          sortable: false,
        },
        {
          text: "Actions",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
    creditHeaders() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Payment Type",
          align: "start",
          sortable: false,
          value: "type",
        },
        {
          text: "Total Amount",
          value: "total_amount",
          align: "right",
          sortable: false,
        },
        {
          text: "Credit Amount",
          value: "credit_courier_charge",
          align: "right",
          sortable: false,
        },
        {
          text: "Actions",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.getCustomer();
    // this.reload();
  },
  mounted() {
    // this.getAll();
  },
  methods: {
    indexInArray(value, array) {
      let index = null;
      array.forEach((item, itemIndex) => {
        if (item.id == value) {
          index = itemIndex;
        }
      });
      return index;
    },
    getCustomer() {
      axios
        .get("user/get-cod-customers")
        .then((response) => {
          this.allCustomers = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.isLoading = false;
          this.btnDisabled = false;
        });
    },
    async addCodTotal(record) {
      let index = this.indexInArray(record.id, this.selected_cods);
      if (index == null) {
        this.selected_cods.push(record);
      } else {
        this.selected_cods.splice(index, 1);
      }
    },
    async addCreditTotal(record) {
      let index = this.indexInArray(record.id, this.selected_credits);
      if (index == null) {
        this.selected_credits.push(record);
      } else {
        this.selected_credits.splice(index, 1);
      }
    },
    reload() {
      this.getAll();
    },
    clear() {
      this.filter = {
        code: "",
        client_id: "",
      };
      this.date = "";
      this.date_end = "";
      this.clearOnCustomerChange();
    },
    async getAll() {
      axios
        .get("/cod/get-all-cod", {
          params: {
            start: this.date,
            end: this.date_end,
            client_id: this.filter.client_id,
          },
        })
        .then((response) => {
          this.cod_packages = response.data.cod_packages;
          this.credit_packages = response.data.credit_packages;
          this.cod = response.data.summary.cod;
          this.credit = response.data.summary.credit;
          this.balance = response.data.summary.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearOnCustomerChange() {
      this.cod = 0;
      this.credit = 0;
      this.balance = 0;
      this.selected_cods = [];
      this.selected_credits = [];
      this.cod_packages = [];
      this.credit_packages = [];
      this.transaction_number = null;
    },
    customerChanged() {
      this.clearOnCustomerChange();
      this.getAll();
    },
    async getPay() {
      const data = {
        selected_cods: this.selected_cods,
        selected_credits: this.selected_credits,
        transaction_number: this.transaction_number,
        paid_amount: this.totalToPay,
      };
      axios
        .post("/cod/cod-payment", data)
        .then((response) => {
          if (response) {
            this.clear();
            this.$toast.success("Payment successfully!");
          }
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.isLoading = false;
          this.btnDisabled = false;
        });
    },
  },
};
</script>
