import axios from 'axios';


const state = {
    branchAreas: [],
    allocatedCites: [],
    unAllocatedCites: [],
    paginate: {
        meta:
        {
            current_page : 1,
            last_page : 1,
        }
    },
    branchAreaTypes: [
        {
            id: 1,
            name: "Withing Branch Area",
        },
        {
            id: 2,
            name: "Suburb Area",
        },
        {
            id: 3,
            name: "Out of Branch Area",
        },
    ],

};

const getters = {
    
    getAllAllocatedCites: state => state.allocatedCites,
    getAllUnAllocatedCites: state => state.unAllocatedCites,
    getAllBranchAreas: state => state.branchAreas,
    setBranchArea: state => state.branchArea,
    getBranchAreaTypes: state => state.branchAreaTypes,
    branchAreaPaginate: state => state.paginate,

};

const actions = {

    async fetchBranchArea({ commit }, filter) {
        console.log(filter);
            const allCityRoutes = await axios.get('branch-area/get-allocated-city',{params: {
                branch_id : filter.branch_id,
                name : filter.search,
                type : filter.type,
                page : filter.page,
            }});
            commit("setBranchAreas",  allCityRoutes.data.data);
            commit("setPagination",  allCityRoutes.data.pagination);
            return allCityRoutes;
    },
    
    async getAllCities({ commit }){
        
        const cities = await axios.get('location-hierarchy/get-unallocated-city');
        commit("setUnAllocatedCites",  cities.data.data);
        return cities.data.data;
    },

    async branchAreaCreate(_, { branch_id, location_hierarchy_id, type }) {
        
        const city = await axios.post('branch-area', {
            type,
            branch_id,
            location_hierarchy_id,
        });
        console.log('xity',city.data.data);
        return city;
    },

    async deleteBranchAreaById({ commit }, id) {
        await axios.delete(`branch-area/${id}`);
    
        commit('removeCity', id);
    }

};

const mutations = {
    setUnAllocatedCites: (state, cities) => (state.unAllocatedCites = cities),
    setBranchAreas: (state, cities) => (state.branchAreas = cities),
    setAllCities: (state, cities) => (state.allCities = cities),
    setPagination: (state, paginate) => (state.paginate = paginate),
    setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
    newBranchArea: (state, city) =>{
        state.branchAreas.unshift(city);
    },   
    removeCity: (state, id) =>{
        state.branchAreas = state.branchAreas.filter(city => city.id !== id);
    },
};

export default {
state,
getters,
actions,
mutations
};