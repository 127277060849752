<template>
    <Dashboard selectedMainItem="Reports" selectedSubItem="User">
      <v-card style="margin: 30px; border: 0" :loading="isLoading">
        <v-row>
         
          
  
          <v-col align="right" cols="12" sm="6" md="2">
            <v-select
              v-model="pay_by"
              :items="pay_by_types"
              item-text="name"
              item-value="id"
              label="Pay By"
              placeholder="Pay By"
              text
              required
              @change="reload()"
            ></v-select>
          </v-col>
          <v-col align="right" cols="12" sm="6" md="2">
            <v-select
              v-model="payment_type"
              :items="payment_types"
              item-text="name"
              item-value="id"
              label="Payment Type"
              placeholder="Payment Type"
              text
              required
              @change="reload()"
            ></v-select>
          </v-col>
          <v-col align="right" cols="12" sm="6" md="4">
            <v-select
              v-model="currier"
              :items="curriersDataNew"
              item-text="name"
              item-value="id"
              class="ml-3"
              :label="'Currier '"
              placeholder="currier"
              @change="reload()"
            ></v-select>
          </v-col>
          </v-row>
        <v-row>
  
          <v-col cols="12" sm="6" md="2"
            ><v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  class="ml-5"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  label="Start Date"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.menu.save(date);
                    reload();
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="2"
            ><v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="date_end"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_end"
                  class="ml-5"
                  label="End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date_end" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu2 = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.menu2.save(date_end);
                    reload();
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
  
          <v-col align="right" cols="12" sm="6" md="2">
            <v-btn color="error" class="ma-4" @click="clear">
              <span>Clear</span>
            </v-btn>
            <v-btn   :disabled="exporting" depressed @click="exportExcel"  :color="exporting ? '#C9C9C9':'success'"
              ><v-icon class="mr-2"> mdi-file-excel </v-icon> Export</v-btn
            >
          </v-col>
        </v-row>
  
        <v-data-table
          :headers="headers"
          :items="respData"
          item-key="name"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:[`item.pay`]="{ item }">
            <p>{{ item.pay_by_text }} , {{ item.payment_type_text }}</p>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="success" @click="viewPackage(item)"> View More </v-btn>
          </template>
        </v-data-table>
        <paginate :paginate="pagination" @paginated="handlePagination" />
      </v-card>
    </Dashboard>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import Dashboard from "../../components/Layouts/Leftbar";
  import paginate from "../../components/Common/PaginateAxios";
  import axios from "axios";
  
  export default {
    name: "reportIndividual",
    data() {
      return {
        pagination: {
          meta: {
            current_page: 1,
          },
        },
        filter: {
          start: this.date,
          end: this.date_end,
          reg_number: this.vehicleId,
          code: "",
          page: 1,
        },
        isLoading: false,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        date_end: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        menu: false,
        menu2: false,
        currier: "",
        item: {},
        courier_id: "",
        pay_by: "",
        payment_type: "",
        respData: [],
        pay_by_types: [
          { name: "All", id: null },
          { name: "Sender", id: 1 },
          { name: "Receiver", id: 2 },
        ],
        payment_types: [
          { name: "All", id: null },
          { name: "Cash", id: 1 },
          { name: "Bank Transfer", id: 3 },
        ],
        package_type: [
          { name: "All", id: null },
          { name: "Documents", id: 1 },
          { name: "Parcel", id: 2 },
        ],
        exporting: false,
        package_id: "",
        client_id: "",
        curriersDataNew: [],
      };
    },
    
    components: {
      Dashboard,
      paginate,
    },
    computed: {
      curriersData: {
        get() {
          return this.$store.getters.curriers;
        },
      },
  
      headers() {
        return [
          
          {
            text: "Code",
            align: "start",
            sortable: false,
            value: "code",
          },
          {
            text: "Assigned Type",
            align: "start",
            sortable: false,
            value: "assigned_type",
          },
          {
            text: "Address",
            value: "address",
            sortable: false,
          },
          {
            text: "Pay By / Payment Type ",
            value: "pay",
            sortable: false,
          },
          {
            text: "Total Amount",
            value: "formatted_total",
            sortable: false,
            align: "end",
          },
          {
            text: "Operation",
            align: "end",
            value: "actions",
            sortable: false,
          },
        ];
      },
    },
    created() {
      this.fetchCouriersUser();
    },
    mounted() {
      const newFirstElement = {
        name: "All",
        id: null,
      };
      const newArray = [newFirstElement].concat(this.curriersData);
      this.curriersDataNew = newArray;
      this.fetchReportCourierIndividual();
    },
  
    methods: {
      ...mapActions(["fetchCouriersUser"]),
  
      async viewPackage(item) {
        
        await this.$store.dispatch("getPackageById", item.package_id);
        this.$router.push({
          name: "package-pending-view",
        });
      },
  
      handlePagination(page) {
        this.filter.page = page;
        this.fetchReportCourierIndividual();
      },
      fetchReportCourierIndividual() {
        axios
          .get("/reports/courier", {
            params: {
              courier_id: this.currier,
              report_start: this.date,
              report_end: this.date_end,
              code: this.filter.code,
              payment_type: this.payment_type,
              pay_by: this.pay_by,
              page: this.filter.page,
            },
          })
          .then((response) => {
            console.log(7544)
            this.respData = response?.data?.data;
            this.pagination = response?.data?.pagination;
          });
      },
      reload() {
        this.fetchReportCourierIndividual();
      },
      clear() {
        this.filter = {
          code: "",
        };
        this.date = "";
        this.branch = "";
        this.currier = "";
        this.payment_type = "";
        this.pay_by = "";
        this.date_end = "";
        this.reload();
      },
      exportExcel() {
        let url = "/reports/courier-individual/export?";
        if(this.currier != null && this.currier != ""){
          url += 'courier_id='+this.currier+'&';
        }
        if(this.filter.code != null && this.filter.code != ""){
          url += 'code='+this.filter.code+'&';
        }
        if(this.payment_type != null && this.payment_type != ""){
          url += 'payment_type='+this.payment_type+'&';
        }
        if(this.pay_by != null && this.pay_by != ""){
          url += 'pay_by='+this.pay_by+'&';
        }
        if(this.date != null && this.date != ""){
          url += 'report_start='+this.date+'&';
        }
        if(this.date_end != null && this.date_end != ""){
          url += 'report_end='+this.date_end;
        }
        this.exporting = true;
        this.exportExcelCommon(url,"courier-individual-report.xlsx",()=>{
          this.exporting = false;
        });
      },
    },
  };
  </script>