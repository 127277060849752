<template>
  <Dashboard selectedMainItem="Transport" selectedSubItem="Vehicle">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-card-title> Add Fuel </v-card-title>
      <v-card-subtitle>
        Add Fuel
        <template>
          <div>
            <v-breadcrumbs
              class="pl-0 pt-0"
              :items="pageNavItems"
            ></v-breadcrumbs>
          </div>
        </template>
      </v-card-subtitle>
      <form style="margin-top: 40px; border: 0">
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="to_day"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="to_day"
                    class="ml-5"
                    label="Select Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="to_day" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(to_day)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                :items="vehicleData"
                v-model="vehicleId"
                item-text="reg_number"
                item-value="id"
                label="Select Vehicle"
                :error-messages="typeErrors"
                solo
                required
                @input="$v.vehicleId.$touch()"
                @blur="$v.vehicleId.$touch()"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="fuel_qty"
                :counter="10"
                :error-messages="fuelErrors"
                label="Number of liters"
                solo
                @input="$v.fuel_qty.$touch()"
                @blur="$v.fuel_qty.$touch()"
                type="number"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="token_number"
                :error-messages="tokenErrors"
                label="Token Number"
                solo
                @input="$v.token_number.$touch()"
                @blur="$v.token_number.$touch()"
                required
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-btn
              :disabled="btnDisabled"
              color="success"
              class="ma-4"
              @click="submit"
            >
              <span> Add </span>
            </v-btn>

            <v-btn class="ma-4" @click="cancelRegister" :color="'red'">
              <span class="white--text"> Cancel </span>
            </v-btn>
          </v-row>
        </v-container>
      </form>
      <v-data-table
        :headers="headers"
        :items="fuelData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click.stop="
              dialog = true;
              toDelete(item);
            "
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar.vue";

export default {
  name: "FuelCreate",
  mixins: [validationMixin],

  validations: {
    vehicleId: { required },
    fuel_qty: { required },
    token_number: { required },
  },

  data: () => ({
    name: "",
    regNumber: "",
    type: "",
    status: 1,
    isCompany: 0,
    token_number: null,
    isLoading: false,
    btnDisable: false,
    checkbox: false,
    to_day: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    vehicleData: [],
    fuel_qty: "",
    vehicleId: "",
    pageNavItems: [
      {
        text: "Dashboard",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "dashboard" },
      },
      {
        text: "Fuel",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "fuel-issuing" },
      },
      {
        text: "Add",
        disabled: true,
        link: true,
        exact: true,
        to: { name: "fuel-issuing-create" },
      },
    ],
  }),
  components: {
    Dashboard,
  },
  created() {
    this.getAllFuel();
  },
  computed: {
    vehicleTypes: {
      get() {
        return this.$store.getters.allVehicles;
      },
    },
    vehicleStatus: {
      get() {
        return this.$store.getters.vehicleStatus;
      },
    },
    fuelData: {
      get() {
        return this.$store.getters.allFuel;
      },
    },
    btnDisabled() {
      const visible =
        this.$v.$anyError ||
        !(this.vehicleId && this.fuel_qty && this.token_number);
      return visible;
    },
    headers() {
      return [
        {
          text: "Reg number",
          align: "start",
          sortable: false,
          value: "vehicle.reg_number",
        },
        {
          text: "Type",
          value: "vehicle.type_text",
          sortable: false,
        },
        {
          text: "Liters",
          value: "qty",
          sortable: false,
        },
        {
          text: "Date",
          value: "date",
          sortable: false,
        },
      ];
    },
    fuelErrors() {
      const errors = [];
      if (!this.$v.fuel_qty.$dirty) return errors;
      !this.$v.fuel_qty.required &&
        errors.push("number of liters is required.");
      return errors;
    },
    tokenErrors() {
      const errors = [];
      if (!this.$v.token_number.$dirty) return errors;
      !this.$v.token_number.required &&
        errors.push("Token number is required.");
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.vehicleId.$dirty) return errors;
      !this.$v.vehicleId.required && errors.push("VehicleId is required.");
      return errors;
    },
  },
  mounted() {
    this.getAllVehicles()
      .then((response) => {
        const arr = response.data;
        let result = arr.map((a) => a.id);
        this.vehicleData = arr;
        console.log(result);
      })
      .catch((e) => {
        this.$toast.error(e.response.data.message);
      });
  },
  methods: {
    ...mapActions(["getAllVehicles", "markFuel", "getAllFuel"]),

    async submit() {
      this.$v.$touch();

      this.btnDisable = true;
      this.isLoading = true;
      const date = this.to_day;
      const qty = this.fuel_qty;
      const token_number = this.token_number;
      const vehicle_id = this.vehicleId;
      const toSend = {
        date,
        token_number,
        qty,
        vehicle_id,
      };

      await this.markFuel(toSend)
        .then((response) => {
          console.log(response);
          this.$toast.success("Record added successfully!");
          this.isLoading = false;
          this.getAllFuel();
          this.clear();
        })
        .catch((e) => {
          this.isLoading = false;
          this.$toast.error(e.response.data.message);
        });
    },
    clear() {
      this.$v.$reset();
      this.fuel_qty = "";
      this.vehicleId = "";
    },
    uppercase() {
      this.regNumber = this.regNumber.toUpperCase();
    },
    cancelRegister() {
      this.$router.push({ name: "fuel-issuing" });
    },
  },
};
</script>
