<template>
  <Dashboard selectedMainItem="Transport" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-text-field
            v-model="filter.code"
            label="Reg Number"
            class="ml-5"
            @input="reload()"
            type="text"
            required
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="2"
          ><v-select
            v-model="owned"
            :items="owned_types"
            item-text="name"
            item-value="id"
            label="Owned"
            placeholder="Owned"
            text
            required
            @change="reload()"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="2"
          ><v-select
            v-model="shuttle"
            :items="shuttle_type"
            item-text="name"
            item-value="id"
            label="Shuttle"
            placeholder="Shuttle"
            text
            required
            @change="reload()"
          ></v-select>
        </v-col>

        <v-col align="left" cols="12" sm="6" md="4">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
          <v-btn   :disabled="exporting" depressed @click="exportExcel"  :color="exporting ? '#C9C9C9':'success'"
            ><v-icon class="mr-2"> mdi-file-excel </v-icon> Export</v-btn
          >
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="respData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.is_company`]="{ item }">
          <span>{{ formatOwnCompany(item) }}</span>
        </template>
        <template v-slot:[`item.reg_number`]="{ item }">
          {{item.reg_number}} ( {{item.type_text}} )
        </template>
      </v-data-table>
      <paginate :paginate="pagination" @paginated="handlePagination" />
    </v-card>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/PaginateAxios";
import axios from "axios";

export default {
  name: "PickupAssigned",
  data() {
    return {
      filter: {
        start: this.date,
        end: this.date_end,
        reg_number: this.vehicleId,
        code: "",
        page: 1,
      },
      pagination: {
        meta: {
          current_page: 1,
        },
      },
      isLoading: false,
      date: "",
      date_end: "",
      menu: false,
      menu2: false,
      currier: "",
      item: {},
      shuttle: "",
      owned: "",
      payment_type: "",
      respData: [],
      owned_types: [
        { name: "All", id: null },
        { name: "Owned", id: 1 },
        { name: "Out side", id: 2 },
      ],
      shuttle_type: [
        { name: "All", id: null },
        { name: "Shuttle", id: 1 },
        { name: "Regular", id: 0 },
      ],
      exporting: false,
      package_id: "",
      client_id: "",
    };
  },
  components: {
    Dashboard,
    paginate,
  },
  computed: {
    headers() {
      return [
        {
          text: "Reg Number",
          align: "start",
          sortable: false,
          value: "reg_number",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },

        {
          text: "Fuel Type",
          value: "fuel_type_text",
          sortable: false,
        },
        {
          text: "Vehicle Type",
          value: "type_text",
          sortable: false,
        },
        {
          text: "Company Owned",
          value: "is_company",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchCouriersUser();
  },
  mounted() {
    this.fetchData();
  },

  methods: {
    ...mapActions(["fetchCouriersUser"]),

    handlePagination(page) {
      this.filter.page = page;
      this.fetchData();
    },
    fetchData() {
      axios
        .get("/reports/vehicle", {
          params: {
            reg_number: this.filter.code,
            owned: this.owned,
            shuttle: this.shuttle,
            page: this.filter.page,
          },
        })
        .then((response) => {
          this.respData = response?.data?.data;
          this.pagination = response?.data?.pagination;
        });
    },
    reload() {
      this.fetchData();
    },
    clear() {
      this.filter = {
        code: "",
      };

      this.shuttle = "";
      this.owned = "";
      this.reload();
    },
    formatOwnCompany(company) {
      if (company.is_company) return "Inside Company";
      return "Out Side Company";
    },
    exportExcel() {
      let url = "/reports/vehicle/export?";
      if(this.filter.code != null && this.filter.code != ""){
        url += 'reg_number='+this.filter.code+'&';
      }
      if(this.owned != null && this.owned != ""){
        url += 'owned='+this.owned+'&';
      }
      if(this.shuttle != null && this.shuttle != ""){
        url += 'shuttle='+this.shuttle+'&';
      }
      
      this.exporting = true;
      this.exportExcelCommon(url,"vehicle-report.xlsx",()=>{
        this.exporting = false;
      });
    },
  },
};
</script>