<template>
  <Dashboard selectedMainItem="Pickup Operation" selectedSubItem="Pickup Pending">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-card-title>Assign Courier </v-card-title>

      <v-card-subtitle>
        Assign Courier
        <template>
          <div>
            <v-breadcrumbs
              class="pl-0 pt-0"
              :items="pageNavItems"
            ></v-breadcrumbs>
          </div>
        </template>
      </v-card-subtitle>

      <v-data-table
        :headers="headers"
        :items="couriers"
        item-key="id"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.package_type`]="{ item }">
          <span>{{ setPageTypeName(item) }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="green" dark @click="submit(item)"> Assign </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </Dashboard>
</template>
  
  <script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar.vue";

export default {
  name: "AssignCourier",
  mixins: [validationMixin],

  validations: {
    vehicleId: { required },
    fuel_qty: { required },
  },

  data: () => ({
    type: "",
    status: 1,
    package: "",
    selectedItem: {},
    courierId: "",
    isLoading: false,
    btnDisable: false,
    to_day: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    item: [],
    pageNavItems: [
      {
        text: "Dashboard",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "dashboard" },
      },
      {
        text: "Add",
        disabled: true,
        link: true,
        exact: true,
        to: { name: "package-pending-assign" },
      },
    ],
  }),
  components: {
    Dashboard,
  },
  created() {
    this.getForOperation(this.to_day);
    const filter = {
      date: this.to_day,
      city_id: this.$route.params.item.delivery_city_id,
    };
    this.fetchCouriers(filter);
  },
  computed: {
    couriers: {
      get() {
        return this.$store.getters.vehicleForOperation;
      },
    },
    packageAll: {
      get() {
        return this.$store.getters.couriersPackageAll;
      },
    },

    headers() {
      return [
        {
          text: "Courier Name",
          align: "start",
          sortable: false,
          value: "courier_name",
        },
        {
          text: "Vehicle Number",
          value: "reg_number",
          sortable: false,
        },
        {
          text: "Vehicle Type",
          value: "vehicle_type",
          sortable: false,
        },
        {
          text: "Route Name",
          value: "route_name",
          sortable: false,
        },
        {
          text: "Driver Name",
          value: "driver_name",
          sortable: false,
        },
        {
          text: "Date",
          value: "date",
          sortable: false,
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  mounted() {
    this.package = this.$route.params.item;
  },
  methods: {
    ...mapActions(["fetchCouriers", "markCourierAssign", "getForOperation"]),

    async submit(item) {
      this.$v.$touch();
      this.btnDisable = true;
      this.isLoading = true;
      const courier_id = item.courier_id;
      const vehicle_id = item.vehicle_id;
      const package_id = this.package.id;
      const type = this.$route.params.type;
      const toSend = {
        package_id,
        vehicle_id,
        type,
        courier_id,
      };

      await this.markCourierAssign(toSend)
        .then(() => {
          this.$toast.success("Record added successfully!");
          this.isLoading = false;
          this.clear();
          this.$router.go(-1);
        })
        .catch((e) => {
          this.isLoading = false;
          this.$toast.error(e.response.data.message);
        });
    },
    clear() {
      this.$v.$reset();
      this.type = "";
      this.vehicleId = "";
    },
  },
};
</script>
  