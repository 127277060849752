<template>
  <Dashboard selectedMainItem="Transfer Operation" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-text-field
            v-model="filter.code"
            label="Code"
            class="ml-5"
            @input="reload()"
            type="text"
            required
          >
          </v-text-field>
        </v-col>
        <v-col align="right" cols="12" sm="6" md="3">
          <v-select
            v-model="from_branch_id"
            :items="allBranches"
            item-text="name"
            item-value="id"
            label="From branch"
            placeholder="Select branch"
            text
            required
            @change="reload()"
          ></v-select>
        </v-col>
        <v-col align="right" cols="12" sm="6" md="3">
          <v-select
            v-model="to_branch_id"
            :items="allBranches"
            item-text="name"
            item-value="id"
            label="To branch"
            placeholder="Select branch"
            text
            required
            @change="reload()"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>

        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu"
            v-model="menu"
            class="ml-5"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                label="Start Date"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu2"
            v-model="menu2"
            class="ml-5"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(date_end);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col align="left" cols="12" sm="6" md="4">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
           <v-btn   :disabled="exporting" depressed @click="exportExcel"  :color="exporting ? '#C9C9C9':'success'"
            ><v-icon class="mr-2"> mdi-file-excel </v-icon> Export</v-btn
          >
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="respData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.vehicle`]="{ item }">
          {{item.vehicle_reg}} ( {{item.vehicle_type}} )
        </template>
        <template v-slot:[`item.courier`]="{ item }">
          {{item.courier}}
        </template>
      </v-data-table>
      <paginate :paginate="pagination" @paginated="handlePagination" />
    </v-card>
  </Dashboard>
</template>

<script>
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/PaginateAxios";
import axios from "axios";

export default {
  name: "ReportPickupAssigned",
  data() {
    return {
      pagination: {
        meta: {
          current_page: 1,
        },
      },
      filter: {
        start: this.date,
        end: this.date_end,
        reg_number: this.vehicleId,
        code: "",
        page: 1,
      },
      menu: false,
      menu2: false,
      isLoading: false,
      exporting: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date_end: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      item: {},
      branch: "",
      respData: [],
      allBranches: [],
      clientType: [
        { name: "corporate", id: 1 },
        { name: "regular", id: 2 },
      ],
      package_type: [
        { name: "Documents", id: 1 },
        { name: "Parcel", id: 2 },
      ],
      from_branch_id: "",
      to_branch_id: ""
    };
  },
  components: {
    Dashboard,
    paginate,
  },
  computed: {
    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Transfer Date",
          align: "start",
          sortable: false,
          value: "date",
        },
        {
          text: "Vehicle",
          align: "start",
          sortable: false,
          value: "vehicle",
        },
        {
          text: "Courier",
          align: "start",
          sortable: false,
          value: "courier_name",
        },
        {
          text: "Driver",
          align: "start",
          sortable: false,
          value: "driver_name",
        },
        {
          text: "From Branch",
          align: "start",
          sortable: false,
          value: "from_branch_name",
        },
        {
          text: "To Branch",
          align: "start",
          sortable: false,
          value: "to_branch_name",
        }
      ];
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    this.loadBranches();
  },
  methods: {
    handlePagination(page) {
      this.filter.page = page;
      this.getData();
    },
    loadBranches() {
      axios.get("branch").then((response) => {
        this.allBranches = response.data.data;
        const newFirstElement = {
          name: "All",
          id: null,
        };
        const newArray = [newFirstElement].concat(this.allBranches);
        this.allBranches = newArray;
      });
    },
    getData() {
      axios
        .get("/reports/transfer", {
          params: {
            from_branch_id: this.from_branch_id,
            to_branch_id: this.to_branch_id,
            report_start: this.date,
            report_end: this.date_end,
            code: this.filter.code,
            page: this.filter.page,
          },
        })
        .then((response) => {
          this.respData = response?.data?.data;
          this.pagination = response?.data?.pagination;
        });
    },
    reload() {
      this.getData();
    },
    clear() {
      this.filter = {
        code: "",
      };
      this.from_branch_id = null;
      this.to_branch_id = null;
      this.date = "";
      this.branch = "";
      this.date_end = "";
      this.reload();
    },
    exportExcel() {
      let url = "/reports/transfer/export?";
      if (this.from_branch_id != null && this.from_branch_id != "") {
        url += "from_branch_id=" + this.from_branch_id + "&";
      }
      if (this.to_branch_id != null && this.to_branch_id != "") {
        url += "to_branch_id=" + this.to_branch_id + "&";
      }
      if (this.date != null && this.date != "") {
        url += "report_start=" + this.date + "&";
      }
      if (this.date_end != null && this.date_end != "") {
        url += "report_end=" + this.date_end;
      }
      if (this.filter.code != null) {
        url += "code=" + this.filter.code + "&";
      }

      this.exporting = true;
      this.exportExcelCommon(url, "transfer-report.xlsx", () => {
        this.exporting = false;
      });
    },
  },
};
</script>