<template>
  <Dashboard selectedMainItem="Operation" selectedSubItem="Vehicle">
    <v-card style="margin: 20px; border: 0" :loading="isLoading">
      <v-card-subtitle>
        <v-icon @click="$router.go(-1)">mdi-arrow-left-circle-outline</v-icon>
        Package Details 
      </v-card-subtitle>
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <v-card>
              <v-card-text>
                <p class="text-h5 text--primary">Package details 
        </p>
              </v-card-text>
              <v-row>
                <v-col cols="12" md="3">
                  <p class="text--primary ml-3">
                    Package code : {{ packageInfo.package.code }}
                  </p> </v-col
                ><v-col cols="12" md="3">
                  <p class="text--primary ml-3"> 
                    Barcode: {{ packageInfo.package.barcode }}
                  </p> </v-col
                ><v-col cols="12" md="3">
                  <p class="text--primary ml-3">
                    Pickup Date: {{ packageInfo.package.package_date }}
                  </p> </v-col
                ><v-col cols="12" md="3">
                  <p class="text--primary ml-3">
                    Type: {{ packageInfo.package.package_type_text }}
                  </p> </v-col
                ><v-col cols="12" md="3">
                  <p class="text--primary ml-3">
                    Delivery: {{ packageInfo.package.delivery_type_text }}
                  </p> </v-col
                ><v-col cols="12" md="3">
                  <p class="text--primary ml-3">
                    weight: {{ packageInfo.package.weight }} (Kg)
                  </p>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="text--primary ml-3">
                    Dimensions : {{ packageInfo.package.length }} *
                    {{ packageInfo.package.width }} *
                    {{ packageInfo.package.height }} (cm)
                  </p>
                </v-col>
                <v-col cols="12" md="12">
                  <p class="text--primary ml-3">
                    Description : {{ packageInfo.package.description }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-card>
              <v-card-text>
                <p class="text-h5 text--primary mb-0">Pickup & Delivery</p>
                <SenderReturn :show="packageInfo.package.returned" class="text-h7" />
              </v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <p class="text--primary ml-3">
                    Pickup city : {{ packageInfo.package.pickup_city_name }}
                  </p>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="text--primary ml-3">
                    Delivery city :
                    {{ packageInfo.package.delivery_city_name }}
                  </p>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="text--primary ml-3">
                    Delivery Type :
                    {{ packageInfo.package.delivery_type_text }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <p class="text--primary ml-3">
                    Area Type : {{ packageInfo.package.area_type_text }}
                  </p>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="text--primary ml-3">
                    Pickup Branch :
                    {{ packageInfo.package.pickup_branch }}
                  </p>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="text--primary ml-3">
                    Delivery Branch :
                    {{ packageInfo.package.delivery_branch }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card>
              <v-card-text>
                <p class="text-h5 text--primary">Payment Details</p>
              </v-card-text>
              <v-row>
                <v-col cols="12" md="8">
                  <p class="text--primary ml-3">
                    Payment Type :
                    {{
                      packageInfo.package.pay_by == 1 ? "Sender" : "Receiver"
                    }}
                    / {{ packageInfo.package.payment_type_text.toUpperCase() }}
                  </p>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="text--primary ml-3">
                    Paid Amount :
                    {{ packageInfo.package.paid_amount.toFixed(2) }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <p class="text--primary ml-3">
                    Courier Charge :
                    {{ packageInfo.package.courier_charge }}
                  </p>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="text--primary ml-3">
                    Item Price :
                    {{ packageInfo.package.cod_charge }}
                  </p>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="text--primary ml-3">
                    Total Amount :
                    {{ packageInfo.package.formatted_total }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-card>
              <v-card-text>
                <p class="text-h5 text--primary">Sender Details</p>
              </v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Sender Name : {{ packageInfo.package_person.sender_name }}
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Sender Contact :
                    {{ packageInfo.package_person.sender_contact }}
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Sender Fax : {{ packageInfo.package_person.sender_fax }}
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Sender Email :
                    {{ packageInfo.package_person.sender_email }}
                  </p> </v-col
                ><v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Sender Address :
                    {{ packageInfo.package_person.sender_address }}
                  </p> </v-col
                ><v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Sender Landmark :
                    {{ packageInfo.package_person.sender_landmark }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card>
              <v-card-text>
                <p class="text-h5 text--primary">Receiver Details</p>
              </v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Receiver Name :
                    {{ packageInfo.package_person.receiver_name }}
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Receiver Contact :
                    {{ packageInfo.package_person.receiver_contact }}
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Receiver Fax :
                    {{ packageInfo.package_person.receiver_fax }}
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Receiver Email :
                    {{ packageInfo.package_person.receiver_email }}
                  </p> </v-col
                ><v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Receiver Address :
                    {{ packageInfo.package_person.receiver_address }}
                  </p> </v-col
                ><v-col cols="12" md="6">
                  <p class="text--primary ml-3">
                    Receiver Landmark :
                    {{ packageInfo.package_person.receiver_landmark }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <br />
        <br />
        <v-row>
          <v-col cols="12" md="6">
            <v-card>
              <v-card-text>
                <p class="text-h5 text--primary">Package timeline</p>
              </v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <div
                    class="stage-warapper"
                    v-for="timeline in packageInfo.timeline"
                    :key="timeline.id"
                  >
                    <p class="text--primary">
                      <b>{{ timeline.stage_name }}</b>
                      <InternalReturn :show="timeline.recurring"/>
                    </p>
                    <p class="text--primary">
                      {{ timeline.description }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card>
              <v-card-text>
                <p class="text-h5 text--primary">Payments Timeline</p>
              </v-card-text>

              <v-row>
                <v-col cols="12" md="12" v-if="packageInfo.payments.length > 0">
                  <v-timeline align-top dense>
                    <v-timeline-item
                      v-for="(payment, index) in packageInfo.payments"
                      :key="index"
                      :color="payment.status == 1 ? 'green' : 'red'"
                      small
                    >
                      <div>
                        <div class="font-weight-normal">
                          <strong>{{ payment.type_text }} payment</strong> @{{
                            payment.date
                          }}
                        </div>
                        <div>
                          Record inserted by {{ payment.insert_by_name }} for
                          amount of {{ payment.paid_amount_formatted }} LKR
                        </div>
                        <div>
                          <i>{{ payment.status_message }}</i>
                        </div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
                <v-col cols="12" md="12" class="text-center" v-else>
                  <span class="no-data-text">- - No payments yet - - </span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </Dashboard>
</template>

<script>
import InternalReturn from "@/components/Common/CodeColumn/InternalReturn.vue";
import SenderReturn from "@/components/Common/CodeColumn/SenderReturn.vue";
import Dashboard from "../../components/Layouts/Leftbar";

export default {
  name: "PackageView",
  props: ["setVehicleEdit"],
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    Dashboard,
    SenderReturn,
    InternalReturn,
},
  computed: {
    packageInfo: {
      get() {
        return this.$store.getters.packageInfo;
      },
    },
  },
};
</script>
<style scoped>
.stage-warapper {
  background-color: rgb(229, 229, 229);
  border-radius: 10px;
  padding: 10px 10px;
  margin: 10px 10px;
}
.no-data-text {
  color: rgb(83, 83, 83);
}
</style>
