<template>
  <Dashboard selectedMainItem="Administration" selectedSubItem="Customers">
    <template>
      <FilterBar @updateFilterData="setFilterValues" forName="Customer" />
    </template>

    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-data-table
        :headers="headers"
        :items="allCustomers"
        item-key="id"
        :items-per-page="pagination.meta.per_page"
        class="elevation-1"
        hide-default-footer
      >
        <template :v-slot="body.append">
          <tr>
            <td></td>
            <td>
              <v-text-field
                v-model="calories"
                type="number"
                label="Less than"
              ></v-text-field>
            </td>
            <td colspan="4"></td>
          </tr>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editUser(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-pencil </v-icon>
                  Edit
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate :paginate="pagination" @paginated="handlePagination" />
    </v-card>
  </Dashboard>
</template>

<script>
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/PaginateAxios";
import FilterBar from "../../components/Common/FilterBar";
import axios from "axios";

export default {
  name: "all-operation",
  data() {
    return {
      pagination: {
        meta: {
          current_page: 1,
          per_page: 10,
        },
      },
      filter: {
        search: "",
        page: 1,
      },
      allCustomers: [],
      search: "",
      calories: "",
      deleteId: "",
      isLoading: false,
    };
  },
  components: {
    paginate,
    FilterBar,
    Dashboard,
  },
  computed: {
    headers() {
      return [
        {
          text: "Name",
          value: "name",
          sortable: false,
        },
        {
          text: "Customer Type",
          value: "customer_type",
          sortable: false,
        },
        {
          text: "Phone",
          value: "phone",
          sortable: false,
        },
        {
          text: "Address",
          value: "address",
          sortable: false,
        },
        {
          text: "Registered at",
          value: "created_at",
          sortable: false,
        },
        {
          text: "Actions",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.getCustomers();
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    async editUser(item) {
      this.$router.push({
        name: "customer-edit",
        params: { item: item },
      });
    },

    setUserDelete(item) {
      this.deleteId = item.id;
    },

    setFilterValues(childValues) {
      this.filter.name = childValues.search;
      this.getCustomers();
    },
    handlePagination(page) {
      this.filter.page = page;
      this.getCustomers();
    },
    async getCustomers() {
      axios
        .get("user/get-customers", {
          params: {
            name: this.filter.name,
            page: this.filter.page,
          },
        })
        .then((response) => {
          console.log(response);
          this.allCustomers = response.data.data;
          this.pagination = response?.data?.pagination;
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.isLoading = false;
          this.btnDisabled = false;
        });
    },
  },
};
</script>
