<template>

<Dashboard
  selectedMainItem = "Administration"
  selectedSubItem = "Branch"
>
<v-row>
      <v-col cols="12" md="6">
  <v-card
  style="margin:20px;border: 0;"
  :loading="isLoading"
  >
   <v-card-title>
    Edit Question
  </v-card-title>
  <v-card-subtitle>
    edit new question
    <template>
    <div>
      <v-breadcrumbs
      class="pl-0 pt-0"  
      :items="pageNavItems"></v-breadcrumbs>
    </div>
  </template>
  </v-card-subtitle>
      <form style="margin-top:40px;border: 0;">
        <v-container>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-textarea
              v-model="question"
              :error-messages="questionErrors"
              label="Question"
              placeholder="Question"
              solo
              required
              @input="$v.question.$touch()"
              @blur="$v.question.$touch()"
            ></v-textarea>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-textarea
              v-model="answer"
              :error-messages="answerErrors"
              label="Answer"
              placeholder="Answer"
              solo
              required
              @input="$v.answer.$touch()"
              @blur="$v.answer.$touch()"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12">
          </v-col>
        </v-row>
        <v-row>
          <v-btn
            class="ma-4"
            @click="submit"
            :color="$v.$anyError ? 'gray' : 'primary'"
            :disabled="btnDisabled"
          >
          <span class="white--text">
            ADD
          </span>    
          </v-btn>
          <!-- <v-btn 
          class="mr-4"
          @click="clear">
            clear
          </v-btn> -->
          <v-btn 
          class="ma-4"
          @click="cancelEdit"
          :color="'red'">
          <span class="white--text">
            Cancel
          </span>
          </v-btn>
        </v-row>
        </v-container>
    </form>

  </v-card>
  </v-col>
  </v-row>
</Dashboard>
</template>

<script>
  import { mapActions } from "vuex";
  import { validationMixin } from 'vuelidate'
  import { required  } from 'vuelidate/lib/validators'
  import Dashboard from '../../components/Layouts/Leftbar'


  export default {
    name:'branch-edit',
    mixins: [validationMixin],

    validations: {
      question: { required },
      answer: { required },
    },
    data(){
      return{
        id : '',
        question : '',
        answer : '',
        isLoading : false,
        btnDisable : false,
        pageNavItems: [
        {
          text: 'Dashboard',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
        {
          text: 'FAQ',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'faq'}
        },
        {
          text: 'Edit',
          disabled: true,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
      ],
      }
    },
    components:{
        Dashboard
    },
    computed: {
      btnDisabled () {
        const visible = this.$v.$anyError || !(this.question && this.answer);
        return visible
      },
      questionErrors () {
        const errors = []
        if (!this.$v.question.$dirty) return errors
        !this.$v.question.required && errors.push('Question is required')
        return errors
      },
      answerErrors () {
        const errors = []
        if (!this.$v.answer.$dirty) return errors
        !this.$v.answer.required && errors.push('Answer is required.')
        return errors
      },
    },
    created(){
      this.setEditValues();
    },
    methods: {

      ...mapActions(["questionUpdate"]),


      async submit () {

        this.$v.$touch();
        this.btnDisable = true;
        this.isLoading = true;

        await this.questionUpdate({
            id : this.id ,
            questionContent : this.question ,
            answer : this.answer ,
        }).then( response =>{

          console.log(response);

          this.$toast.success("Question updated successfully!");
          this.isLoading = false;
          this.cancelEdit();

        }).catch((e)=>{
          
          this.$toast.error(e.response.data.message);
          this.isLoading = false;
        });
      },
      clear () {
        this.$v.$reset()
        this.id = ''
        this.question = ''
        this.answer = ''
      },
      uppercase() {
        this.regNumber = this.regNumber.toUpperCase();
    },
    setEditValues(){
      console.log( this.$store.getters.editBranch.id );
      const editQuestion =  this.$store.getters.editQuestion;
        this.id =  editQuestion.id ;
        this.question =  editQuestion.question ;
        this.answer =  editQuestion.answer ;
    },
    cancelEdit(){
      this.$router.push({name:'faq'});
    }
    },
  }
</script>
