<template>
  <Dashboard selectedMainItem="Human Resource" selectedSubItem="User">
    <template>
      <FilterBar @updateFilterData="setFilterValues" forName="User" />
    </template>

    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col cols="12" sm="6" md="4"> </v-col>
        <v-col cols="12" sm="6" md="4"> </v-col>
        <v-col align="right" cols="12" sm="6" md="4">
          <v-btn
            class="mr-5"
            color="primary"
            elevation="2"
            :to="{ name: 'user-create' }"
          >
            Register
            <v-icon right> mdi-file-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="allUsers"
        item-key="name"
        class="elevation-1"
        hide-default-footer
        :items-per-page="ROWS_PER_PAGE"
      >
        <template v-slot:[`item.is_company`]="{ item }">
          <span>{{ formatOwnCompany(item) }}</span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span>{{ formatTypes(item) }}</span>
        </template>
        <template :v-slot="body.append">
          <tr>
            <td></td>
            <td>
              <v-text-field
                v-model="calories"
                type="number"
                label="Less than"
              ></v-text-field>
            </td>
            <td colspan="4"></td>
          </tr>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editUser(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-pencil </v-icon>
                  Edit
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate store="User" method="fetchUsers" :filter="filter" />
    </v-card>
  </Dashboard>
</template>

<script>
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/Paginate";
import FilterBar from "../../components/Common/FilterBar";
import axios from "axios";

export default {
  name: "all-operation",
  data() {
    return {
      filter: {
        search: "",
        firstName: "",
        email: "",
        userRole: "",
        page: 1,
      },
      allUsers:[],
      search: "",
      calories: "",
      deleteId: "",
      isLoading: false,
    };
  },
  components: {
    paginate,
    FilterBar,
    Dashboard,
  },
  computed: {
    headers() {
      return [
        {
          text: "Branch",
          align: "start",
          sortable: false,
          value: "branch",
        },
        {
          text: "Username",
          align: "start",
          sortable: false,
          value: "username",
        },
        {
          text: "First Name",
          value: "first_name",
          sortable: false,
        },
        {
          text: "Last Name",
          value: "last_name",
          sortable: false,
        },
        {
          text: "e-mail",
          value: "email",
          sortable: false,
        },
        {
          text: "Role",
          value: "role",
          sortable: false,
        },
        {
          text: "Created at",
          value: "created_at",
          sortable: false,
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    async editUser(item) {
      await this.$store.dispatch("getUserById", item.id);
      this.$router.push({ name: "user-edit" });
    },

    setUserDelete(item) {
      this.deleteId = item.id;
    },

    setFilterValues(childValues) {
      this.filter.search = childValues.search;
      this.filter.firstName = childValues.firstName;
      this.filter.email = childValues.email;
      this.filter.userRole = childValues.userRole;
      this.fetchUsers();
    },
    fetchUsers() {
      axios
        .get("user/get-paginate", {
          params: {
            username: this.filter.search,
            first_name: this.filter.firstName,
            email: this.filter.email,
            role_id: this.filter.userRole,
            page: this.filter.page,
            per_page:this.ROWS_PER_PAGE
          },
        })
        .then((response) => {
          this.allUsers = response.data.data;
        })
    },
    formatOwnCompany(company) {
      if (company.is_company) return "Inside Company";
      return "Out Side Company";
    },
    formatTypes(vehicle) {
      if (Number.isInteger(vehicle.type)) {
        const vehicleArray = this.$store.getters.vehiclesTypes;
        return vehicleArray.find((x) => x.id === vehicle.type).name;
      }
    },
  },
};
</script>
