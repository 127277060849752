import axios from "axios";

const state = {
  user: null,
  users: [],
  curriers: [],
  customers: [],
  userRoles: [],
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
};

const getters = {
  allUsers: (state) => state.users,
  allCustomers: (state) => state.customers,
  curriers: (state) => state.curriers,
  allCurriers: (state) => state.allCurriers,
  allUserRoles: (state) => state.userRoles,
  editUser: (state) => state.user,
  usersPaginate: (state) => state.paginate,
  userStatus: (state) => state.userStatus,
  usersTypes: (state) => state.usersTypes,
};

const actions = {
  async fetchUsers({ commit }, filter) {
    const allUsers = await axios.get("user/get-paginate", {
      params: {
        username: filter.search,
        first_name: filter.firstName,
        email: filter.email,
        role_id: filter.userRole,
        page: filter.page,
      },
    });
    commit("setUsers", allUsers.data.data);
    commit("setPagination", allUsers.data.pagination);
    return allUsers;
  },
  async fetchCustomers({ commit }, filter) {
    const allUsers = await axios.get("user/get-customers", {
      params: {
        name: filter.name,
        page: filter.page,
      },
    });
    commit("setCustomers", allUsers.data.data);
    commit("setPagination", allUsers.data.pagination);
    return allUsers;
  },
  async fetchCouriersUser({ commit }) {
    const allUsers = await axios.get("user/get-couriers");
    commit("setCurriers", allUsers.data.data);
    return allUsers;
  },

  async getAllUsersRoles({ commit }) {
    var allUserRoles = await axios.get("role/get-all");
    var roles = allUserRoles.data.data;

    const filteredUserRole = roles.map((role) =>
      role.name ? { ...role, name: role.name.replace(/_/g, " ") } : role
    );
    commit("setAllUserRoles", filteredUserRole);
    return filteredUserRole;
  },
  async getAllusers({ commit }) {
    const allUsers = await axios.get("user");
    commit("setVehicle", allUsers.data);
    return allUsers.data;
  },

  async userRegister(
    { commit },
    {
      username,
      role_id,
      email,
      phone,
      status,
      branch,
      password,
      password_confirmation,
      first_name,
      last_name,
    }
  ) {
    const user = await axios.post("user", {
      username,
      role_id,
      email,
      phone,
      status,
      branch,
      password,
      password_confirmation,
      first_name,
      last_name,
    });
    commit("newUser", user.data.data);
    return user;
  },

  async userUpdate(
    { commit },
    {
      id,
      username,
      role_id,
      email,
      phone,
      branch,
      status,
      password,
      password_confirmation,
      first_name,
      last_name,
    }
  ) {
    const user = await axios.put("user/" + id, {
      username,
      role_id,
      email,
      phone,
      branch,
      status,
      password,
      password_confirmation,
      first_name,
      last_name,
    });

    commit("updateUser", user.data.data);
    return user;
  },

  async getUserById({ commit }, userId) {
    const user = await axios.get("user/" + userId);
    commit("setUser", user.data.data);
    return user.data.data;
  },

  async deleteUserById({ commit }, id) {
    await axios.delete(`user/${id}`);

    commit("removeUser", id);
  },
};

const mutations = {
  setUsers: (state, users) => (state.users = users),
  setCustomers: (state, customers) => (state.customers = customers),
  setCurriers: (state, curriers) => (state.curriers = curriers),
  setUser: (state, user) => (state.user = user),
  setAllUserRoles: (state, Roles) => (state.userRoles = Roles),
  setPagination: (state, paginate) => (state.paginate = paginate),
  setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
  newUser: (state, user) => {
    state.users.unshift(user);
    state.user = null;
  },
  removeUser: (state, id) => {
    state.users = state.users.filter((user) => user.id !== id);
  },
  updateUser: (state, updUser) => {
    
    const index = state.users.findIndex((user) => user.id === updUser.id);
    
    if (index !== -1) {
      state.users.splice(index, 1, updUser);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
