var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dashboard',{attrs:{"selectedMainItem":"Operation","selectedSubItem":"Admin Packages"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticStyle:{"margin":"20px","border":"0"},attrs:{"loading":_vm.isLoading}},[_c('v-form',[_c('v-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticStyle:{"padding":"20px","border":"0"},attrs:{"elevation":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("Package Code : "+_vm._s(_vm.code))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-danger"},[_vm._v("This page provides Super Admin privileges, allowing you to modify package details with less validation. Please be responsible for your changes.")])])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('span',[_vm._v("Sender Details1")])]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Sender First Name ( required )","clearable":"","solo":"","rules":[(v) => !!v || 'Name is required']},model:{value:(_vm.sender_first_name),callback:function ($$v) {_vm.sender_first_name=$$v},expression:"sender_first_name"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Sender Last Name ( required )","clearable":"","solo":"","rules":[(v) => !!v || 'Name is required']},model:{value:(_vm.sender_last_name),callback:function ($$v) {_vm.sender_last_name=$$v},expression:"sender_last_name"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Sender Contact ( required )","clearable":"","solo":"","rules":[
                                (v) => !!v || 'Contact number is required',
                              ]},model:{value:(_vm.sender_contact),callback:function ($$v) {_vm.sender_contact=$$v},expression:"sender_contact"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Sender Fax ( optional )","clearable":"","solo":""},model:{value:(_vm.sender_fax),callback:function ($$v) {_vm.sender_fax=$$v},expression:"sender_fax"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Sender E-mail ( optional )","clearable":"","solo":""},model:{value:(_vm.sender_email),callback:function ($$v) {_vm.sender_email=$$v},expression:"sender_email"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Pick up address ( required )","clearable":"","solo":"","rules":[(v) => !!v || 'Address is required']},model:{value:(_vm.sender_address),callback:function ($$v) {_vm.sender_address=$$v},expression:"sender_address"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Sender Landmark ( optional )","clearable":"","solo":""},model:{value:(_vm.sender_landmark),callback:function ($$v) {_vm.sender_landmark=$$v},expression:"sender_landmark"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('span',[_vm._v("Receiver Details")])]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Receiver Name ( required )","clearable":"","solo":"","rules":[(v) => !!v || 'Name is required']},model:{value:(_vm.receiver_name),callback:function ($$v) {_vm.receiver_name=$$v},expression:"receiver_name"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Receiver Contact ( required )","clearable":"","solo":"","rules":[
                                (v) => !!v || 'Contact number is required',
                              ]},model:{value:(_vm.receiver_contact),callback:function ($$v) {_vm.receiver_contact=$$v},expression:"receiver_contact"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Receiver Fax ( optional )","clearable":"","solo":""},model:{value:(_vm.receiver_fax),callback:function ($$v) {_vm.receiver_fax=$$v},expression:"receiver_fax"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Receiver email ( optional )","clearable":"","solo":""},model:{value:(_vm.receiver_email),callback:function ($$v) {_vm.receiver_email=$$v},expression:"receiver_email"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Receiver address ( required )","clearable":"","solo":"","rules":[(v) => !!v || 'Address is required']},model:{value:(_vm.receiver_address),callback:function ($$v) {_vm.receiver_address=$$v},expression:"receiver_address"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Receiver Landmark ( optional )","clearable":"","solo":""},model:{value:(_vm.receiver_landmark),callback:function ($$v) {_vm.receiver_landmark=$$v},expression:"receiver_landmark"}})],1)],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('span',[_vm._v("Package Details")])]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-combobox',{attrs:{"required":"","autocomplete":"nope","items":_vm.pickupCities,"item-text":"name","item-value":"id","label":"PickUp City ( required )","solo":"","dense":"","rules":[
                                (v) => !!v || 'Pick up city is required',
                              ]},on:{"change":function($event){return _vm.setPickCityId()}},model:{value:(_vm.pickup_city),callback:function ($$v) {_vm.pickup_city=$$v},expression:"pickup_city"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-combobox',{attrs:{"autocomplete":"nope","items":_vm.deliveryCities,"item-text":"name","item-value":"id","label":"Delivery City ( required )","solo":"","dense":"","rules":[
                                (v) => !!v || 'Delivery city is required',
                              ]},on:{"change":function($event){return _vm.setDeliveryCityId()}},model:{value:(_vm.delivery_city),callback:function ($$v) {_vm.delivery_city=$$v},expression:"delivery_city"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('label',[_vm._v("Select Your Pickup Date")])]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Select Your Pickup Date ( required )","type":"date","clearable":"","solo":"","rules":[(v) => !!v || 'Date is required']},on:{"input":_vm.dateChange},model:{value:(_vm.package_date),callback:function ($$v) {_vm.package_date=$$v},expression:"package_date"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-checkbox',{attrs:{"label":"Same day delivery requested","color":"blue","value":1,"hide-details":""},on:{"change":_vm.calTotal},model:{value:(_vm.same_day),callback:function ($$v) {_vm.same_day=$$v},expression:"same_day"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"step":"0.1","min":"0","type":"number","label":"Weight (kg) ( required )","clearable":"","solo":"","rules":[(v) => !!v || 'Weight is required']},on:{"input":_vm.calTotal},model:{value:(_vm.weight),callback:function ($$v) {_vm.weight=$$v},expression:"weight"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"step":"0.1","min":"0","type":"number","label":_vm.totals.package_type.id == 1
                                  ? 'length (cm)'
                                  : 'length (cm) ( required )',"clearable":"","solo":"","rules":_vm.totals.package_type.id == 1
                                  ? []
                                  : [(v) => !!v || 'Length is required']},on:{"input":_vm.calTotal},model:{value:(_vm.length),callback:function ($$v) {_vm.length=$$v},expression:"length"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"step":"0.1","min":"0","type":"number","label":_vm.totals.package_type.id == 1
                                  ? 'height (cm)'
                                  : 'height (cm) ( required )',"clearable":"","solo":"","rules":_vm.totals.package_type.id == 1
                                  ? []
                                  : [(v) => !!v || 'Height is required']},on:{"input":_vm.calTotal},model:{value:(_vm.height),callback:function ($$v) {_vm.height=$$v},expression:"height"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"step":"0.1","min":"0","type":"number","label":_vm.totals.package_type.id == 1
                                  ? 'width (cm)'
                                  : 'width (cm) ( required )',"clearable":"","solo":"","rules":_vm.totals.package_type.id == 1
                                  ? []
                                  : [(v) => !!v || 'Width is required']},on:{"input":_vm.calTotal},model:{value:(_vm.width),callback:function ($$v) {_vm.width=$$v},expression:"width"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('h4',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'span',attrs,false),on),[_vm._v(" "+_vm._s(" Package Estimated Weight for Pricing : " + _vm.totals.total_weight + "kg ")+" ")])]}}])},[_c('span',[_vm._v("Estimated weight was calculated by considering package dimensions")])])],1)]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-textarea',{attrs:{"solo":"","name":"input-7-4","label":"Items Description ( Optional )"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('span',[_vm._v("Payment Details")])]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.payBy,"label":"Pay By","item-value":"id","item-text":"name","dense":"","solo":"","rules":[(v) => !!v || 'Pay By is required']},on:{"change":function($event){return _vm.changeValuePayBy()}},model:{value:(_vm.pay_by),callback:function ($$v) {_vm.pay_by=$$v},expression:"pay_by"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.paymentTypes,"label":"Payment Type","item-value":"id","item-text":"name","dense":"","solo":"","rules":[
                                (v) => !!v || 'Payment Type is required',
                              ]},on:{"input":_vm.calTotal},model:{value:(_vm.payment_type),callback:function ($$v) {_vm.payment_type=$$v},expression:"payment_type"}})],1),(_vm.pay_by == 3)?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"type":"number","min":"0","label":"Item Price (Rs)","clearable":"","solo":""},on:{"input":_vm.calTotal},model:{value:(_vm.cod),callback:function ($$v) {_vm.cod=$$v},expression:"cod"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('h4',[_vm._v(" Courier Charges : "+_vm._s(_vm.totals.with_transaction_cost)+" ")])]),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('h4',[_vm._v("Net Total : "+_vm._s(_vm.totals.net_total))])]),(_vm.payment_type == '3')?_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('h4',[_vm._v("Bank Details")]),_c('p',{staticClass:"address_line"},[_vm._v(" Bank     : Sampath Bank ")]),_c('p',{staticClass:"address_line"},[_vm._v(" Acc       : 1069 1402 9547 ")]),_c('p',{staticClass:"address_line"},[_vm._v(" Branch : Homagama branch ")])]):_vm._e(),_c('v-col',{staticClass:"d-flex align-right mb-2",attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{attrs:{"depressed":"","disabled":_vm.calculating || _vm.packageOverlay || _vm.isLoading,"color":"primary"},on:{"click":function($event){return _vm.addPackagesData()}}},[_vm._v(" Override")]),_c('v-btn',{staticClass:"ml-4 cancel__btn white--text",attrs:{"color":"#ff0000","depressed":""},on:{"click":function($event){return _vm.backToList()}}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }