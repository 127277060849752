import axios from "axios";

const state = {
  fuel: [],
  allFuel: [],
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
};

const getters = {
  fuel: (state) => state.fuel,
  allFuel: (state) => state.allFuel,
};

const actions = {
  async fetchFuel({ commit }, filter) {
   
    const fuel = await axios.get("fuel/get-paginate", {
      params: {
        start: filter.start,
        end: filter.end,
        page: filter.page,
      },
    });
    console.log(fuel);
    commit("setFuel", fuel.data.data);
    commit("setPagination", fuel.data.pagination);
    return fuel;
  },
  async deleteFuelById({ commit }, id) {
    await axios.delete(`fuel/${id}`);

    commit("removeFuel", id);
  },
  async getAllFuel({ commit }) {
    const fuel = await axios.get("fuel");

    commit("setAllFuel", fuel.data.data);
    return fuel.data;
  },

  async markFuel({ commit }, data) {
    const user = await axios.post("fuel",data);
    console.log(
      "🚀🐈  ~ file: Attendance.js ~ line 61 ~ markVehicle ~ user",
      user
    );
    commit("newUser", user.data.data);
    return user;
  },
};

const mutations = {
  setFuel: (state, fuel) => (state.fuel = fuel),
  removeFuel: (state, id) => {
    state.fuel = state.fuel.filter((user) => user.id !== id);
  },
  setAllFuel: (state, allFuel) => (state.allFuel = allFuel),
  setPagination: (state, paginate) => (state.paginate = paginate),
  setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
