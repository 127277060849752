<template>
  <Dashboard selectedMainItem="Transport" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col cols="12" sm="6" md="2"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                class="ml-5"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                label="Start Date"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="2"
          ><v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                class="ml-5"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(date_end);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col align="right" cols="12" sm="6" md="4">
          <v-select
            :items="vehicleData"
            v-model="vehicleId"
            item-text="reg_number"
            item-value="reg_number"
            label="Vehicle"
            required
            v-on:change="reload()"
          ></v-select>
        </v-col>
        <v-col align="right" cols="12" sm="6" md="4">
          <v-btn
            class="mr-5"
            color="primary"
            elevation="2"
            :to="{ name: 'driver-assigning-create' }"
          >
            Assign
            <v-icon right> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="fuelData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click.stop="
              dialog = true;
              toDelete(item);
            "
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <paginate store="User" method="fetchDriverAssign" :filter="filter" />
    </v-card>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete this item?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            cancel
          </v-btn>

          <v-btn color="error" text @click="deleteItem()"> delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/Paginate";
// import FilterBar from "../../components/Common/FilterBar";

export default {
  name: "all-operation",
  data() {
    return {
      filter: {
        start: this.date,
        end: this.date_end,
        reg_number: this.vehicleId,
        page: 1,
      },
      search: "",
      calories: "",
      deleteId: "",
      dialogDelete: false,
      isLoading: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date_end: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      dialog: false,
      item: {},
      vehicleData: [],
      vehicleId: "",
    };
  },
  components: {
    paginate,
    Dashboard,
  },
  computed: {
    fuelData: {
      get() {
        return this.$store.getters.driverAssign;
      },
    },

    headers() {
      return [
        {
          text: "Reg number",
          align: "start",
          sortable: false,
          value: "reg_number",
        },
        {
          text: "Date",
          value: "date",
          sortable: false,
        },
        {
          text: "Type",
          value: "type_text",
          sortable: false,
        },
        {
          text: "Driver Name",
          value: "user_name",
          sortable: false,
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchDriverAssign(this.filter);
  },
  mounted() {
    this.getAllVehicles()
      .then((response) => {
        const arr = response.data;
        this.vehicleData = arr;
      })
      .catch((e) => {
        this.$toast.error(e.response.data.message);
      });
      this.reload();
  },
  methods: {
    ...mapActions(["fetchDriverAssign", "deleteDriverById", "getAllVehicles"]),

    reload() {
      const filter = {
        start: this.date,
        end: this.date_end,
        reg_number: this.vehicleId,
        page: 1,
      };
      this.fetchDriverAssign(filter);
    },
    toDelete(item) {
      this.item = item;
    },
    async deleteItem() {
      await this.deleteDriverById(this.item.id)
        .then(() => {
          this.$toast.success("Deleted successfully!");
          this.dialog = false;
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.dialog = false;
        });
    },
  },
};
</script>