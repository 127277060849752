import axios from 'axios';


const state = {
    location: null,
    locations: [],
    parentLocations: [],
    locationTypes:[
        {
            id:"",
            name: 'All Types'
        },
        {
            id: 1,
            name: 'Country'
        },
        {
            id: 2,
            name: 'Province'
        },
        {
            id: 3,
            name: 'District'
        },
        {
            id: 4,
            name: 'City'
        },
    ],
    paginate: {
        meta:
        {
            current_page : 1,
            last_page : 1,
        }
    },
};

const getters = {
    
    allLocations: state => state.locations,
    editLocation: state => state.location,
    locationTypes: state => state.locationTypes,
    LocationsPaginate: state => state.paginate,
    LocationStatus: state => state.locationStatus,
};

const actions = {

    async fetchLocations({ commit }, filter) {
        console.log('filter obj',filter);
            const allLocations = await axios.get('location-hierarchy/get-paginate',{params: {
                parent_id: filter.location, 
                type : filter.locationType,
                name : filter.search,
                page : filter.page,
            }});
            console.log(allLocations.data.data);
            commit("setLocations",  allLocations.data.data);
            commit("setPagination",  allLocations.data.pagination);
            return allLocations;
    },
    
    async getAllLocations({commit}){
        const allLocations = await axios.get('Location');
        commit("setLocation",  allLocations.data);
        return allLocations.data;
    },

    async setParentLocation(_,ParentLocation){
        if(ParentLocation != 1){
            const allParentLocations = await axios.get('location-hierarchy',{params:{type : --ParentLocation }});
            return allParentLocations.data.data;
        }
        return null;
    },

    async locationRegister({ commit }, { name, type, status, parent_id }) {
        
        const Location = await axios.post('location-hierarchy', {
            name,
            type,
            status,
            parent_id,
        });
        commit("newLocation",  Location.data.data );
        return Location;
    },

    async locationUpdate({ commit },  { id,  name, type, status, parent_id }) {

        const Location = await axios.put('location-hierarchy/'+id, {
            name,
            type,
            status,
            parent_id,
        });
        commit("updateLocation",  Location.data.data );
        return Location;
    },

    async getLocationById({ commit },LocationId) {
        
        const Location = await axios.get('location-hierarchy/'+ LocationId);
        commit("setLocation",  Location.data.data );
        return Location.data.data;
    },

    async deleteLocationById({ commit }, id) {
        await axios.delete(`location-hierarchy/${id}`);
    
        commit('removeLocation', id);
    }

};

const mutations = {
    setLocations: (state, locations) => (state.locations = locations),
    setLocation: (state, location) => (state.location = location),
    setPagination: (state, paginate) => (state.paginate = paginate),
    setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
    newLocation: (state, Location) =>{
        state.locations.unshift(Location);
        state.location = null;
    },   
    removeLocation: (state, id) =>{
        state.Locations = state.locations.filter(location => location.id !== id);
    },
    updateLocation: (state, updLocation) => {
        const index = state.locations.findIndex(location => location.id === updLocation.id);
        if (index !== -1) {
        state.locations.splice(index, 1, updLocation);
        }
    }
};

export default {
state,
getters,
actions,
mutations
};