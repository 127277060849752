<template>
  <Dashboard selectedMainItem="Administration" selectedSubItem="Route">
    <template>
      <FilterBar @updateFilterData="setFilterValues" forName="Route" />
    </template>

    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col cols="12" sm="6" md="4"> </v-col>
        <v-col cols="12" sm="6" md="4"> </v-col>
        <v-col align="right" cols="12" sm="6" md="4">
          <v-btn
            class="mr-5"
            color="primary"
            elevation="2"
            :to="{ name: 'route-create' }"
          >
            ADD
            <v-icon right> mdi-file-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="allRoutes"
        item-key="id"
        class="elevation-1"
        hide-default-footer
      >
        <template :v-slot="body.append">
          <tr>
            <td></td>
            <td>
              <v-text-field
                v-model="calories"
                type="number"
                label="Less than"
              ></v-text-field>
            </td>
            <td colspan="4"></td>
          </tr>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editRoute(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-pencil </v-icon>
                  Edit
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="routeView(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-eye </v-icon>
                  View
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="addRouteCity(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-home </v-icon>
                  City Assign
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="setRouteDelete(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-delete </v-icon>
                  Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate store="Route" method="fetchRoutes" :filter="filter" />
      <!-- ******************* delete user dialog ********************** -->
      <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
          <v-card-title class="headline">Route Delete?</v-card-title>
          <v-card-text>Do you want to Delete ?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialogDelete = false"
              >Cancel</v-btn
            >

            <v-btn color="red darken-1" text @click="deleteRoute">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/Paginate";
import FilterBar from "../../components/Common/FilterBar";

export default {
  name: "route-list",
  data() {
    return {
      filter: {
        search: "",
        page: 1,
      },
      search: "",
      calories: "",
      deleteId: "",
      dialogDelete: false,
      isLoading: false,
    };
  },
  components: {
    paginate,
    FilterBar,
    Dashboard,
  },
  computed: {
    allRoutes: {
      get() {
        return this.$store.getters.allRoutes;
      },
    },
    headers() {
      return [
        {
          text: "Route name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "No of Cities",
          value: "cities_count",
          sortable: false,
        },
        {
          text: "Branch name",
          value: "branch.name",
          sortable: false,
        },
        {
          text: "Description",
          value: "description",
          sortable: false,
        },
        {
          text: "Actions",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchRoutes(this.filter);
  },
  methods: {
    ...mapActions(["fetchRoutes", "deleteRouteById"]),

    async editRoute(route) {
      await this.$store.dispatch("getRouteById", route.id);
      this.$router.push({ name: "route-edit" });
    },
    async routeView(route) {
      await this.$store.dispatch("getRouteById", route.id);
      this.$router.push({ name: "route-view" });
    },

    async addRouteCity(route) {
      await this.$store.dispatch("getRouteById", route.id);
      this.$router.push({ name: "route-city-assign" });
    },

    setRouteDelete(item) {
      this.deleteId = item.id;
      this.dialogDelete = true;
    },
    async deleteRoute() {
      this.dialogDelete = false;
      await this.deleteRouteById(this.deleteId)
        .then((response) => {
          console.log(response);
          this.$toast.success("Route deleted successfully!");
        })
        .catch((e) => {
          
          this.$toast.error(e.response.data.message);
        });
    },
    setFilterValues(childValues) {
      this.filter.search = childValues.search;
      this.filter.page = childValues.page;

      this.fetchRoutes(this.filter);
    },
  },
};
</script>
