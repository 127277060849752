<template>
  <Dashboard selectedMainItem="Pickup Operation" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-text-field
            v-model="filter.code"
            label="Code"
            @input="reload()"
            type="text"
            class="ml-5"
            required
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                class="ml-5"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                class="ml-5"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(date_end);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col align="right" cols="12" sm="6" md="2"> </v-col>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="sortingData"
        item-key="id"
        class="elevation-1"
        hide-default-footer
        :items-per-page="ROWS_PER_PAGE"
        
      >
      <template v-slot:[`item.code`]="{ item }">
          <CodeColumn :code="item.code" :stage_return="item.active_stage.recurring" :sender_return="item.returned" />
        </template>
        <template v-slot:[`item.is_attend`]="{ item }">
          <span>{{ formatAttendanceStatus(item) }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="success" @click="toSort(item)"> Sort </v-btn>
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="viewPackage(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-eye </v-icon>
                  View More
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate store="User" method="fetchSorting" :filter="filter" />
    </v-card>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="headline"
          >Please select delivery branch</v-card-title
        >
        <form>
          <v-select
            :items="branchesData"
            v-model="branch_id"
            item-text="name"
            item-value="id"
            class="mx-5"
            label="Select Branch"
            @input="$v.branch_id.$touch()"
            @blur="$v.branch_id.$touch()"
            required
          ></v-select>
        </form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false"
            >Cancel</v-btn
          >

          <v-btn
            :color="$v.$anyError ? 'gray' : 'primary'"
            :disabled="btnDisabled"
            text
            @click="sortItem()"
            >sort</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/Paginate";
import axios from "axios";
import CodeColumn from "@/components/Common/CodeColumn/Index";

export default {
  name: "SortPackage",

  validations: {
    branch_id: { required },
  },
  data() {
    return {
      name: "",
      filter: {
        name: this.name,
        status: 1,
        code: "",
      },
      isLoading: false,
      menu: false,
      menu2: false,
      dialog: false,
      date: "",
      date_end: "",
      branch_id: "",
      item: {},
      selected: [],
      branchesData: [],
    };
  },
  components: {
    Dashboard,
    paginate,
    CodeColumn
},
  computed: {
    sortingData: {
      get() {
        return this.$store.getters.Sorting;
      },
      branchErrors() {
        const errors = [];
        if (!this.$v.branch_id.$dirty) return errors;
        !this.$v.branch_id.required && errors.push("Branch is required.");
        return errors;
      },
    },
    btnDisabled() {
      const visible = this.$v.$anyError || !this.branch_id;
      return visible;
    },

    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Client name",
          align: "start",
          sortable: false,
          value: "client_name",
        },
        {
          text: "Package Type",
          value: "package_type_text",
          sortable: false,
        },
        {
          text: "Pickup City",
          value: "pickup_city_name",
          sortable: false,
        },
        {
          text: "Delivery City",
          value: "current_delivery_city_name",
          sortable: false,
        },
        {
          text: "Date",
          value: "package_date",
          sortable: false,
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchSorting(this.filter);
    this.getBranches();
  },
  mounted() {
    this.getBranches();
  },
  methods: {
    ...mapActions([
      "fetchSorting",
      "deletePackage",
      "getAllVehicles",
      "UpdateSorting",
    ]),

    getBranches() {
      axios.get("/branch/get-all-without-auth").then((response) => {
        this.branchesData = response.data.data;
      });
    },

    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    clear() {
      this.filter = {
        code: "",
      };
      this.date = "";
      this.date_end = "";
      this.reload();
    },
    reload() {
      const filter = {
        start: this.date,
        end: this.date_end,
        code: this.filter.code,
      };
      this.fetchSorting(filter);
    },
    toSort(item) {
      this.dialog = true;
      this.item = item;
      this.branch_id = item.current_branch_id;
    },
    async sortItem() {
      this.$v.$touch();
      this.isLoading = true;
      const toSend = {
        package_id: this.item.id,
        branch_id: this.branch_id,
      };
      await this.UpdateSorting(toSend)
        .then(() => {
          this.reload();
          this.$toast.success("Sort successfully!");
          this.dialog = false;
      this.isLoading = false;

        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.dialog = false;
      this.isLoading = false;

        });
    },
  },
};
</script>