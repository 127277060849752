<template>
  <Dashboard selectedMainItem="Pickup Operation" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="3">
          <v-text-field
            v-model="filter.code"
            ref="code_input"
            label="Code"
            class="ml-5"
            @change="reload()"
            type="text"
            required
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-select
            v-model="filter.courier"
            :items="curriersData"
            item-text="name"
            item-value="id"
            class="ml-3"
            :label="'Courier'"
            placeholder="Placeholder"
            @change="reload()"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <template>
            <div>
              <div>
                <button @click="switchCamera">
                  <v-icon size="30" class="black--text mt-5"
                    >mdi-barcode-scan</v-icon
                  >
                </button>
              </div>
              <div id="scan" class="qrcode">
                <qrcode-stream
                  :camera="camera"
                  @decode="onDecode"
                  @init="onInit"
                  class="ml-3"
                >
                </qrcode-stream>
              </div>
            </div>
          </template>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="tableData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
        :items-per-page="ROWS_PER_PAGE"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="success" @click="markItem(item)">
            Mark as collected
          </v-btn>
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="viewPackage(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-eye </v-icon>
                  View More
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate :paginate="pagination" @paginated="handlePagination" />
    </v-card>
    <v-dialog v-model="dialogCollected" max-width="450">
      <v-card>
        <v-card-title class="headline"
          >Do you want to mark as collected?</v-card-title
        >
        <v-card-text>You're going to mark collected</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" dark @click="cancelEdit()">Cancel</v-btn>

          <v-btn color="green darken-1" dark @click="editPackage()">mark</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import { QrcodeStream } from "vue-qrcode-reader";
import paginate from "@/components/Common/PaginateAxios";
import axios from "axios";

export default {
  name: "all-operation",
  data() {
    return {
      filter: {
        courier: null,
        status: 1,
        code: "",
      },
      pagination: {
        meta: {
          current_page: 1,
        },
      },
      camera: "rear",
      tableData: [],
      noRearCamera: false,
      noFrontCamera: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      isLoading: false,
      menu: false,
      type: "",
      menu2: false,
      dialogCollected: false,
      dialog: false,
      item: {},
      mark_item: {},
      collectedType: [
        { name: "collected", id: 1 },
        { name: "not-collected", id: 2 },
      ],
    };
  },
  components: {
    Dashboard,
    paginate,
    QrcodeStream,
  },
  computed: {
    curriersData: {
      get() {
        return this.$store.getters.curriers;
      },
    },

    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Barcode",
          align: "start",
          sortable: false,
          value: "barcode",
        },
        {
          text: "Package Type",
          value: "package_type_text",
          sortable: false,
        },
        {
          text: "Pickup City",
          value: "pickup_city_name",
          sortable: false,
        },
        {
          text: "Collected By",
          value: "pickup_courier_name",
          sortable: false,
        },
        {
          text: "Date",
          value: "package_date",
          sortable: false,
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchCouriersUser();
    window.addEventListener("keyup", this.keyHandler);
  },
  mounted() {
    this.reload();
  },
  methods: {
    ...mapActions([
      "deletePackage",
      "UpdateCollected",
      "getAllVehicles",
      "fetchCouriersUser",
    ]),
    handlePagination(page) {
      this.filter.page = page;
      this.reload();
    },
    keyHandler(key) {
      if (key.which === 13) {
        this.focusCodeFilter();
      }
    },
    focusCodeFilter() {
      this.$nextTick(() => this.$refs.code_input.focus());
    },
    cancelEdit() {
      this.dialogCollected = false;
      this.focusCodeFilter();
    },
    async editPackage() {
      this.isLoading = true;
      await this.UpdateCollected(this.mark_item.id)

        .then(() => {
          this.$toast.success("Marked successfully!");
          this.dialogCollected = false;
          this.isLoading = false;
          this.reload();
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
          this.dialogCollected = false;
          this.isLoading = false;
          this.focusCodeFilter();
        });
    },
    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    markItem(item) {
      this.mark_item = item;
      this.dialogCollected = true;
    },
    async reload() {
      await axios
        .get("package/get-collected", {
          params: {
            courier_id: this.filter.courier,
            code: this.filter.code,
            page:this.filter.page,
            per_page:this.ROWS_PER_PAGE
          },
        })
        .then((response) => {
          this.tableData = response.data.package;
          this.pagination =  response?.data?.pagination;
          this.promptToMark();
          this.focusCodeFilter();
        });
    },
    clear() {
      this.filter = {
        stage_id: "",
        code: "",
      };
      this.type = "";
      this.date_end = "";
      this.reload();
    },
    switchCamera() {
      var element = document.getElementById("scan");
      element.classList.toggle("qrcode");
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
      }
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        const triedFrontCamera = this.camera === "front";
        const triedRearCamera = this.camera === "rear";

        const cameraMissingError = error.name === "OverconstrainedError";

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true;
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true;
        }

        //  console.error(error)
      }
    },
    promptToMark() {
      if (this.tableData.length == 1 && this.filter.code.length == 11) {
        this.markItem(this.tableData[0]);
      }
      if(this.filter.code.length >= 11){
        this.filter.code = '';
      }
    },
    async onDecode(result) {
      this.noRearCamera = false;
      this.noFrontCamera = false;
      this.filter.code = result;
      await this.reload();
    },
  },
};
</script>

<style scoped>
.qrcode {
  display: none;
}
</style>
