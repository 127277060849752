<template>
  <Dashboard selectedMainItem="Delivery Operation" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-text-field
            class="ml-5"
            v-model="filter.code"
            label="Code"
            @input="reload()"
            type="text"
            required
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4"> </v-col>

        <v-col align="right" cols="12" sm="6" md="2"> </v-col
        ><v-col align="right" cols="12" sm="6" md="2"> </v-col>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="shipped"
        item-key="id"
        class="elevation-1"
        hide-default-footer
        :items-per-page="ROWS_PER_PAGE"
      >
      <template v-slot:[`item.code`]="{ item }">
          <CodeColumn :code="item.code" :stage_return="item.active_stage.recurring" :sender_return="item.returned" />
        </template>
        <template v-slot:[`item.delivery_phone`]="{ item }">
          <a :href="'tel:'+item.delivery_phone">{{item.delivery_phone}}</a>
        </template>
        <template v-slot:[`item.delivery_address`]="{ item }">
          <span>{{item.delivery_name}}</span>, &nbsp;
          <span>{{item.delivery_address}}</span>
        </template>
          
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-if="authUserRole == 'COURIER'" color="success" @click="editPackage(item)">
            Mark Delivery
          </v-btn>
          <v-btn v-else color="success" @click="viewPackage(item)">View More</v-btn>
          <v-menu v-if="authUserRole == 'COURIER'" left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-verviewtical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="viewPackage(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-eye </v-icon>
                  View More
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-menu left bottom>
            
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="toReturn(item)">
                <v-list-item-title>
                  <v-icon class="mr-3">  mdi-keyboard-return </v-icon>
                  Return 
                </v-list-item-title>
              </v-list-item>
              
            </v-list> 
            </v-menu> -->

        </template>
      </v-data-table>
      <paginate store="User" method="fetchShipped" :filter="filter" />
    </v-card>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/Paginate";
import CodeColumn from "@/components/Common/CodeColumn/Index";

export default {
  name: "DeliveryList",
  data() {
    return {
      name: "",
      filter: {
        page: 1,
        code: "",
      },
      isLoading: false,
      menu: false,
      menu2: false,
      dialog: false,
      dialog_reject: false,
      item: {},
      selected: [],
      collectedType: [
        { name: "collected", id: 1 },
        { name: "not-collected", id: 2 },
      ],
    };
  },
  components: {
    Dashboard,
    paginate,
    CodeColumn
},
  computed: {
    authUserRole() {
      return this.$store?.state?.Auth?.user?.role[0];
    },
    shipped: {
      get() {
        return this.$store.getters.shipped;
      },
    },

    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Client name",
          align: "start",
          sortable: false,
          value: "client_name",
        },
        {
          text: "Package Type",
          value: "package_type_text",
          sortable: false,
        },
        {
          text: "Pickup City",
          value: "pickup_city_name",
          sortable: false,
        },
        {
          text: "Delivery Address",
          align: "start",
          sortable: false,
          value: "current_delivery_address",
        },
        {
          text: "Receiver Contact",
          align: "start",
          sortable: false,
          value: "current_delivery_phone",
        },
        {
          text: "Date",
          value: "package_date",
          sortable: false,
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.fetchShipped(this.filter);
  },
  mounted() {},
  methods: {
    ...mapActions(["fetchShipped"]),
    async editPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "mark-delivery",
        params: { item: item },
      });
    },
    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    reload() {
      const filter = {
        page: this.page,
        code: this.filter.code,
      };
      this.fetchShipped(filter);
    },
    clear() {
      this.filter = {
        code: "",
      };

      this.reload();
    },
    toReturn(item) {
      this.dialog_reject = true;
      this.item = item;
    },
  },
};
</script>