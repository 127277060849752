<template>
<Dashboard
  selectedMainItem = "Administration"
  selectedSubItem = "Pricing">
      <template >
      <FilterBar @updateFilterData="setFilterValues" forName="Pricing" />
    </template>

  <v-card
      style="margin:30px;border: 0;"
      :loading="isLoading"
  >
    <v-data-table
      :headers="headers"
      :items="allPriceFactors"
      item-key="id"
      class="elevation-1"
      hide-default-footer
    >

      <template v-slot:[`item.actions`]="{ item }">
          <v-btn 
            class="ma-4"
            @click="setEditFactor(item)"
            :color="'primary'">
            <span>
              Edit
            </span>
          </v-btn>
      </template>

    </v-data-table>
        <paginate
        store="Pricing"
        method="fetchPriceFactors"
        :filter ="filter"
        />
  </v-card>
</Dashboard >
</template>

<script>
  import { mapActions } from "vuex";
  import Dashboard from '../../components/Layouts/Leftbar'
  import paginate from '../../components/Common/Paginate';
  import FilterBar from '../../components/Common/FilterBar';

  export default {
    name:"all-operation",
    data () {
      return {
        filter:{
          package_type:'',
          area_type:'',
          page:1,
        },
        isLoading: false,
      }
    },
    components:{
      paginate,
      FilterBar,
      Dashboard,
    },
    computed: {
      allPriceFactors:{
          get(){
              return this.$store.getters.getAllPriceFactors;
          }
      },
      headers () {
        return [
          {
            text: 'Package Type',
            align: 'start',
            sortable: false,
            value: 'package_type_text',
          },
          {
            text: 'Area Type',
            value: 'area_type_text',
            sortable: false
          },
          { 
            text: 'Initial', 
            value: 'initial',
            sortable: false 
          },
          { 
            text: 'Additional', 
            value: 'additional',
            sortable: false 
          },
          { 
            text: 'Actions',  
            align: 'end', 
            value: 'actions',
            sortable: false 
          },
        ]
      },
    },
    mounted() {
      this.fetchPriceFactors(this.filter);

    },
    methods: {
      ...mapActions(["fetchPriceFactors","getPriceById"]),

      async setEditFactor(price){

        await this.$store.dispatch('getPriceById',price.id);
        this.$router.push({name:'price-edit'});
      
      },

      setFilterValues(childValues){
        this.filter.package_type = childValues.packageType;
        this.filter.area_type = childValues.branchAreaType;
        this.fetchPriceFactors(this.filter);
      },

      setPageTypeName(type) {
        if (Number.isInteger(type.package_type)) {          
          const packageArray =this.$store.getters.getAllPackageTypes;
          return packageArray.find(x => x.id === type.package_type).name;
        }
      },
      setAreaTypeName(type) {
        console.log(this.$store.getters.getBranchAreaTypes);
        if (Number.isInteger(type.package_type)) {          
          const packageArray =this.$store.getters.getBranchAreaTypes;
          return packageArray.find(x => x.id === type.area_type).name;
        }
      },
    },
  }
</script>