import axios from "axios";

const state = {
  transfer: [],
  transferById: [],
  allTransfer: [],
  pending: [],
  couriersAttended: [],
  driversAttended: [],
  paginate: {
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
};

const getters = {
  transfer: (state) => state.transfer,
  pending: (state) => state.pending,
  couriersAttended: (state) => state.couriersAttended,
  driversAttended: (state) => state.driversAttended,
  allTransfer: (state) => state.allTransfer,
  transferById: (state) => state.transferById,
};

const actions = {
  async fetchTransfer({ commit }, filter) {
    const transfer = await axios.get("transfer/get-paginate", {
      params: {
        start: filter.start,
        status: filter.status,
        end: filter.end,
        page: filter.page,
      },
    });

    commit("setFuel", transfer.data.data);
    commit("setPagination", transfer.data.pagination);
    return transfer;
  },
  async fetchCouriersAttended({ commit }, filter) {
    const transfer = await axios.get("attendance/get-attended-couriers", {
      params: {
        date: filter.date,
      },
    });
    commit("setCouriersAttended", transfer.data.data);

    return transfer;
  },
  async fetchDriversAttended({ commit }, filter) {
    const transfer = await axios.get("attendance/get-attended-drivers", {
      params: {
        date: filter.date,
      },
    });
    commit("setDriversAttended", transfer.data.data);

    return transfer;
  },
  async fetchPendingTransfer({ commit }, filter) {
    const transfer = await axios.get("package/get-transfer-pending", {
      params: {
        code: filter.code,
        page: filter.page,
      },
    });

    commit("setPending", transfer.data.package);
    commit("setPagination", transfer.data.pagination);
    return transfer;
  },
  async transferById({ commit }, items) {
    const status = items.status;
    const transfer_id = items.transfer_id;
    const package_id = items.package_id;
    const transfer = await axios.post("transfer-package", {
      transfer_id,
      package_id,
      status,
    });
    commit("setPending", transfer.data.package);
  },
  async getAllFuel({ commit }) {
    const transfer = await axios.get("transfer");

    commit("setAllTransfer", transfer.data.data);
    return transfer.data;
  },

  async markTransfer({ commit }, toSend) {
    const user = await axios.post("transfer",toSend);

    commit("newUser", user.data.data);
    return user;
  },
  async deleteTransferById({ commit }, id) {
    console.log(commit)
    return await axios.delete(`transfer/${id}`);
  },
  async getTransferById({ commit }, id) {
    const packageIn = await axios.get("transfer/" + id.id);
    commit("setTransferById", packageIn.data.data);

    return packageIn.data;
  },
  async editTransfer({ commit }, toSend) {
    const id = toSend.id;

    const user = await axios.put("transfer/" + id, toSend);

    commit("newUser", user.data.data);
    return user;
  },
};

const mutations = {
  setFuel: (state, transfer) => (state.transfer = transfer),
  setDriversAttended: (state, driversAttended) =>
    (state.driversAttended = driversAttended),
  setCouriersAttended: (state, couriersAttended) =>
    (state.couriersAttended = couriersAttended),
  setPending: (state, pending) => (state.pending = pending),
  removeFuel: (state, id) => {
    state.transfer = state.transfer.filter((user) => user.id !== id);
  },
  setAllTransfer: (state, allTransfer) => (state.allTransfer = allTransfer),
  setTransferById: (state, transferById) => (state.transferById = transferById),
  setPagination: (state, paginate) => (state.paginate = paginate),
  setCurrentPage: (state, page) => (state.paginate.meta.current_page = page),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
