<template>
<Dashboard
    selectedMainItem = "Administration"
  selectedSubItem = "Locations">
      <template >
      <FilterBar @updateFilterData="setFilterValues" forName="Location" />
    </template>

  <v-card
      style="margin:30px;border: 0;"
      :loading="isLoading"
  >
      <v-row>
      <v-col
          cols="12"
          sm="6"
          md="4"
          >
      </v-col>
      <v-col
          cols="12"
          sm="6"
          md="4"
          >
      </v-col>
      <v-col
          align="right"
          cols="12"
          sm="6"
          md="4"
          >
          <v-btn
          class="mr-5"
          color="primary"
          elevation="2"
          :to="{name: 'location-create'}"
          >
          ADD
              <v-icon right>
                  mdi-file-plus
              </v-icon>
          </v-btn>
          
      </v-col>
    </v-row>


    <v-data-table
      :headers="headers"
      :items="allLocations"
      item-key="id"
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:[`item.type`]="{ item }">
        <span>{{ setLocationTypeName(item)  }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">


      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
          :disabled="item.type !=4"
            @click="editLocation(item)"
          >
            <v-list-item-title>
              <v-icon
                class="mr-3"
              >
                mdi-pencil
              </v-icon>
              Edit
            </v-list-item-title>
          </v-list-item>

          <v-list-item
          :disabled="item.type !=4"
            @click="setLocationDelete(item)"
          >
            <v-list-item-title>
              <v-icon
                class="mr-3"
              >
                mdi-delete
              </v-icon>
              Delete
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    </v-data-table>
        <paginate
        store="Location"
        method="fetchLocations"
        :filter ="filter"
        />
      <!-- ******************* delete user dialog ********************** -->

    <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
        <v-card-title class="headline">Location Delete?</v-card-title>
        <v-card-text>Do you want to Delete ?</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialogDelete = false">Cancel</v-btn>

            <v-btn color="red darken-1" text @click="deleteLocation">Delete</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
  </v-card>
</Dashboard>
</template>

<script>
  import { mapActions } from "vuex";
  import Dashboard from '../../components/Layouts/Leftbar'
  import paginate from '../../components/Common/Paginate';
  import FilterBar from '../../components/Common/FilterBar';

  export default {
    name:"all-operation",
    data () {
      return {
        filter:{
          search:'',
          location : '',
          locationType : '',
          page:1,
        },
        search: '',
        isLoading: false,
        deleteId: '',
        dialogDelete: false,
      }
    },
    components:{
      paginate,
      FilterBar,
      Dashboard,
    },
    computed: {

    allLocations:{
        get(){
            return this.$store.getters.allLocations;
        }
      },
      headers () {
        return [
          {
            text: 'Location Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          {
            text: 'Location Type',
            value: 'type',
            sortable: false
          },
          { 
            text: 'Hierarchy', 
            value: 'hierarchy',
            sortable: false 
          },
          { 
            text: 'Operation',  
            align: 'end', 
            value: 'actions',
            sortable: false 
          },
        ]
      },
    },
    created() {
      this.fetchLocations(this.filter);

    },
    methods: {
      ...mapActions(["fetchLocations","deleteLocationById","getLocationById"]),
      filterOnlyCapsText (value, search) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },
      async editLocation(vehicle){

        await this.$store.dispatch('getLocationById',vehicle.id);
        this.$router.push({name:'location-edit'});
      
      },

      setLocationDelete(item){

        this.deleteId = item.id;
        this.dialogDelete = true;
      },

      async deleteLocation(){

          this.dialogDelete = false;
          await this.deleteLocationById(this.deleteId).then( response =>{

          console.log(response);
          this.$toast.success("Location deleted successfully!");

        }).catch((e)=>{
          
          this.$toast.error(e.response.data.message);
        });
      },

      async setFilterValues(childValues){

        console.log(childValues);
        this.filter.search = childValues.search;
        this.filter.location = childValues.location;
        this.filter.locationType = childValues.locationType;

        this.fetchLocations(this.filter);
      },
      setLocationTypeName(item) {

        const locationType =  this.$store.state.Location.locationTypes;
        return locationType.find(x => x.id === item.type).name;
      },
    },
  }
</script>