<template>
  <Dashboard
    selectedMainItem="Pickup Operation"
    selectedSubItem="Edit Packages"
  >
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="2">
          <v-text-field
            v-model="filter.code"
            label="Code"
            class="ml-5"
            @input="getData()"
            type="text"
            required
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                class="ml-5"
                label="Start Date (Pickup)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  getData();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu2"
            :items-per-page="15"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                class="ml-5"
                label="End Date (Pickup)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(date_end);
                  getData();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col align="right" cols="12" sm="6" md="2">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="AllPackages"
        item-key="id"
        class="elevation-1"
        hide-default-footer
        :items-per-page="ROWS_PER_PAGE"
      >
        <template v-slot:[`item.dimensions`]="{ item }">
          <span title="length * width * height" style="white-space: nowrap"
            >{{ item.length }} * {{ item.width }} * {{ item.height }} cm</span
          >
        </template>

        <template v-slot:[`item.weight`]="{ item }">
          <span style="white-space: nowrap">{{ item.weight }} kg</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn class="ma-4" color="success" @click="markCompleted(item)">
            Confirm
          </v-btn>
          <v-btn color="primary" @click="editPackage(item)"> Edit </v-btn>
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="viewPackage(item)">
                <v-list-item-title>
                  <v-icon class="mr-3"> mdi-eye </v-icon>
                  View More
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <paginate :paginate="pagination" @paginated="handlePagination" />
    </v-card>
    <!-- ******************* modal ********************** -->
    <v-dialog v-model="modal_view" max-width="290">
      <v-card>
        <v-card-title class="headline">Confirm Details?</v-card-title>
        <v-card-text>Have you checked details correctly?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="
              modal_view = false;
              modal_id = null;
            "
            >Cancel</v-btn
          >

          <v-btn color="green darken-1" text @click="modalConfirm"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Dashboard>
</template>

<script>
// import { mapActions } from "vuex";
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/PaginateAxios";
import axios from "axios";

export default {
  name: "review-packages",
  data() {
    return {
      pagination: {
        meta: {
          current_page: 1,
        },
      },
      name: "",
      filter: {
        page: 1,
        code: "",
        start: this.date,
        end: this.date_end,
      },
      // date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      // date_end: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      date: null,
      date_end: null,
      isLoading: false,
      menu: false,
      menu2: false,
      dialog: false,
      item: {},
      AllPackages: [],
      modal_id: null,
      modal_view: null,
    };
  },
  components: {
    Dashboard,
    paginate,
  },

  computed: {
    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Client",
          align: "start",
          sortable: false,
          value: "client_name",
        },
        {
          text: "Package Type",
          value: "package_type_text",
          sortable: false,
        },
        {
          text: "Dimensions",
          value: "dimensions",
          sortable: false,
        },
        {
          text: "Weight",
          value: "weight",
          sortable: false,
        },
        {
          text: "Actions",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    handlePagination(page) {
      this.filter.page = page;
      this.getData();
    },
    async editPackage(item) {
      this.$router.push({
        name: "operation-edit-packages",
        params: { item: item },
      });
    },
    clear() {
      this.filter = {
        code: "",
      };
      this.date = "";
      this.date_end = "";
      this.getData();
    },
    async getData() {
      await axios
        .get("package/get-for-edit", {
          params: {
            start: this.date,
            end: this.date_end,
            code: this.filter.code,
            page: this.filter.page,
            per_page:this.ROWS_PER_PAGE
          },
        })
        .then((response) => {
          this.AllPackages = response.data.package;
          this.pagination = response?.data?.pagination;
        });
    },
    markCompleted(item) {
      this.modal_id = item.id;
      this.modal_view = true;
    },
    modalConfirm() {
      axios.put("package/confirm-review-stage/" + this.modal_id).then(() => {
        this.modal_view = false;
        this.$toast.success("Package update successfully!");
        this.getData();
      });
    },
  },
};
</script>
