<template>
  <Dashboard selectedMainItem="Operation" selectedSubItem="Admin Packages">
    <v-row>
      <v-col cols="12" md="12">
        <v-card style="margin: 20px; border: 0" :loading="isLoading">
          <v-form>
            <v-card>
              <v-row>
                <v-col cols="12" md="12">
                  <v-card elevation="2" style="padding: 20px; border: 0">
                    <v-row>
                      <v-col cols="12">
                        <h4>Package Code : {{ code }}</h4>
                      </v-col>
                      <v-col cols="12">
                        <p class="text-danger">This page provides Super Admin privileges, allowing you to modify package details with less validation. Please be responsible for your changes.</p>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12">
                      <v-card elevation="2">
                        <v-card-title>
                          <span>Sender Details1</span>
                        </v-card-title>
                        <v-container fluid>
                          <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-text-field
                                label="Sender First Name ( required )"
                                v-model="sender_first_name"
                                clearable
                                solo
                                :rules="[(v) => !!v || 'Name is required']"
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-text-field
                                label="Sender Last Name ( required )"
                                v-model="sender_last_name"
                                clearable
                                solo
                                :rules="[(v) => !!v || 'Name is required']"
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-text-field
                                label="Sender Contact ( required )"
                                v-model="sender_contact"
                                clearable
                                solo
                                :rules="[
                                  (v) => !!v || 'Contact number is required',
                                ]"
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-text-field
                                label="Sender Fax ( optional )"
                                v-model="sender_fax"
                                clearable
                                solo
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-text-field
                                label="Sender E-mail ( optional )"
                                v-model="sender_email"
                                clearable
                                solo
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-text-field
                                v-model="sender_address"
                                label="Pick up address ( required )"
                                clearable
                                solo
                                :rules="[(v) => !!v || 'Address is required']"
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-text-field
                                v-model="sender_landmark"
                                label="Sender Landmark ( optional )"
                                clearable
                                solo
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <v-card elevation="2">
                        <v-card-title>
                          <span>Receiver Details</span>
                        </v-card-title>
                        <v-container fluid>
                          <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-text-field
                                label="Receiver Name ( required )"
                                v-model="receiver_name"
                                clearable
                                solo
                                :rules="[(v) => !!v || 'Name is required']"
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-text-field
                                label="Receiver Contact ( required )"
                                v-model="receiver_contact"
                                clearable
                                solo
                                :rules="[
                                  (v) => !!v || 'Contact number is required',
                                ]"
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-text-field
                                label="Receiver Fax ( optional )"
                                v-model="receiver_fax"
                                clearable
                                solo
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-text-field
                                label="Receiver email ( optional )"
                                v-model="receiver_email"
                                clearable
                                solo
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-text-field
                                label="Receiver address ( required )"
                                v-model="receiver_address"
                                clearable
                                solo
                                :rules="[(v) => !!v || 'Address is required']"
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-text-field
                                label="Receiver Landmark ( optional )"
                                v-model="receiver_landmark"
                                clearable
                                solo
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12">
                      <v-card elevation="2">
                        <v-card-title>
                          <span>Package Details</span>
                        </v-card-title>
                        <v-container fluid>
                          <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-combobox
                                v-model="pickup_city"
                                required
                                autocomplete="nope"
                                :items="pickupCities"
                                item-text="name"
                                item-value="id"
                                label="PickUp City ( required )"
                                solo
                                dense
                                @change="setPickCityId()"
                                :rules="[
                                  (v) => !!v || 'Pick up city is required',
                                ]"
                              ></v-combobox>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-combobox
                                v-model="delivery_city"
                                autocomplete="nope"
                                :items="deliveryCities"
                                item-text="name"
                                item-value="id"
                                label="Delivery City ( required )"
                                solo
                                dense
                                @change="setDeliveryCityId()"
                                :rules="[
                                  (v) => !!v || 'Delivery city is required',
                                ]"
                              ></v-combobox>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <label>Select Your Pickup Date</label>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-text-field
                                label="Select Your Pickup Date ( required )"
                                type="date"
                                v-model="package_date"
                                @input="dateChange"
                                clearable
                                solo
                                :rules="[(v) => !!v || 'Date is required']"
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-checkbox
                                v-model="same_day"
                                label="Same day delivery requested"
                                color="blue"
                                :value="1"
                                hide-details
                                @change="calTotal"
                              ></v-checkbox>
                            </v-col>
                            <v-col class="d-flex" cols="6" sm="6">
                              <v-text-field
                                step="0.1"
                                min="0"
                                type="number"
                                label="Weight (kg) ( required )"
                                v-model="weight"
                                clearable
                                solo
                                @input="calTotal"
                                :rules="[(v) => !!v || 'Weight is required']"
                              ></v-text-field>
                            </v-col>

                            <v-col class="d-flex" cols="6" sm="6">
                              <v-text-field
                                step="0.1"
                                min="0"
                                type="number"
                                @input="calTotal"
                                :label="
                                  totals.package_type.id == 1
                                    ? 'length (cm)'
                                    : 'length (cm) ( required )'
                                "
                                v-model="length"
                                clearable
                                solo
                                :rules="
                                  totals.package_type.id == 1
                                    ? []
                                    : [(v) => !!v || 'Length is required']
                                "
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="6" sm="6">
                              <v-text-field
                                step="0.1"
                                @input="calTotal"
                                min="0"
                                type="number"
                                :label="
                                  totals.package_type.id == 1
                                    ? 'height (cm)'
                                    : 'height (cm) ( required )'
                                "
                                v-model="height"
                                clearable
                                solo
                                :rules="
                                  totals.package_type.id == 1
                                    ? []
                                    : [(v) => !!v || 'Height is required']
                                "
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="6" sm="6">
                              <v-text-field
                                step="0.1"
                                min="0"
                                @input="calTotal"
                                type="number"
                                :label="
                                  totals.package_type.id == 1
                                    ? 'width (cm)'
                                    : 'width (cm) ( required )'
                                "
                                v-model="width"
                                clearable
                                solo
                                :rules="
                                  totals.package_type.id == 1
                                    ? []
                                    : [(v) => !!v || 'Width is required']
                                "
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <h4>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span
                                      color="primary"
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{
                                        " Package Estimated Weight for Pricing : " +
                                        totals.total_weight +
                                        "kg "
                                      }}
                                    </span>
                                  </template>
                                  <span
                                    >Estimated weight was calculated by
                                    considering package dimensions</span
                                  >
                                </v-tooltip>
                              </h4>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12">
                              <v-textarea
                                v-model="description"
                                solo
                                name="input-7-4"
                                label="Items Description ( Optional )"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <v-card elevation="2">
                        <v-card-title>
                          <span>Payment Details</span>
                        </v-card-title>
                        <v-container fluid>
                          <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="6">
                              <v-select
                                v-model="pay_by"
                                :items="payBy"
                                label="Pay By"
                                item-value="id"
                                item-text="name"
                                @change="changeValuePayBy()"
                                dense
                                solo
                                :rules="[(v) => !!v || 'Pay By is required']"
                              ></v-select>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="6">
                              <v-select
                                v-model="payment_type"
                                :items="paymentTypes"
                                label="Payment Type"
                                item-value="id"
                                item-text="name"
                                @input="calTotal"
                                dense
                                solo
                                :rules="[
                                  (v) => !!v || 'Payment Type is required',
                                ]"
                              ></v-select>
                            </v-col>
                            <v-col
                              v-if="pay_by == 3"
                              class="d-flex"
                              cols="12"
                              sm="12"
                            >
                              <v-text-field
                                v-model="cod"
                                type="number"
                                @input="calTotal"
                                min="0"
                                label="Item Price (Rs)"
                                clearable
                                solo
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6">
                              <h4>
                                Courier Charges :
                                {{ totals.with_transaction_cost }}
                              </h4>
                            </v-col>
                            <v-col cols="6" sm="6">
                              <h4>Net Total : {{ totals.net_total }}</h4>
                            </v-col>
                            <v-col v-if="payment_type == '3'" cols="12" sm="12">
                              <h4>Bank Details</h4>
                              <p class="address_line">
                                Bank &nbsp;&nbsp;&nbsp;&nbsp;: Sampath Bank
                              </p>
                              <p class="address_line">
                                Acc &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 1069
                                1402 9547
                              </p>
                              <p class="address_line">
                                Branch : Homagama branch
                              </p>
                            </v-col>
                            <v-col
                              class="d-flex align-right mb-2"
                              cols="12"
                              sm="12"
                            >
                              <v-btn
                                depressed
                                :disabled="
                                  calculating || packageOverlay || isLoading
                                "
                                @click="addPackagesData()"
                                color="primary"
                              >
                                Override</v-btn
                              >
                              <v-btn
                                color="#ff0000"
                                class="ml-4 cancel__btn white--text"
                                depressed
                                @click="backToList()"
                                >Cancel</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar";
import axios from "axios";

export default {
  name: "super-admin-override",
  mixins: [validationMixin],

  validations: {
    packageType: { required },
    branchAreaType: { required },
    initial: { required },
    additional: { required },
  },
  data() {
    return {
      pickup_city: null,
      delivery_city: null,
      customer: null,
      calculating: false,
      id: "",
      code: "",
      packageType: "",
      branchAreaType: "",
      initial: "",
      additional: "",
      isLoading: false,
      package_id: null,
      pickup_city_id: "",
      delivery_city_id: "",
      package_date: "",
      weight: "",
      length: "",
      height: "",
      width: "",
      cod: "",
      description: "",
      pay_by: 1,
      payment_type: 1,
      receiver_name: "",
      receiver_contact: "",
      receiver_fax: "",
      receiver_email: "",
      receiver_address: "",
      receiver_landmark: "",
      sender_first_name: "",
      sender_last_name: "",
      sender_name: "",
      sender_contact: "",
      sender_fax: "",
      sender_email: "",
      sender_address: "",
      sender_landmark: "",
      user_id: "",
      same_day: null,
      pickupCities: [],
      deliveryCities: [],
      allCities: [],
      allCustomers: [],
      pageNavItems: [
        {
          text: "Dashboard",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "dashboard" },
        },
        {
          text: "Branch",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "branch" },
        },
        {
          text: "Edit",
          disabled: true,
          link: true,
          exact: true,
          to: { name: "dashboard" },
        },
      ],
      payBy: [
        {
          id: 1,
          name: "Sender",
        },
        {
          id: 2,
          name: "Receiver",
        },
        {
          id: 3,
          name: "Receiver ( COD )",
        },
      ],
      totals: {
        total_weight: 0,
        package_type: { id: 1, name: "Document" },
        with_transaction_cost: 0,
        net_total: 0,
      },
      packageOverlay: false,
    };
  },
  components: {
    Dashboard,
  },
  computed: {
    packageTypes: {
      get() {
        return this.$store.getters.getAllPackageTypes;
      },
    },
    branchAreaTypes: {
      get() {
        return this.$store.getters.getBranchAreaTypes;
      },
    },
    btnDisabled() {
      const visible =
        this.$v.$anyError ||
        !(
          this.packageType &&
          this.branchAreaType &&
          this.initial &&
          this.additional
        );
      return visible;
    },

    packageTypeErrors() {
      const errors = [];
      if (!this.$v.packageType.$dirty) return errors;
      !this.$v.packageType.required && errors.push("Package Type is required");
      return errors;
    },
    branchAreaTypeErrors() {
      const errors = [];
      if (!this.$v.branchAreaType.$dirty) return errors;
      !this.$v.branchAreaType.required &&
        errors.push("Branch Type Type is required");
      return errors;
    },
    initialErrors() {
      const errors = [];
      if (!this.$v.initial.$dirty) return errors;
      !this.$v.initial.required && errors.push("Initial is required.");
      return errors;
    },
    additionalErrors() {
      const errors = [];
      if (!this.$v.additional.$dirty) return errors;
      !this.$v.additional.required &&
        errors.push("Additional Value is required.");
      return errors;
    },
    paymentTypes() {
      let array = [
        {
          id: 1,
          name: "Cash",
        },

        {
          id: 3,
          name: "Bank transfer",
        },
      ];
      array.push({
        id: 2,
        name: "Card",
      });

      array.push({
        id: 4,
        name: "Credit",
      });
      return array;
    },
  },
  created() {
    this.getCity();
    this.getCustomers();
  },
  mounted() {
    this.packageChanged();
    this.setEditValues();
  },
  methods: {
    ...mapActions(["priceUpdate"]),
    changeValuePayBy() {
      this.cod = 0;
      this.payment_type = 1;
      this.calTotal();
    },
    packageChanged() {
      if (this.packageType == 1) {
        this.additional = this.initial;
      }
    },
    valueChanged() {
      if (this.packageType == 1) {
        this.additional = this.initial;
      }
      this.$v.initial.$touch();
    },
    async submit() {
      this.$v.$touch();
      this.btnDisable = true;
      this.isLoading = true;

      await this.priceUpdate({
        id: this.id,
        package_type: this.packageType,
        area_type: this.branchAreaType,
        initial: this.initial,
        additional: this.additional,
      })
        .then(() => {
          this.$toast.success("Price updated successfully!");
          this.isLoading = false;
          this.cancelEdit();
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
          this.isLoading = false;
        });
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.contact = "";
      this.openTime = "";
      this.address = "";
    },
    uppercase() {
      this.regNumber = this.regNumber.toUpperCase();
    },
    async setEditValues() {
      this.package_id = this.$route.query?.id;
      this.isLoading = true;
      axios
        .get("/package/view-more/" + this.package_id)
        .then((response) => {
          console.log(response.data.data);
          this.pickup_city = response.data.data.package.pickup_city.name;
          this.delivery_city = response.data.data.package.delivery_city_name;
          this.pickup_city_id = response.data.data.package.pickup_city_id;
          this.delivery_city_id = response.data.data.package.delivery_city_id;
          this.package_date = response.data.data.package.package_date;
          this.weight = response.data.data.package.weight;
          this.length = response.data.data.package.length;
          this.height = response.data.data.package.height;
          this.code = response.data.data.package.code;
          this.width = response.data.data.package.width;
          this.sender_first_name = response.data.data.package.client_first_name;
          this.sender_last_name = response.data.data.package.client_last_name;
          this.description = response.data.data.package.width;
          this.payment_type = response.data.data.package.payment_type;
          this.receiver_name = response.data.data.package_person.receiver_name;
          this.cod = response.data.data.package.cod;
          this.receiver_contact =
          response.data.data.package_person.receiver_contact;
          this.receiver_fax = response.data.data.package_person.receiver_fax;
          this.receiver_email =
            response.data.data.package_person.receiver_email;
          this.receiver_address =
          response.data.data.package_person.receiver_address;
          this.receiver_landmark =
            response.data.data.package_person.receiver_landmark;
            this.sender_fax = response.data.data.package_person.sender_fax;
            
          this.sender_landmark =
            response.data.data.package_person.sender_landmark;
          this.sender_name = response.data.data.package_person.sender_name;
          this.sender_email = response.data.data.package_person.sender_email;
          this.sender_address =
            response.data.data.package_person.sender_address;
          this.sender_contact =
            response.data.data.package_person.sender_contact;
          this.address = response.data.data.package_person.sender_address;

          this.same_day = response.data.data.package.same_day;
          this.cod = response.data.data.package.cod;
          this.payment_type = response.data.data.package.payment_type;
          this.totals.net_total = response.data.data.package.formatted_total;
          this.totals.with_transaction_cost =
            response.data.data.package.courier_charge;
          this.customer = response.data.data.package_person.sender_name;
          this.user_id = response.data.data.package.client_id;
          this.totals.total_weight = response.data.data.package.weight;
          this.setPayBy(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setPayBy(response){
      let payBy = response.data.data.package.pay_by;
      if(response.data.data.package.cod > 0){
        payBy = 3;
      }
      this.pay_by = payBy;
    },
    cancelEdit() {
      this.$router.push({ name: "price" });
    },
    getCity() {
      axios
        .get("/client/get-cities")
        .then((response) => {
          this.allCities = response.data.data;
          this.setPickupCities();
          this.setDeliveryCities();
          this.setPackageCities();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setPickupCities() {
      let array = [];
      this.allCities.forEach((item) => {
        if (item.area_type_id < 3) {
          array.push(item);
        }
      });
      this.pickupCities = array;
    },
    setDeliveryCities() {
      let array = [];
      this.allCities.forEach((item) => {
        if (item.area_type_id < 4) {
          array.push(item);
        }
      });
      this.deliveryCities = array;
    },
    setPackageCities() {
      if (
        this.pickup_city == null &&
        this.pickupCities.length > 0 &&
        this.form.pickup_city_id != null
      ) {
        this.pickup_city = this.getRecordById(
          this.pickupCities,
          this.form.pickup_city_id
        )[0];
      }
      if (
        this.delivery_city == null &&
        this.deliveryCities.length > 0 &&
        this.delivery_city_id != null
      ) {
        this.delivery_city = this.getRecordById(
          this.deliveryCities,
          this.delivery_city_id
        )[0];
      }
    },
    async getCustomers() {
      axios
        .get("user/get-all-customers")
        .then((response) => {
          this.allCustomers = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.isLoading = false;
          this.btnDisabled = false;
        });
    },
    setPickCityId() {
      this.pickup_city_id = this.pickup_city.id;
      this.calTotal();
    },
    setDeliveryCityId() {
      this.delivery_city_id = this.delivery_city.id;
      this.calTotal();
    },

    calTotal() {
      this.totals.total_weight = this.weight;
      this.calculating = true;
      const packageData = {
        weight: this.weight,
        height: this.height,
        length: this.length,
        width: this.width,
        delivery_city_id: this.delivery_city_id,
        pickup_city_id: this.pickup_city_id,
        cod: this.cod,
        same_day: this.same_day == null ? 0 : this.same_day,
        payment_type: this.payment_type,
        user_id: this.user_id,
      };

      if (this.id != null) {
        packageData.id = this.id;
      }
      axios
        .post("client/cal-total", packageData)
        .then((response) => {
          this.totals = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.calculating = false;
        });
    },
    dateChange(date) {
      const today = new Date();
      const newDate = new Date(date);

      //Today's date
      today.toDateString() === newDate.toDateString();
      this.calTotal();
    },
    async addPackagesData() {
      const packageRegData = {
        package_id: this.package_id,
        weight: this.weight,
        height: this.height,
        length: this.length,
        width: this.width,
        delivery_city_id: this.delivery_city_id,
        pickup_city_id: this.pickup_city_id,
        package_date: this.package_date,
        pay_by: this.pay_by,
        payment_type: this.payment_type,
        receiver_name: this.receiver_name,
        receiver_contact: this.receiver_contact,
        receiver_fax: this.receiver_fax,
        receiver_email: this.receiver_email,
        receiver_address: this.receiver_address,
        receiver_landmark: this.receiver_landmark,
        sender_name: this.sender_first_name + " " + this.sender_last_name,
        sender_first_name: this.sender_first_name,
        sender_last_name: this.sender_last_name,
        sender_contact: this.sender_contact,
        sender_fax: this.sender_fax,
        sender_email: this.sender_email,
        sender_address: this.sender_address,
        sender_landmark: this.sender_landmark,
        description: this.description,
        cod: this.cod,
        same_day: this.same_day == null ? 0 : this.same_day,
        user_id: this.user_id,
        admin_package_add: 1,
      };

      this.packageOverlay = true;

      axios
        .put("package/override", packageRegData)
        .then((response) => {
          if (response) {
            this.$toast.success("Package override successfully!");
            this.backToList();
          }
        })
        .catch((error) => {
          console.log(error);
          this.errorAlertText = "";
          this.$toast.error(error.response.data.message);
          this.scrollToTop();
        })
        .finally(() => {
          this.packageOverlay = false;
        });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: Add smooth scrolling animation
      });
    },
    clearForm() {
      this.pickup_city = null;
      this.delivery_city = null;
      this.pickup_city_id = null;
      this.delivery_city_id = null;
      this.package_date = null;
      this.weight = null;
      this.length = null;
      this.height = null;
      this.width = null;
      this.description = null;
      this.pay_by = 1;
      this.payment_type = 1;
      this.receiver_name = null;
      this.cod = null;
      this.receiver_contact = null;
      this.receiver_fax = null;
      this.receiver_email = null;
      this.receiver_address = null;
      this.receiver_landmark = null;
      this.same_day = null;
      this.cod = null;
      this.payment_type = null;
      this.calTotal();
    },
    pickupCitiesErrors() {
      const errors = [];
      if (!this.$v.pickupCities.$dirty) return errors;
      !this.$v.pickupCities.required &&
        errors.push("Pickup Cities is required");
      return errors;
    },
    backToList() {
      this.clearForm();
      this.$router.push({
        name: "all-packages",
      });
    },
  },
};
</script>
<style scoped>
.text-danger{
  color: red;
  font-weight: 600;
}
</style>