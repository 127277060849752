<template>
  <Dashboard selectedMainItem="Administration" selectedSubItem="Settings">
    <template>
      <FilterBar @updateFilterData="setFilterValues" forName="Settings" />
    </template>

    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-data-table
        :headers="headers"
        :items="settings"
        item-key="id"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn class="ma-4" @click="editRecord(item)" :color="'primary'">
            <span> Change </span>
          </v-btn>
        </template>
      </v-data-table>
      <paginate store="Pricing" method="fetchPriceFactors" :filter="filter" />
    </v-card>
  </Dashboard>
</template>

<script>
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/Paginate";
import FilterBar from "../../components/Common/FilterBar";
import axios from "axios";

export default {
  name: "all-operation",
  data() {
    return {
      filter: {
        name: "",
        page: 1,
      },
      settings: [],
      isLoading: false,
    };
  },
  components: {
    paginate,
    FilterBar,
    Dashboard,
  },
  computed: {
    allPriceFactors: {
      get() {
        return this.$store.getters.getAllPriceFactors;
      },
    },
    headers() {
      return [
        {
          text: "Setting",
          align: "start",
          sortable: false,
          value: "display_name",
        },
        {
          text: "Value",
          value: "current_value",
          align: "end",
          sortable: false,
        },
        {
          text: "Actions",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    editRecord(item) {
      this.$router.push({
        name: "settings-edit",
        params: { item: item },
      });
    },
    
    setFilterValues(item) {
      this.filter.name = item.search;
      this.getSettings();
    },
    async getSettings() {
      axios
        .get("system-setting", {
          params: {
            name: this.filter.name,
            page: this.filter.page,
          },
        })
        .then((response) => {
          this.settings = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
          this.isLoading = false;
          this.btnDisabled = false;
        });
    },
  },
};
</script>
