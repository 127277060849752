<template>
  <Dashboard selectedMainItem="Transport" selectedSubItem="Vehicle">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-card-title> Assign Driver </v-card-title>
      <v-card-subtitle>
        Assign Driver
        <template>
          <div>
            <v-breadcrumbs
              class="pl-0 pt-0"
              :items="pageNavItems"
            ></v-breadcrumbs>
          </div>
        </template>
      </v-card-subtitle>
      <form style="margin-top: 40px; border: 0">
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="today"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="today"
                    class="ml-5"
                    label="Select Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="today" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.menu.save(today);
                      reload();
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :items="vehicleData"
                v-model="vehicleId"
                :item-text="getVehicleItemText"
                item-value="vehicle_id"
                label="Select Vehicle"
                solo
                @change="reload()"
                required
                @input="$v.vehicleId.$touch()"
                @blur="$v.vehicleId.$touch()"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :items="userData"
                :error-messages="userErrors"
                v-model="userId"
                :item-text="getUserItemText"
                item-value="id"
                label="Select user"
                solo
                required
                @change="reload()"
                @input="$v.userId.$touch()"
                @blur="$v.userId.$touch()"
              ></v-select>
            </v-col>

            <v-btn
              :disabled="btnDisabled"
              color="success"
              class="ma-4"
              @click="submit"
            >
              <span> Submit </span>
            </v-btn>

            <v-btn class="ma-4" @click="cancelRegister" :color="'red'">
              <span class="white--text"> Cancel </span>
            </v-btn>
          </v-row>
        </v-container>
      </form>
      <v-data-table
        :headers="headers"
        :items="assignedVehicles"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
      </v-data-table>
    </v-card>
  </Dashboard>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Dashboard from "../../components/Layouts/Leftbar.vue";
import axios from "axios";

export default {
  name: "vehicle-register",
  mixins: [validationMixin],

  validations: {
    vehicleId: { required },
    userId: { required },
    type: { required },
  },

  data: () => ({
    name: "",
    isLoading: false,
    btnDisable: false,
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    filter: {
      start:new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
      end:new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
      vehicle_id: "",
      user_id: "",
    },
    menu: false,
    vehicleData: [],
    userData: [],
    vehicleId: "",
    userId: "",
    assignedVehicles:[],
    pageNavItems: [
      {
        text: "Dashboard",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "dashboard" },
      },
      {
        text: "Driver Assign",
        disabled: false,
        link: true,
        exact: true,
        to: { name: "driver-assigning-to-vehicles" },
      },
      {
        text: "Add",
        disabled: true,
        link: true,
        exact: true,
        to: { name: "dashboard" },
      },
    ],
  }),
  components: {
    Dashboard,
  },
  created() {
    const filterUser = {
      date: this.today,
    };
    this.reload();
    this.getAllUsersAttendance(filterUser);
  },
  computed: {
    vehicleAssigned: {
      get() {
        return this.$store.getters.vehicleAssigned;
      },
    },
    allVehicles: {
      get() {
        return this.$store.getters.vehicles;
      },
    },

    btnDisabled() {
      const visible = this.$v.$anyError || !(this.vehicleId && this.userId);
      return visible;
    },
    headers() {
      return [
        {
          text: "Vehicle Number",
          align: "start",
          sortable: false,
          value: "reg_number",
        },
        {
          text: "Date",
          value: "date",
          sortable: false,
        },
        {
          text: "Type",
          value: "type_text",
          sortable: false,
        },
        {
          text: "Driver Name",
          value: "user_name",
          sortable: false,
        },
      ];
    },
    userErrors() {
      const errors = [];
      if (!this.$v.userId.$dirty) return errors;
      !this.$v.userId.required && errors.push("user is required.");
      return errors;
    },
  },
  mounted() {
    this.getAttendedVehicles();
    this.loadDriverCurrier();
  },
  methods: {
    ...mapActions(["markDriverAssign", "getAllUsersAttendance"]),
    getAttendedVehicles() {
      axios
        .get(`vehicle-attendance/get-on-duty`)
        .then((response) => {
          const arr = response.data.data;
          this.vehicleData = arr;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
        });
    },
    getVehicleItemText(item) {
      return (
        item.reg_number + " (" + item.type_text + ") - " + item.branch_name
      );
    },
    getUserItemText(item) {
      return item.name + " (" + this.ucFirst(item.role) + ") - " + item.branch;
    },

    loadDriverCurrier() {
      const attend_date = this.today;
      axios
        .get(`user/get-driver-courier?attend_date=${attend_date}`)
        .then((response) => {
          this.userData = response.data.data;
          this.reload();
        });
    },

    async submit() {
      this.$v.$touch();
      this.btnDisable = true;
      this.isLoading = true;
      const date = this.today;
      const user_id = this.userId;
      const vehicle_id = this.vehicleId;
      const toSend = {
        date,
        user_id,
        vehicle_id,
      };

      await this.markDriverAssign(toSend)
        .then(() => {
          this.$toast.success("Record saved successfully!");
          this.isLoading = false;
          this.clear();
        })
        .catch((e) => {
          this.isLoading = false;
          this.$toast.error(e.response.data.message);
        })
        .finally(() => {
          this.btnDisable = false;
          this.isLoading = false;
        });
    },
    clear() {
      this.$v.$reset();
      this.vehicleId = "";
      this.userId = "";
      this.reload();
    },
    reload() {
      this.filter.start = this.today;
      this.filter.end = this.today;
      this.filter.vehicle_id = this.vehicleId;
      this.filter.user_id = this.userId;
      this.fetchDriverAssign();
    },

    fetchDriverAssign() {
      axios
        .post(`vehicle-assign/get-all`, this.filter)
        .then((response) => {
         this.assignedVehicles = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
        });
    },

    cancelRegister() {
      this.$router.push({ name: "driver-assigning-to-vehicles" });
    },
  },
};
</script>
