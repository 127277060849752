<template>
  <Dashboard selectedMainItem="Dashboard" selectedSubItem="">
    <v-card
      id="dashboard-card"
      style="margin: 30px; border: 0"
      :loading="isLoading"
    >
      <v-row class="ml-4">
        <v-col cols="10" class="text-center dashboard-title">{{ title }}</v-col>
        <v-col
          cols="2"
          v-if="this.$store.state.Auth.user.user.has_branch == 1"
          class="text-right"
        >
          <button @click="fullScreen" title="Full Screen">
            <span class="mdi mdi-fullscreen"></span>
          </button>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(item, index) in items"
          :key="index"
          cols="12"
          sm="12"
          md="4"
          class="text-center"
        >
          <v-card class="item-card" :style="{ backgroundColor: item.color }">
            <!-- <v-icon color="white">{{item.icon}}</v-icon><br/> -->
            <p class="title" @click="redirect(item)">{{ item.name }}</p>
            <p class="text">{{ item.count }}</p>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </Dashboard>
</template>

<script>
import Dashboard from "../../components/Layouts/Leftbar";
import axios from "axios";

export default {
  props: [],
  data: () => ({
    items: [],
    title: "",
    isLoading: false,
  }),
  mounted() {
    if (this.$store.state.Auth.user.user.has_branch == 1) {
      this.startTimer();
    } else {
      this.loadData();
    }
  },
  components: {
    Dashboard,
  },
  methods: {
    startTimer() {
      this.loadData();
      setTimeout(() => {
        this.startTimer();
      }, 1000 * 60 * 2);
    },
    loadData() {
      // this.isLoading = true;
      axios
        .get("dashboard/get-data")
        .then((response) => {
          this.title = response.data.title;
          this.items = response.data.items;
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    redirect(item) {
      console.log(item);
    },
    fullScreen() {
      var elem = document.getElementById("dashboard-card");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    tostME() {
      this.$toast.success("I'm a toast!");
    },
  },
};
</script>
<style scoped>
.item-card {
  border: 3px solid #b3d9de87;
  border-radius: 10px;
  color: white;
  min-height: 20vh;
}

#dashboard-card {
  padding: 10px;
}

.title {
  margin-top: 2vh;
  font-size: 5vh !important;
}

.dashboard-title {
  font-size: 30px;
}

.mdi-fullscreen {
  font-size: 30px;
}

.text {
  font-size: 10vh;
}
</style>
