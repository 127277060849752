<template>

<Dashboard
  selectedMainItem = "Administration"
  selectedSubItem = "Route">
  <v-row>
      <v-col cols="12" md="6">
  <v-card
  style="margin:20px;border: 0;"
  :loading="isLoading"
  >
  <v-card-title>
    Route Edit
  </v-card-title>
  <v-card-subtitle>
    edit Route here
    <template>
    <div>
      <v-breadcrumbs
      class="pl-0 pt-0"  
      :items="pageNavItems"></v-breadcrumbs>
    </div>
  </template>
  </v-card-subtitle>
      <form style="margin-top:40px;border: 0;">
        <v-container>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-model="branch"
              :items="allBranches"
              item-text="name"
              item-value="id"
              :error-messages="branchErrors"
              label="Branch"
              solo
              required
              @input="$v.branch.$touch()"
              @blur="$v.branch.$touch()"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="name"
              :error-messages="nameErrors"
              :counter="100"
              label="Route Name"
              solo
              required
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="description"
              :error-messages="descriptionErrors"
              :counter="1000"
              label="Description"
              solo
              required
              @input="$v.description.$touch()"
              @blur="$v.description.$touch()"
            >
            </v-text-field>
          </v-col>
         
          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-model="status"
              :items="routeStatus"
              item-text="name"
              item-value="id"
              :error-messages="statusErrors"
              label="Status"
              solo
              required
              @input="$v.status.$touch()"
              @blur="$v.status.$touch()"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>          
          <v-btn
            class="ma-4"
            @click="submit"
            :color="$v.$anyError ? 'gray' : 'primary'"
            :disabled="btnDisabled"
          >
            Edit
          </v-btn>
          <!-- <v-btn 
          class="mr-4"
          @click="clear">
            clear
          </v-btn> -->
          <v-btn  
          class="ma-4"
          @click="cancelEdit"
          :color="'red'">
          <span class="white--text">
            Cancel
          </span>
          </v-btn>
        </v-row>
        </v-container>
    </form>
  </v-card>
  </v-col>
  </v-row>
</Dashboard>
</template>

<script>
  import { mapActions } from "vuex";
  import { validationMixin } from 'vuelidate'
  import { required, maxLength } from 'vuelidate/lib/validators'
  import Dashboard from '../../components/Layouts/Leftbar'


  export default {
    name:'route-edit',
    mixins: [validationMixin],

    validations: {
      name: { required, maxLength: maxLength(100) },
      description: { required },
      branch: { required },
      status: { required },
    },
    data(){
      return{
        id : '',
        name : '',
        description : '',
        branch : '',
        status : 1,
        isLoading : false,
        pageNavItems: [
        {
          text: 'Dashboard',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
        {
          text: 'Route',
          disabled: false,
          link: true,
          exact: true,
          to: {name: 'route'}
        },
        {
          text: 'Edit',
          disabled: true,
          link: true,
          exact: true,
          to: {name: 'dashboard'}
        },
      ],
      }
    },
    components:{
        Dashboard
    },
    computed: {
      allBranches:{
        get(){
            return this.$store.getters.allBranchesList;
        }
      },
      routeStatus:{
      get(){
          return this.$store.getters.vehicleStatus;
        }
      },
      btnDisabled () {
        const visible = this.$v.$anyError || this.isLoading || !(this.name && this.description && this.branch );
        return visible
      },
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.required && errors.push('Name is required')
        return errors
      },
      descriptionErrors () {
        const errors = []
        if (!this.$v.description.$dirty) return errors
        !this.$v.description.required && errors.push('Description is required.')
        return errors
      },
      branchErrors () {
        const errors = []
        if (!this.$v.branch.$dirty) return errors
        !this.$v.branch.required && errors.push('Branch is required.')
        return errors
      },
      statusErrors () {
        const errors = []
        if (!this.$v.status.$dirty) return errors
        !this.$v.status.required && errors.push('Status is required.')
        return errors
      },
    },
    created(){
      this.getAllBranches();
      this.setEditValues();
    },
    methods: {

      ...mapActions(["routeUpdate","getAllBranches"]),


      async submit () {

        this.$v.$touch();

        this.isLoading = true;

        await this.routeUpdate({
            id:this.id,
            name: this.name,
            description : this.description,
            branch_id : this.branch,
            status : this.status,
        }).then( response =>{

          console.log(response);

          this.$toast.success("Route updated successfully!");
          this.isLoading = false;
          this.cancelEdit();
        }).catch((e)=>{
          
          this.$toast.error(e.response.data.message);
          this.isLoading = false;
        });
      },
      clear () {
        this.$v.$reset()
        this.name = ''
        this.description = ''
        this.branch = ''
        this.status = null
      },
    setEditValues(){
        const editRoute = this.$store.getters.setRoute;
        this.id =  editRoute.id ; 
        this.name =  editRoute.name ; 
        this.description =  editRoute.description ; 
        this.branch =  editRoute.branch.id ; 
        this.status =  editRoute.status ; 
    },
    cancelEdit(){
      this.$router.push({name:'route'});
    }
    },
  }
</script>
