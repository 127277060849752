<template>
  <Dashboard selectedMainItem="Pickup Operation" selectedSubItem="User">
    <v-card style="margin: 30px; border: 0" :loading="isLoading">
      <v-row>
        <v-col align="right" cols="12" sm="6" md="3">
          <v-text-field
            v-model="filter.code"
            label="Code"
            class="ml-5"
            @input="reload()"
            type="text"
            required
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                class="ml-5"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                label="Start Date ( Pickup )"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3"
          ><v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date_end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end"
                class="ml-5"
                label="End Date ( Pickup )"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_end" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(date_end);
                  reload();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col align="right" cols="12" sm="6" md="2">
          <v-btn color="error" class="ma-4" @click="clear">
            <span>Clear</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="tableData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
        :items-per-page="ROWS_PER_PAGE"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="success" @click="viewPackage(item)"> View More </v-btn>
        </template>
      </v-data-table>
      <paginate :paginate="pagination" @paginated="handlePagination" />
    </v-card>
  </Dashboard>
</template>

<script>
import Dashboard from "../../components/Layouts/Leftbar";
import paginate from "../../components/Common/PaginateAxios";
import axios from "axios";
import { ROWS_PER_PAGE } from "@/common";

export default {
  name: "PickupAccepted",
  data() {
    return {
      filter: {
        start: this.date,
        end: this.date_end,
        reg_number: this.vehicleId,
        code: "",
        page: 1,
      },
      search: "",
      calories: "",
      deleteId: "",
      dialogDelete: false,
      isLoading: false,
      date: "",
      date_end: "",
      menu: false,
      menu2: false,
      dialog: false,
      item: {},
      tableData: [],
      pagination: {
        meta: {
          current_page: 1,
        },
      },
      clientType: [
        { name: "corporate", id: 1 },
        { name: "regular", id: 2 },
      ],
      package_type: [
        { name: "Documents", id: 1 },
        { name: "Parcel", id: 2 },
      ],
      vehicleId: "",
      package_id: "",
      client_id: "",
    };
  },
  components: {
    Dashboard,
    paginate,
  },
  computed: {
    headers() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Client name",
          align: "start",
          sortable: false,
          value: "client_name",
        },
        {
          text: "Package Type",
          value: "package_type_text",
          sortable: false,
        },
        {
          text: "Pickup Date",
          value: "package_date",
          sortable: false,
        },
        {
          text: "Courier Name",
          value: "pickup_courier_name",
          sortable: false,
        },
        {
          text: "Pickup Address",
          value: "pickup_address",
          sortable: false,
        },
        {
          text: "Operation",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  mounted() {
    this.fetchPickUpAccepted(this.filter);
  },
  methods: {
    handlePagination(page) {
      this.filter.page = page;
      this.fetchPickUpAccepted(this.filter);
    },
    async fetchPickUpAccepted(filter) {
      axios
        .get("package/get-pickup-accept", {
          params: {
            start: filter.start,
            end: filter.end,
            code: filter.code,
            page: filter.page,
            per_page:ROWS_PER_PAGE
          },
        })
        .then((response) => {
          this.tableData = response.data.package;
          this.pagination = response?.data?.pagination;
        });
    },
    async editPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "pickup-assigned-edit",
        params: { item: item },
      });
    },
    async viewPackage(item) {
      await this.$store.dispatch("getPackageById", item.id);
      this.$router.push({
        name: "package-pending-view",
      });
    },
    reload() {
      const filter = {
        start: this.date,
        end: this.date_end,
        code: this.filter.code,
        client_type: this.client_id,
        package_type: this.package_id,
      };
      this.fetchPickUpAccepted(filter);
    },
    clear() {
      this.filter = {
        code: "",
      };
      this.date = "";
      this.client_id = "";
      this.package_id = "";
      this.date_end = "";
      this.reload();
    },
  },
};
</script>
