<template>
  <div>
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list dense>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
          v-show="checkPermission(item.Permissions)"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"> </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.subtitle"
            :to="{
              path: '/admin/' + subItem.routePath,
              params: { name: subItem.routePath },
            }"
            v-show="checkPermission(subItem.Permissions)"
            :class="selectedSubItem == subItem.subtitle ? '#ffffff' : ' '"
          >
            <v-list-item-content>
              <v-list-item-title v-text="subItem.subtitle"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="blue" dense>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="headline" style="margin-right: 30px">
        <v-avatar>
          <img
            src="@/assets/image/falcon-icon-only-white.png"
            alt="Falcon"
            width="44px"
            height="44px"
          />
        </v-avatar>
        <span class="title white--text">
          <a
            href="/home"
            class="title white--text"
            style="text-decoration: none"
            >Falcon</a
          >
        </span>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-title class="headline" style="margin-right: 30px">
        <span class="user-name">
          {{ this.$store.state.Auth.username }}
        </span>
        <span class="role-header">
          ({{
            authUserRole ? authUserRole.replaceAll("_", " ").toLowerCase() : ""
          }})
        </span>
      </v-toolbar-title>
      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon class="white--text">mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="userInfo()">
            <v-list-item-title>
              <v-icon class="ma-2">mdi-account</v-icon>User Info
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="dialogLogout = true">
            <v-list-item-title>
              <v-icon class="ma-2">mdi-logout</v-icon>Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- ******************* logout user dialog ********************** -->

    <v-dialog v-model="dialogLogout" max-width="200">
      <v-card>
        <v-card-title class="headline">Logout?</v-card-title>
        <v-card-text>You're going to logout</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialogLogout = false"
            >Cancel</v-btn
          >

          <v-btn color="green darken-1" text @click="LogOutSubmit"
            >Logout</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main>
      <slot> </slot>
    </v-main>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AdminDashboard",
  props: ["selectedMainItem", "selectedSubItem"],
  data: () => ({
    showPassword: false,
    drawer: true,
    dialogLogout: false,
    userRoleForAccess: "Temp",
    items: [
      {
        action: "mdi-monitor-shimmer",
        title: "Dashboard",
        active: false,
        Permissions: [],
        items: [
          {
            subtitle: "Dashboard",
            routePath: "dashboard",
            Permissions: [],
          },
        ],
        url: "",
      },
      {
        action: "mdi-sitemap-outline",
        title: "Operation",
        active: false,
        Permissions: [
          "route-assign-view",
          "all-package-view",
          "courier-individual-report-view",
        ],
        items: [
          {
            subtitle: "Route Assign",
            routePath: "route-assign",
            Permissions: "route-assign-view",
          },
          {
            subtitle: "All Packages",
            routePath: "all-packages",
            Permissions: "all-package-view",
          },
          {
            subtitle: "Courier Individual Report",
            routePath: "courier-individual-report",
            Permissions: "courier-individual-report-view",
          },
          {
            subtitle: "Admin Packages",
            routePath: "admin-packages",
            Permissions: "admin-packages-view",
          },
        ],
        url: "",
      },
      {
        action: "mdi-package-variant-plus",
        title: "Pickup Operation",
        active: false,
        Permissions: [
          "pending-package-view",
          "pickup-assigned-staff-view",
          "pickup-accepted-view",
          "receive-package-view",
          "sort-package-view",
          "daily-pickup-report-view",
        ],
        items: [
          {
            subtitle: "Pickup Pending",
            routePath: "pickup-pending",
            Permissions: "pending-package-view",
          },
          {
            subtitle: "Pickup Assigned",
            routePath: "pickup-assigned-staff",
            Permissions: "pickup-assigned-staff-view",
          },
          {
            subtitle: "Pickup Accepted",
            routePath: "pickup-accepted",
            Permissions: "pickup-accepted-view",
          },
          {
            subtitle: "Receive Packages",
            routePath: "receive-package",
            Permissions: "receive-package-view",
          },
          {
            subtitle: "Review Packages",
            routePath: "review-packages",
            Permissions: "customer-view",
          },
          {
            subtitle: "Sort",
            routePath: "sort-package",
            Permissions: "sort-package-view",
          },
          {
            subtitle: "Daily Pickup Report",
            routePath: "daily-pickup",
            Permissions: "daily-pickup-report-view",
          },
        ],
        url: "",
      },
      {
        action: "mdi-atv",
        title: "Courier Operation",
        active: false,
        Permissions: ["courier-screen-view"],
        items: [
          {
            subtitle: "Pickup Assigned",
            routePath: "pickup-assigned-staff",
            Permissions: "courier-screen-view",
          },
          {
            subtitle: "Delivery Assigned",
            routePath: "delivery-assigned",
            Permissions: "courier-screen-view",
          },
          {
            subtitle: "Courier Screen",
            routePath: "courier-screen",
            Permissions: "courier-screen-view",
          },
        ],
        url: "",
      },
      {
        action: "mdi-truck-fast",
        title: "Transfer Operation",
        active: false,
        Permissions: [
          "transfer-pending-view",
          "transfer-view",
          "transfer-report-view",
        ],
        items: [
          {
            subtitle: "Pending Transfer",
            routePath: "pending-transfer",
            Permissions: "transfer-pending-view",
          },
          {
            subtitle: "Transfer",
            routePath: "transfer",
            Permissions: "transfer-view",
          },
          {
            subtitle: "Transfer In",
            routePath: "transfer-in",
            Permissions: "transfer-pending-view",
          },
          {
            subtitle: "Transfer Report",
            routePath: "transfer-report",
            Permissions: "transfer-report-view",
          },
        ],
        url: "",
      },
      {
        action: "mdi-package-variant-minus",
        title: "Delivery Operation",
        active: false,
        Permissions: [
          "delivery-assign-view",
          "delivery-assigned-view",
          "delivery-view",
          "daily-delivery-report-view",
        ],
        items: [
          {
            subtitle: "Delivery Pending",
            routePath: "delivery-pending",
            Permissions: "delivery-assign-view",
          },
          {
            subtitle: "Delivery Assigned",
            routePath: "delivery-assigned",
            Permissions: "delivery-assigned-view",
          },
          {
            subtitle: "Delivery Accepted",
            routePath: "delivery-accepted",
            Permissions: "delivery-view",
          },
          {
            subtitle: "Daily Delivery Report",
            routePath: "daily-delivery",
            Permissions: "daily-delivery-report-view",
          },
        ],
        url: "",
      },
      {
        action: "mdi-clipboard-arrow-left",
        title: "Return Operation",
        active: false,
        Permissions: [
          "return-pending"
        ],
        items: [
          {
            subtitle: "Return Pending",
            routePath: "return-pending",
            Permissions: "return-pending",
          },
          //     {
          //       subtitle: "Return Assigned",
          //       routePath: "return-assigned",
          //       Permissions: "return-assigned",
          //     },
          //     {
          //       subtitle: "Return Accepted",
          //       routePath: "return-accepted",
          //       Permissions: "return-accepted",
          //     },
          //     {
          //       subtitle: "Returned",
          //       routePath: "returned",
          //       Permissions: "returned",
          //     },
        ],
        url: "",
      },
      // {
      //   action: "mdi-file-powerpoint",
      //   title: "Bulk Operation",
      //   active: false,
      //   Permissions: ["customer-view"],
      //   items: [
      //     {
      //       subtitle: "Excel Upload",
      //       routePath: "excel-upload",
      //       Permissions: "customer-view",
      //     },
      //   ],
      //   url: "",
      // },
      {
        action: "mdi-bus-multiple",
        title: "Transport",
        active: false,
        Permissions: [
          "vehicle-view",
          "vehicle-attendance-view",
          "driver-assign-view",
          "fuel-issue-view",
          "fuel-issue-report-view",
          "vehicle-report-view",
          "vehicle-attendance-report-view",
        ],
        items: [
          {
            subtitle: "Vehicle",
            routePath: "vehicle",
            Permissions: "vehicle-view",
          },
          {
            subtitle: "Vehicle Attendance",
            routePath: "vehicle-attendance",
            Permissions: "vehicle-attendance-view",
          },
          {
            subtitle: "Driver assigning",
            routePath: "driver-assigning-to-vehicles",
            Permissions: "driver-assign-view",
          },
          {
            subtitle: "Fuel Issuing",
            routePath: "fuel-issuing",
            Permissions: "fuel-issue-view",
          },
          {
            subtitle: "Fuel issue Report",
            routePath: "fuel-report",
            Permissions: "fuel-issue-report-view",
          },
          {
            subtitle: "Vehicle Report",
            routePath: "vehicle-report",
            Permissions: "vehicle-report-view",
          },
          {
            subtitle: "Vehicle Attendance Report",
            routePath: "vehicle-attendance-report",
            Permissions: "vehicle-attendance-report-view",
          },
        ],
        url: "",
      },
      {
        action: "mdi-account-group",
        title: "Human Resource",
        active: false,
        Permissions: [
          "user-view",
          "staff-attendance-view",
          "staff-attendance-report-view",
          "daily-pickup-report-view",
          "attendance-report-view",
        ],
        items: [
          { subtitle: "Users", routePath: "user", Permissions: "user-view" },
          {
            subtitle: "Staff attendance",
            routePath: "staff-attendance",
            Permissions: "staff-attendance-view",
          },
          {
            subtitle: "Staff Report",
            routePath: "staff-report",
            Permissions: "staff-attendance-report-view",
          },
          {
            subtitle: "Attendance Report",
            routePath: "staff-attendance-report",
            Permissions: "attendance-report-view",
          },
        ],
        url: "",
      },
      {
        action: "mdi-cash-multiple",
        title: "Finance",
        active: false,
        Permissions: ["payment-view", "payment-report-view"],
        items: [
          {
            subtitle: "Payments",
            routePath: "payment",
            Permissions: "payment-view",
          },
          {
            subtitle: "Pay COD",
            routePath: "cod-payments",
            Permissions: "cod-payments",
          },
          {
            subtitle: "Payment Report",
            routePath: "payment-report",
            Permissions: "payment-report-view",
          },
        ],
        url: "",
      },
      {
        action: "mdi-account-tie",
        title: "Administration",
        active: false,
        Permissions: [
          "location-view",
          "branch-view",
          "branch-area-view",
          "route-view",
          "faq-view",
          "pricing-view",
          "setting-view",
          "customer-view",
        ],
        items: [
          {
            subtitle: "Locations",
            routePath: "location",
            Permissions: "location-view",
          },
          {
            subtitle: "Branch",
            routePath: "branch",
            Permissions: "branch-view",
          },
          {
            subtitle: "Branch Areas",
            routePath: "branch-areas",
            Permissions: "branch-area-view",
          },
          {
            subtitle: "Branch Groups",
            routePath: "branch-group",
            Permissions: "branch-area-view",
          },
          { subtitle: "Routes", routePath: "route", Permissions: "route-view" },
          { subtitle: "FAQ", routePath: "faq", Permissions: "faq-view" },
          {
            subtitle: "Customers",
            routePath: "customers",
            Permissions: "customer-view",
          },
          {
            subtitle: "Pricing",
            routePath: "price",
            Permissions: "customer-view",
          },
          {
            subtitle: "Settings",
            routePath: "settings",
            Permissions: "customer-view",
          },
        ],
      },
      {
        action: "mdi-finance",
        title: "Reports",
        active: false,
        Permissions: ["courier-report-view"],
        items: [
          {
            subtitle: "Courier  Report",
            routePath: "courier-report",
            Permissions: "courier-report-view",
          },
        ],
      },
    ],
  }),
  methods: {
    ...mapActions(["logout"]),

    async LogOutSubmit() {
      await this.logout()
        .then((response) => {
          console.log("click logout");

          console.log(response);

          this.$router.push("/");
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
        });
    },
    checkPermission(permissions) {
      return this.hasAnyPermission(permissions);
      // const userPermission = this.$store.getters.authUser.permissions;
      // return (userPermission.includes(permission));
    },
    setActiveMainMenu() {
      const specifiedMainItems = this.selectedMainItem.split(",");
      specifiedMainItems.forEach((specifiedMainItem) => {
        var mainItem = this.items.find(
          (item) => item.title == specifiedMainItem
        );
        mainItem.active = true;
      });
    },
  },
  computed: {
    authUserRole() {
      return this.$store?.state?.Auth?.user?.role[0];
    },
  },
  created() {
    this.setActiveMainMenu();
  },
};
</script>

<style scoped>
.role-header {
  font-size: 11px;
  color: #ffffffc4;
}
.user-name {
  font-size: 15px;
  color: #ffffffc4;
}
</style>
