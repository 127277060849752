import Vue from "vue";
import Router from "vue-router";
import AdminLogin from "../Pages/Login/AdminLogin.vue";
import AdminDashboard from "../Pages/Dashboard/AdminDashboard";
import vehicleAll from "../Pages/Transport/Vehicle/List";
import vehicleRegister from "../Pages/Transport/Vehicle/Create";
import vehicleEdit from "../Pages/Transport/Vehicle/Edit";
import Branch from "../Pages/Branch/List";
import BranchEdit from "../Pages/Branch/Edit";
import BranchView from "../Pages/Branch/View";
import BranchCreate from "../Pages/Branch/Create";
import Faq from "../Pages/Faq/List";
import FaqCreate from "../Pages/Faq/Create";
import FaqEdit from "../Pages/Faq/Edit";
import User from "../Pages/User/List";
import Customer from "../Pages/Customer/List";
import CustomerEdit from "../Pages/Customer/Edit";
import UserCreate from "../Pages/User/Create";
import UserEdit from "../Pages/User/Edit";
import Attendance from "../Pages/Attendance/List";
import AttendanceCreate from "../Pages/Attendance/Create";
import VehicleAttendance from "../Pages/VehicleAttendance/List";
import VehicleAttendanceCreate from "../Pages/VehicleAttendance/Create";
import Location from "../Pages/Location/List";
import LocationCreate from "../Pages/Location/Create";
import LocationEdit from "../Pages/Location/Edit";
import Route from "../Pages/Route/List";
import RouteView from "../Pages/Route/View";
import RouteCreate from "../Pages/Route/Create";
import RouteCityCreate from "../Pages/Route/RouteCity/Create";
import BranchAreas from "../Pages/Branch/BranchAreas/Create";
import RouteEdit from "../Pages/Route/Edit";
import Price from "../Pages/Pricing/List";
import PriceEdit from "../Pages/Pricing/Edit";
import Fuel from "../Pages/Fuel/List.vue";
import FuelCreate from "../Pages/Fuel/Create";
import RouteAssign from "../Pages/RouteAssign/List";
import RouteAssignCreate from "../Pages/RouteAssign/Create";
import DriverAssign from "../Pages/DriverAssign/List.vue";
import DriverAssignCreate from "../Pages/DriverAssign/Create";
import PendingPackages from "../Pages/PickupPending/List.vue";
import PendingPackagesEdit from "../Pages/PickupPending/Create.vue";
import PendingPackagesView from "../Pages/Package/View.vue";
import PickupAccepted from "../Pages/PickupAccepted/List.vue";
import PickupAssignedCreate from "../Pages/PickupAccepted/Create.vue";
import ReceivePackage from "../Pages/ReceivePackage/List.vue";
import Sorting from "../Pages/Sorting/List.vue";
import DeliveryAssigned from "../Pages/DeliveryAssigned/List.vue";
import DeliveryAssignedCreate from "../Pages/DeliveryAssigned/Create.vue";
import AllPackages from "../Pages/AllPackages/List.vue";
import SuperAdminPackageOverride from "../Pages/SuperAdminPackageOverride/Override.vue";
import DeliveryAccepted from "../Pages/DeliveryAccepted/List.vue";
import MarkDelivery from "../Pages/DeliveryAccepted/Create.vue";
import DeliveryPending from "../Pages/DeliveryPending/List.vue";
import Payment from "../Pages/Payment/List.vue";
import PaymentCreate from "../Pages/Payment/Create.vue";
import Transfer from "../Pages/Transfer/List.vue";
import TransferView from "../Pages/Transfer/View.vue";
import PendingTransfer from "../Pages/PendingTransfer/List.vue";
import TransferIn from "../Pages/TransferIn/List.vue";
import TransferCreate from "../Pages/Transfer/Create.vue";
import TransferEdit from "../Pages/Transfer/Edit.vue";
import Settings from "../Pages/SystemSettings/List.vue";
import SettingsEdit from "../Pages/SystemSettings/Edit.vue";
import ReportDailyPickup from "../Pages/ReportDailyPickup/List.vue";
import ReportDailyDelivery from "../Pages/Reports/DailyDelivery/List.vue";

import ReportCourierIndividual from "../Pages/ReportCourierIndividual/List.vue";

import ReportStaff from "../Pages/ReportStaff/List.vue";
import ReportStaffAttendance from "../Pages/ReportStaffAttendance/List.vue";
import ReportVehicleAttendance from "../Pages/ReportVehicleAttendance/List.vue";
import ReportFuel from "../Pages/ReportFuel/List.vue";
import ReportPayment from "../Pages/ReportPayment/List.vue";
import ReportTransfer from "../Pages/ReportTransfer/List.vue";
import ReportVehicle from "../Pages/ReportVehicle/List.vue";
import Leave from "../Pages/Leave/List.vue";
import PickupAssignedStaff from "../Pages/PickupAssignedStaff/List.vue";
import ReportCourier from "../Pages/ReportCourier/List.vue";
import PriceStore from "../Pages/Pricing/Store";
import AdminPackagesList from "../Pages/AdminPackage/List.vue"
import AdminPackagesAdd from "../Pages/AdminPackage/Add";
import EditPackageOperationList from "../Pages/ReviewPackage/List.vue";
import EditPackageOperation from "../Pages/ReviewPackage/Edit.vue";
import BranchGroupList from "../Pages/Branch/BranchGroup/List.vue";
import BranchGroupCreate  from "../Pages/Branch/BranchGroup/Create.vue";
import BranchGroupUpdate  from "../Pages/Branch/BranchGroup/Edit.vue";
import BranchGroupAssign from "../Pages/Branch/BranchGroup/BranchAssign.vue";
import CodPayments from "../Pages/CodPayments/List.vue";
import DeliveryReturn from "../Pages/DeliveryReturn/List.vue"
import CourierScreen from "../Pages/CourierScreen/List.vue"
import CourierPickupAssigned from "../Pages/CourierPickupAssigned/List.vue"
import ReturnPending from "../Pages/ReturnPending/List";
import ReturnAssign from "../Pages/ReturnAssigned/List";
import ReturnAccepted from "../Pages/ReturnAccepted/List";
import Returned from "../Pages/Returned/List.vue";
import ExcelUpload from "../Pages/ExcelUpload/Create.vue"
// authentication guards
function auth(to, from, next) {
  if (localStorage.getItem("isLogin") == "true") {
    next();
  } else {
    next("/");
  }
}

function guest(to, from, next) {
  if (localStorage.getItem("isLogin") == "true") {
    next("/admin/dashboard");
  } else {
    next();
  }
}

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: AdminLogin,
      name: "login",
      beforeEnter: guest,
    },
    {
      path: "/admin/dashboard",
      component: AdminDashboard,
      name: "dashboard",
      beforeEnter: auth,
    },
    //Transport
    {
      path: "/admin/vehicle",
      component: vehicleAll,
      name: "vehicle",
      beforeEnter: auth,
    },
    {
      path: "/admin/vehicle/register",
      component: vehicleRegister,
      name: "vehicle-register",
      beforeEnter: auth,
    },
    {
      path: "/admin/vehicle/edit",
      component: vehicleEdit,
      name: "vehicle-edit",
      beforeEnter: auth,
    },
    //branch
    {
      path: "/admin/branch",
      component: Branch,
      name: "branch",
      beforeEnter: auth,
    },
    {
      path: "/admin/branch/edit",
      component: BranchEdit,
      name: "branch-edit",
      beforeEnter: auth,
    },
    {
      path: "/admin/branch/view",
      component: BranchView,
      name: "branch-view",
      beforeEnter: auth,
    },
    {
      path: "/admin/branch/create",
      component: BranchCreate,
      name: "branch-create",
      beforeEnter: auth,
    },
    {
      path: "/admin/branch-areas",
      component: BranchAreas,
      name: "branch-areas",
      beforeEnter: auth,
    },
    {
      path: "/admin/branch-group",
      component: BranchGroupList,
      name: "branch-group",
      beforeEnter: auth,
    },
    {
      path: "/admin/branch-group/create",
      component: BranchGroupCreate,
      name: "branch-group-create",
      beforeEnter: auth,
    },
    {
      path: "/admin/branch-group/update",
      component: BranchGroupUpdate,
      name: "branch-group-update",
      beforeEnter: auth,
    },
    {
      path: "/admin/branch-group/assign",
      component: BranchGroupAssign,
      name: "branch-group-branch-assign",
      beforeEnter: auth,
    },
    //Faq
    {
      path: "/admin/faq",
      component: Faq,
      name: "faq",
      beforeEnter: auth,
    },
    {
      path: "/admin/faq/edit",
      component: FaqEdit,
      name: "faq-edit",
      beforeEnter: auth,
    },
    {
      path: "/admin/faq/create",
      component: FaqCreate,
      name: "faq-create",
      beforeEnter: auth,
    },
    //Settings
    {
      path: "/admin/settings",
      component: Settings,
      name: "settings",
      beforeEnter: auth,
    },
    {
      path: "/admin/settings/edit",
      component: SettingsEdit,
      name: "settings-edit",
      beforeEnter: auth,
    },
    //User
    {
      path: "/admin/customers",
      component: Customer,
      name: "customers",
      beforeEnter: auth,
    },
    {
      path: "/admin/customer-edit",
      component: CustomerEdit,
      name: "customer-edit",
      beforeEnter: auth,
    },
    {
      path: "/admin/user",
      component: User,
      name: "user",
      beforeEnter: auth,
    },
    {
      path: "/admin/user/edit",
      component: UserEdit,
      name: "user-edit",
      beforeEnter: auth,
    },
    {
      path: "/admin/user/create",
      component: UserCreate,
      name: "user-create",
      beforeEnter: auth,
    },
    //Attendance
    {
      path: "/admin/staff-attendance",
      component: Attendance,
      name: "attendance",
      beforeEnter: auth,
    },
    {
      path: "/admin/staff-attendance/edit",
      name: "staff-attendance-edit",
      beforeEnter: auth,
    },
    {
      path: "/admin/staff-attendance/create",
      component: AttendanceCreate,
      name: "staff-attendance-create",
      beforeEnter: auth,
    },
    //Vehicle Attendance
    {
      path: "/admin/vehicle-attendance",
      component: VehicleAttendance,
      name: "vehicle-attendance",
      beforeEnter: auth,
    },
    {
      path: "/admin/vehicle-attendance/create",
      component: VehicleAttendanceCreate,
      name: "vehicle-attendance-create",
      beforeEnter: auth,
    },
    //fuel
    {
      path: "/admin/fuel-issuing",
      component: Fuel,
      name: "fuel-issuing",
      beforeEnter: auth,
    },
    {
      path: "/admin/fuel-issuing/create",
      component: FuelCreate,
      name: "fuel-issuing-create",
      beforeEnter: auth,
    },
    {
      path: "/admin/driver-assigning-to-vehicles",
      component: DriverAssign,
      name: "driver-assigning-to-vehicles",
      beforeEnter: auth,
    },
    {
      path: "/admin/driver-assigning-to-vehicles/create",
      component: DriverAssignCreate,
      name: "driver-assigning-create",
      beforeEnter: auth,
    },
    //Route Assign
    {
      path: "/admin/route-assign",
      component: RouteAssign,
      name: "route-assign",
      beforeEnter: auth,
    },

    {
      path: "/admin/route-assign/create",
      component: RouteAssignCreate,
      name: "route-assign-create",
      beforeEnter: auth,
    },
    //pending delivery
    {
      path: "/admin/pickup-pending",
      component: PendingPackages,
      name: "pickup-pending",
      beforeEnter: auth,
    },
    {
      path: "/admin/package-status/pending-assign",
      component: PendingPackagesEdit,
      name: "package-pending-assign",
      beforeEnter: auth,
    },
    {
      path: "/admin/deliveries/view",
      component: PendingPackagesView,
      name: "package-pending-view",
      beforeEnter: auth,
    },
    {
      path: "/admin/delivery-return",
      component: DeliveryReturn,
      name: "delivery-return",
      beforeEnter: auth,
    },
    //PickupAssigned

    {
      path: "/admin/pickup-accepted",
      component: PickupAccepted,
      name: "pickup-accepted",
      beforeEnter: auth,
    },
    {
      path: "/admin/courier-screen",
      component: CourierScreen,
      name: "courier-screen",
      beforeEnter: auth,
    },
    {
      path: "/admin/pickup-assigned/update",
      component: PickupAssignedCreate,
      name: "pickup-assigned-edit",
      beforeEnter: auth,
    },
    {
      path: "/courier/pickup-assigned",
      component: CourierPickupAssigned,
      name: "pickup-accepted-courier-view",
      beforeEnter: auth,
    },
    //Collected
    {
      path: "/admin/receive-package",
      component: ReceivePackage,
      name: "receive-package",
      beforeEnter: auth,
    },
    //sorting
    {
      path: "/admin/sort-package",
      component: Sorting,
      name: "sort-package",
      beforeEnter: auth,
    },
    //DeliveryAssigned
    {
      path: "/admin/delivery-assigned",
      component: DeliveryAssigned,
      name: "delivery-assigned",
      beforeEnter: auth,
    },
    {
      path: "/admin/delivery-assign",
      component: DeliveryAssignedCreate,
      name: "delivery-assign",
      beforeEnter: auth,
    },
    {
      path: "/admin/pickup-assigned-staff",
      component: PickupAssignedStaff,
      name: "pickup-assigned-staff",
      beforeEnter: auth,
    },
     //Return
     {
      path: "/admin/return-pending",
      component: ReturnPending,
      name: "return-pending",
      beforeEnter: auth,
    },
    {
      path: "/admin/return-assigned",
      component: ReturnAssign,
      name: "return-assigned",
      beforeEnter: auth,
    },
    {
      path: "/admin/return-accepted",
      component: ReturnAccepted,
      name: "return-accepted",
      beforeEnter: auth,
    },
    {
      path: "/admin/returned",
      component: Returned,
      name: "returned",
      beforeEnter: auth,
    },
    //AllPackages
    {
      path: "/admin/all-packages",
      component: AllPackages,
      name: "all-packages",
      beforeEnter: auth,
    },
    {
      path: "/admin/super-admin-override-packages",
      component: SuperAdminPackageOverride,
      name: "super-admin-override-packages",
      beforeEnter: auth,
    },
    {
      path: "/admin/admin-packages/add",
      component: AdminPackagesAdd,
      name: "admin-packages-add",
      beforeEnter: auth,
    },
    {
      path: "/admin/review-packages",
      component: EditPackageOperationList,
      name: "review-packages",
      beforeEnter: auth,
    },
    {
      path: "/admin/operation-edit-packages",
      component: EditPackageOperation,
      name: "operation-edit-packages",
      beforeEnter: auth,
    },
    {
      path: "/admin/edit-admin-packages",
      component: AdminPackagesAdd,
      name: "edit-admin-packages",
      beforeEnter: auth,
    },
    {
      path: "/admin/admin-packages",
      component: AdminPackagesList,
      name: "admin-packages",
      beforeEnter: auth,
    },
    //Delivery
    {
      path: "/admin/delivery-accepted",
      component: DeliveryAccepted,
      name: "delivery-accepted",
      beforeEnter: auth,
    },
    {
      path: "/admin/mark-delivery",
      component: MarkDelivery,
      name: "mark-delivery",
      beforeEnter: auth,
    },

    //Assigning
    {
      path: "/admin/delivery-pending",
      component: DeliveryPending,
      name: "delivery-pending",
      beforeEnter: auth,
    },

    //location
    {
      path: "/admin/location",
      component: Location,
      name: "location",
      beforeEnter: auth,
    },
    {
      path: "/admin/location/edit",
      component: LocationEdit,
      name: "location-edit",
      beforeEnter: auth,
    },
    {
      path: "/admin/location/create",
      component: LocationCreate,
      name: "location-create",
      beforeEnter: auth,
    },
    //Route
    {
      path: "/admin/route",
      component: Route,
      name: "route",
      beforeEnter: auth,
    },
    {
      path: "/admin/route/edit",
      component: RouteEdit,
      name: "route-edit",
      beforeEnter: auth,
    },
    {
      path: "/admin/route/create",
      component: RouteCreate,
      name: "route-create",
      beforeEnter: auth,
    },
    {
      path: "/admin/route/view",
      component: RouteView,
      name: "route-view",
      beforeEnter: auth,
    },
    {
      path: "/admin/route/city-assign",
      component: RouteCityCreate,
      name: "route-city-assign",
      beforeEnter: auth,
    },
    //Pricing
    {
      path: "/admin/price",
      component: Price,
      name: "price",
      beforeEnter: auth,
    },
    {
      path: "/admin/price/edit",
      component: PriceEdit,
      name: "price-edit",
      beforeEnter: auth,
    },
    //Payment
    {
      path: "/admin/payment",
      component: Payment,
      name: "payment",
      beforeEnter: auth,
    },
    {
      path: "/admin/payment/edit",
      component: PaymentCreate,
      name: "payment-edit",
      beforeEnter: auth,
    },
    //Transfer
    {
      path: "/admin/transfer",
      component: Transfer,
      name: "transfer",
      beforeEnter: auth,
    },
    {
      path: "/admin/transfer-view",
      component: TransferView,
      name: "transfer-view",
      beforeEnter: auth,
    },
    {
      path: "/admin/transfer-create",
      component: TransferCreate,
      name: "transfer-create",
      beforeEnter: auth,
    },
    {
      path: "/admin/transfer-edit",
      component: TransferEdit,
      name: "transfer-edit",
      beforeEnter: auth,
    },
    {
      path: "/admin/pending-transfer",
      component: PendingTransfer,
      name: "pending-transfer",
      beforeEnter: auth,
    },
    {
      path: "/admin/transfer-in",
      component: TransferIn,
      name: "transfer-in",
      beforeEnter: auth,
    },
    //reports
    {
      path: "/admin/daily-pickup",
      component: ReportDailyPickup,
      name: "daily-pickup",
      beforeEnter: auth,
    },
    {
      path: "/admin/daily-delivery",
      component: ReportDailyDelivery,
      name: "daily-delivery",
      beforeEnter: auth,
    },
    {
      path: "/admin/courier-individual-report",
      component: ReportCourierIndividual,
      name: "courier-individual-report",
      beforeEnter: auth,
    },
    {
      path: "/admin/staff-report",
      component: ReportStaff,
      name: "staff-report",
      beforeEnter: auth,
    },
    {
      path: "/admin/staff-attendance-report",
      component: ReportStaffAttendance,
      name: "staff-attendance-report",
      beforeEnter: auth,
    },
    {
      path: "/admin/vehicle-attendance-report",
      component: ReportVehicleAttendance,
      name: "vehicle-attendance-report",
      beforeEnter: auth,
    },
    {
      path: "/admin/fuel-report",
      component: ReportFuel,
      name: "fuel-report",
      beforeEnter: auth,
    },
    {
      path: "/admin/payment-report",
      component: ReportPayment,
      name: "payment-report",
      beforeEnter: auth,
    },
    {
      path: "/admin/transfer-report",
      component: ReportTransfer,
      name: "transfer-report",
      beforeEnter: auth,
    },
    {
      path: "/admin/leave",
      component: Leave,
      name: "leave",
      beforeEnter: auth,
    },
    {
      path: "/admin/vehicle-report",
      component: ReportVehicle,
      name: "vehicle-report",
      beforeEnter: auth,
    },
    {
      path: "/admin/courier-report",
      component: ReportCourier,
      name: "courier-report",
      beforeEnter: auth,
    },
    {
      path: "/admin/price/store",
      component: PriceStore,
      name: "price-store",
      beforeEnter: auth,
    },
    {
      path: "/admin/cod-payments",
      component: CodPayments,
      name: "cod-payments",
      beforeEnter: auth,
    },
    {
      path: "/admin/excel-upload",
      component: ExcelUpload,
      name: "excel-upload",
      beforeEnter: auth,
    },
  ],
});

export default router;
